import React from "react";
import { Grow, Dialog, DialogTitle, DialogContent, IconButton, Breakpoint, Typography } from "@mui/material";
import { css, cx } from "@emotion/css";
import { TransitionProps } from "@mui/material/transitions";
import { Button } from "@prescientai/component-library";
import { MediaBlender } from "../../../utils/mediaBlender";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const modalClass = (width: string | undefined, headerSize: string, padding: boolean, disableMarginTop: boolean) => css`
    .MuiPaper-root {
        z-index: 100000000000;
        background-color: #ffffff;
        border: 0.5px solid rgba(255, 255, 255, 0.12);
        box-shadow: none;
        border-radius: 8px;
        padding: ${padding ? "0 40px" : "0"};
        width: ${width ? width : "408px"};
        top: 56px;
        ${MediaBlender.down("md")(`
            padding: 0 25px !important;
            top: 112px;
        `)}

        div.heading {
            margin-top: 40px;
            font-size: ${headerSize === HeaderSize.NORMAL ? "28px" : "22px"};
            line-height: ${headerSize === HeaderSize.NORMAL ? "32px" : "24px"};
            font-weight: normal;
            text-transform: capitalize;
            color: #182026;
            ${MediaBlender.down("md")(`
                margin-top: 25px !important;
                font-size: 22px;
            `)}
        }

        .customized-dialog-title {
            margin-top: 20px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
        }

        .customized-dialog-title .MuiIconButton-root {
            color: rgb(0, 0, 0);
            ${MediaBlender.down("md")(`
                    margin-top: 25px !important;
            `)}
        }

        .MuiDialogContent-root {
            padding: 0;
            overflow: hidden;
        }

        .modalBody {
            margin: ${!disableMarginTop ? `40px 0px` : `20px 0 40px 0px`};
            background: rgb(255, 255, 255);
        }
    }
`;

interface IModalProps {
    isOpen: boolean;
    showFooter?: boolean;
    canOutsideClickClose?: boolean;
    padding?: boolean;
    className?: string;
    title?: string;
    content: any;
    headerSize?: string;
    width?: string;
    maxWidth?: Breakpoint;
    disableMarginTop?: boolean;
    onClose?(...args: any[]): any;
    onSubmit?(...args: any[]): any;
}

export enum HeaderSize {
    SMALL = "small",
    NORMAL = "normal",
}

const DialogTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Grow in={true} ref={ref} {...props} />;
});

export const Modal: React.FC<IModalProps> = ({
    isOpen,
    title,
    content,
    showFooter,
    canOutsideClickClose = true,
    className,
    onClose,
    onSubmit,
    width,
    headerSize = HeaderSize.NORMAL,
    padding = true,
    disableMarginTop = false,
    maxWidth = "sm",
}) => {
    return (
        <Dialog
            open={isOpen}
            TransitionComponent={DialogTransition}
            keepMounted
            onClose={canOutsideClickClose ? onClose : () => {}}
            scroll={"body"}
            aria-describedby="alert-dialog-slide-description"
            className={cx(modalClass(width, headerSize, padding, disableMarginTop), className)}
            style={{ zIndex: 20 }}
            maxWidth={maxWidth}
        >
            <DialogTitle {...({ component: "div" } as any)} className="customized-dialog-title">
                <Typography variant="h3">{title}</Typography>
                <div>
                    <IconButton onClick={onClose} size="small">
                        <SVGIconRenderer icon="closeIcon" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers={false}>
                <div className="modalBody">{content}</div>
                {showFooter && (
                    <div>
                        <Button onClick={onClose}>Close</Button>
                        <Button onClick={onSubmit}>Save</Button>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
