import React, { FC, useEffect } from "react";
import { CardHeader, DialogContent, IconButton, Paper, Theme, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

import { selectCurrentlyViewingCode } from "../../../reduxState/slices/organizationSlice";
import { PerformanceTabHolderComponent } from "./PerformanceTab/PerformanceTabHolderComponent";
import { ActivePerformanceTabs, ActivePerformanceTabsForEvent } from "../../../enums/ActivePerfomanceTabs";
import {
    handleInsightsModalMinimization,
    closeInsightsmodal,
    insightsModalSelector,
    setInsightsModalActiveTab,
} from "../../../reduxState/slices/insightsModalSlice";
import { handleDeleteParamKey } from "../../../services/performancePage/performancePage";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../services/utils";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";

export interface IInsightsModal {
    modalInfo: {
        data: IMetricAttributionTableValuesTransformed;
        isMinimize: boolean;
        activeTab: ActivePerformanceTabs;
        activeMetric: string;
    };
}
export const useStylesForInsightsModal = (theme: Theme) => ({
    modalRoot: css({
        padding: "0",
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            width: "100% !important",
            height: "100%",
        },
    }),
    miniMizeClass: css({
        display: "none",
    }),
    dialogPosition: css({
        borderRadius: "4px 4px 0px 0px",
        width: "616px",
        height: "90vh",
        margin: 0,
        background: "white",
        boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            top: 0,
            right: 0,
            bottom: "unset",
            left: "unset",
            height: "100vh",
        },
    }),
    extraClass: css({
        width: "260px !important",
        height: "44px",
        position: "absolute",
        bottom: 0,
        "& div .MuiCardHeader-root .MuiCardHeader-content span": {
            fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
            top: "auto",
            bottom: 0,
        },
    }),
    heightClass: css({
        height: "100%",
        [theme.breakpoints.down("md")]: {
            maxHeight: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            height: "calc(100% - 20px)",
        },
    }),
    cardHeader: css({
        height: "44px",
        padding: "10px 16px",
        background: theme.palette.primary.main,
        color: "white",
        cursor: "pointer",
        top: 0,
        zIndex: 1,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",

        "& .MuiCardHeader-action": { color: "#BDBDBD" },
        "& .MuiCardHeader-content": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        "& .MuiCardHeader-content span": {
            fontSize: "16px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    }),
});

export const InsightsModal: FC<IInsightsModal> = ({ modalInfo }) => {
    const dispatch = useDispatch();
    const { data, isMinimize, activeTab } = modalInfo;
    const { campaignName, campaignId } = data;

    const theme = useTheme();
    const classes = useStylesForInsightsModal(theme);
    const insightsModalList = useSelector(insightsModalSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const navigate = useNavigate();
    const analyticsService = useAnalyticsService();

    const resizeWindow = () => {
        const windowSize = window.innerWidth;
        if (insightsModalList.length >= 2 && windowSize < 1300) {
            dispatch(closeInsightsmodal(insightsModalList[1].data.campaignId));
        }
    };

    useEffect(() => {
        // if window size < 1300 then close last opened modal
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        dispatch(
            setInsightsModalActiveTab({
                campaignId,
                activeTab,
            }),
        );
    }, [activeTab]);

    const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleDeleteParamKey("viewing[]", navigate);
        dispatch(closeInsightsmodal(data.campaignId));
    };

    const handleMinimizeModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(handleInsightsModalMinimization(data.campaignId));
    };

    const handleTabChangeEvent = (newValue: ActivePerformanceTabs) => {
        analyticsService.logEvent("Performance Detail Page Viewed", {
            Page: getPathForAnalytics(window.location.pathname, window.location.search),
            "Detail page": ActivePerformanceTabsForEvent[newValue],
        });
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => {
        handleTabChangeEvent(newValue);
        dispatch(
            setInsightsModalActiveTab({
                campaignId,
                activeTab: newValue,
            }),
        );
    };

    const handleOpenNew = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        window.open(`/org/${orgCode}/campaigns/${campaignId}/${activeTab}${window.location.search}`, "_blank");
    };

    return (
        <div style={isMinimize ? { position: "relative", width: "260px" } : {}}>
            <Paper
                component="div"
                elevation={3}
                className={`customInsightsModalClass_${campaignId} ${classes.dialogPosition} ${classes.modalRoot} ${
                    isMinimize && classes.extraClass
                } customInsightsModalClass`}
                id={`customInsightsModalClass_${campaignId}`}
            >
                <CardHeader
                    onClick={handleMinimizeModal}
                    className={classes.cardHeader}
                    action={
                        <div>
                            <IconButton
                                color="inherit"
                                onClick={handleMinimizeModal}
                                aria-label="minimize"
                                size="small"
                                title="Minimize"
                            >
                                {isMinimize ? (
                                    <SVGIconRenderer icon="maximizeIcon" strokeColor={theme.palette.common.white} />
                                ) : (
                                    <SVGIconRenderer icon="minimizeIcon" strokeColor={theme.palette.common.white} />
                                )}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleOpenNew}
                                aria-label="openInNew"
                                size="small"
                                title="Open in new"
                            >
                                <SVGIconRenderer icon="externalLinkIcon" strokeColor={theme.palette.common.white} />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleCloseModal}
                                aria-label="close"
                                size="small"
                                title="Close"
                            >
                                <SVGIconRenderer icon="closeIcon" strokeColor={theme.palette.common.white} />
                            </IconButton>
                        </div>
                    }
                    title={<span title={campaignName || ""}>{campaignName}</span>}
                    id={`insights-header-${campaignId}`}
                />
                <DialogContent className={`${isMinimize ? classes.miniMizeClass : classes.heightClass}`}>
                    <PerformanceTabHolderComponent
                        handleTabChange={handleTabChange}
                        tabValue={activeTab}
                        performanceCampaignData={data}
                        modalInfo={modalInfo}
                    />
                </DialogContent>
            </Paper>
        </div>
    );
};
