import { IOrganization } from "src/interfaces/entities/IOrganization";

export const LOGIN_PATH = "/login";
export const ACCEPT_INIVITATION_PATH = "/accept-invitation";
export const HELP_PAGE_PATH = "/help-page";
export const RESET_PASSWORD_PATH = "/reset-password";
export const CONFITM_MAIL_PATH = "/confirm-email";
export const GETTING_STARTED_PATH = "/getting-started";
export const UNSUBSCRIBE_PATH = "/unsubscribe";

export const SUPERADMIN_ORG_PATH = "/adm/organizations";
export const SUPERADMIN_MEMBERS_PATH = "/adm/members";
export const SUPERADMIN_FEATURES_PATH = "/adm/features";
export const SUPERADMIN_EVENT_LOGS_PATH = "/adm/event-logs";
export const SUPERADMIN_ANNOUNCEMENT_PATH = "/adm/announcement";
export const SUPERADMIN_SLACK_ALERTS_PATH = "/adm/slack-alerts";

export const DATA_INTEGRATION_PATH = "/adm/data-integrations";
export const DATA_INTEGRATION_SOURCES_PATH = `${DATA_INTEGRATION_PATH}/sources`;
export const DATA_INTEGRATION_SERVICES_PATH = `${DATA_INTEGRATION_PATH}/services`;
export const DATA_INTEGRATION_PROVIDERS_PATH = `${DATA_INTEGRATION_PATH}/providers`;

export const HOME_PATH = "/home";
export const CAMPAIGNS_PATH = "/campaigns";
export const PERFORMANCE_PATH = "/performance";
export const PERFORMANCE_CHANNELS_PATH = "/performance/channels";
export const PERFORMANCE_CAMPAIGNS_PATH = "/performance/campaigns";
export const PERFORMANCE_TACTICS_PATH = "/performance/tactics";
export const USER_SETTINGS_PATH = "/user/settings";
export const SETTINGS_PATH = "/settings";
export const CONNECTORS_PATH = "/settings/connectors";
export const NEW_CONNECTOR_PATH = "/settings/connectors/new";
export const BILLING_PATH = "/settings/billing";
export const MEMBER_PATH = "/settings/members";
export const TACTICS_PATH = "/settings/tactics";
export const VERIFY_DATA_PATH = "/settings/connectors/verify_data";
export const CAMPAIGN_INCLUSION_PATH = "/settings/campaign_inclusion";
export const CUSTOM_CREATE_CONNECTOR_PATH = "/custom-connector-create";
export const VERIFY_QA_METRICS_PATH = "/settings/connectors/verify_data";
export const SETTINGS_DATA_QA_METRICS_PATH = "/settings/data-qa";

export const NO_ORGANIZATION_PATH = "/no-organization";
export const ACCESS_REVOKED_PATH = "/access-revoked";
export const TRIAL_END_PATH = "/trial-end";
export const SUBSCRIPTION_EXPIRED_PATH = "/subscription-expired";

export const OPTIIMIZATION_PATH = "/optimization";
export const OPTIIMIZATION_CREATE_PATH = "/optimization/new";

export const EMAIL_PATH = "/email";
export const ACCESS_DENIED_PATH = "/403";

export const DEFAULT_ORG_PATH = HOME_PATH;
export const getDefaultRedirectPath = (organization: IOrganization) =>
    organization.defaultRedirectPath || DEFAULT_ORG_PATH;

// old path
export const HOME_DASHBOARD_PATH = "/dashboard";
export const PERFORMANCE_PAID_PATH = "/performance/paid";
export const PERFORMANCE_ECOMMERCE_PATH = "/performance/e-commerce";
export const WELCOME_MODAL_HASH = "#welcome";
export const ECOMMERCE_PATH = "/e-commerce";

export const INTERCOM_HELP_CENTER_PATH = "https://help.prescient-ai.io/en";
export const FAQ_HELP_CENTER_PATH = `${INTERCOM_HELP_CENTER_PATH}/articles/8156801-frequently-asked-questions`;
export const GUIDE_HELP_CENTER_PATH = `${INTERCOM_HELP_CENTER_PATH}/articles/8229396-quick-start-guide`;
export const HALO_EFFECT_PATH = `${INTERCOM_HELP_CENTER_PATH}/articles/8156961-halo-effects`;
