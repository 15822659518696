import React, { FC } from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { object, string } from "yup";
import { TextField } from "@mui/material";
import Spinner from "@mui/material/CircularProgress";
import { Button } from "@prescientai/component-library";

import { IAnnouncement } from "../../../interfaces/entities/IAnnouncement";

export interface IAnnouncementFormProps extends FormikConfig<IAnnouncement> {
    isEditMode?: boolean;
}

export const AnnouncementForm: FC<IAnnouncementFormProps> = (props) => {
    const { isEditMode, ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                title: string().required("Title is required"),
                releaseDate: string().required("Release date is required"),
                link: string().matches(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/),
            })}
            {...rest}
        >
            {({ errors, handleChange, touched, values, isSubmitting, setFieldValue }: FormikProps<IAnnouncement>) => {
                const hasTitleError = touched.title && (errors.title || errors.base);
                const hasreleaseDateError = touched.releaseDate && errors.releaseDate;
                const hasLinkError = touched.link && errors.link;

                const disabledInput = isSubmitting || (Boolean(values.id) && !isEditMode);

                return (
                    <Form>
                        <TextField
                            fullWidth={true}
                            id="title"
                            name="title"
                            disabled={disabledInput}
                            label="Title"
                            value={values.title}
                            onChange={handleChange}
                            placeholder="Title"
                            sx={{ marginTop: "15px" }}
                            error={!!hasTitleError}
                            helperText={hasTitleError ? errors.title : ""}
                        />
                        <TextField
                            type="date"
                            fullWidth={true}
                            id="releaseDate"
                            name="releaseDate"
                            label="Release Date"
                            InputLabelProps={{ shrink: true }}
                            value={values.releaseDate}
                            onChange={handleChange}
                            sx={{ marginTop: "15px" }}
                            error={!!hasreleaseDateError}
                            helperText={hasreleaseDateError ? errors.releaseDate : ""}
                        />
                        <TextField
                            fullWidth={true}
                            id="description"
                            name="description"
                            label="Description"
                            disabled={disabledInput}
                            value={values.description}
                            onChange={handleChange}
                            placeholder="Description"
                            sx={{ marginTop: "15px" }}
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 300 }}
                        />
                        <TextField
                            fullWidth={true}
                            id="link"
                            name="link"
                            disabled={disabledInput}
                            label="Link"
                            value={values.link}
                            onChange={handleChange}
                            placeholder="Link"
                            sx={{ marginTop: "15px" }}
                            error={!!hasLinkError}
                            helperText={hasLinkError ? errors.link : ""}
                        />

                        <Button
                            sx={{ marginTop: "15px" }}
                            fullWidth={true}
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || !values.title || !values.releaseDate}
                        >
                            {isSubmitting ? <Spinner size={25} /> : isEditMode ? "Save changes" : "Create Announcement"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
