import { FC } from "react";
import { Stack } from "@mui/system";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Button } from "@prescientai/component-library";

import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { FORMATS } from "src/enums/Formats";
import { formatValue } from "src/services/utils";

interface IBudgetInfoDialog {
    isOpenBudgetModal: boolean;
    closeBudgetModal: () => void;
    budgetInfo: {
        originalBudget: number;
        updatedBudget: number;
        customization: number;
    };
    reallocateDollars: () => void;
    saveChanges: () => void;
    isBulkLoading: boolean;
    classes: any;
}

export const BudgetInfoDialog: FC<IBudgetInfoDialog> = ({
    isOpenBudgetModal,
    closeBudgetModal,
    budgetInfo,
    reallocateDollars,
    saveChanges,
    isBulkLoading,
    classes,
}) => {
    return (
        <Dialog
            open={isOpenBudgetModal}
            onClose={closeBudgetModal}
            sx={{
                "& .MuiPaper-root": {
                    width: "580px",
                },
            }}
        >
            <DialogTitle component="div" id="alert-dialog-title" display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold">
                    Budget Information
                </Typography>
                <SVGIconRenderer icon="closeIcon" onClick={closeBudgetModal} />
            </DialogTitle>
            <DialogContent>
                <div style={{ maxWidth: "552px" }}>
                    <Stack gap="8px">
                        <Typography>
                            You have made {budgetInfo.customization} customizations to this scenario.
                        </Typography>
                        <Typography>
                            {`Your original budget was ${formatValue(budgetInfo.originalBudget, FORMATS.DOLLAR, 0)} 
                    and your new budget is ${formatValue(budgetInfo.updatedBudget, FORMATS.DOLLAR, 0)}.`}
                        </Typography>
                        {budgetInfo.updatedBudget < budgetInfo.originalBudget ? (
                            <Typography>
                                Do you wish to reallocate the remaining{" "}
                                <b>
                                    {formatValue(
                                        budgetInfo.originalBudget - budgetInfo.updatedBudget,
                                        FORMATS.DOLLAR,
                                        0,
                                    )}
                                </b>{" "}
                                or keep with the new revised budget?
                            </Typography>
                        ) : (
                            ""
                        )}
                    </Stack>
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "16px 16px" }}>
                {budgetInfo.updatedBudget < budgetInfo.originalBudget && (
                    <Button
                        onClick={reallocateDollars}
                        className={classes.outlinedBtn}
                        variant="outlined"
                        disabled={isBulkLoading}
                    >
                        Reallocate Remaining Budget
                    </Button>
                )}
                <Button
                    onClick={saveChanges}
                    className={classes.containedBtn}
                    variant="contained"
                    disabled={isBulkLoading}
                >
                    Keep Revised Budget
                </Button>
            </DialogActions>
        </Dialog>
    );
};
