import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { Form, Formik, FormikProps } from "formik";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import ScenarioForm from "./ScenarioForm/ScenarioForm";
import addScenarioFormModel, { ScenarioFormType } from "../formModel/addScenarioFormModel";
import validationSchema from "../formModel/validationSchema";
import {
    OPTIMIZED_MODELS_BY_METRIC,
    SCENARIO_TYPE,
    TIMEFRAME_OPTIONS,
} from "../../../consts/optimizationPage/optimizationPage";
import {
    calculatePercentage,
    capitalizeFirstLetter,
    formatValue,
    getDiffObjectKeys,
    getPathForAnalytics,
} from "../../../services/utils";
import { ScenaioAppBar } from "../AppBar/ScenaioAppBar";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { userStateSelector } from "../../../reduxState/slices/userSlice";
import { optimizationStyle } from "../../../containers/Optimization/optimizationStyle";
import { OPTIIMIZATION_CREATE_PATH, OPTIIMIZATION_PATH } from "../../../consts/path/path";
import {
    useLazyGetAvailableOptimizationModelsQuery,
    useCreateScenarioMutation,
    useEditScenarioMutation,
    useLazyGetScenarioByIdQuery,
} from "../../../reduxState/apis/optimizationApi";
import { ScenarioFooter } from "../ScenarioFooter/ScenarioFooter";
import { TransitionComponent } from "../../../components/core/TransitionComponent/TransitionComponent";
import { currentlyViewingScenarioSelector } from "../../../reduxState/slices/scenarioSlice";
import noConnectors from "../../../assets/noConnectors.png";
import { Message } from "../../../components/Message/Message";
import { getReportingDataText, getReportingDateObj } from "../../../services/optimizationPage/optimization";
import { optimizerWithConfidenceSelector } from "src/reduxState/slices/featuresSlice";
import { FORMATS } from "src/enums/Formats";
import { useGetFilterViewQuery } from "src/reduxState/apis/filterViewApi";
import { useLazyGetPerformancePageQuery } from "src/reduxState/apis/performancePageApi";
import { getPaidDataSources } from "src/consts/performancePaidPage/performancePaidPage";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";

const { formId, formField } = addScenarioFormModel;

const MainScenarioForm = () => {
    const theme = useTheme();
    const classes = optimizationStyle(theme);
    const navigate = useNavigate();
    const { scenarioId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const analyticsService = useAnalyticsService();
    const isNewScenario = location.pathname.includes(OPTIIMIZATION_CREATE_PATH);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const currentUser = useSelector(userStateSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const currentlyViewingScenario = useSelector(currentlyViewingScenarioSelector);
    const optimizerWithConfidence = useSelector(optimizerWithConfidenceSelector);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);

    const [selectedScenario, setSelectedScenario] = useState<ScenarioFormType>({
        id: "",
        name: `${currentUser ? currentUser.firstName : ""}’s Scenario ${format(new Date(), "MMM d, yyyy")}`,
        description: "",
        scenarioType: SCENARIO_TYPE.ROAS,
        forecastTimeframe: TIMEFRAME_OPTIONS.LAST28DAYS,
        budget: 0,
        revenue: 0,
        roas: 1.0,
        campaignData: [],
        optimizationConfidence: optimizerWithConfidence ? 70 : 0,
        isPrivate: false,
        isHidden: true,
    });
    const [timeframeValue, setTimeframeValue] = useState<TIMEFRAME_OPTIONS>(TIMEFRAME_OPTIONS.LAST28DAYS);
    const [forecastTarget, setForecastTarget] = useState<OPTIMIZED_MODELS_BY_METRIC>(
        OPTIMIZED_MODELS_BY_METRIC.REVENUE,
    );
    const [errMsg, setErrMsg] = useState("");
    const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
    const [lastDaysTooltip, setLastDaysTooltip] = useState("");

    useGetFilterViewQuery({ orgId: currentOrgId });

    const [createScenario] = useCreateScenarioMutation();
    const [editScenario] = useEditScenarioMutation();
    const [getScenarioById] = useLazyGetScenarioByIdQuery();
    const [
        getAvailableOptimizationModels,
        {
            data: performanceData = {
                data: [],
                meta: { spendDate: "" },
            },
            isLoading: isCampaignLoading,
            isFetching: isCampaignFetching,
            isUninitialized,
            isSuccess,
        },
    ] = useLazyGetAvailableOptimizationModelsQuery();
    const [
        getPerformanceModels,
        {
            data: performancePageData = {
                data: [],
            },
        },
    ] = useLazyGetPerformancePageQuery();

    const currentValidationSchema = validationSchema[0];

    useEffect(() => {
        if (!scenarioId) {
            return;
        }

        if (currentlyViewingScenario?.id === scenarioId) {
            setSelectedScenario(currentlyViewingScenario);
        } else {
            getScenarioById({ orgId: currentOrgId, scenarioId })
                .unwrap()
                .catch((error) => {
                    if (error.status === 404) {
                        setErrMsg(`Scenario with 'id'=${scenarioId} not found.`);
                        return;
                    }
                    setErrMsg("An unexpected error occurred while fetching the scenario.");
                });
        }
    }, [scenarioId, currentlyViewingScenario]);

    useEffect(() => {
        const lastDataDateObj =
            scenarioId && selectedScenario?.createdAt
                ? getReportingDateObj(timeframeValue, selectedScenario.createdAt)
                : getReportingDateObj(timeframeValue);

        getPerformanceModels({
            orgId: currentOrgId,
            data: {
                start_date: lastDataDateObj.start_date,
                end_date: lastDataDateObj.end_date,
                connectors: getPaidDataSources(supportedDataSources),
            },
        });
    }, [timeframeValue, selectedScenario]);

    useEffect(() => {
        if (!timeframeValue) {
            return;
        }

        const fetchOptimizationModels = (startDate: string, endDate: string) => {
            setLastDaysTooltip(getReportingDataText(timeframeValue, startDate, endDate));
            getAvailableOptimizationModels({
                orgId: currentOrgId,
                data: {
                    start_date: startDate,
                    end_date: endDate,
                    scenarioType: forecastTarget,
                },
            })
                .unwrap()
                .catch((error) => {
                    enqueueSnackbar(
                        `Unable to fetch optimization models. ${error.status ? `(Error code - ${error.status})` : ""}`,
                        {
                            id: uniqueId(),
                            variant: "error",
                        },
                    );
                });
        };

        const lastDataDateObj =
            scenarioId && selectedScenario?.createdAt
                ? getReportingDateObj(timeframeValue, selectedScenario.createdAt)
                : getReportingDateObj(timeframeValue);

        fetchOptimizationModels(lastDataDateObj.start_date, lastDataDateObj.end_date);
    }, [selectedScenario, timeframeValue, forecastTarget, scenarioId]);

    const handleExit = () => navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}`);
    const redirectToScenarioOutcome = (id: string) => navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${id}`);

    const onFinalSubmit = async (values: ScenarioFormType) => {
        let eventData = {
            Page: getPathForAnalytics(location.pathname),
        };
        if (values.scenarioType === SCENARIO_TYPE.SCALE) {
            const scaleType = capitalizeFirstLetter(values.scenarioType);
            const maxSpendTotal = values.campaignData.reduce((a, p) => a + (p.currentEstimatedSpend || 0), 0);
            const percentChange = calculatePercentage(values.budget, maxSpendTotal);
            eventData = { ...eventData, [scaleType]: formatValue(percentChange, FORMATS.PERCENT, 0) };
        }
        analyticsService.logEvent("Configure Optimization Request Sent", eventData);
        const changedValues = getDiffObjectKeys(values, selectedScenario);
        setIsFormSubmitLoading(true);
        if (!values.id) {
            createScenario({ body: values, orgId: currentOrgId })
                .unwrap()
                .then((response) => {
                    redirectToScenarioOutcome(response.id);
                })
                .catch((error) => {
                    setIsFormSubmitLoading(false);
                    enqueueSnackbar(
                        `Unable to create scenario. ${error.status ? `(Error code - ${error.status})` : ""}`,
                        {
                            id: uniqueId(),
                            variant: "error",
                        },
                    );
                });
        } else if (changedValues.length) {
            editScenario({
                body: values,
                orgId: currentOrgId,
                scenarioId: values.id.toString(),
            })
                .unwrap()
                .then((response) => {
                    redirectToScenarioOutcome(response.id);
                });
        } else {
            setIsFormSubmitLoading(false);
            navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${values.id}`);
        }
    };

    const getTimeframeValue = useCallback((value: TIMEFRAME_OPTIONS) => {
        setTimeframeValue(value);
    }, []);

    const getSelectedForecastTarget = useCallback((value: OPTIMIZED_MODELS_BY_METRIC) => {
        setForecastTarget(value);
    }, []);

    const getFooterBtnTitle = () => {
        return isNewScenario ? "Create Optimization Scenario" : "See Optimization Outcome";
    };

    const getFormTitle = () => {
        return scenarioId ? "Edit Optimization" : isNewScenario ? "Create New Optimization" : "Optimization Outcome";
    };

    const isPerformanceLoading = isUninitialized || isCampaignFetching || isCampaignLoading || !isSuccess;

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "#f5f5f5",
                },
            }}
            fullScreen
            open={true}
            onClose={handleExit}
            TransitionComponent={TransitionComponent}
            disableEscapeKeyDown
        >
            <Formik
                initialValues={selectedScenario}
                validationSchema={currentValidationSchema}
                onSubmit={onFinalSubmit}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                }: FormikProps<ScenarioFormType>) => {
                    const hasBudgetError = Boolean(errors.budget);
                    const hasCampaignError = Boolean(errors.campaignData);

                    return (
                        <>
                            <ScenaioAppBar
                                handleExitModal={handleExit}
                                heading={getFormTitle()}
                                isCloseDisabled={false}
                            />
                            {errMsg ? (
                                <Stack alignContent="center" height="100%" justifyContent="center">
                                    <Message title={errMsg} subtitle="" icon={noConnectors} filters={[]} />
                                </Stack>
                            ) : (
                                <Stack direction="row">
                                    <Form style={{ width: "100%" }} id={formId}>
                                        <ScenarioForm
                                            formField={formField}
                                            errors={errors}
                                            touched={touched}
                                            values={values}
                                            handleChange={handleChange}
                                            classes={classes}
                                            setFieldValue={setFieldValue}
                                            performanceData={performanceData}
                                            isPerformanceLoading={isPerformanceLoading}
                                            getTimeframeValue={getTimeframeValue}
                                            getSelectedForecastTarget={getSelectedForecastTarget}
                                            selectedScenario={selectedScenario}
                                            lastDaysTooltip={lastDaysTooltip}
                                            performancePageData={performancePageData?.data || []}
                                        />
                                        <ScenarioFooter
                                            isDisplayBackBtn={false}
                                            isBtnDisabled={isPerformanceLoading || isFormSubmitLoading}
                                            handleCancelClick={handleExit}
                                            submitBtnTitle={getFooterBtnTitle()}
                                            onFinalSubmit={handleSubmit}
                                            isNotValidForm={hasBudgetError || hasCampaignError}
                                        />
                                    </Form>
                                </Stack>
                            )}
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default memo(MainScenarioForm);
