import { IDimCampaignResponse } from "src/interfaces/dimCampaignResponse/IDimCampaignResponse";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { store } from "src/reduxState/stores/store";
import { keysToCamelCase } from "src/utils/format";
import { getDemoCampaignName } from "../utils";

export const formatDimCampaigns = (response: { data: IDimCampaignResponse[] }): IDimCampaign[] => {
    const { isDemoMode } = store.getState();

    return response?.data?.length
        ? response.data.map((res) => {
              const { channel, campaign_name } = res.attributes;
              const demoCampaignName = getDemoCampaignName(channel);
              return {
                  ...keysToCamelCase(res.attributes),
                  campaignName: isDemoMode ? demoCampaignName : campaign_name,
              };
          })
        : [];
};
