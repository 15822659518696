import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { Button } from "@prescientai/component-library";

import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import { css } from "@emotion/css";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { PERFORMANCE_PATH } from "../../../../consts/path/path";
import { BLACK_COLOR } from "../../../../consts/colors";
import { LightMenu } from "../../../LightMenu/LightMenu";
import {
    selectCurrentFilterView,
    selectIsSavedViewOn,
    setFilterView,
    selectFilterViewList,
    DEFAULT_STATE_FOR_SAVED_VIEW,
} from "../../../../reduxState/slices/filterViewSlice";
import { selectCurrentlyViewingId, selectCurrentlyViewingCode } from "../../../../reduxState/slices/organizationSlice";
import { crudPerfomanceView } from "../../../../reduxState/actions/performanceView";
import { DynamicSavedFilterList } from "../../../../components/DynamicSavedFilterList/DynamicSavedFilterList";
import { getPathForAnalytics, handleFilteredData, handleSingleFilter } from "../../../../services/utils";
import { getInitialColumnFilter } from "../../../../services/performancePage/performancePage";
import { SVGIconRenderer } from "../../../SVGIconRenderer/SVGIconRenderer";
import {
    useDeleteFilterViewMutation,
    useEditFilterViewMutation,
    useLazyGetFilterViewQuery,
    useSaveFilterViewMutation,
} from "../../../../reduxState/apis/filterViewApi";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { DEFAULT_PERFORMANCE_FILTER_TITLE } from "src/consts/performancePaidPage/performancePaidPage";

interface ISavedFilterList {
    filterChipsCount: number;
    searchCampaign: string;
    filterList: any[];
    handleSavedViewChange: (name: string, filters: string, id: string) => void;
}

const useStyles = () => ({
    viewButton: css({}),
    saveViewDialog: css({
        "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "30px",
        },
        "& .title": {
            display: "flex",
            justifyContent: "space-between",
            "& p": { fontWeight: "bold", fontSize: "18px" },
            "& .MuiSvgIcon-root": { color: BLACK_COLOR, cursor: "pointer" },
        },
        "& .actionBtn": {
            width: "50%",
            padding: "0px, 30px, 0px, 30px",
            textTransform: "inherit",
            height: "54px",
        },
        "& .MuiTypography-h6": {
            fontSize: "18px",
            padding: 0,
        },
        "& .MuiDialogContent-root": {
            padding: 0,
        },
        "& .MuiDialogActions-root": {
            padding: 0,
        },
    }),
});

export const SavedFilterList: FC<ISavedFilterList> = ({
    filterChipsCount,
    searchCampaign,
    filterList,
    handleSavedViewChange,
}) => {
    const classes = useStyles();
    const allViewList = useSelector(selectFilterViewList);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const childRef = React.useRef<React.ElementRef<typeof LightMenu>>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentFilterView = useSelector(selectCurrentFilterView);
    const isSavedViewOn = useSelector(selectIsSavedViewOn);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const connectors = useSelector(connectorsSelector);
    const [saveFilterView] = useSaveFilterViewMutation();
    const [editFilterView] = useEditFilterViewMutation();
    const [deleteFilterView] = useDeleteFilterViewMutation();
    const [viewName, setViewName] = useState("");
    const [duplicateNameError, setDuplicateNameError] = useState("");
    const [deleteMode, setDeleteMode] = useState(false);
    const [openSaveViewModal, setSaveViewModal] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [getFilterView] = useLazyGetFilterViewQuery();
    const { enqueueSnackbar } = useSnackbar();

    const initialColumnFilter = getInitialColumnFilter({}, connectors);

    const getExcludeColForHaloView = () => {
        const haloColumns = new Set([
            "haloEffectRevenue",
            "amazonSellingPartnerHaloEffectRevenue",
            "storeModeledHaloEffectRevenue",
        ]);

        return initialColumnFilter
            .split(",")
            .filter((column) => !haloColumns.has(column))
            .join(",");
    };

    const goToMainPage = () => {
        const currentUrl = location.pathname.split("/");
        const currentTab = currentUrl[currentUrl.length - 1];

        dispatch(
            setFilterView({
                isSavedViewOn: false,
                data: DEFAULT_STATE_FOR_SAVED_VIEW,
            }),
        );
        navigate(`/org/${orgCode}${PERFORMANCE_PATH}/${currentTab}`);
    };

    const defaultFilterList = [
        {
            id: "defaultFilter",
            filters: "",
            name: DEFAULT_PERFORMANCE_FILTER_TITLE,
        },
        {
            id: "mmmRevenueFilter",
            filters: `filter[trueRevenue][gt]=0&excluded_columns=${initialColumnFilter}`,
            name: "Has MMM Revenue",
        },
        {
            id: "secondOrderEffectFilter",
            filters: `properties=has_halo_effects&excluded_columns=${getExcludeColForHaloView()}`,
            name: "Halo View",
        },
    ];

    const isSaveViewActionOn =
        currentFilterView.data.id &&
        !["defaultFilter", "secondOrderEffectFilter", "mmmRevenueFilter"].includes(currentFilterView.data.id);

    const isDefaultFilterActive = !currentFilterView.isSavedViewOn && !!(filterChipsCount && filterChipsCount > 0);

    const isDeleteViewActionOn =
        currentFilterView.data.id && filterList.map((v) => v.id).includes(currentFilterView.data.id);

    const actionList = [
        {
            name: "Save View",
            icon: (
                <CreateOutlinedIcon
                    sx={{ opacity: 0.54, color: !isSaveViewActionOn ? "rgba(0, 0, 0, 0.38)" : "#000000" }}
                />
            ),
            clickHandler: () => handleSaveCurrentView(),
            disabled: !isSaveViewActionOn,
        },
        {
            name: "Save New View",
            icon: <AddIcon sx={{ color: "#000000" }} />,
            clickHandler: () => handleSaveNewView(),
        },
        {
            name: "Delete View",
            icon: <DeleteIcon sx={{ color: !isDeleteViewActionOn ? "rgba(0, 0, 0, 0.38)" : "#000000" }} />,
            clickHandler: () => handleDeleteView(),
            disabled: !isDeleteViewActionOn,
        },
    ];

    const handleMenuClick = (filter: { name: string; filters: string; id: string }) => {
        handleSavedViewChange(filter.name, filter.filters, filter.id);
        if (childRef.current) {
            childRef.current.closeDialog();
        }
    };

    const handleSaveCurrentView = async () => {
        setDisableSave(true);
        if (searchCampaign) {
            handleFilteredData("contains", { id: searchCampaign }, navigate);
            handleSingleFilter("search_text", "", navigate);
        }

        await editFilterView({
            body: {
                name: currentFilterView.data.name,
                filters: location.search,
            },
            id: currentFilterView.data.id,
            orgId: currentOrgId,
        })
            .then((res: any) => {
                const { name, filters } = res.data.attributes;
                dispatch(
                    crudPerfomanceView({
                        eventName: "Performance View Edited",
                        page: getPathForAnalytics(window.location.pathname, location.search),
                        viewName: currentFilterView.data.name,
                    }),
                );
                dispatch(setFilterView({ isSavedViewOn: true, data: { name, filters, id: res.data.id } }));
                getFilterView({ orgId: currentOrgId });
                enqueueSnackbar("View updated successfully.", { id: uniqueId(), variant: "success" });
            })
            .catch((error: any) => {
                throw new Error(`save view error ${error}`);
            })
            .finally(() => {
                closeSaveViewHandle();
                setDisableSave(false);
            });
    };

    const handleSaveNewView = async () => {
        setSaveViewModal(true);
    };

    const saveViewAction = async () => {
        if (allViewList.filter((v) => v.name === viewName).length > 0) {
            setDuplicateNameError("View name is already exists.");
        } else {
            if (searchCampaign) {
                handleFilteredData("contains", { id: searchCampaign }, navigate);
                handleSingleFilter("search_text", "", navigate);
            }

            setDisableSave(true);
            await saveFilterView({
                body: {
                    name: viewName,
                    filters: location.search,
                },
                orgId: currentOrgId,
            })
                .unwrap()
                .then((res: any) => {
                    dispatch(
                        crudPerfomanceView({
                            eventName: "Performance View Created",
                            page: getPathForAnalytics(window.location.pathname, location.search),
                            viewName,
                        }),
                    );
                    enqueueSnackbar("View added successfully.", {
                        id: uniqueId(),
                        variant: "success",
                    });
                    getFilterView({ orgId: currentOrgId });
                    const { attributes } = res.data;
                    dispatch(
                        setFilterView({
                            isSavedViewOn: true,
                            data: { name: attributes.name, filters: attributes.filters, id: res.data.id },
                        }),
                    );
                })
                .catch((error) => {
                    console.log("error", error);
                    throw new Error(`save view error ${error}`);
                })
                .finally(() => {
                    closeSaveViewHandle();
                    setDisableSave(false);
                });
        }
    };

    const closeSaveViewHandle = () => {
        setViewName("");
        setDuplicateNameError("");
        setSaveViewModal(false);
        setDeleteMode(false);
    };

    const exitViewHandle = (event: any) => {
        event.stopPropagation();
        closeSaveViewHandle();
        dispatch(
            crudPerfomanceView({
                eventName: "Performance View Closed",
                page: getPathForAnalytics(window.location.pathname, window.location.search),
                viewName: currentFilterView.data.name,
            }),
        );
        goToMainPage();
    };

    const handleDeleteView = () => {
        setSaveViewModal(true);
        setViewName(currentFilterView.data.name);
        setDeleteMode(true);
    };

    const handleDeleteViewAction = async () => {
        setDisableSave(true);
        await deleteFilterView({
            id: currentFilterView.data.id,
            orgId: currentOrgId,
        })
            .unwrap()
            .then(() => {
                dispatch(
                    crudPerfomanceView({
                        eventName: "Performance View Deleted",
                        page: getPathForAnalytics(window.location.pathname, window.location.search),
                        viewName: currentFilterView.data.name,
                    }),
                );
                goToMainPage();
                enqueueSnackbar("View deleted.", {
                    id: uniqueId(),
                    variant: "error",
                });
                getFilterView({ orgId: currentOrgId });
            })
            .catch((error) => {
                throw new Error(`delete view error ${error}`);
            })
            .finally(() => {
                closeSaveViewHandle();
                setDisableSave(false);
            });
    };

    return (
        <>
            <DynamicSavedFilterList
                isSavedViewOn={isSavedViewOn}
                currentFilterView={currentFilterView}
                childRef={childRef}
                defaultFilterList={defaultFilterList}
                savedFilterList={filterList}
                actionList={actionList}
                handleMenuClick={handleMenuClick}
                exitViewHandler={exitViewHandle}
                id="campaign_saved_views"
                dataCy="campaignSavedViews"
                isUnsavedFilters={isDefaultFilterActive}
            />
            {openSaveViewModal && (
                <Dialog
                    className={classes.saveViewDialog}
                    maxWidth="xs"
                    fullWidth={true}
                    open={openSaveViewModal}
                    onClose={closeSaveViewHandle}
                >
                    <DialogTitle className="title">
                        <Typography variant="h3">{deleteMode ? "Delete view" : "Save Current View"}</Typography>
                        <SVGIconRenderer icon="closeIcon" onClick={closeSaveViewHandle} />
                    </DialogTitle>
                    <DialogContent>
                        {deleteMode ? (
                            <span>
                                Are you sure you want to delete the <b>{currentFilterView.data.name}</b> view?
                            </span>
                        ) : (
                            <>
                                <FormControlLabel
                                    sx={{
                                        "& .MuiFormControlLabel-label, & .MuiTextField-root": {
                                            width: "100%",
                                            cursor: "default",
                                        },
                                    }}
                                    label="View Name"
                                    labelPlacement="top"
                                    control={
                                        <TextField
                                            placeholder="Enter name..."
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            value={viewName}
                                            onChange={(e) => {
                                                setDuplicateNameError("");
                                                setViewName(e.target.value);
                                            }}
                                            helperText={duplicateNameError}
                                            error={!!duplicateNameError}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && viewName && !duplicateNameError) {
                                                    saveViewAction();
                                                }
                                            }}
                                        />
                                    }
                                    style={{ margin: 0, width: "100%" }}
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "space-around", padding: "20px 24px", gap: "10px" }}>
                        <Button
                            sx={{ color: "black" }}
                            className="actionBtn"
                            variant="outlined"
                            onClick={closeSaveViewHandle}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="actionBtn"
                            variant="contained"
                            onClick={deleteMode ? handleDeleteViewAction : saveViewAction}
                            disabled={!viewName || !!duplicateNameError || disableSave}
                        >
                            {deleteMode ? "Delete view" : "Save view"}{" "}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
