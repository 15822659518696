import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import { Range } from "react-date-range";
import { useSelector } from "react-redux";
import { Button } from "@prescientai/component-library";

import prescientEmptyHeroImage from "src/assets/PrescientEmptyHero.png";
import { HomePagePartialCard } from "src/components/Dashboard/CardComponents/HomePagePartialCard";
import { VerifyDataQa } from "src/components/Dashboard/CardComponents/VerifyDataQa";
import { VerticalLightTabs } from "src/components/LightTabs/VerticalLightTabs";
import { DataValueView, KeyKPIPerformanceTabTypes } from "src/consts/homeDashboard/homeDashboard";
import { chartGrpKey } from "src/consts/performancePaidPage/performancePaidPage";
import { UserRole } from "src/enums/UserRole";
import { dataQaReady } from "src/reduxState/slices/organizationSlice";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "src/reduxState/slices/userSlice";
import logoAttribution from "../../../assets/Prescient-Logo-Bug.png";
import { IChartItem } from "../../../interfaces/chart/IChartItem";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../services/utils";
import { ChartComponent } from "../ChartComponent/ChartComponent";
import { getInitialDateRangeForCompany } from "src/components/DateRangeFilter/reactDateRangeUtils";

interface IChartTemplateComponentProps {
    compareMode?: boolean;
    pastDateString?: string;
    title: string;
    tabs: any[];
    chartData: {
        data: IChartItem[];
        isPercent?: boolean;
        isDollar?: boolean;
        legendTotal?: any[];
    };
    emptyDataButtonText: string;
    showEmptyState: boolean;
    isFetching: boolean;
    hasError: boolean;
    style?: object;
    grpBy: chartGrpKey;
    id: string;
    ecommerceState: KeyKPIPerformanceTabTypes;
    dataValueView: DataValueView;
    onFilterChange?: (currentDateFilter: Range[], chartData: IChartItem[]) => void;
    setSelectedMetricSeries?: (data: any) => void;
    onTabChange: (currentTab: any) => void;
    handleEmptyDataButtonClick(): void;
}

const StyledDiv = styled("div")({
    position: "relative",
    borderBottom: "1px #E0E0E0 solid",
    minHeight: "50px",
});

export const ChartTemplateComponent: React.FC<IChartTemplateComponentProps> = ({
    title,
    onTabChange,
    onFilterChange,
    handleEmptyDataButtonClick,
    emptyDataButtonText,
    tabs,
    chartData,
    isFetching,
    hasError = false,
    showEmptyState,
    style = {},
    grpBy,
    id,
    setSelectedMetricSeries,
    ecommerceState,
    compareMode = false,
    pastDateString = "",
    dataValueView,
}) => {
    const theme = useTheme();
    const analyticsService = useAnalyticsService();
    const isPartialStateForHome = useSelector(partialStateSelectorForHome);

    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const isAdmin = userRole === UserRole.ADMIN || isSuperAdmin;

    const isDataQaReady = useSelector(dataQaReady);

    useEffect(() => {
        onFilterChange && onFilterChange(getInitialDateRangeForCompany(), chartData.data);
    }, [grpBy]);

    const handleTabClick = (item: any) => {
        if (typeof item === "number" && item < tabs.length) {
            analyticsService.logEvent("Dashboard Chart Tab Clicked", {
                Chart: title,
                "Chart tab": tabs[item]?.props.name,
            });
        }
        onTabChange(item);
    };

    const handleEmptyDataClick = () => {
        analyticsService.logEvent("Dashboard Connect Clicked", {
            Page: getPathForAnalytics(location.pathname),
            "Connector type": title,
        });

        handleEmptyDataButtonClick();
    };

    const mmmTabs = [];
    const nonMMMTabs = [];

    const getTabSubLabel = () => {
        if (isPartialStateForHome) {
            return "In Progress...";
        } else if (isDataQaReady) {
            return "Data Not Verified";
        }
        return "";
    };

    for (const tab of tabs) {
        const tabId = tab.id || "";

        if (tabId.includes("modeled") && !tab.isHide) {
            mmmTabs.push({ ...tab, subLable: getTabSubLabel() });
        } else if (!tabId.includes("modeled")) {
            nonMMMTabs.push(tab);
        }
    }

    const mmmActiveTab = mmmTabs.find((c) => c.id === ecommerceState)?.id;
    const otherActiveMetric = nonMMMTabs.find((c) => c.id === ecommerceState)?.id;

    const partialHomeElement = (
        <HomePagePartialCard
            image={prescientEmptyHeroImage}
            heading="Aggregating MMM KPI Report"
            description={
                <>
                    Results typically begins to flow 1 week after a data verification is complete. Please allow more
                    time for Amazon.
                    <br />
                    Check back later!
                </>
            }
            actionButton={
                <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    href="https://help.prescient-ai.io/en/articles/9293715-new-feature-tour-homepage#h_1e728cceef"
                    {...({ target: "_blank" })}
                >
                    Learn More About MMM KPI Report
                </Button>
            }
            style={{ height: "auto" }}
        />
    );

    const filteredLegendData = (data: any[] | undefined) => {
        if (!data) {
            return [];
        }
        return data.filter((elem) => elem.value !== 0 && !!elem.name);
    };

    const filteredChartData = useCallback(
        (data: IChartItem[]) => {
            let shouldFilter = false;
            const filteredNames: string[] = [];
            filteredLegendData(data).forEach((elem) => {
                if (elem.seriesName) {
                    shouldFilter = true;
                }
                filteredNames.push(elem.seriesName);
            });
            if (!shouldFilter) {
                return data.filter((elem) => !!elem.name);
            }
            return data.filter((elem) => filteredNames.includes(elem.seriesName || "") && !!elem.name);
        },
        [chartData.data, chartData.legendTotal],
    );

    return (
        <Stack direction="row" gap={4}>
            <Stack sx={{ height: "567px", position: "relative" }} className="stack-container">
                <Stack
                    sx={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        position: "relative",
                        "::-webkit-scrollbar": {
                            width: 0,
                            height: 0,
                        },
                    }}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            height: `${getTabSubLabel() === "" ? mmmTabs.length * 49 : mmmTabs.length * 66}px`,
                            position: "absolute",
                        }}
                    >
                        <img src={logoAttribution} style={{ height: "28px", width: "25px" }} />
                    </Stack>
                    <VerticalLightTabs
                        style={{
                            width: "280px",
                            borderWidth: "0 1px 1px 29px",
                            borderStyle: "solid",
                            borderColor: theme.palette.primary.main,
                            "& .MuiTabs-vertical .MuiTabs-scroller .MuiTabs-flexContainer button": {
                                borderTop: `1px solid ${theme.palette.primary.main}`,
                            },
                        }}
                        callback={handleTabClick}
                        tabs={mmmTabs}
                        scrollButtons={false}
                        initialValue={mmmActiveTab ? mmmActiveTab : otherActiveMetric ? false : false}
                    />
                    <VerticalLightTabs
                        style={{ width: "260px", position: "relative", left: "20px" }}
                        callback={handleTabClick}
                        tabs={nonMMMTabs}
                        scrollButtons={false}
                        initialValue={otherActiveMetric ? otherActiveMetric : mmmActiveTab ? false : false}
                    />
                </Stack>
            </Stack>
            {isDataQaReady && isAdmin && mmmActiveTab ? (
                <VerifyDataQa tabName="Prescient MMM Insights" style={{ height: "auto" }} />
            ) : isPartialStateForHome && mmmActiveTab ? (
                partialHomeElement
            ) : (
                <StyledDiv
                    style={{
                        width: "calc(100% - 310px)",
                        border: showEmptyState ? "1px solid rgba(0, 0, 0, 0.12)" : 0,
                    }}
                >
                    {showEmptyState && (
                        <Button
                            sx={(theme) => ({
                                zIndex: 1,
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -75%)",
                                position: "absolute",
                                background: theme.palette.primary.main,
                                fontWeight: "bold",
                                fontSize: "15px",
                            })}
                            onClick={handleEmptyDataClick}
                            variant="contained"
                            color="primary"
                        >
                            {emptyDataButtonText}
                        </Button>
                    )}
                    <ChartComponent
                        legendTotal={filteredLegendData(chartData.legendTotal)}
                        tabs={tabs}
                        isFetching={isFetching}
                        data={filteredChartData(chartData.data)}
                        grpBy={grpBy}
                        id={id}
                        setSelectedMetricSeries={setSelectedMetricSeries}
                        ecommerceState={ecommerceState}
                        isNewHomePage={true}
                        compareMode={compareMode}
                        pastDateString={pastDateString}
                        dataValueView={dataValueView}
                    />
                </StyledDiv>
            )}
        </Stack>
    );
};
