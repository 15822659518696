import DateRangeIcon from "@mui/icons-material/DateRange";
import { Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { endOfMonth, format, isValid, startOfDay, startOfMonth, subDays } from "date-fns";
import queryString from "query-string";
import React, { FC, useEffect, useRef, useState } from "react";
import { DateRangePicker, Range, RangeKeyDict, StaticRange, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@prescientai/component-library";
import { useNavigate } from "react-router";

import {
    formattedCompareDateSelector,
    formattedDateSelector,
    isCompareSelector,
    setCompare,
    setCompareDateFilter,
    setCurrentDateFilter,
} from "../../reduxState/slices/dateFilterSlice";
import { isCampaignAvailableSelector, isModelingAvailableSelector } from "../../reduxState/slices/settingsSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { handleDeleteParamKey } from "../../services/performancePage/performancePage";
import { convertObjectToQueryParam, getPathForAnalytics } from "../../services/utils";
import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { DATE_RANGE_COLORS, useStylesForDateRange } from "./dateRangeStyle";
import "./reactDateRangeStyles.css";
import {
    DefaultDateRangeInText,
    getDefaultRange,
    getPastPeriod,
    getRangeFormattedTitle,
    getSelectedDateRange,
    getSelectedPreset,
    isCorrectPreset,
    isNewFirstOfMonth,
    sideBarDateOptions,
    wholeMonthSelected,
} from "./reactDateRangeUtils";
import { selectDefaultLastReportedDate } from "src/reduxState/slices/organizationSlice";

interface IProps {
    appendRangeInLocation?: boolean;
    minDate?: Date | undefined;
    compareView: boolean;
}

interface IDateRanges {
    selection: Range;
    compare: Range;
}

const ReactDateRangeSelect: FC<IProps> = ({ minDate, appendRangeInLocation = true, compareView }) => {
    const theme = useTheme();
    const classes = useStylesForDateRange(theme);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const defaultLastReportedDate = useSelector(selectDefaultLastReportedDate);

    const childRef = useRef<React.ElementRef<typeof LightMenu>>(null);
    const datePickerRef = useRef(null);
    const buttonStackRef = useRef(null);

    const filterQueryParamsObj = queryString.parse(window.location.search);
    const formattedDates = useSelector(formattedDateSelector);
    const formattedCompareDates = useSelector(formattedCompareDateSelector);
    const compareToPast = useSelector(isCompareSelector);
    const [isDatePickerMenuOpen, setDatePickerMenu] = useState<boolean>(false);
    const [compareSwitch, setCompareSwitch] = useState(compareToPast);
    const [state, setState] = useState<IDateRanges>({
        selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
        compare: {
            ...getPastPeriod(formattedDates, true)[0],
            key: "compare",
        },
    });
    const sideBar = sideBarDateOptions(state, compareSwitch || false, compareView, defaultLastReportedDate);
    const staticRanges = [...createStaticRanges(sideBar as StaticRange[])];
    let timeout: ReturnType<typeof setTimeout>;

    useEffect(() => {
        timeout = setTimeout(() => {
            const rdrStaticRangeSelected = document.querySelectorAll(".rdrStaticRangeSelected");
            if (rdrStaticRangeSelected.length) {
                if (rdrStaticRangeSelected.length > 1) {
                    const prevNode = rdrStaticRangeSelected[rdrStaticRangeSelected.length - 1] as HTMLElement;
                    if (prevNode) {
                        const textElements = prevNode.getElementsByTagName("span");
                        prevNode.style.color = DATE_RANGE_COLORS.compare;
                        prevNode.style.boxShadow = "inset -4px 0px 0px rgb(255, 214, 57) !important";

                        if (textElements.length) {
                            textElements[0].style.color = "#000000";
                        }
                    }
                }
                if (rdrStaticRangeSelected && rdrStaticRangeSelected[0]) {
                    const currNode = rdrStaticRangeSelected[0] as HTMLElement;
                    currNode.style.color = DATE_RANGE_COLORS.selection;
                }
            }
            const wrapperDateDisplayClass = document.getElementsByClassName("rdrDateDisplayWrapper");
            if (wrapperDateDisplayClass.length && wrapperDateDisplayClass[0]) {
                if (compareSwitch && compareView) {
                    wrapperDateDisplayClass[0].classList.add("extraText");
                } else {
                    wrapperDateDisplayClass[0].classList.remove("extraText");
                }
            }
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [state, compareSwitch]);

    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);

    const isCompareAvailable = isCampaignAvailable && isModelingAvailable;

    const handleCompareRageSwitch = () => {
        if (isCompareAvailable) {
            setCompareSwitch((prevSwitch) => !prevSwitch);
        }
    };

    const changeCustomRange = (item: IDateRanges) => {
        const { startDate, endDate } = item.selection;
        if (startDate && endDate) {
            dispatch(
                setCurrentDateFilter({
                    date: JSON.stringify([item.selection]),
                }),
            );
            dispatch(
                setCompareDateFilter({
                    compareDate: JSON.stringify([item.compare]),
                }),
            );

            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            const selectedPreset = getSelectedPreset(item.selection, sideBar);

            analyticsService.logEvent("Dates Filtered", {
                Page: getPathForAnalytics(location.pathname, location.search),
                "Preset selected": selectedPreset,
                "Start date": formattedStartDate,
                "End date": formattedEndDate,
            });

            if (appendRangeInLocation) {
                const updateDateFilter = {
                    ...filterQueryParamsObj,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };
                navigate({
                    search: convertObjectToQueryParam(updateDateFilter),
                });
            }
            const eventName = `Performance Comparison ${compareSwitch ? "Enabled" : "Disabled"}`;
            analyticsService.logEvent(eventName, {
                Page: getPathForAnalytics(location.pathname),
            });

            if (!compareSwitch) {
                handleDeleteParamKey("comparison_start_date", navigate);
                handleDeleteParamKey("comparison_end_date", navigate);
            }
        }
    };

    const closeDatePicker = () => {
        if (childRef.current) {
            childRef.current.closeDialog();
            handleDatePickerOpenAction(false);
        }
    };

    const handleDatePickerOpenAction = (isOpen: boolean) => {
        setCompareSwitch(compareToPast);
        setDatePickerMenu(isOpen);

        if (isOpen) {
            if (formattedCompareDates[0].startDate && formattedCompareDates[0].endDate) {
                setState({
                    selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
                    compare: {
                        startDate: new Date(formattedCompareDates[0].startDate),
                        endDate: new Date(formattedCompareDates[0].endDate),
                        key: "compare",
                    },
                });
            } else {
                setState({
                    selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
                    compare: {
                        ...getPastPeriod(formattedDates, true)[0],
                        key: "compare",
                    },
                });
            }
        }
    };

    const applyDateRangeChangesToUI = () => {
        let isAllValidDates = true;
        Object.values(state).forEach((value) => {
            if (!isValid(value.startDate) || !isValid(value.endDate)) {
                isAllValidDates = false;
            }
        });
        if (isAllValidDates) {
            closeDatePicker();
            changeCustomRange(state);
            dispatch(setCompare({ isCompareOn: compareSwitch }));
        }
    };

    const renderDateRangeButtonTitle = () => {
        return (
            <Stack direction="row" alignItems="center" gap={2}>
                <DateRangeIcon />
                {compareToPast && compareView ? (
                    <Typography component="div">
                        <Typography sx={{ fontSize: "15px" }}>{getRangeFormattedTitle(formattedDates)}</Typography>
                        <Typography sx={{ fontSize: "13px" }}>
                            {state.compare.startDate && getRangeFormattedTitle(formattedCompareDates)}
                        </Typography>
                    </Typography>
                ) : (
                    getRangeFormattedTitle(formattedDates)
                )}
            </Stack>
        );
    };

    const renderCompareDateRangeButtonTitle = (sidebar: StaticRange) => {
        return (
            <label style={{ width: "100%", cursor: "pointer" }}>
                {sidebar.label}
                {isCompareAvailable ? (
                    <Switch onChange={handleCompareRageSwitch} size="small" checked={compareSwitch} />
                ) : (
                    <Switch size="small" disabled checked={compareSwitch} sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                )}
            </label>
        );
    };

    const renderStaticSidebar = (sidebar: StaticRange) => {
        if (sidebar.label === "Compare") {
            return isCompareAvailable ? (
                renderCompareDateRangeButtonTitle(sidebar)
            ) : (
                <Tooltip title="Unavailable until modeling has ran">
                    {renderCompareDateRangeButtonTitle(sidebar)}
                </Tooltip>
            );
        }
        return <>{sidebar.label}</>;
    };

    // Make the buttons sticky to the bottom of the viewport
    useEffect(() => {
        const updateFooterPosition = () => {
            if (datePickerRef.current && buttonStackRef.current) {
                const datePicker = datePickerRef.current as HTMLDivElement;
                const buttonStack = buttonStackRef.current as HTMLDivElement;
                const buttonStackHeight = buttonStack.offsetHeight;
                const viewportHeight = window.innerHeight;
                const datePickerRect = datePicker.getBoundingClientRect();
                const datePickerBottom = datePickerRect.bottom;
                const difference = datePickerBottom - viewportHeight;

                datePicker.style.paddingBottom = `${buttonStackHeight}px`;

                if (difference > 0) {
                    buttonStack.style.bottom = "0";
                    buttonStack.style.transform = `translateY(${-difference}px)`;
                } else {
                    buttonStack.style.transform = "unset";
                }
            }
        };

        const observerCallback = () => {
            if (datePickerRef.current && buttonStackRef.current) {
                const buttonStack = buttonStackRef.current as HTMLDivElement;
                buttonStack.style.transitionDuration = "0.1s";
                updateFooterPosition();
                setTimeout(() => (buttonStack.style.transitionDuration = "0s"), 100); // wait for animation to complete
            }
        };

        const observer = new MutationObserver(observerCallback);

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        window.addEventListener("scroll", updateFooterPosition);
        window.addEventListener("resize", updateFooterPosition);
        updateFooterPosition();

        return () => {
            observer.disconnect();
            window.removeEventListener("scroll", updateFooterPosition);
            window.removeEventListener("resize", updateFooterPosition);
        };
    }, [compareSwitch, isDatePickerMenuOpen]);

    const onRangeChangeHandler = (item: RangeKeyDict) => {
        const key = Object.keys(item)[0];
        const innerKey = item[key].key;

        if (innerKey !== "compareSwitch") {
            const preset = getSelectedPreset(item[key], sideBar);
            if (wholeMonthSelected(state, key) && isNewFirstOfMonth(state, item, key) && isCorrectPreset(preset)) {
                item[key].startDate = startOfMonth(item[key].startDate!); // confirmed not to be null in if statement
                item[key].endDate =
                    preset === DefaultDateRangeInText.THIS_MONTH
                        ? subDays(new Date(), 1)
                        : startOfDay(endOfMonth(item[key].startDate!));
            }
            const updatedStateObj = getSelectedDateRange(key, innerKey || "", state, item);
            setState(updatedStateObj);

            const allInputsArr = document.querySelectorAll(".rdrDateDisplayItem input");
            let focusInput = allInputsArr[0] as HTMLElement;
            if (compareSwitch) {
                if (item[key].key === "compare" || item[key].key === "compareCustom") {
                    focusInput = allInputsArr[2] as HTMLElement;
                }
            }
            focusInput.focus();
        }
    };

    return (
        <LightMenu
            id="current-date-filter-btn"
            dataCy="currentDateFilterBtn"
            title={<>{renderDateRangeButtonTitle()}</>}
            isList={false}
            placement="bottom-end"
            transformOrigin="top right"
            extraButtonClass={isDatePickerMenuOpen ? classes.openMenuBtnStyle : classes.buttonClass}
            closeCustomEndIcon={<SVGIconRenderer icon="chevronUpIcon" />}
            openCustomEndIcon={<SVGIconRenderer icon="chevronDownIcon" />}
            ref={childRef}
            onClickHandleOpen={() => handleDatePickerOpenAction(true)}
            menuCloseHandle={() => handleDatePickerOpenAction(false)}
            overFlow={false}
        >
            <Stack
                className="range-selector"
                ref={datePickerRef}
                sx={{ zIndex: 10, position: "relative", height: "100%" }}
            >
                <DateRangePicker
                    onChange={(item) => onRangeChangeHandler(item)}
                    renderStaticRangeLabel={(sidebar: StaticRange) => renderStaticSidebar(sidebar)}
                    ranges={compareView && compareSwitch ? [state.selection, state.compare] : [state.selection]}
                    rangeColors={[DATE_RANGE_COLORS.selection, DATE_RANGE_COLORS.compare]}
                    dateDisplayFormat="MMM dd, yyyy"
                    months={2}
                    staticRanges={staticRanges}
                    minDate={minDate ? new Date(minDate) : undefined}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    editableDateInputs
                    showMonthAndYearPickers={false}
                    moveRangeOnFirstSelection
                    retainEndDateOnFirstSelection={false}
                    navigatorRenderer={() => {
                        return <></>;
                    }}
                    calendarFocus="backwards"
                    startDatePlaceholder="From"
                    endDatePlaceholder="To"
                    inputRanges={[]}
                    monthDisplayFormat="MMMM yyyy"
                    maxDate={endOfMonth(new Date())}
                />
                <Stack
                    ref={buttonStackRef}
                    flexDirection="row"
                    justifyContent="end"
                    gap="15px"
                    sx={{
                        padding: "15px 20px",
                        boxShadow: "inset 0px 1px 0px #bfbfbf",
                        bottom: 0,
                        width: "100%",
                        position: "absolute",
                        overflow: "hidden",
                        transitionDuration: "0.1s",
                        backgroundColor: "white",
                        borderRadius: "0 0 4px 4px",
                    }}
                >
                    <Button
                        sx={{ textTransform: "inherit", color: "#000000", fontSize: "15px", padding: "7px 30px" }}
                        variant="outlined"
                        onClick={closeDatePicker}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ textTransform: "inherit", fontSize: "15px", padding: "7px 30px" }}
                        variant="contained"
                        onClick={applyDateRangeChangesToUI}
                        id="btn-apply-range"
                        data-cy="btnApplyRange"
                    >
                        Apply
                    </Button>
                </Stack>
            </Stack>
        </LightMenu>
    );
};

export default ReactDateRangeSelect;
