import { FC, useContext, useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { uniqueId } from "lodash";

import { IForecast } from "../../../interfaces/entities/IForecast";
import {
    emptyForecast,
    getInterpolation,
} from "../../../components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { useGetForecastMutation } from "../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import { IErrors } from "../../../interfaces/entities/IErrors";
import { ForecastChart } from "../../../components/CampaignComponents/InsightsModal/ForecastTab/ForecastChart.v2";
import { IChartValue } from "../../../components/CampaignComponents/InsightsModal/ForecastTab/ForecastTab";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    MetricRate,
} from "../../../interfaces/entities/IScenario";
import { OptimizationOutcomeContext } from "../../../containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { getScenarioTypeLabel } from "src/services/optimizationPage/optimization";
import { SCENARIO_TYPE, getModelTypeFromScenarioType } from "src/consts/optimizationPage/optimizationPage";
import { getModeledMetricLabelBasedOnService } from "src/consts/performancePaidPage/performancePaidPage";
import { defaultRevenueSourceSelector } from "src/reduxState/slices/connectorsSlice";

interface IForecastChart {
    performanceCampaignData: any;
    scenario: IScenario;
    compareToLabel: string;
    campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation;
}

export const ScenarioForecastChart: FC<IForecastChart> = ({
    performanceCampaignData,
    scenario,
    compareToLabel,
    campaignRow,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { campaignId, connectorName, allocationSpend, spend } = performanceCampaignData;
    const isAmazonMetricSelected = [SCENARIO_TYPE.AMAZON_REVENUE, SCENARIO_TYPE.AMAZON_CAC].includes(
        scenario.scenarioType,
    );
    const isCacMetricSelected = [SCENARIO_TYPE.CAC, SCENARIO_TYPE.AMAZON_CAC].includes(scenario.scenarioType);
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);

    const [allForecastData, setAllForecastData] = useState<IForecast>(emptyForecast);
    const [tableValues, setTableValues] = useState<{
        [key: string]: IChartValue;
    }>({});

    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const [forecastData, { isLoading, isSuccess }] = useGetForecastMutation();

    const getValueDependOnMetricRate = (value: number) => {
        if (optimizationOutcomeContext?.metricRate === MetricRate.Daily) {
            return value;
        }
        return value / scenario.forecastTimeframe;
    };

    const getBoundValuesDependOnMetricRate = (value: number) => {
        if (optimizationOutcomeContext?.metricRate === MetricRate.Daily) {
            return value;
        }
        return value * scenario.forecastTimeframe;
    };

    const getMinimumMaximumSpend = () => {
        return Math.max(getValueDependOnMetricRate(spend), getValueDependOnMetricRate(allocationSpend));
    };

    const getForecastData = () => {
        forecastData({
            orgId: currentOrgId,
            campaignId,
            connectorName,
            modelType: getModelTypeFromScenarioType(scenario.scenarioType),
            minimumMaximumSpend: getMinimumMaximumSpend(),
        })
            .unwrap()
            .then((response: IForecast | IErrors) => {
                if ("error" in response || response === null) {
                    if (response === null) {
                        throw new Error("null data response received");
                    }
                    throw new Error(response.error[0]);
                }
                setAllForecastData(response);
                const get28DaysForecast = getInterpolation(response, getValueDependOnMetricRate(allocationSpend));
                const get28DaysDecreaseForecast = getInterpolation(response, getValueDependOnMetricRate(spend));

                const updatedTableValues = {
                    forecast1: {
                        heading: `Expected (${compareToLabel})`,
                        revenue: get28DaysDecreaseForecast.saturatedRevenue,
                        worstCase: getBoundValuesDependOnMetricRate(get28DaysDecreaseForecast.worstCase),
                        bestCase: getBoundValuesDependOnMetricRate(get28DaysDecreaseForecast.bestCase),
                        spend: +getValueDependOnMetricRate(spend).toFixed(0),
                        spendPercentage: 0,
                    },

                    forecast2: {
                        heading: getScenarioTypeLabel[scenario.scenarioType],
                        revenue: get28DaysForecast.saturatedRevenue,
                        worstCase: getBoundValuesDependOnMetricRate(get28DaysForecast.worstCase),
                        bestCase: getBoundValuesDependOnMetricRate(get28DaysForecast.bestCase),
                        spend: +getValueDependOnMetricRate(allocationSpend).toFixed(0),
                        spendPercentage: 0,
                    },
                };

                setTableValues(updatedTableValues);
            })
            .catch((error) => {
                enqueueSnackbar(
                    `${
                        error.status
                            ? `Error in fetching forecast data for chart (Code: ${error.status})`
                            : `Something went wrong`
                    } `,
                    {
                        id: uniqueId(),
                        variant: "error",
                    },
                );
            });
    };

    useEffect(() => {
        getForecastData();
    }, []);

    return (
        <>
            {!isLoading && isSuccess ? (
                <ForecastChart
                    performanceCampaignData={performanceCampaignData}
                    allForecastData={allForecastData}
                    tableValues={tableValues}
                    chartStyle={{
                        chartContainer: {
                            height: "350px",
                            width: "980px",
                            maxWidth: "100%",
                        },
                        grid: {
                            show: false,
                            containLabel: true,
                            left: "5%",
                            right: "28%",
                            bottom: "15%",
                        },
                        legend: {
                            orient: "vertical",
                            right: "1%",
                            bottom: "20%",
                            itemGap: 15,
                            selected: {
                                "Worst Case": false,
                                "Best Case": false,
                                Confidence: false,
                            },
                            textStyle: {
                                fontSize: 14,
                            },
                            padding: [0, 0, 0, 25],
                            itemWidth: 32,
                        },
                    }}
                    isCacChart={isCacMetricSelected}
                    campaignRow={campaignRow}
                    modeledMetricLabel={getModeledMetricLabelBasedOnService(
                        defaultRevenueSource,
                        true,
                        isAmazonMetricSelected,
                    )}
                />
            ) : (
                <Skeleton variant="rectangular" animation="wave" width="850px" height="350px" />
            )}
        </>
    );
};
