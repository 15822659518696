import React, { memo } from "react";
import { Typography, Stack } from "@mui/material";
import { Button } from "@prescientai/component-library";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { css } from "@emotion/css";
import AddIcon from "@mui/icons-material/Add";

import CustomBreadcrumbs from "../CustomBreadCrumbs/CusomBreadCrumbs";
import { allAvailablePaths } from "../../containers/Home/ProtectedOrgRoutes";
import { toUnderlineCase } from "src/services/utils";
import { useTheme } from "@mui/system";

interface IPageHeader {
    pageHeading: string;
    buttonName?: string;
    disabled?: boolean;
    dateFilterComponent?: JSX.Element;
    pastDateFilter?: JSX.Element;
    iconToBeRendered?: string;
    title?: string;
    customStyle?: any;
    showBreadcrumbs?: boolean;
    welcomeMessage?: string;
    tabs?: any;
    stats?: any;
    onAdd?(): void;
}

const useStyles = () => ({
    headerWrapper: css({
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid rgba(0, 0, 0, 0.50)",
    }),
});

export const PageHeader: React.FC<IPageHeader> = memo(
    ({
        pageHeading,
        buttonName,
        onAdd,
        disabled,
        dateFilterComponent,
        pastDateFilter,
        iconToBeRendered = "plusIcon",
        tabs,
        stats,
        title = "",
        customStyle,
        showBreadcrumbs = true,
        welcomeMessage = false,
    }) => {
        const breadCrumbs = useBreadcrumbs(allAvailablePaths);
        const classes = useStyles();
        const theme = useTheme();

        return (
            <Stack
                className={classes.headerWrapper}
                gap={welcomeMessage ? 1 : 3}
                sx={{ padding: tabs ? "40px 32px 0 32px" : "32px" }}
            >
                {welcomeMessage && <Typography color="text.primary">{welcomeMessage}</Typography>}
                <Stack className="page-header-top" gap={2}>
                    {showBreadcrumbs && <CustomBreadcrumbs title={title} breadCrumbs={breadCrumbs} />}
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography data-cy="pageHeader" variant="h1" color="text.primary">
                            {pageHeading}
                        </Typography>
                        {pastDateFilter && <Stack>{pastDateFilter}</Stack>}
                        {dateFilterComponent
                            ? dateFilterComponent
                            : buttonName && (
                                  <Button
                                      color="primary"
                                      variant="contained"
                                      startIcon={<AddIcon />}
                                      onClick={onAdd}
                                      disabled={disabled}
                                      data-cy={toUnderlineCase(buttonName)}
                                      sx={{ minHeight: "54px", /* backgroundColor: theme.palette.primary.main */ }}
                                  >
                                      {buttonName}
                                  </Button>
                              )}
                    </Stack>
                </Stack>
                {stats && stats}
                {tabs && <Stack pt={1}>{tabs}</Stack>}
            </Stack>
        );
    },
);
