import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import InfoIcon from "@mui/icons-material/Info";
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    AccordionSummaryProps,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    StackProps,
    Typography,
    styled,
    accordionSummaryClasses,
    AccordionProps,
    Grid,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import capitalize from "lodash/capitalize";
import { FC, useEffect, useMemo, useState } from "react";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { FORMATS } from "src/enums/Formats";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { formatValue } from "src/services/utils";
import { DeclineTooltip } from "./DeclineTooltip";
import { MetricBreakdownTable } from "./MetricBreakdownTable";
import { useSelector } from "react-redux";
import { isSuperAdminSelector } from "src/reduxState/slices/userSlice";
import { useSearchParams } from "react-router-dom";

interface IMetricVerificationItemProps {
    metricData: IQaMetric;
    onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, metric: IQaMetric, selectedValue: boolean) => void;
    onExpectedValueChange?: (value: string, id: string) => void;
    onDeclineReasonSave?: (metricData: IQaMetric) => void;
    onDeclineReasonClose?: (metricData: IQaMetric) => void;
    userReportedData?: {
        id: string;
        value: string;
    };
    currentCheckboxAnchorEl: any;
    isDataSubmitted: boolean;
}

export const valueFromType = (value: string | number, type: string, includeType = false) => {
    const isSessions = type.toLowerCase() === "sessions";

    const str = isSessions ? formatValue(value, FORMATS.NUMERIC, 0) : formatValue(value, FORMATS.DOLLAR, 0);

    if (includeType) {
        return isSessions ? `${str} sessions` : `${str} in ${capitalize(type)}`;
    } else {
        return str;
    }
};

export const MetricVerificationItem: FC<IMetricVerificationItemProps> = ({
    metricData,
    onCheckboxChange,
    onExpectedValueChange,
    onDeclineReasonSave,
    onDeclineReasonClose,
    userReportedData,
    currentCheckboxAnchorEl,
    isDataSubmitted,
}) => {
    const shouldShowUser = useMemo(() => !!metricData.lastResponse?.user?.firstName, [metricData]);

    const checkboxes = isDataSubmitted ? (
        metricData.isAccepted ? (
            <Typography variant="body2">Approved</Typography>
        ) : (
            <Stack alignItems="flex-end">
                <Typography variant="body2" color="text.disabled">
                    Sent for Review
                </Typography>
                {metricData.userReportedValue > 0 && (
                    <Typography variant="body2" color="text.disabled">
                        {`Expected Value: ${valueFromType(metricData.userReportedValue, metricData.metricType)}`}
                    </Typography>
                )}
            </Stack>
        )
    ) : (
        <FormGroup
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={metricData.isAccepted === true}
                        onChange={(e) => onCheckboxChange && onCheckboxChange(e, metricData, true)}
                    />
                }
                label="Accept"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={metricData.isAccepted === false}
                        onChange={(e) => onCheckboxChange && onCheckboxChange(e, metricData, false)}
                    />
                }
                label="Decline"
            />
        </FormGroup>
    );

    const disabledContent = (
        <Stack alignItems="flex-end">
            <Typography variant="body2" sx={{ maxWidth: "50%", textAlign: "right" }}>
                {`${metricData.connector?.connectorName} syncing is in progress. We'll contact you once data is fully synced and ready for verification.`}
            </Typography>
        </Stack>
    );

    const accordionDetails = metricData.breakdownByMonth ? (
        metricData.breakdownByMonth.length ? (
            <MetricBreakdownTable months={metricData.breakdownByMonth} metricType={metricData.metricType} />
        ) : (
            <Typography ml={4}>No {metricData.metricType.toLowerCase()} reported.</Typography>
        )
    ) : null;

    const isSuperadmin = useSelector(isSuperAdminSelector);

    return (
        <AccordionOrStack
            stackProps={{ sx: { opacity: metricData.disabled ? 0.5 : 1 } }}
            isAccordion={!!metricData.breakdownByMonth}
            accordionDetails={accordionDetails}
        >
            <ListItem secondaryAction={metricData.disabled ? disabledContent : checkboxes}>
                <ListItemAvatar>
                    <DataSourceAvatar
                        programmaticName={metricData.connector?.dataSource?.programmaticName}
                        sx={{ width: "40px", height: "40px" }}
                    />
                </ListItemAvatar>
                <Grid container>
                    <Grid item xs={shouldShowUser ? 3 : 12} xl={shouldShowUser ? 2 : 12}>
                        <ListItemText
                            sx={{ mr: 4 }}
                            primary={`${metricData.connector?.connectorName}`}
                            secondary={
                                !metricData.disabled
                                    ? valueFromType(metricData.expectedValue, metricData.metricType, true)
                                    : null
                            }
                        />
                    </Grid>
                    {shouldShowUser && (
                        <Grid item xs={3} sm={5} alignContent="center">
                            <Typography color="text.secondary" fontStyle="italic">
                                {`Last updated by ${metricData.lastResponse?.user?.firstName} ${
                                    metricData.lastResponse?.user?.lastName
                                } on ${format(parseISO(metricData.lastResponse?.createdAt), "MM/dd/yyy")}`}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {metricData.isAccepted === false && metricData.isModalOpen === true && (
                    <DeclineTooltip
                        onExpectedValueChange={onExpectedValueChange}
                        metricData={metricData}
                        userReportedData={userReportedData}
                        currentCheckboxAnchorEl={currentCheckboxAnchorEl}
                        onDeclineReasonClose={onDeclineReasonClose}
                        onDeclineReasonSave={onDeclineReasonSave}
                    />
                )}
            </ListItem>
            {!isDataSubmitted && metricData.userReportedValue ? (
                <Typography variant="body2" mr={2} pb={1} color="error.main" textAlign="right">
                    {`Expected Value: ${valueFromType(metricData.userReportedValue, metricData.metricType)}`}
                </Typography>
            ) : (
                <></>
            )}
            {!isDataSubmitted && metricData.message && (
                <Stack sx={{ padding: "0 0 8px 72px" }} gap="10px" alignItems="center" direction="row">
                    <Divider orientation="vertical" sx={{ height: "40px" }} />
                    <InfoIcon
                        sx={(theme) => ({
                            fontSize: "16px",
                            color: theme.palette.primary.main,
                        })}
                    />
                    <Typography variant="caption" color="text.primary">
                        {metricData.message}
                    </Typography>
                </Stack>
            )}
            {metricData.startDateOverride && metricData.endDateOverride && (
                <Stack sx={{ padding: "0 0 8px 72px" }} gap="10px" alignItems="center" direction="row">
                    <Divider orientation="vertical" sx={{ height: "40px" }} />
                    <InfoIcon
                        sx={(theme) => ({
                            fontSize: "16px",
                            color: theme.palette.primary.main,
                        })}
                    />
                    <Typography variant="caption" color="text.primary">
                        {`Note: this connector is using data from ${format(
                            parseISO(metricData.startDateOverride),
                            "MM/dd/yyyy",
                        )} to ${format(parseISO(metricData.endDateOverride), "MM/dd/yyyy")}`}
                    </Typography>
                </Stack>
            )}
        </AccordionOrStack>
    );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />} {...props} />
))(({ theme }) => ({
    flexDirection: "row-reverse",
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
        transform: "rotate(90deg)",
    },
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        "&::before": {
            display: "none",
        },
    }),
);

interface IAccordionOrStackProps {
    isAccordion?: boolean;
    children: React.ReactNode;
    accordionDetails?: React.ReactNode;
    stackProps: StackProps;
}

const AccordionOrStack: React.FC<IAccordionOrStackProps> = ({
    isAccordion = false,
    children,
    accordionDetails,
    stackProps,
}) => {
    const [params] = useSearchParams();
    const [isOpen, setIsOpen] = useState(params.get("rows") === "expanded");

    useEffect(() => {
        const newSetting = params.get("rows");
        if (newSetting === "expanded") {
            setIsOpen(true);
        } else if (newSetting === "collapsed") {
            setIsOpen(false);
        }
    }, [params]);

    return isAccordion && children ? (
        <Accordion elevation={0} disableGutters expanded={isOpen} onChange={() => setIsOpen((prev) => !prev)}>
            <AccordionSummary>
                <Stack {...stackProps} width="100%" ml={2}>
                    {children}
                </Stack>
            </AccordionSummary>
            {accordionDetails && <AccordionDetails>{accordionDetails}</AccordionDetails>}
        </Accordion>
    ) : (
        <Stack {...stackProps}>{children}</Stack>
    );
};
