import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, Typography, useTheme } from "@mui/material";
import { Button } from "@prescientai/component-library";

import { ListItemLink } from "../core/Drawer/ListItemLinks";
import {
    selectCurrentlyViewingCode,
    selectDefaultLastReportedDayCount,
} from "../../reduxState/slices/organizationSlice";
import { isSuperAdminSelector } from "../../reduxState/slices/userSlice";
import { formattedCurrentDateStringSelector } from "../../reduxState/slices/dateFilterSlice";
import { DEFAULT_STATE_FOR_SAVED_VIEW, setFilterView } from "../../reduxState/slices/filterViewSlice";
import {
    SUPERADMIN_ANNOUNCEMENT_PATH,
    SUPERADMIN_EVENT_LOGS_PATH,
    SUPERADMIN_FEATURES_PATH,
    SUPERADMIN_MEMBERS_PATH,
    SUPERADMIN_ORG_PATH,
    PERFORMANCE_PATH,
    EMAIL_PATH,
    OPTIIMIZATION_PATH,
    HOME_PATH,
    DATA_INTEGRATION_SOURCES_PATH,
    DATA_INTEGRATION_SERVICES_PATH,
    DATA_INTEGRATION_PROVIDERS_PATH,
    SUPERADMIN_SLACK_ALERTS_PATH,
} from "../../consts/path/path";
import { klaviyoEmailPageSelector, optimizationPageSelector } from "../../reduxState/slices/featuresSlice";
import { EMAIL_ATTRIBUTION_HEADERS } from "../../consts/emailPage/emailPage";
import { getCurrentPerformanceTab } from "src/services/utils";

const useTabStyles = (theme: Theme) => ({
    listItem: css({
        padding: "25px 16px",
        height: "80px",
        fontSize: "15px",
        color: theme.palette.text.secondary,
        "&.active": {
            color: theme.palette.text.primary,
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
        },
        "&.active .MuiListItemText-primary, &.active .MuiListItemIcon-root": {
            color: theme.palette.text.primary,
        },
    }),
    moreListItem: css({
        padding: "6px 16px",
        "&.active": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
    }),
});

const Home = "Home";
const PerformancePagePaid = "Performance";
const optimization = "Optimization";
const Email = "Email";

const Organizations = "Organizations";
const Members = "Users";
const SUPER_ADMIN_MENU_ITEMS = [
    { id: "adminFeatures", to: SUPERADMIN_FEATURES_PATH, primary: "Features" },
    { id: "eventLogs", to: SUPERADMIN_EVENT_LOGS_PATH, primary: "Event Logs" },
    { id: "announcement", to: SUPERADMIN_ANNOUNCEMENT_PATH, primary: "Announcement" },
    { id: "dataSources", to: DATA_INTEGRATION_SOURCES_PATH, primary: "Data Integrations" },
    { id: "slackAlerts", to: SUPERADMIN_SLACK_ALERTS_PATH, primary: "Slack Alerts" },
];

export const HeaderTabs = () => {
    const dispatch = useDispatch();
    const classes = useTabStyles(useTheme());
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const currentDate = useSelector(formattedCurrentDateStringSelector);
    const klaviyoEmailPage = useSelector(klaviyoEmailPageSelector);
    const isOptimizationPageVisible = useSelector(optimizationPageSelector);
    const defaultLastReportedDayCount = useSelector(selectDefaultLastReportedDayCount);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMoreMenuOpen = Boolean(anchorEl);
    const isMoreMenuActive = [
        SUPERADMIN_FEATURES_PATH,
        SUPERADMIN_EVENT_LOGS_PATH,
        SUPERADMIN_ANNOUNCEMENT_PATH,
        DATA_INTEGRATION_SOURCES_PATH,
        DATA_INTEGRATION_SERVICES_PATH,
        DATA_INTEGRATION_PROVIDERS_PATH,
        SUPERADMIN_SLACK_ALERTS_PATH,
    ].includes(location.pathname);

    const hiddenEmailCols = EMAIL_ATTRIBUTION_HEADERS.filter((c) => c.hidden).map((c) => c.id);

    const resetPerformanceView = () => {
        dispatch(
            setFilterView({
                isSavedViewOn: false,
                data: DEFAULT_STATE_FOR_SAVED_VIEW,
            }),
        );
    };

    const performanceUrl = `/org/${orgCode}${PERFORMANCE_PATH}/${getCurrentPerformanceTab()}`;

    const emailUrl = `/org/${orgCode}${EMAIL_PATH}${
        currentDate && currentDate[0]?.startDate && currentDate[0]?.endDate
            ? `?start_date=${currentDate[0].startDate}&end_date=${
                  currentDate[0].endDate
              }&excluded_columns=${hiddenEmailCols.join(",")}`
            : ""
    }`;

    const handleMoreClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {location.pathname.startsWith("/adm") && isSuperAdmin ? (
                <>
                    <ListItemLink
                        key="adminOrganizations"
                        id="adminOrganizations"
                        to={SUPERADMIN_ORG_PATH}
                        primary={Organizations}
                        className={classes.listItem}
                    />
                    <ListItemLink
                        key="adminMembers"
                        id="adminMembers"
                        to={SUPERADMIN_MEMBERS_PATH}
                        primary={Members}
                        className={classes.listItem}
                    />
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        aria-controls={isMoreMenuOpen ? "basic-menu" : undefined}
                        aria-expanded={isMoreMenuOpen ? "true" : undefined}
                        onClick={handleMoreClick}
                        endIcon={<ArrowDropDownIcon />}
                        sx={{
                            padding: "25px 10px",
                            height: "80px",
                            borderRadius: 0,
                            color: isMoreMenuActive ? "#000000" : "rgba(0, 0, 0, 0.6)",
                            borderBottom: isMoreMenuActive ? "3px solid #1FD25A" : undefined,
                        }}
                        data-cy="moreLink"
                    >
                        <Typography>More</Typography>
                    </Button>
                    <Menu anchorEl={anchorEl} open={isMoreMenuOpen} onClose={handleMoreClose}>
                        {SUPER_ADMIN_MENU_ITEMS.map(({ id, to, primary }) => (
                            <ListItemLink
                                key={id}
                                id={id}
                                to={to}
                                primary={primary}
                                onClick={handleMoreClose}
                                className={classes.moreListItem}
                                data-cy={id}
                            />
                        ))}
                    </Menu>
                </>
            ) : (
                <>
                    <ListItemLink
                        key={Home}
                        id={Home}
                        to={`/org/${orgCode}${HOME_PATH}`}
                        primary={Home}
                        className={classes.listItem}
                        data-cy={Home}
                    />
                    <ListItemLink
                        key={PerformancePagePaid}
                        id={PerformancePagePaid}
                        to={performanceUrl}
                        primary={PerformancePagePaid}
                        className={classes.listItem}
                        data-cy={PerformancePagePaid}
                        onClick={resetPerformanceView}
                    />
                    {isOptimizationPageVisible && (
                        <ListItemLink
                            key={optimization}
                            id={optimization}
                            to={`/org/${orgCode}${OPTIIMIZATION_PATH}`}
                            primary={optimization}
                            className={classes.listItem}
                            data-cy={optimization}
                        />
                    )}
                    {klaviyoEmailPage && (
                        <ListItemLink
                            key={Email}
                            id={Email}
                            to={emailUrl}
                            primary={Email}
                            className={classes.listItem}
                            data-cy={Email}
                        />
                    )}
                </>
            )}
        </>
    );
};
