import { css } from "@emotion/css";
import { Divider, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from "date-fns";
import { toDate } from "date-fns-tz";
import capitalize from "lodash/capitalize";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Loader from "src/components/core/Loader/Loader";
import { IConnector } from "src/interfaces/IConnector";
import { IDictionary } from "src/interfaces/IDictionary";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { useGetQaMetricsQuery, useGetQaMetricTypesQuery } from "src/reduxState/apis/qaMetricsApi";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { userSuperAdminRoleSelector } from "src/reduxState/slices/userSlice";
import { formatMetricsData } from "src/services/connectors/connectors";
import { MetricsList } from "../Connectors/DataVerify/sharedComponent/MetricList";
import { ApiError } from "../Errors/ApiError";

const useStyles = () => ({
    boxStyle: css({
        border: "1px solid rgba(0, 0, 0, 0.5)",
        padding: "30px 30px 50px",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "#000000",
    }),
});

function getLastResponder(metrics: IQaMetric[]) {
    let latestResponse = null;

    for (const metric of metrics) {
        const currentResponse = metric.lastResponse;

        if (!latestResponse) {
            latestResponse = currentResponse;
        } else {
            const currentCreatedAt = new Date(currentResponse.createdAt);
            const latestCreatedAt = new Date(latestResponse.createdAt);

            if (currentCreatedAt > latestCreatedAt) {
                latestResponse = currentResponse;
            }
        }
    }

    return { ...latestResponse?.user, createdAt: latestResponse?.createdAt };
}

export const SettingsDataQA = () => {
    const classes = useStyles();

    const orgId = useSelector(selectCurrentlyViewingId);
    const connectors: IConnector[] = useSelector(connectorsSelector);
    const isSuperadmin = useSelector(userSuperAdminRoleSelector);
    const {
        data: qaMetricsQuestion = { data: [], meta: { startDate: "", endDate: "" } },
        isLoading,
        error,
    } = useGetQaMetricsQuery({ orgId, includes: isSuperadmin ? "expected_metric_by_month" : null });
    const { data: metricTypes = [], isLoading: isMetricsTypeLoading } = useGetQaMetricTypesQuery({ orgId });

    const lastSubmission = useMemo(() => getLastResponder(qaMetricsQuestion.data), [qaMetricsQuestion.data]);

    const [metricsWithConnector, setMetricsWithConnector] = useState<IQaMetric[]>([]);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        if (qaMetricsQuestion.data.length && metricTypes.length && connectors.length) {
            const formattedData = formatMetricsData(qaMetricsQuestion.data, metricTypes, connectors);
            setMetricsWithConnector(formattedData);
        }
    }, [qaMetricsQuestion, metricTypes, connectors]);

    const categorizedMetrics = useMemo(() => {
        const metricsToShow = metricsWithConnector;

        const metrics: IDictionary<IQaMetric[]> = metricsToShow.length
            ? metricsToShow.reduce((metrics, item) => {
                  (metrics[item.metricType] = metrics[item.metricType] || []).push(item);
                  return metrics;
              }, {} as IDictionary<IQaMetric[]>)
            : {};

        Object.keys(metrics).forEach((metricType) => {
            metrics[metricType].sort((a, b) => {
                const nameA = a.connector?.connectorName?.toLowerCase() || "";
                const nameB = b.connector?.connectorName?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            });
        });

        return metrics;
    }, [metricsWithConnector]);

    if (isLoading || isMetricsTypeLoading) {
        return <Loader />;
    }

    const handleToggle = () => {
        const newParams = new URLSearchParams();
        if (params.get("rows") === "expanded") {
            newParams.set("rows", "collapsed");
        } else {
            newParams.set("rows", "expanded");
        }
        setParams(newParams);
    };

    return (
        <Stack gap="30px" sx={{ marginTop: 3 }} className={classes.boxStyle}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <h2 style={{ margin: 0, color: "inherit", fontSize: "28px" }}>Data QA</h2>
            </Box>
            {error ? (
                <ApiError errMsg={`Fetch QA metrics - ${JSON.stringify(error)}`} />
            ) : (
                <Stack gap={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start">
                        <Stack gap={1}>
                            <Typography component="div">
                                Metrics Timeframe:{" "}
                                <Typography fontWeight={700} component="span">
                                    {qaMetricsQuestion.meta.startDate && qaMetricsQuestion.meta.endDate
                                        ? ` ${format(toDate(qaMetricsQuestion.meta.startDate), "MMMM d")} - ${format(
                                              toDate(qaMetricsQuestion.meta.endDate),
                                              "MMMM d, yyyy",
                                          )}`
                                        : " --"}
                                </Typography>
                            </Typography>
                            {isSuperadmin && lastSubmission.createdAt && (
                                <Typography variant="body2" color="text.secondary">
                                    {`${lastSubmission.firstName} ${
                                        lastSubmission.lastName
                                    } clicked submit on  ${format(parseISO(lastSubmission.createdAt), "MM/dd/yyyy")}`}
                                </Typography>
                            )}
                        </Stack>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={params.get("rows") === "expanded"}
                                    onChange={handleToggle}
                                    color="primary"
                                />
                            }
                            label="Expand all Metrics"
                        />
                    </Stack>
                    <Stack>
                        {metricTypes.map((metricType) => (
                            <Stack key={metricType.name}>
                                <Stack gap={1}>
                                    <Typography variant="h6">{capitalize(metricType.name)}</Typography>
                                    <Divider variant="fullWidth" sx={{ height: "2px", width: "100%" }} />
                                </Stack>
                                {!categorizedMetrics[metricType.name]?.length ? (
                                    <Typography variant="subtitle1" color="text.secondary" m={1} mb={2}>
                                        No metrics accepted/submitted
                                    </Typography>
                                ) : (
                                    <MetricsList
                                        metricsWithConnector={categorizedMetrics[metricType.name]}
                                        isDataSubmitted={true}
                                    />
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
