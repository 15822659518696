import { createSlice, createSelector } from "@reduxjs/toolkit";
import union from "lodash/union";
import differenceInDays from "date-fns/differenceInDays";

import { RootState } from "../stores/store";
import { logoutUser } from "../actions/auth";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IMember } from "../../interfaces/entities/IMember";
import { SubscriptionStatusKeys } from "../../enums/SubscriptionStatusKeys";
import { DEFAULT_ORG_PATH } from "../../consts/path/path";
import { ascendingComparator } from "../../utils/sort";
import { checkIsIncompletePagesExist } from "src/services/onboardingPage/onboardingPage";
import { OnboardingStateValue } from "src/consts/onboardingPage/onboardingPage";
import { subDays } from "date-fns";

export interface IOrganizationState {
    order: string[];
    byId: { [key: string]: IOrganization };
    mapCodeToId: { [key: string]: string };
    currentlyViewingCode: string;
    currentlyViewingId: string;
}

const initialState: IOrganizationState = {
    order: [],
    byId: {},
    mapCodeToId: {},
    currentlyViewingCode: "",
    currentlyViewingId: "",
};

const setOrganizationsWithPath = (item: IOrganization) => {
    return { ...item, defaultRedirectPath: DEFAULT_ORG_PATH };
};

export const updateOrganizationState = (
    state: IOrganizationState,
    action: {
        type: string;
        payload: { companies: IOrganization[]; isSuperAdmin: boolean };
    },
): IOrganizationState => {
    const actionType = action.type;
    const { companies = [], isSuperAdmin } = action.payload;

    const newState: IOrganizationState = {
        ...state,
        byId: {},
        order: [],
        mapCodeToId: {},
    };

    companies.forEach((item) => {
        const orgWithPath = setOrganizationsWithPath(item);
        newState.byId[item.id] = orgWithPath;
        newState.order.push(item.id);
        newState.mapCodeToId[item.code] = item.id;
    });

    if (isSuperAdmin && actionType === "organizations/setOrganizations") {
        return newState;
    }

    if (actionType === "organizations/setOrganizationsOnSignIn") {
        return { ...newState, currentlyViewingCode: companies[0].code, currentlyViewingId: companies[0].id };
    }

    if (isSuperAdmin) {
        return {
            byId: { ...state.byId, ...newState.byId },
            order: union(state.order, newState.order),
            mapCodeToId: { ...state.mapCodeToId, ...newState.mapCodeToId },
            currentlyViewingCode: state.currentlyViewingCode || companies[0].code,
            currentlyViewingId: state.currentlyViewingId || companies[0].id,
        };
    }

    return {
        ...newState,
        currentlyViewingCode: state.currentlyViewingCode || companies[0].code,
        currentlyViewingId: state.currentlyViewingId || companies[0].id,
    };
};

const organizationsSlice = createSlice({
    name: "organizations",
    initialState,
    reducers: {
        setOrganizations: (state, action) =>
            updateOrganizationState(state, {
                ...action,
                payload: { companies: action.payload, isSuperAdmin: true },
            }),
        editOrganizationById: (state, action) => {
            const { id, data } = action.payload;
            state.byId[id] = data;
        },
        setOrganizationsOnSignIn: (state, action) => updateOrganizationState(state, action),
        setCurrentlyViewing: (state, { payload }) => {
            const currentlyViewingId = state.mapCodeToId[payload];
            if (currentlyViewingId) {
                state.currentlyViewingCode = payload;
                state.currentlyViewingId = currentlyViewingId;
            }
        },
        addOrganizations: (state, action) => updateOrganizationState(state, action),
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const {
    setOrganizations,
    setOrganizationsOnSignIn,
    setCurrentlyViewing,
    addOrganizations,
    editOrganizationById,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;

// Selectors
export const organizationsSelector = (state: RootState) => state.organizations;

export const selectListOfOrganizations = createSelector(organizationsSelector, (data) => data.byId);

export const selectMapCodeToId = createSelector(organizationsSelector, (data) => data.mapCodeToId);
export const selectCompanyIdByCode = createSelector(
    [selectMapCodeToId, (_, code: string): string => code],
    (map, code: string) => {
        return map && map[code];
    },
);

export const OrganizationsOrderSelector = createSelector(organizationsSelector, (data) => data.order);
export const OrganizationsListedByOrderSelector = createSelector(
    [selectListOfOrganizations, OrganizationsOrderSelector],
    (byId, order) => {
        return order.reduce((acc: IOrganization[], id) => {
            if (byId) {
                acc.push({ ...byId[id] });
            }
            return acc;
        }, []);
    },
);

export const parentOrganizationSelector = createSelector(OrganizationsListedByOrderSelector, (orgsList) =>
    orgsList.filter((item: IOrganization) => item.parentId == null).sort((a, b) => ascendingComparator(a, b, "name")),
);

export const selectCurrentlyViewingCode = createSelector(organizationsSelector, (data) => data.currentlyViewingCode);
export const selectCurrentlyViewingId = createSelector(organizationsSelector, (data) => data.currentlyViewingId);

export const memberRoleForContextSelector = createSelector(
    [selectCurrentlyViewingId, (_, member: IMember): IMember => member],
    (id, { rolesByCompanyId }) => {
        return rolesByCompanyId && rolesByCompanyId[id] && rolesByCompanyId[id].role;
    },
);

export const selectCurrentlyViewingCompanyById = createSelector(
    [selectListOfOrganizations, selectCurrentlyViewingId],
    (byId, currentlyViewingId) => byId && byId[currentlyViewingId],
);

export const getAssignedCSMCalenderLink = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company.customerSuccessManager?.calendarUrl || "https://calendar.app.google/yWDFVm5nnQxxFK9G7",
);

export const selectFeaturesOfCurrentlyViewingCompany = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company?.features || [],
);

export const isOnboardingCompletedOfCurrentCompany = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => !checkIsIncompletePagesExist(company?.onboardingState),
);

export const selectCurrentlyViewingOrgIsPaused = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company?.dataState === "PAUSED",
);

export const dataQaReady = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company?.onboardingState?.dataQa === OnboardingStateValue.ready,
);

export const selectSubscriptionStatus = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company?.subscription?.status,
);

export const selectHasAccessToDashboard = createSelector(
    selectCurrentlyViewingCompanyById,
    (company) => company?.subscription?.hasAccessToDashboard,
);

export const selectPeriodExpiredDaysCount = createSelector(selectCurrentlyViewingCompanyById, (company) =>
    company?.subscription?.currentPeriodEnd
        ? differenceInDays(new Date(company.subscription.currentPeriodEnd), new Date())
        : -1,
);

export const selectPeriodExpired = createSelector(selectCurrentlyViewingCompanyById, (company) =>
    company?.subscription?.currentPeriodEnd
        ? new Date(company.subscription.currentPeriodEnd).getTime() < new Date().getTime()
        : false,
);

export const selectIsActiveTrial = createSelector(
    [selectSubscriptionStatus, selectPeriodExpired],
    (status, expired) => {
        return status === SubscriptionStatusKeys.TRIALING && !expired;
    },
);

export const selectIsExpiredTrial = createSelector(
    [selectSubscriptionStatus, selectPeriodExpired],
    (status, expired) => {
        return status === SubscriptionStatusKeys.TRIALING && expired;
    },
);

export const selectIsActiveSubscription = createSelector(
    [selectIsActiveTrial, selectSubscriptionStatus],
    (isActiveTrial, status) => isActiveTrial || status === SubscriptionStatusKeys.ACTIVE,
);

export const selectSubscriptionPastDueAndHasAccess = createSelector(
    [selectSubscriptionStatus, selectHasAccessToDashboard],
    (status, hasAccessToDashboard) => {
        return status === SubscriptionStatusKeys.PAST_DUE && hasAccessToDashboard;
    },
);

export const selectDefaultLastReportedDate = createSelector(
    [selectListOfOrganizations, selectCurrentlyViewingId],
    (byId, currentlyViewingId) =>
        byId && subDays(new Date(), byId[currentlyViewingId]?.defaultLastReportedDateDelay || 1),
);

export const selectDefaultLastReportedDayCount = createSelector(
    [selectListOfOrganizations, selectCurrentlyViewingId],
    (byId, currentlyViewingId) => byId[currentlyViewingId]?.defaultLastReportedDateDelay || 1,
);
