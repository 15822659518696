import baseCreateApi from "./baseCreateApi";
import { deleteConnectorById, setConnector, setConnectorsLoading } from "../slices/connectorsSlice";
import {
    getLegacyCustomConnectorId,
    transformConnectors,
    transformConnector,
    transformCustomConnector,
} from "../../services/connectors/connectors";

import { ICreateConnectorResponse } from "../../interfaces/connectors/ICreateConnectorResponse";
import { ICreateCustomConnectorResponse } from "src/interfaces/connectors/ICreateCustomConnectorResponse";
import { IGetConnectorResponse, IGetSingleConnectorResponse } from "../../interfaces/connectors/IGetConnectorResponse";
import { IDictionary } from "../../interfaces/IDictionary";
import { IGetLegacyCustomConnectorCredentialResponse } from "src/interfaces/connectors/IGetLegacyCustomConnectorCredentialResponse";
import { ICreateLegacyCustomConnectorCredentialResponse } from "src/interfaces/connectors/ICreateLegacyCustomConnectorCredentialResponse";
import { IConnector } from "src/interfaces/IConnector";

const CONNECTORS_API_PATH = "/connectors";

interface IConnectorBody {
    data_source_id: number;
    config?: any;
}

export interface IEditConnectorBody {
    config?: any;
}

interface IConnectorCredentialsBody {
    apiKey?: string;
    username?: string;
    password?: string;
    schema?: string;
}

interface IConnectorUrlParams {
    orgId: string;
    connectorId: string;
    connectorName: string;
}

interface ILegacyCustomConnectorParams extends IConnectorUrlParams {
    body: IConnectorCredentialsBody;
}

export const STATUS_MAPPING: IDictionary = {
    incomplete: "incomplete",
    broken: "broken",
    historical_syncing: "historical_syncing",
    syncing: "syncing",
    connected: "active",
    paused: "paused",
};

const setConnectorAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    dispatch(setConnectorsLoading(true));
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(
                setConnector(
                    res.data.map((c: any) => ({
                        ...c,
                        status: STATUS_MAPPING[c.status],
                    })),
                ),
            );
        }
    } catch (error) {
        console.error(error);
    } finally {
        dispatch(setConnectorsLoading(false));
    }
};

export const connectors = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        createConnector: builder.mutation({
            query: (args: { body: IConnectorBody; orgId: string }) => ({
                url: `organizations/${args.orgId}${CONNECTORS_API_PATH}`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            data_source_id: args.body.data_source_id,
                            config: args.body.config,
                        },
                        type: "connectors",
                    },
                }),
            }),
            transformResponse(response: ICreateConnectorResponse) {
                return {
                    ...transformConnector(response.data),
                    fivetranCardUri: response.meta.fivetran_connect_url,
                };
            },
        }),
        createCustomConnector: builder.mutation({
            query: (args: { body: IConnectorBody; orgId: string }) => ({
                url: `organizations/${args.orgId}${CONNECTORS_API_PATH}/custom`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            data_source_id: args.body.data_source_id,
                        },
                        type: "connectors",
                    },
                }),
            }),
            transformResponse(response: ICreateCustomConnectorResponse) {
                return transformCustomConnector(response.data);
            },
        }),
        editConnector: builder.mutation({
            query: (args: { body: IEditConnectorBody; orgId: string; connectorId: string }) => ({
                url: `organizations/${args.orgId}${CONNECTORS_API_PATH}/${args.connectorId}`,
                method: "PATCH",
                body: JSON.stringify({
                    data: {
                        attributes: args.body,
                        type: "connectors",
                    },
                }),
            }),
            transformResponse(response: ICreateConnectorResponse) {
                return {
                    fivetranCardUri: response.meta.fivetran_connect_url,
                };
            },
            invalidatesTags: (_, __, { connectorId }) => [{ type: "Connector", id: connectorId }],
        }),
        reconnectConnector: builder.mutation({
            query: (args: { orgId: string; connectorId: string }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/reconnect`,
                method: "PUT",
            }),
        }),
        getConnectors: builder.query({
            query: (orgId: string) => ({
                url: `organizations/${orgId}${CONNECTORS_API_PATH}`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setConnectorAsyncHandler({ dispatch, queryFulfilled });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: IGetConnectorResponse): IConnector[] => {
                return transformConnectors(response);
            },
        }),
        getConnector: builder.query({
            query: (args: { orgId: string; connectorId: string }) => ({
                url: `organizations/${args.orgId}${CONNECTORS_API_PATH}/${args.connectorId}`,
                method: "GET",
            }),
            transformResponse: (response: IGetSingleConnectorResponse) => {
                return transformConnector(response.data);
            },
            providesTags: (_, __, { connectorId }) => [{ type: "Connector", id: connectorId }],
        }),
        deleteConnector: builder.mutation({
            query: (args: { id: string; orgId: string }) => ({
                url: `organizations/${args.orgId}${CONNECTORS_API_PATH}/${args.id}`,
                method: "DELETE",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() => {
                    dispatch(deleteConnectorById(queryArgs.id));
                });
            },
            transformResponse(response: ICreateConnectorResponse) {
                return response;
            },
        }),
        purgeConnector: builder.mutation({
            query: (args: { orgId: string; connectorId: string }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/purge`,
                method: "DELETE",
            }),
        }),
        historicalResyncConnector: builder.mutation({
            query: (args: { orgId: string; connectorId: string }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/historical_resync`,
                method: "POST",
            }),
        }),
        getLegacyCustomConnectors: builder.query({
            query: (args: IConnectorUrlParams) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/${args.connectorName}_credentials `,
                method: "GET",
            }),
            transformResponse: (
                response: { data: IGetLegacyCustomConnectorCredentialResponse[] },
                meta: any,
                args: IConnectorUrlParams,
            ): any[] => {
                return getLegacyCustomConnectorId(response.data, args.orgId, args.connectorId);
            },
        }),
        legacyCustomConnectorCredentialsAdd: builder.mutation({
            query: (args: ILegacyCustomConnectorParams) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/${args.connectorName}_credentials`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: { ...args.body },
                        type: `${args.connectorName}_credentials`,
                    },
                }),
            }),
            transformResponse(response: ICreateLegacyCustomConnectorCredentialResponse) {
                return response;
            },
        }),
        legacyCustomConnectorCredentialsEdit: builder.mutation({
            query: (args: ILegacyCustomConnectorParams & { customConnectorId: string }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/${args.connectorName}_credentials/${args.customConnectorId}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        attributes: { ...args.body },
                        type: `${args.connectorName}_credentials`,
                    },
                }),
            }),
            invalidatesTags: (_, __, { connectorId }) => [{ type: "Connector", id: connectorId }],
        }),
        shareConnectorConfigure: builder.mutation({
            query: (args: { orgId: string; connectorId: string; body: { user_id: string; message: string } }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/share_setup_instructions`,
                method: "POST",
                body: JSON.stringify({ ...args.body }),
            }),
        }),
    }),
});

export const {
    useCreateConnectorMutation,
    useEditConnectorMutation,
    useCreateCustomConnectorMutation,
    useLazyGetConnectorsQuery,
    useGetConnectorsQuery,
    useDeleteConnectorMutation,
    useReconnectConnectorMutation,
    usePurgeConnectorMutation,
    useHistoricalResyncConnectorMutation,
    useLegacyCustomConnectorCredentialsAddMutation,
    useLegacyCustomConnectorCredentialsEditMutation,
    useLazyGetLegacyCustomConnectorsQuery,
    useShareConnectorConfigureMutation,
    useGetConnectorQuery,
} = connectors;
