// MetricsList.tsx
import { List, Box } from "@mui/material";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { MetricVerificationItem } from "src/components/ConnectorV3/DataVerify/MetricVerificationItem";
import { FC } from "react";

interface IMetricsListProps {
    metricsWithConnector: IQaMetric[];
    userReportedData?: { id: string; value: string };
    currentCheckboxAnchorEl?: HTMLElement | null;
    isDataSubmitted: boolean;
    onCheckboxChange?: (
        e: React.ChangeEvent<HTMLInputElement>,
        metric: IQaMetric,
        selectedValue: boolean | null,
    ) => void;
    onExpectedValueChange?: (value: string, id: string) => void;
    onDeclineReasonSave?: (metricData: IQaMetric) => void;
    onDeclineReasonClose?: (metric: IQaMetric) => void;
}

export const MetricsList: FC<IMetricsListProps> = ({
    metricsWithConnector = [],
    userReportedData,
    currentCheckboxAnchorEl,
    isDataSubmitted,
    onCheckboxChange,
    onExpectedValueChange,
    onDeclineReasonSave,
    onDeclineReasonClose,
}) => (
    <Box sx={{ width: "100%" }} height={"100%"}>
        <List
            sx={{
                width: "100%",
                maxWidth: "100%",
                position: "relative",
                "& ul": { padding: 0 },
            }}
        >
            {metricsWithConnector.map((metricData, index) => (
                <MetricVerificationItem
                    key={`item-${index}`}
                    metricData={metricData}
                    userReportedData={userReportedData}
                    currentCheckboxAnchorEl={currentCheckboxAnchorEl}
                    isDataSubmitted={isDataSubmitted}
                    onCheckboxChange={onCheckboxChange}
                    onExpectedValueChange={onExpectedValueChange}
                    onDeclineReasonSave={onDeclineReasonSave}
                    onDeclineReasonClose={onDeclineReasonClose}
                />
            ))}
        </List>
    </Box>
);
