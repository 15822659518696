import { css } from "@emotion/css";
import {
    FormControl,
    FormHelperText,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Slider,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Field as FormikField } from "formik";
import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { OPTIIMIZATION_CREATE_PATH } from "src/consts/path/path";
import { ICampaignData } from "src/interfaces/entities/IScenario";
import { amazonRevCacOptimizerSelector, optimizerWithConfidenceSelector } from "src/reduxState/slices/featuresSlice";
import {
    getTargetOptions,
    HIGH_CONFIDENCE_COLOR,
    LOW_CONFIDENCE_COLOR,
    MEDIUM_CONFIDENCE_COLOR,
    valueLabelFormat,
} from "src/services/optimizationPage/optimization";
import { inputSpendFormat } from "../../../../components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { SVGIconRenderer } from "../../../../components/SVGIconRenderer/SVGIconRenderer";
import {
    OPTIMIZED_MODELS_BY_METRIC,
    SCENARIO_TYPE,
    TIMEFRAME_OPTIONS,
    TIMEFRAME_RADIO_OPTIONS,
} from "../../../../consts/optimizationPage/optimizationPage";
import { isScenarioEditableSelector } from "../../../../reduxState/slices/scenarioSlice";
import { useAnalyticsService } from "../../../../services/analytics/useAnalyticsService";
import { calculatePercentDiff, getPathForAnalytics } from "../../../../services/utils";
import { Field } from "../../../core/Field/Field";
import { IScenarioFromProps } from "../../formModel/addScenarioFormModel";
import CampaignSelection from "../CampaignSelection/CampaignSelection";
import { defaultRevenueSourceSelector, isAmazonSellingPartnerConnected } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import {
    isCACAvailableForCompany,
    MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE,
} from "src/consts/performancePaidPage/performancePaidPage";

const min = 0;
const max = 100;

const useStyles = (theme: Theme) => ({
    markLable: css({
        position: "relative",
        fontSize: "14px",
        transform: "translateX(-3%)",
        marginBottom: "2rem",
    }),
    sliderClass: css({
        padding: "10px 0",
        marginTop: "30px",
        "& .MuiSlider-mark": {
            height: "5px",
            width: "5px",
            borderRadius: "50px",
            backgroundColor: "green",
        },
        "& .MuiSlider-valueLabel": {
            fontSize: 14,
            fontWeight: "normal",
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    }),
});

const ScenarioForm: FC<IScenarioFromProps> = (props) => {
    const {
        formField: { name, description, scenarioType, forecastTimeframe, budget, optimizationConfidence },
        errors,
        touched,
        values,
        handleChange,
        classes,
        getTimeframeValue,
        getSelectedForecastTarget,
        setFieldValue,
        performanceData,
        lastDaysTooltip,
    } = props;
    const formClasses = useStyles(useTheme());
    const analyticsService = useAnalyticsService();

    const defaultRevenue = useSelector(defaultRevenueSourceSelector);
    const revenueLabel = MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[defaultRevenue] || "Store";
    const hasAmazonStore = useSelector(isAmazonSellingPartnerConnected);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    const isScenarioEditable =
        location.pathname.includes(OPTIIMIZATION_CREATE_PATH) || useSelector(isScenarioEditableSelector);
    const optimizerWithConfidence = useSelector(optimizerWithConfidenceSelector);

    const isCacSupported = isCACAvailableForCompany(defaultRevenue, orgCode);
    const isAmazonRevCacOptimizerSupported = useSelector(amazonRevCacOptimizerSelector);

    const [budgetInput, setBudgetInput] = useState(0);
    const [isBudgetChanged, setIsBudgetChanged] = useState(false);
    const [isCampaignsChanged, setIsCampaignsChanged] = useState(false);

    const targetOptions = [
        ...getTargetOptions(revenueLabel, isCacSupported, hasAmazonStore, isAmazonRevCacOptimizerSupported),
    ];

    useEffect(() => {
        analyticsService.logEvent("Configure Optimization Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    // Update performance data based on forecast timeframe
    const updatedPerformanceData = useMemo(
        () =>
            performanceData.data.map((d) => ({
                ...d,
                currentEstimatedSpend: +(d.currentEstimatedDailySpend || 0).toFixed(0) * values.forecastTimeframe,
            })),
        [performanceData.data, values.forecastTimeframe],
    );

    const filterChangeEvent = (timeframe: string | number, scenarioType: string, campaigns: ICampaignData) => {
        analyticsService.logEvent("Configure Optimization Filter Applied", {
            "Forecast timeframe": `${timeframe} Days`,
            "Scenario type": scenarioType,
            "Campaign selections": campaigns,
        });
    };

    const minSpendForScale = useMemo(() => {
        const selectedCampaignsId = values.campaignData.map((campaign) => campaign.campaignId);
        const selectedPerformances = updatedPerformanceData.filter((p) =>
            selectedCampaignsId.includes(p.campaignId || ""),
        );
        const totalSelectedSpend = +selectedPerformances
            .reduce((prev, curr) => prev + (curr.currentEstimatedSpend || 0), 0)
            .toFixed(0);
        setIsBudgetChanged(totalSelectedSpend !== values.budget);
        return totalSelectedSpend;
    }, [updatedPerformanceData, values.campaignData, values.budget]);

    // Handle budget input change with debounce
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (budgetInput) {
                analyticsService.logEvent("Configure Optimization Input Changed", {
                    Budget: budgetInput,
                });
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [budgetInput]);

    useEffect(() => {
        // set timeframe value for update the campaign list based on timeframe selected
        getTimeframeValue(values.forecastTimeframe as TIMEFRAME_OPTIONS);
    }, [values.forecastTimeframe]);

    // set budget for selected campaigns when target/timeframe change
    useEffect(() => {
        if (isCampaignsChanged) {
            const selectedCampaignsId = values.campaignData.map((s: any) => s.campaignId);
            const selectedPerformances: any = updatedPerformanceData.filter((p) =>
                selectedCampaignsId.includes(p.campaignId || ""),
            );

            setFieldValue(
                "budget",
                +selectedPerformances
                    .reduce(
                        (prev: any, curr: { currentEstimatedSpend: any }) => prev + +(curr.currentEstimatedSpend || 0),
                        0,
                    )
                    .toFixed(0),
            );
        }
    }, [isCampaignsChanged, updatedPerformanceData]);

    useEffect(() => {
        const scenarioToTargetMap: Partial<Record<SCENARIO_TYPE, OPTIMIZED_MODELS_BY_METRIC>> = {
            [SCENARIO_TYPE.CAC]: OPTIMIZED_MODELS_BY_METRIC.CUSTOMERS,
            [SCENARIO_TYPE.AMAZON_CAC]: OPTIMIZED_MODELS_BY_METRIC.AMAZON_CUSTOMERS,
            [SCENARIO_TYPE.AMAZON_REVENUE]: OPTIMIZED_MODELS_BY_METRIC.AMAZON_REVENUE,
        };
        const selectedTarget = scenarioToTargetMap[values.scenarioType] || OPTIMIZED_MODELS_BY_METRIC.REVENUE;
        getSelectedForecastTarget(selectedTarget);
    }, [values.scenarioType]);

    const handleBudgetInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (/^(\d|,)*\.?\d*$/.test(inputValue) || inputValue === "") {
            const clearBudget = +inputValue.toString().replace(/,/g, "");
            setFieldValue("budget", clearBudget);
            setBudgetInput(clearBudget);
            setIsCampaignsChanged(false);
        }
    };

    const handleSelectInputChange = (e: SelectChangeEvent<any>) => {
        const { name, value } = e.target;
        setFieldValue(name, value);
        filterChangeEvent(
            name === "forecastTimeframe" ? value : values.forecastTimeframe,
            name === "scenarioType" ? value : values.scenarioType,
            [],
        );
        setIsCampaignsChanged(true);
    };

    const handleConfidenceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const clampedValue = Math.min(Math.max(+e.target.value, min), max);
        setFieldValue(optimizationConfidence.name, clampedValue);
    };

    const formatSliderLabelFormat = (val: number) => {
        if (isNaN(val)) {
            return "0%";
        }
        const percentageChange = calculatePercentDiff(val, minSpendForScale, false);
        return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(0)}%`;
    };

    return (
        <Stack
            direction="row"
            sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            })}
        >
            <Stack className={`leftCol ${classes.rightSection}`} flexGrow={1}>
                <Stack className={classes.formContainer}>
                    <Stack className={classes.formContentStyle} gap="40px">
                        <Typography variant="h2" fontWeight="700">
                            Scenario Info
                        </Typography>
                        <Stack direction="row" gap="20px" flexWrap="wrap">
                            <FormControl sx={{ width: "100%" }}>
                                <label>{scenarioType.label}</label>
                                <Select
                                    displayEmpty={true}
                                    name={scenarioType.name}
                                    error={Boolean(touched.scenarioType && errors.scenarioType)}
                                    value={values.scenarioType || ""}
                                    onChange={handleSelectInputChange}
                                    disabled={!isScenarioEditable}
                                >
                                    {targetOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText className={classes.formError}>
                                    {Boolean(touched.scenarioType && errors.scenarioType) ? errors.scenarioType : null}
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <Stack direction="row" gap="20px" flexWrap="wrap">
                            <FormControl sx={{ width: "300px" }}>
                                <label className={classes.formSimpleLabel}>{name.label}</label>
                                <Field
                                    name={name.name}
                                    placeholder={name.placeholder}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={Boolean(touched.name && errors.name) ? errors.name : ""}
                                    value={values.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl sx={{ flexGrow: "1" }}>
                                <label className={classes.formSimpleLabel}>{description.label}</label>
                                <Field
                                    name={description.name}
                                    placeholder={description.placeholder}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={
                                        Boolean(touched.description && errors.description) ? errors.description : ""
                                    }
                                    value={values.description}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row" gap="20px" flexWrap="wrap">
                            <Stack sx={{ width: "300px" }}>
                                <label className={classes.formSimpleLabel}>Scenario Basis</label>
                                <Field value="Fixed Budget" disabled />
                            </Stack>
                            <FormControl sx={{ flexGrow: "1" }}>
                                <label>{forecastTimeframe.label}</label>
                                <Select
                                    displayEmpty={true}
                                    name={forecastTimeframe.name}
                                    error={Boolean(touched.forecastTimeframe && errors.forecastTimeframe)}
                                    value={values.forecastTimeframe || ""}
                                    onChange={handleSelectInputChange}
                                    disabled={!isScenarioEditable}
                                >
                                    {TIMEFRAME_RADIO_OPTIONS.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText className={classes.formError}>
                                    {Boolean(touched.forecastTimeframe && errors.forecastTimeframe)
                                        ? errors.forecastTimeframe
                                        : null}
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <CampaignSelection
                            {...{ ...props, performanceData: { ...performanceData, data: updatedPerformanceData } }}
                            filterChangeEvent={filterChangeEvent}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack className={classes.leftSection} sx={{ borderLeft: "1px solid #D9D9D9" }} gap="30px">
                <Stack gap="10px" mb="20px">
                    <Typography variant="h3">Scenario Inputs</Typography>
                    <Typography color="#000" fontSize="18px">
                        Based on selected campaigns
                    </Typography>
                </Stack>
                <Box>
                    <Typography color="text.secondary" fontSize="15px" gutterBottom>
                        <Tooltip arrow title={isBudgetChanged ? "" : lastDaysTooltip}>
                            <span>{budget.label}</span>
                        </Tooltip>
                    </Typography>
                    <FormikField name={budget.name}>
                        {({ meta }: any) => (
                            <>
                                <OutlinedInput
                                    name={budget.name}
                                    size="small"
                                    type="text"
                                    value={inputSpendFormat(+(values.budget || "").toString().replace(/,/g, ""))}
                                    startAdornment={
                                        <InputAdornment
                                            position="start"
                                            sx={{ ".MuiTypography-body1": { color: "black" } }}
                                        >
                                            $
                                        </InputAdornment>
                                    }
                                    sx={{ width: "100%", color: "black" }}
                                    error={!!meta.error}
                                    onChange={(e) => handleBudgetInputChange(e)}
                                    disabled={!isScenarioEditable}
                                />
                                {meta.error && (
                                    <FormHelperText className={classes.formError}>{meta.error}</FormHelperText>
                                )}
                            </>
                        )}
                    </FormikField>
                    <Stack sx={{ marginTop: "10px" }} gap="10px">
                        <Stack direction="row">
                            <SVGIconRenderer
                                width="16px"
                                height="16px"
                                style={{ opacity: 0.6 }}
                                icon="minusIcon"
                                onClick={() => {
                                    isScenarioEditable && setFieldValue("budget", values.budget - 1000);
                                }}
                            />
                            <SVGIconRenderer
                                width="16px"
                                height="16px"
                                style={{ opacity: 0.6, marginLeft: "auto" }}
                                icon="plusIcon"
                                onClick={() => {
                                    isScenarioEditable && setFieldValue("budget", values.budget + 1000);
                                }}
                            />
                        </Stack>
                        <Slider
                            className={formClasses.sliderClass}
                            size="small"
                            name={budget.name}
                            value={values.budget}
                            aria-label="Small"
                            onChange={handleChange}
                            min={0}
                            max={minSpendForScale * 2}
                            disabled={!isScenarioEditable}
                            valueLabelDisplay="on"
                            valueLabelFormat={formatSliderLabelFormat}
                        />
                        {optimizerWithConfidence && (
                            <Box>
                                <Stack direction="row" alignItems="center" gap="5px">
                                    <Typography color="text.secondary" fontSize="15px" gutterBottom>
                                        Optimization Confidence
                                    </Typography>
                                    <Tooltip
                                        arrow
                                        title="You can configure what level of confidence you wish for the optimizer to find in our outcome depending on your risk tolerance. Note that if you select High confidence, the optimizer might not always be able to find high confidence solutions across all of your campaigns selected."
                                    >
                                        <span>
                                            <SVGIconRenderer
                                                icon="infoIcon"
                                                height="18px"
                                                width="18px"
                                                strokeColor="rgba(0, 0, 0, 0.6)"
                                            />
                                        </span>
                                    </Tooltip>
                                </Stack>
                                <OutlinedInput
                                    name={optimizationConfidence.name}
                                    placeholder={optimizationConfidence.placeholder}
                                    size="small"
                                    type="number"
                                    value={values.optimizationConfidence}
                                    sx={{ width: "100%", color: "black" }}
                                    disabled={!isScenarioEditable}
                                    inputProps={{ min, max }}
                                    onChange={handleConfidenceChange}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            sx={{ ".MuiTypography-body1": { color: "black" } }}
                                        >
                                            %
                                        </InputAdornment>
                                    }
                                />
                                <Stack sx={{ marginTop: "10px" }} gap="10px">
                                    <Slider
                                        name={optimizationConfidence.name}
                                        value={+values.optimizationConfidence}
                                        onChange={handleChange}
                                        min={min}
                                        max={max}
                                        sx={{
                                            height: "10px",
                                            "& .MuiSlider-thumb": {
                                                color: "white",
                                                border: "1px solid black",
                                            },
                                            "& .MuiSlider-rail": {
                                                backgroundImage: `linear-gradient(0.25turn, ${LOW_CONFIDENCE_COLOR} 0%, ${MEDIUM_CONFIDENCE_COLOR} 70%, ${HIGH_CONFIDENCE_COLOR} 90%)`,
                                                opacity: 1,
                                            },
                                        }}
                                        track={false}
                                    />
                                    <Typography fontSize="15px">
                                        {valueLabelFormat(+values.optimizationConfidence)}
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
};

export default memo(ScenarioForm);
