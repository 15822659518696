import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "src/components/core/Loader/Loader";
import { Modal } from "src/components/core/Modal/Modal";
import { PageContainer } from "src/components/PageContainer/PageContainer";
import { PageHeader } from "src/components/PageHeader/PageHeader.v2";
import { ISlackAlert } from "src/interfaces/entities/ISlackAlert";
import {
    useEditSlackAlertMutation,
    useGetSlackChannelsQuery,
    useGetSlackUsersQuery,
    useLazyGetSlackAlertsQuery,
} from "src/reduxState/apis/slackAlertsApi";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { recalculateSortDirection } from "src/services/utils";
import { EditSlackAlertModal } from "./EditSlackAlertModal";
import { SlackAlertsTable } from "./SlackAlertsTable";
import { ICells } from "src/consts/slackAlertsPage/slackAlertsPage";

export const SlackAlerts = () => {
    const [getSlackAlerts, { isLoading, data: slackAlertsData }] = useLazyGetSlackAlertsQuery();
    const { data: slackUsers } = useGetSlackUsersQuery({});
    const { data: slackChannels } = useGetSlackChannelsQuery({});
    const [editSlackAlert] = useEditSlackAlertMutation();

    const rowsPerPage = useSelector(paginationCountSelector);
    const [editModalOpen, setEditModalOpen] = useState("");
    const [slackAlerts, setSlackAlerts] = useState<ISlackAlert[]>([]);

    const [order, setOrder] = useState<"asc" | "desc">("desc");
    const [orderBy, setOrderBy] = useState("createdAt");
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (slackAlertsData && rowsPerPage * page < slackAlertsData.pagination.totalCount) {
            setPage(0);
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
        setPage(0);
    };

    const getData = () => {
        getSlackAlerts({ params: { page: page + 1, rowsPerPage } })
            .unwrap()
            .then((data) => {
                const alerts: ISlackAlert[] = data.data.map((alert) => ({
                    ...alert,
                    channelName: slackChannels?.find((channel) => channel.id === alert.channelId)?.name,
                    slackUsers: alert.slackUserIds.map((userId) => ({
                        name: slackUsers?.find((user) => user.slackUserId === userId)?.displayName || "",
                        id: userId,
                    })),
                }));
                setSlackAlerts(alerts);
            });
    };

    useEffect(() => {
        getData();
    }, [rowsPerPage, page, slackChannels, slackUsers]);

    const editSlackAlertAction = async (values: ISlackAlert) => {
        if (values.id) {
            const response = await editSlackAlert({
                params: { id: +values.id },
                body: values,
            }).unwrap();
            getData();
            setEditModalOpen("");
        }
    };

    return (
        <div>
            <PageHeader pageHeading="Slack Alerts" />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    slackAlertsData && (
                        <>
                            <SlackAlertsTable
                                slackAlerts={slackAlerts}
                                count={slackAlertsData.pagination.totalCount}
                                editClick={setEditModalOpen}
                                orderBy={orderBy}
                                order={order}
                                handleRequestSort={handleRequestSort}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage}
                                page={page}
                            />
                        </>
                    )
                )}
            </PageContainer>
            <Modal
                isOpen={!!editModalOpen}
                content={
                    <EditSlackAlertModal
                        slackAlert={slackAlerts.find((alert) => alert.programmaticName === editModalOpen)}
                        slackUsers={slackUsers}
                        slackChannels={slackChannels}
                        allAlertNames={slackAlerts
                            .filter((alert) => alert.programmaticName !== editModalOpen)
                            .map((alert) => alert.programmaticName)}
                        handleSubmit={editSlackAlertAction}
                    />
                }
                onClose={() => setEditModalOpen("")}
                maxWidth="md"
                width="100%"
                title="Edit Slack Alert"
            />
        </div>
    );
};
