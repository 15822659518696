import React, { FC, useContext, useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Typography, SelectChangeEvent, Skeleton, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";

import { useLazyGetPerformanceMetricQuery } from "../../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId, selectCurrentlyViewingCode } from "../../../../reduxState/slices/organizationSlice";
import { MetricChart } from "./MetricChart";
import {
    HALO_EFFECT_COLUMNS,
    MAP_VALUE_PARAMS,
    PERFORMANCE_ATTRIBUTION_HEADERS,
    PERFORMANCE_CAC_METRICS,
    RETURN_VALUE,
    isCACAvailableForCompany,
} from "../../../../consts/performancePaidPage/performancePaidPage";
import {
    getUpdatedChartDataForReturnChart,
    removeNegativeTrueMetrics,
    getPercentChangeInMetric,
    getSpecificGrpByData,
    getRevenueTotalData,
} from "../../../../services/performancePage/performancePage";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IPerformanceCampaignMetrics } from "../../../../interfaces/IPerformanceCampaignMetrics";
import { useAnalyticsService } from "../../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../../services/utils";
import {
    formattedCompareDateStringSelector,
    formattedCurrentDateStringSelector,
} from "../../../../reduxState/slices/dateFilterSlice";
import GroupBySelection from "./GroupBySelection";
import { PerformanceContext } from "../../../../containers/CampaignPageV2/CampaignPage";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";

interface IReturnChart {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    classes: any;
}

export const ReturnChart: FC<IReturnChart> = ({ performanceCampaignData, classes }) => {
    const performanceContext = useContext(PerformanceContext);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    const updatedReturnOptions = isCACAvailableForCompany(performanceContext?.defaultRevenueConnectorSource, orgCode)
        ? RETURN_VALUE
        : RETURN_VALUE.filter(
              (c) => ![MAP_VALUE_PARAMS.cacVsSpend, MAP_VALUE_PARAMS.newCustomersVsSpend].includes(c.value),
          );

    const { campaignId, connectorName, trueRevenue } = performanceCampaignData;
    const [returnValue, setReturnValue] = useState(MAP_VALUE_PARAMS.roasVsSpend);
    const analyticsService = useAnalyticsService();
    const currentDate = useSelector(formattedCurrentDateStringSelector);
    const pastDate = useSelector(formattedCompareDateStringSelector);
    const connectors = useSelector(connectorsSelector);

    const [grpBy, setGrpBy] = useState("day");

    const handleChange = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value as string;
        const findLable = RETURN_VALUE.find((returnValue) => returnValue.value === selectedValue)?.label;
        if (findLable) {
            analyticsService.logEvent("Performance Detail Metric Changed", {
                Page: getPathForAnalytics(window.location.pathname, window.location.search),
                "Selected metric": findLable,
            });
            setReturnValue(selectedValue);
        }
    };

    const [
        getPerformanceMetric,
        {
            data: chartData = {
                data: [],
                percentageData: {},
                meta: { comparisonData: [] },
            } as unknown as IPerformanceCampaignMetrics,
            isFetching,
            isSuccess,
            isUninitialized,
        },
    ] = useLazyGetPerformanceMetricQuery();

    const filteredChartData = [...chartData.data];

    let currentData = [...filteredChartData];
    let comparisonData = chartData.meta.comparisonData ?? [];
    const isChartDataReceived = Boolean(!isFetching && !isUninitialized && isSuccess);

    const companyId = useSelector(selectCurrentlyViewingId);

    useEffect(() => {
        if (campaignId && currentDate[0].startDate && currentDate[0].endDate) {
            getPerformanceMetric({
                orgId: companyId,
                data: {
                    campaign_id: campaignId,
                    start_date: currentDate[0].startDate,
                    end_date: currentDate[0].endDate,
                    metrics: returnValue,
                },
                pastDate: pastDate[0],
            });
        }
    }, [performanceCampaignData, returnValue, JSON.stringify(currentDate)]);

    currentData = getRevenueTotalData(currentData);
    comparisonData = getRevenueTotalData(comparisonData);

    let getDataWithLegend = getUpdatedChartDataForReturnChart(
        currentData,
        returnValue,
        connectorName || "",
        connectors,
    );
    let getComparisonDataWithLegend = getUpdatedChartDataForReturnChart(
        comparisonData,
        returnValue,
        connectorName || "",
        connectors,
    );

    currentData = getDataWithLegend.data;
    const legendValues = getDataWithLegend.legendValues;
    currentData = removeNegativeTrueMetrics(legendValues, currentData, trueRevenue);
    const percentageData = getPercentChangeInMetric(legendValues, getComparisonDataWithLegend.legendValues);

    const sortedValuesByDate = [...filteredChartData].sort((a, b) => +new Date(a.date) - +new Date(b.date));

    getDataWithLegend = getSpecificGrpByData(
        grpBy,
        sortedValuesByDate,
        returnValue,
        connectorName || "",
        getDataWithLegend,
        getUpdatedChartDataForReturnChart,
        performanceCampaignData,
        connectors,
    );
    getComparisonDataWithLegend = getUpdatedChartDataForReturnChart(
        comparisonData,
        returnValue,
        connectorName || "",
        connectors,
    );
    currentData = getDataWithLegend.data;

    const handleGrpByChange = (grp: string) => {
        setGrpBy(grp);
    };

    return (
        <div className="returnChart" style={{ minWidth: "512px", width: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2" display="block" gutterBottom color="text.primary" className={classes.boldText}>
                    Return
                </Typography>
                <GroupBySelection grpBy={grpBy} handleGrpByChange={handleGrpByChange} />
            </Stack>
            <FormControl variant="standard" sx={{ mb: 2, minWidth: 210, fontSize: "18px" }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={returnValue}
                    onChange={handleChange}
                    MenuProps={{ disableScrollLock: true }}
                >
                    {updatedReturnOptions.map((metric) => {
                        return (
                            <MenuItem value={metric.value} key={metric.value}>
                                {metric.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {isChartDataReceived && currentData ? (
                <>
                    <MetricChart
                        chartData={currentData}
                        customValue={returnValue}
                        id={`return_chart_${campaignId}`}
                        performanceCampaignData={performanceCampaignData}
                        legendValues={legendValues}
                        percentageData={percentageData}
                        pastLegendValues={getComparisonDataWithLegend.legendValues}
                        grpBy={grpBy}
                        metrics={[
                            ...PERFORMANCE_ATTRIBUTION_HEADERS,
                            ...HALO_EFFECT_COLUMNS,
                            ...PERFORMANCE_CAC_METRICS,
                        ]}
                    />
                </>
            ) : (
                <Skeleton variant="rectangular" animation="wave" height="344px" style={{ marginTop: "1rem" }} />
            )}
        </div>
    );
};
