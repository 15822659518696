import { Group, Notes } from "@mui/icons-material";
import { Chip, FormControlLabel, MenuItem, Switch, Typography } from "@mui/material";
import Spinner from "@mui/material/CircularProgress";
import { Stack } from "@mui/system";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { Field } from "src/components/core/Field/Field";
import { ISlackAlert, ISlackChannel, ISlackUserResponse } from "src/interfaces/entities/ISlackAlert";
import { object, string } from "yup";
import { Button } from "@prescientai/component-library";

interface IEditSlackAlertModalProps {
    slackAlert: ISlackAlert | undefined;
    slackUsers: ISlackUserResponse[] | undefined;
    slackChannels: ISlackChannel[] | undefined;
    allAlertNames: string[];
    handleSubmit: (values: ISlackAlert) => void;
}

export const EditSlackAlertModal: React.FC<IEditSlackAlertModalProps> = ({
    slackAlert,
    slackUsers,
    slackChannels,
    allAlertNames,
    handleSubmit,
    ...rest
}) => {
    const slackChannelIds = slackChannels?.map((channel) => channel.id) || [];

    const [showExplanation, setShowExplanation] = useState(false);

    const validationSchema = object().shape({
        programmaticName: string()
            .required("Programmatic Name is required")
            .notOneOf(allAlertNames, "Another alert with this programmatic name already exists"),
        displayName: string().required("Display Name is required"),
        message: string().required("Message is required"),
        channelId: string().required("Slack channel is required").oneOf(slackChannelIds),
    });

    return slackAlert ? (
        <Formik validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={slackAlert}>
            {({ touched, errors, handleChange, values, setFieldValue, isSubmitting }: FormikProps<ISlackAlert>) => {
                return (
                    <Form>
                        <Stack gap={3}>
                            <Stack gap={2}>
                                <Stack direction="row" justifyContent="space-between" gap={2}>
                                    <FormControlLabel
                                        label="Enabled"
                                        control={
                                            <Switch
                                                id="isActive"
                                                name="isActive"
                                                color="success"
                                                checked={values.enabled}
                                                onChange={(e) => setFieldValue("enabled", e.target.checked)}
                                                disabled={isSubmitting}
                                            />
                                        }
                                    />
                                    <Field
                                        select
                                        id="channelId"
                                        name="channelId"
                                        label="Channel"
                                        value={values.channelId}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        fullWidth
                                    >
                                        {slackChannels
                                            ?.slice()
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((channel) => (
                                                <MenuItem key={channel.id} value={channel.id}>
                                                    #{channel.name}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                </Stack>
                                <Stack direction="row" gap={2}>
                                    <Field
                                        id="displayName"
                                        name="displayName"
                                        label="Display Name"
                                        disabled={isSubmitting}
                                        value={values.displayName}
                                        onChange={handleChange}
                                        error={Boolean(touched.displayName && errors.displayName)}
                                        helperText={touched.displayName && errors.displayName}
                                        fullWidth
                                    />
                                    <Field
                                        id="programmaticName"
                                        name="programmaticName"
                                        label="Programmatic Name"
                                        disabled={isSubmitting}
                                        value={values.programmaticName}
                                        onChange={handleChange}
                                        error={Boolean(touched.programmaticName && errors.programmaticName)}
                                        helperText={touched.programmaticName && errors.programmaticName}
                                        fullWidth
                                    />
                                </Stack>
                            </Stack>
                            <Stack gap={2}>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <Notes />
                                    <Typography variant="h4">Alert content</Typography>
                                </Stack>
                                {showExplanation && (
                                    <Stack p={2} gap={2} sx={{ backgroundColor: "#ededed", borderRadius: 2 }}>
                                        <Typography>
                                            You can use mustache syntax here to insert the available fields into the
                                            template. You can also use Slack's syntax for things like emojis or links.
                                        </Typography>
                                        <Stack direction="row" gap={2}>
                                            <Stack gap={1} flexGrow={1}>
                                                <Typography>For a single value:</Typography>
                                                <Stack
                                                    p={2}
                                                    sx={{
                                                        border: "1px solid lightgray",
                                                        borderRadius: 2,
                                                        backgroundColor: "white",
                                                    }}
                                                    height="100%"
                                                >
                                                    <Typography>{"{{value_name}}"}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack gap={1} flexGrow={1}>
                                                <Typography>For a list:</Typography>
                                                <Stack
                                                    p={2}
                                                    sx={{
                                                        border: "1px solid lightgray",
                                                        borderRadius: 2,
                                                        backgroundColor: "white",
                                                    }}
                                                    height="100%"
                                                >
                                                    <Typography>{"{{#value_list}}"}</Typography>
                                                    <Typography>{"\u00A0\u00A0{{.}}"}</Typography>
                                                    <Typography>{"{{/value_list}}"}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}
                                <Stack>
                                    <Stack direction="row" gap={2} justifyContent="space-between">
                                        <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                                            <Typography>Available fields:</Typography>
                                            {slackAlert.availableFieldsForMessage.map((field) => (
                                                <Chip
                                                    key={field}
                                                    data-cy={`available-field-${field}`}
                                                    label={field}
                                                    size="small"
                                                />
                                            ))}
                                        </Stack>
                                        <Button variant="outlined" onClick={() => setShowExplanation((prev) => !prev)}>
                                            <em>{showExplanation ? "Ok got it" : "How does this work?"}</em>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Field
                                    multiline
                                    id="message"
                                    name="message"
                                    label="Message"
                                    minRows={3}
                                    disabled={isSubmitting}
                                    value={values.message}
                                    onChange={handleChange}
                                    error={Boolean(touched.message && errors.message)}
                                    helperText={touched.message && errors.message}
                                />
                            </Stack>
                            <Stack gap={2}>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <Group />
                                    <Typography variant="h4">Users</Typography>
                                </Stack>
                                <Stack direction="row" gap={2}>
                                    <Field
                                        select
                                        onChange={handleChange}
                                        value={values.slackUserIds || []}
                                        label="Tagged Users"
                                        name="slackUserIds"
                                        id="slackUserIds"
                                        SelectProps={{ multiple: true }}
                                        disabled={isSubmitting}
                                        fullWidth
                                    >
                                        {slackUsers
                                            ?.slice()
                                            .sort((a, b) => a.realName.localeCompare(b.realName))
                                            .map((user) => (
                                                <MenuItem key={user.slackUserId} value={user.slackUserId}>
                                                    {user.realName}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    <FormControlLabel
                                        label="Tag Customer Success Manager"
                                        control={
                                            <Switch
                                                id="tagCustomerSuccessManager"
                                                name="tagCustomerSuccessManager"
                                                checked={values.tagCustomerSuccessManager}
                                                onChange={(e) =>
                                                    setFieldValue("tagCustomerSuccessManager", e.target.checked)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        }
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction="row-reverse" gap={2}>
                                <Button type="submit" disabled={isSubmitting} variant="contained" size="large">
                                    {isSubmitting ? <Spinner /> : "Save Changes"}
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    ) : (
        <></>
    );
};
