import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Autocomplete,
    Dialog,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useParams } from "react-router";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { Button } from "@prescientai/component-library";

import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { formatValue } from "../../../services/utils";
import { inputSpendFormat } from "../../../components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { FORMATS } from "../../../enums/Formats";
import { IScenario, MetricRate } from "../../../interfaces/entities/IScenario";
import { RECOMMANDATION_TABLE_HEADERS } from "../../../consts/optimizationPage/optimizationPage";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { calculatePercentage } from "../../../services/utils";
import { optimizationStyle } from "../../../containers/Optimization/optimizationStyle";
import { getCleanValueTotalSum } from "../../../services/optimizationPage/optimization";
import { IScenarioCampaignBody } from "../../../reduxState/apis/optimizationApi";
import { OptimizationOutcomeContext } from "../../../containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { BudgetInfoDialog } from "./BudgetInfoDialog/BudgetInfoDialog";
import { DataSourceAvatar } from "../../../components/DataSourceAvatar/DataSourceAvatar";

export interface ICustomizeCampaigns {
    scenarioCampaignId: number;
    channel: string;
    campaignId: string;
    allocationSpend: number;
    noChangeSpend: number;
    lastDaysSpend: number;
    isLocked: boolean;
    campaignName: string;
    allocationPercentage: number;
    isEdited?: boolean;
    connectorName: string;
}

interface IOptionType {
    label: string;
    value: string;
}

interface ICustomizeRecommendationsModal {
    recommendationsModalToggle: () => void;
    selectedCampaigns: ICustomizeCampaigns[];
    saveCustomizationChanges: (updatedCampaignList: IScenarioCampaignBody[], allocationSpendTotal: number) => void;
    reallocateRemainingBudget: (updatedCampaignList: IScenarioCampaignBody[]) => void;
    totalAllocationCampaigns: {
        allocationSpend: number;
        spend: number;
        totalAllocationSpendPercentages: number;
    };
    isBulkLoading: boolean;
    selectedScenario: IScenario;
}

export const CustomizeRecommendationsModal: React.FC<ICustomizeRecommendationsModal> = ({
    recommendationsModalToggle,
    selectedCampaigns,
    saveCustomizationChanges,
    reallocateRemainingBudget,
    totalAllocationCampaigns,
    isBulkLoading,
    selectedScenario,
}) => {
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);
    const { scenarioId } = useParams();
    const classes = optimizationStyle(useTheme());
    const analyticsService = useAnalyticsService();
    const headerClasses = useStylesForHeaderInTable();

    const [recommendationCampaigns, setRecommendationCampaigns] = useState<ICustomizeCampaigns[]>(selectedCampaigns);
    const [isOpenBudgetModal, setIsOpenBudgetModal] = useState(false);
    const [budgetInfo, setBudgetInfo] = useState({ originalBudget: 0, updatedBudget: 0, customization: 0 });

    const { allocationSpend } = totalAllocationCampaigns;
    const isRenderModal = useRef(true);

    useEffect(() => {
        if (!isBulkLoading) {
            setRecommendationCampaigns(selectedCampaigns.map((c) => ({ ...c, isEdited: false })));
        }
    }, [selectedCampaigns]);

    const allocationSpendTotal = getCleanValueTotalSum(recommendationCampaigns, "allocationSpend");
    const totalAllocationSpendPercentages = getCleanValueTotalSum(recommendationCampaigns, "allocationPercentage");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!isRenderModal.current) {
                let changedCampaigns: any = [];
                recommendationCampaigns.forEach((c) => {
                    const findCampaign = selectedCampaigns.find((s) => s.campaignId === c.campaignId);
                    if (
                        findCampaign &&
                        (findCampaign.allocationSpend !== c.allocationSpend ||
                            findCampaign.allocationPercentage !== c.allocationPercentage)
                    ) {
                        changedCampaigns = [
                            ...changedCampaigns,
                            {
                                name: c.campaignName,
                                "Allocation spend": +c.allocationSpend.toString().replace(/,/g, ""),
                                "Allocation percentage": c.allocationPercentage,
                            },
                        ];
                    }
                });
                if (changedCampaigns.length) {
                    analyticsService.logEvent("Outcome Optimization Customize Input Changed", {
                        Campaigns: changedCampaigns,
                        "Allocated $": allocationSpendTotal,
                        "Allocated %": totalAllocationSpendPercentages,
                    });
                }
            }
        }, 2000);

        return () => {
            isRenderModal.current = false;
            clearTimeout(delayDebounceFn);
        };
    }, [JSON.stringify(recommendationCampaigns)]);

    const handleInputChange = (e: any, campaign: ICustomizeCampaigns) => {
        const { name, value } = e.target;
        const formattedValue = +value.toString().replace(/,/g, "");
        const updatedCampaigns = recommendationCampaigns.map((c) => {
            if (c.campaignId === campaign.campaignId) {
                const isFieldChanged = formattedValue !== c.allocationSpend;
                if (name === "allocationSpend") {
                    const allocationPercentage = calculatePercentage(formattedValue, allocationSpend);
                    return {
                        ...c,
                        allocationPercentage,
                        [name]: formattedValue,
                        isEdited: isFieldChanged,
                        isLocked: isFieldChanged,
                    };
                } else {
                    const updateAllocationSpend = (formattedValue / 100) * allocationSpend;
                    return {
                        ...c,
                        allocationSpend: updateAllocationSpend,
                        [name]: formattedValue,
                        isEdited: isFieldChanged,
                        isLocked: isFieldChanged,
                    };
                }
            } else {
                return c;
            }
        });
        setRecommendationCampaigns(updatedCampaigns);
    };

    const handleLocking = (campaign: ICustomizeCampaigns) => {
        const updatedCampaigns = recommendationCampaigns.map((c) => {
            if (c.campaignId === campaign.campaignId) {
                return { ...c, isLocked: !c.isLocked };
            } else {
                return c;
            }
        });
        setRecommendationCampaigns(updatedCampaigns);
    };

    const handleUndoItem = (campaign: ICustomizeCampaigns) => {
        const updatedCampaigns = recommendationCampaigns.map((c) => {
            const originalCampaign = selectedCampaigns.find((c) => c.campaignId === campaign.campaignId);
            if (c.campaignId === campaign.campaignId && originalCampaign) {
                const allocationPercentage = calculatePercentage(originalCampaign.allocationSpend, allocationSpend);
                return {
                    ...originalCampaign,
                    allocationPercentage,
                    allocationSpend: originalCampaign.allocationSpend,
                    isEdited: false,
                };
            } else {
                return c;
            }
        });
        setRecommendationCampaigns(updatedCampaigns);
    };

    const saveChanges = () => {
        if (recommendationCampaigns.length && scenarioId) {
            const changedCampaigns = differenceWith(recommendationCampaigns, selectedCampaigns, isEqual).map((c) => ({
                id: c.scenarioCampaignId,
                channel: c.channel,
                campaign_id: c.campaignId,
                budget_allocated:
                    c.allocationSpend /
                    (optimizationOutcomeContext?.metricRate === MetricRate.Daily
                        ? 1
                        : selectedScenario.forecastTimeframe),
                is_locked: c.isLocked,
            }));
            saveCustomizationChanges(changedCampaigns as IScenarioCampaignBody[], allocationSpendTotal);
        }
    };

    const reallocateDollars = () => {
        if (scenarioId) {
            const changedCampaigns = recommendationCampaigns
                .map((c) => ({
                    channel: c.channel,
                    campaign_id: c.campaignId,
                    budget_allocated: c.allocationSpend / selectedScenario.forecastTimeframe,
                    id: c.scenarioCampaignId,
                    is_locked: c.isLocked,
                }))
                .filter((c) => c.is_locked) as IScenarioCampaignBody[];
            reallocateRemainingBudget(changedCampaigns);
        }
    };

    const handleSpendSelection = (newValue: any, c: ICustomizeCampaigns) => {
        const value = typeof newValue === "string" ? newValue : newValue?.value || 0;
        handleInputChange(
            {
                target: {
                    name: "allocationSpend",
                    value,
                },
            },
            c,
        );
    };

    const handleSaveCampaigns = () => {
        if (recommendationCampaigns.length && scenarioId) {
            const changedCampaigns = differenceWith(
                recommendationCampaigns.map((s) => ({ allocationSpend: +s.allocationSpend.toFixed(0) })),
                selectedCampaigns.map((s) => ({ allocationSpend: +s.allocationSpend.toFixed(0) })),
                isEqual,
            );
            const filteredCamp = selectedCampaigns.filter((c) => {
                const camp = recommendationCampaigns.find((selectedC) => selectedC.campaignId === c.campaignId);
                if (camp) {
                    return camp;
                }
            });
            const updatedBudget = getCleanValueTotalSum(recommendationCampaigns, "allocationSpend");
            const originalBudget = getCleanValueTotalSum(filteredCamp, "allocationSpend");

            if (changedCampaigns.length) {
                setBudgetInfo({ originalBudget, updatedBudget, customization: changedCampaigns.length });
                setIsOpenBudgetModal(true);
            } else {
                recommendationsModalToggle();
            }
        }
    };

    const closeBudgetModal = () => {
        setIsOpenBudgetModal(false);
    };

    return (
        <>
            <Dialog maxWidth="lg" open={true}>
                <Stack p="30px" gap="30px">
                    <Stack gap="20px">
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6" fontWeight="700">
                                Customize Recommendations
                            </Typography>
                            <Typography onClick={recommendationsModalToggle}>
                                <SVGIconRenderer icon="closeIcon" />
                            </Typography>
                        </Stack>
                        <Typography>Adjust the recommendations for the current scenario.</Typography>
                    </Stack>
                    <TableContainer sx={{ border: "1px solid #D9D9D9", borderBottom: 0, width: "100%" }}>
                        <Table>
                            <TableHead className={headerClasses.headerRow}>
                                <TableRow className={headerClasses.headerRow}>
                                    {RECOMMANDATION_TABLE_HEADERS.map((h) => (
                                        <TableCell
                                            key={h.id}
                                            className={headerClasses.tableCell}
                                            width="200px"
                                            align="left"
                                        >
                                            {h.label}
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recommendationCampaigns.length === 0 ? (
                                    <TableRow>
                                        <TableCell align="center" colSpan={6}>
                                            <Typography color="text.secondary"> No campaign selected</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    recommendationCampaigns.map((c, i) => {
                                        const spendOptions: IOptionType[] = selectedCampaigns
                                            .filter((s) => s.campaignId === c.campaignId)
                                            .map((c) => [
                                                {
                                                    label: `Last ${selectedScenario.forecastTimeframe} Days Spend`,
                                                    value: inputSpendFormat(
                                                        +(c.lastDaysSpend || "").toString().replace(/,/g, "") || 0,
                                                    ),
                                                },
                                                {
                                                    label: `No Change Spend`,
                                                    value: inputSpendFormat(
                                                        +(c.noChangeSpend || "").toString().replace(/,/g, "") || 0,
                                                    ),
                                                },
                                                {
                                                    label: `Optimal Spend`,
                                                    value: inputSpendFormat(
                                                        +(c.allocationSpend || "").toString().replace(/,/g, "") || 0,
                                                    ),
                                                },
                                            ])[0];

                                        return (
                                            <TableRow key={c.campaignId}>
                                                <TableCell align="left">
                                                    <Stack direction="row" alignItems="center" gap="10px">
                                                        <DataSourceAvatar
                                                            programmaticName={c.connectorName}
                                                            className={classes.avatarImage}
                                                        />
                                                        <Typography
                                                            className={classes.campaignNameOverflow}
                                                            title={c.campaignName || ""}
                                                            sx={{
                                                                width: "400px!important",
                                                            }}
                                                        >
                                                            {c.campaignName}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Autocomplete
                                                        sx={{ width: "200px" }}
                                                        options={spendOptions}
                                                        size="small"
                                                        freeSolo
                                                        value={inputSpendFormat(
                                                            +c.allocationSpend.toString().replace(/,/g, "") || 0,
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <Box
                                                                {...props}
                                                                component="li"
                                                                key={`${option.label} ($${option.value})`}
                                                            >
                                                                {`${option.label} ($${option.value})`}
                                                            </Box>
                                                        )}
                                                        onChange={(e, newValue) => handleSpendSelection(newValue, c)}
                                                        getOptionLabel={(option: any) => option?.value || option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                sx={{
                                                                    ".MuiOutlinedInput-root": {
                                                                        paddingLeft: "14px!important",
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    min: 0,
                                                                    max: 100,
                                                                    style: { textAlign: "right" },
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                onChange={(e: any) =>
                                                                    handleSpendSelection(e.target.value, c)
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        value={inputSpendFormat(
                                                            +(c.allocationPercentage || "")
                                                                .toString()
                                                                .replace(/,/g, "") || 0,
                                                        )}
                                                        size="small"
                                                        inputProps={{
                                                            min: 0,
                                                            max: 100,
                                                            style: { textAlign: "right" },
                                                        }}
                                                        name="allocationPercentage"
                                                        onChange={(e) => handleInputChange(e, c)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">%</InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: "90px" }}>
                                                    <Stack direction="row" gap="8px">
                                                        <IconButton
                                                            sx={{ padding: 0 }}
                                                            onClick={() => handleLocking(c)}
                                                        >
                                                            <SVGIconRenderer
                                                                icon={c.isLocked ? "lockIcon" : "unlockIcon"}
                                                                title={
                                                                    !c.isLocked
                                                                        ? "Lock allocation"
                                                                        : "Unlock allocation"
                                                                }
                                                            />
                                                        </IconButton>
                                                        {c.isEdited && (
                                                            <IconButton
                                                                sx={{ padding: 0 }}
                                                                onClick={() => handleUndoItem(c)}
                                                            >
                                                                <SVGIconRenderer
                                                                    icon="undoIcon"
                                                                    title="Back to original"
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}

                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight="bold">Total</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">
                                            {formatValue(allocationSpendTotal, FORMATS.DOLLAR, 0)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">
                                            {formatValue(totalAllocationSpendPercentages, FORMATS.PERCENT, 0)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack direction="row" justifyContent="end" gap="10px">
                        <Button
                            onClick={recommendationsModalToggle}
                            className={classes.outlinedBtn}
                            variant="outlined"
                            disabled={isBulkLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.containedBtn}
                            variant="contained"
                            disabled={isBulkLoading}
                            onClick={handleSaveCampaigns}
                        >
                            Save Changes
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
            <BudgetInfoDialog
                isOpenBudgetModal={isOpenBudgetModal}
                closeBudgetModal={closeBudgetModal}
                budgetInfo={budgetInfo}
                reallocateDollars={reallocateDollars}
                saveChanges={saveChanges}
                isBulkLoading={isBulkLoading}
                classes={classes}
            />
        </>
    );
};
