import React, { FC } from "react";
import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FieldArray, FormikErrors } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import { Button } from "@prescientai/component-library";

import { IFivetranTable } from "src/interfaces/entities/IFivetranTable";
import { IDataSourceForm } from "src/interfaces/IData";

interface IFivetranModalContentProps {
    saveFivetranTableAction: (name: string) => Promise<boolean | undefined>;
    deleteFivetranTableAction: (id: string) => Promise<boolean | undefined>;
    editFivetranTableAction: (fivetranTable: IFivetranTable) => Promise<boolean | undefined>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    values: Pick<IDataSourceForm, "fivetranTable">;
    initialValues: Pick<IDataSourceForm, "fivetranTable">;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<IDataSourceForm>>(field: T): T extends React.ChangeEvent<IDataSourceForm>
            ? void
            : (e: string | React.ChangeEvent<IDataSourceForm>) => void;
    };
}

export const FivetranModalContent: FC<IFivetranModalContentProps> = ({
    saveFivetranTableAction,
    deleteFivetranTableAction,
    editFivetranTableAction,
    setFieldValue,
    values,
    initialValues,
    handleChange,
}) => {
    return (
        <FieldArray
            name="fivetranTable"
            render={() => {
                const addEntry = (name: string) => {
                    saveFivetranTableAction(name);
                };

                const updateTable = (updatedTable: IFivetranTable[]) => {
                    setFieldValue("fivetranTable", updatedTable);
                };

                const restoreOriginal = (id: string) => {
                    const findOriginal = initialValues.fivetranTable.find((original) => original.id === id);
                    if (findOriginal) {
                        updateTable(values.fivetranTable.map((table) => (table.id === id ? findOriginal : table)));
                    }
                };

                const toggleEditState = (id: string, isEdit: boolean) => {
                    updateTable(values.fivetranTable.map((table) => (table.id === id ? { ...table, isEdit } : table)));
                };

                const handleEntry = (index: number, fivetranTableEntry: IFivetranTable, action: string) => {
                    const { id } = fivetranTableEntry;
                    if (!id) {
                        return;
                    }

                    switch (action) {
                        case "delete":
                            deleteFivetranTableAction(id);
                            break;
                        case "edit":
                            toggleEditState(id, true);
                            break;
                        case "save":
                            editFivetranTableAction(fivetranTableEntry);
                            break;
                        case "undo":
                            restoreOriginal(id);
                            break;
                        default:
                            console.warn(`Unhandled action: ${action}`);
                    }
                };

                const fivetranTableLength = values.fivetranTable?.length || 0;
                const isLastEntryEmpty =
                    fivetranTableLength > 0 && !values.fivetranTable[fivetranTableLength - 1]?.name;

                return (
                    <div>
                        {values.fivetranTable?.map((apiKey, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Stack
                                        flexDirection="row"
                                        gap="10px"
                                        alignItems="center"
                                        sx={{ marginTop: "24px", width: "100%" }}
                                    >
                                        <TextField
                                            id={`fivetranTable.${index}.name`}
                                            value={apiKey.name}
                                            name={`fivetranTable.${index}.name`}
                                            disabled={!apiKey.isEdit}
                                            label="Fivetran table name"
                                            onChange={handleChange}
                                            placeholder="Fivetran table name"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: apiKey.id && apiKey.isEdit && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-cy="undo"
                                                            onClick={() => handleEntry(index, apiKey, "undo")}
                                                        >
                                                            <UndoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {apiKey.id && (
                                            <Stack direction="row" sx={{ width: "120px" }} gap={1}>
                                                <IconButton
                                                    data-cy="delete"
                                                    onClick={() => handleEntry(index, apiKey, "delete")}
                                                    size="small"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        handleEntry(index, apiKey, apiKey.isEdit ? "save" : "edit")
                                                    }
                                                    data-cy={apiKey.isEdit ? `save` : `edit`}
                                                    size="small"
                                                >
                                                    {apiKey.isEdit ? <Typography>Save</Typography> : <EditIcon />}
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                </React.Fragment>
                            );
                        })}
                        <Button
                            onClick={() => addEntry(values.fivetranTable[fivetranTableLength - 1]?.name)}
                            disabled={isLastEntryEmpty}
                            variant="text"
                            sx={{ marginTop: "16px" }}
                        >
                            Add Fivetran table
                        </Button>
                    </div>
                );
            }}
        />
    );
};
