import { createSlice, createSelector } from "@reduxjs/toolkit";
import { format, subDays } from "date-fns";
import { toDate } from "date-fns-tz";
import { Range } from "react-date-range";

import { getInitialDateRangeForCompany, getPastPeriod } from "../../components/DateRangeFilter/reactDateRangeUtils";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { selectDefaultLastReportedDayCount, setCurrentlyViewing } from "./organizationSlice";
import { WritableDraft } from "immer/dist/internal";

interface IDateFilter {
    date: string;
    compareDate: string;
    minDate: Date | undefined;
    isCompareOn: boolean;
}

const initialCurrentDate = getInitialDateRangeForCompany();

const initialState: IDateFilter = {
    date: JSON.stringify(initialCurrentDate),
    compareDate: JSON.stringify(getPastPeriod(initialCurrentDate, true)),
    minDate: undefined,
    isCompareOn: location.search.includes("comparison_start_date"),
};

const resetDateFiltersOnViewChange = (state: WritableDraft<IDateFilter>, action: any) => {
    const { isSavedViewOn, data } = action.payload;

    const params = new URLSearchParams(data.filters);
    const startDate = params.get("start_date");
    const endDate = params.get("end_date");
    const comparisonStartDate = params.get("comparison_start_date");
    const comparisonEndDate = params.get("comparison_end_date");

    if (isSavedViewOn && startDate && endDate) {
        const currentDateRange = [
            {
                startDate: toDate(startDate),
                endDate: toDate(endDate),
                key: "selection",
            },
        ];

        const compareDateRange =
            comparisonStartDate && comparisonEndDate
                ? [
                      {
                          startDate: toDate(comparisonStartDate),
                          endDate: toDate(comparisonEndDate),
                          key: "compare",
                      },
                  ]
                : getPastPeriod(currentDateRange, false);

        return {
            ...state,
            date: JSON.stringify(currentDateRange),
            compareDate: JSON.stringify(compareDateRange),
            isCompareOn: Boolean(comparisonStartDate && comparisonEndDate),
        };
    }

    return {
        ...initialState,
        date: JSON.stringify(initialCurrentDate),
        compareDate: JSON.stringify(getPastPeriod(initialCurrentDate, false)),
        isCompareOn: false,
    };
};

const dateFilterSlice = createSlice({
    name: "dateFilter",
    initialState,
    reducers: {
        setCurrentDateFilter: (state, { payload }) => ({ ...state, date: payload.date }),
        setCompareDateFilter: (state, { payload }) => ({ ...state, compareDate: payload.compareDate }),
        setMinDate: (state, { payload }) => ({ ...state, minDate: payload.minDate }),
        setCompare: (state, { payload }) => ({ ...state, isCompareOn: payload.isCompareOn }),
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
        builder.addCase("filterView/setFilterView", (state, action) => resetDateFiltersOnViewChange(state, action));
    },
});

export const { setCurrentDateFilter, setMinDate, setCompareDateFilter, setCompare } = dateFilterSlice.actions;

export default dateFilterSlice.reducer;

// Selectors
export const currentDateFilterSelector = (state: RootState) => state.dateFilter;

export const formattedDateSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.date);
    const formattedDate: Range[] = [
        {
            startDate: toDate(dateFilterArr[0].startDate),
            endDate: toDate(dateFilterArr[0].endDate),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const formattedCurrentDateStringSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.date);
    const formattedDate = [
        {
            startDate: format(toDate(dateFilterArr[0].startDate), "yyyy-MM-dd"),
            endDate: format(toDate(dateFilterArr[0].endDate), "yyyy-MM-dd"),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const formattedCompareDateSelector = createSelector(currentDateFilterSelector, (state) => {
    if (state.compareDate) {
        const dateFilterArr = JSON.parse(state.compareDate);
        const formattedDate: Range[] = [
            {
                startDate: toDate(dateFilterArr[0].startDate),
                endDate: toDate(dateFilterArr[0].endDate),
                key: dateFilterArr[0].key,
            },
        ];
        return formattedDate;
    }

    // we will default the time range if state.compareDate does not exist here.
    return getPastPeriod(initialCurrentDate, true);
});

export const formattedCompareDateStringSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.compareDate);
    const formattedDate = [
        {
            startDate: format(toDate(dateFilterArr[0].startDate), "yyyy-MM-dd"),
            endDate: format(toDate(dateFilterArr[0].endDate), "yyyy-MM-dd"),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const minDateSelector = createSelector(currentDateFilterSelector, (state) => state.minDate);
export const isCompareSelector = createSelector(currentDateFilterSelector, (state) => state.isCompareOn);

export const selectInitialCurrentDateRangeBasedOnCompanyReportedDate = createSelector(
    [selectDefaultLastReportedDayCount],
    (defaultReportedDays) => {
        return [
            {
                startDate: subDays(new Date(), 30 + (defaultReportedDays - 1)),
                endDate: subDays(new Date(), defaultReportedDays),
                key: "selection",
            },
        ];
    },
);
