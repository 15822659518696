
import { Button } from "@prescientai/component-library";
import { Avatar,  GlobalStyles, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import queryString from "query-string";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import companyLogoMini from "../../assets/logo/prescient_icon_midnight.svg";
import { BILLING_PATH, CONNECTORS_PATH, LOGIN_PATH, MEMBER_PATH, getDefaultRedirectPath } from "../../consts/path/path";
import { subTitleH4 } from "../../fontStyles/fontStyles";
import { useLogoutMutation } from "../../reduxState/apis/authApi";
import { setContinueAsSuperAdmin } from "../../reduxState/slices/continueAsSuperAdminSlice";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
} from "../../reduxState/slices/organizationSlice";
import { isSuperAdminSelector } from "../../reduxState/slices/userSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { cleanUpUserInfo } from "../../services/auth/logout";
import { getPathForAnalytics } from "../../services/utils";

export const NoCampaignPage = memo(
    ({
        title,
        subTitle,
        isContinueAsSuperAdminBtn = false,
    }: {
        title: string;
        subTitle: string | JSX.Element;
        isContinueAsSuperAdminBtn?: boolean;
    }) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();

        const orgCode = useSelector(selectCurrentlyViewingCode);
        const isSuperAdmin = useSelector(isSuperAdminSelector);
        const defaultOrg = useSelector(selectCurrentlyViewingCompanyById);

        const [logout] = useLogoutMutation();
        const analyticsService = useAnalyticsService();
        const filterQueryParamsObj = queryString.parse(window.location.search);

        useEffect(() => {
            if (location.search) {
                analyticsService.logEvent("Dashboard Building Page Viewed", {
                    Page: `/${orgCode}${getPathForAnalytics(location.pathname, location.search)}`,
                });
            }
        }, [JSON.stringify(filterQueryParamsObj)]);

        const handleBtnClick = (to: string) => {
            navigate({
                pathname: `/org/${orgCode}${to}`,
            });
        };
        const handleLogout = async () => {
            await logout("");
            cleanUpUserInfo(true);
            navigate(LOGIN_PATH);
        };

        const handleSuperAdminAction = () => {
            dispatch(setContinueAsSuperAdmin(true));
            navigate(`/org/${orgCode}${getDefaultRedirectPath(defaultOrg)}`);
        };

        return (
            <Stack justifyContent="center" flexDirection="row">
                <GlobalStyles styles={{ body: { backgroundColor: "white" } }} />
                <Stack
                    sx={(theme) => ({
                        width: "50%",
                        [theme.breakpoints.down("md")]: {
                            width: "100%",
                            padding: "20px",
                        },
                    })}
                    justifyContent="center"
                    gap="30px"
                    padding="3rem 0"
                >
                    <Avatar
                        sx={{ width: "200px", height: "200px", alignSelf: "center", backgroundColor: "transparent" }}
                        src={companyLogoMini}
                        variant="circular"
                        alt="image"
                    />
                    <Typography color="text.secondary" fontWeight={300} fontSize="48px" variant="h1">
                        {title}
                    </Typography>
                    <Typography variant="h4" className={subTitleH4}>
                        {subTitle}
                    </Typography>

                    <div>
                        <Typography variant="h4" className={subTitleH4} mb={1}>
                            Ready to start your subscription?
                        </Typography>
                        <Button data-cy="billingPage" variant="contained" onClick={() => handleBtnClick(BILLING_PATH)}>
                            Billing Page
                        </Button>
                    </div>
                    <div>
                        <Typography variant="h4" className={subTitleH4} mb={1}>
                            Want to add or remove data sources?
                        </Typography>
                        <Button
                            data-cy="connectorPage"
                            variant="contained"
                            onClick={() => handleBtnClick(CONNECTORS_PATH)}
                        >
                            Connectors Page
                        </Button>
                    </div>
                    <div>
                        <Typography variant="h4" className={subTitleH4} mb={1}>
                            Want to add someone to your account?
                        </Typography>
                        <Button data-cy="memberPage" variant="contained" onClick={() => handleBtnClick(MEMBER_PATH)}>
                            Members Page
                        </Button>
                    </div>
                    <Button
                        data-cy="logoutBtn"
                        variant="contained"
                        sx={{ marginTop: "20px", width: "fit-content" }}
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                    {isSuperAdmin && isContinueAsSuperAdminBtn && (
                        <Button
                            variant="outlined"
                            sx={{ marginTop: "20px", width: "fit-content" }}
                            onClick={handleSuperAdminAction}
                        >
                            continue as Superadmin
                        </Button>
                    )}
                </Stack>
            </Stack>
        );
    },
);
