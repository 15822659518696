import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { format, parseISO } from "date-fns";
import { IMonthlyBreakdown } from "src/interfaces/entities/IQaMetric";
import { valueFromType } from "./MetricVerificationItem";

interface IMetricBreakdownTableProps {
    months: IMonthlyBreakdown[];
    metricType: string;
}

const getHeaderFromTimestamps = (startDateStr: string, endDateStr: string) => {
    const startDate = parseISO(startDateStr);
    const endDate = parseISO(endDateStr);
    const monthName = startDate.toLocaleString("default", { month: "long" });

    return (
        <Stack>
            <Typography>{monthName}</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} rowGap={0}>
                <Typography variant="body2" color="text.secondary">
                    {format(startDate, "MM/dd/yyyy")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    -
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {format(endDate, "MM/dd/yyyy")}
                </Typography>
            </Stack>
        </Stack>
    );

    return;
};

export const MetricBreakdownTable: React.FC<IMetricBreakdownTableProps> = ({ months, metricType }) => {
    return (
        <TableContainer component={Box} sx={{ border: "1px solid", borderColor: "#a6a6a6", borderRadius: 2 }} p={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        {months.map((monthData, index) => (
                            <TableCell key={`${metricType}_${index}_head`}>
                                {getHeaderFromTimestamps(monthData.startDate, monthData.endDate)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {months.map((monthData, index) => (
                            <TableCell key={`${metricType}_${index}_head`} sx={{ border: "none" }}>
                                {valueFromType(monthData.value, metricType)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
