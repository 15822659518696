import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";

import { PageContainer } from "src/components/PageContainer/PageContainer";
import { PageHeader } from "src/components/PageHeader/PageHeader.v2";
import { IConnector } from "src/interfaces/IConnector";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import {
    useGetQaMetricTypesQuery,
    useGetQaMetricsQuery,
    useQaMetricResponsesPostMutation,
} from "src/reduxState/apis/qaMetricsApi";
import { MetricDialog } from "./sharedComponent/MetricDialog";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { formatMetricsData } from "src/services/connectors/connectors";
import Loader from "src/components/core/Loader/Loader";
import { useNavigate } from "react-router";
import { HOME_PATH } from "src/consts/path/path";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { useGetConnectorsQuery } from "src/reduxState/apis/connectorsApi";

export const VerifyData = () => {
    const navigate = useNavigate();
    const analyticsService = useAnalyticsService();
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const orgId = useSelector(selectCurrentlyViewingId);
    const { enqueueSnackbar } = useSnackbar();

    const { data: connectors, isLoading: isConnectorsLoading } = useGetConnectorsQuery(orgId);

    const {
        data: qaMetricsQuestion = { data: [], meta: { startDate: "", endDate: "" } },
        isLoading,
        isError,
        error,
    } = useGetQaMetricsQuery({ orgId });
    const [qaMetricResponsesPost, { isLoading: isSubmitLoading }] = useQaMetricResponsesPostMutation();
    const { data: metricTypes = [] } = useGetQaMetricTypesQuery({ orgId });

    const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>(false);
    const [metricsWithConnector, setMetricsWithConnector] = useState<IQaMetric[]>([]);

    useEffect(() => {
        const isEmailRedirect = location.search?.includes("utm_medium=email");
        if (isEmailRedirect) {
            analyticsService.logEvent("Data QA Email Clicked");
        }
    }, []);

    useEffect(() => {
        if (qaMetricsQuestion.data.length && metricTypes.length && connectors?.length) {
            const formattedData = formatMetricsData(qaMetricsQuestion.data, metricTypes, connectors);
            const isAllMetricsProcessed = formattedData.every(
                (metric) => metric.disabled || metric.isAccepted !== null,
            );
            setIsDataSubmitted(isAllMetricsProcessed);
            setMetricsWithConnector(formattedData);
        }
        if (isError) {
            enqueueSnackbar(`Fetch QA metrics - ${JSON.stringify(error)}`, {
                id: uniqueId(),
                variant: "error",
            });
        }
    }, [qaMetricsQuestion, metricTypes, connectors]);

    const isAllMetricsProcessed = useMemo(
        () => metricsWithConnector.every((metric) => metric.disabled || metric.isAccepted !== null),
        [metricsWithConnector],
    );

    const isAllMetricsAccepted = useMemo(
        () => metricsWithConnector.every((metric) => metric.disabled || metric.isAccepted === true),
        [metricsWithConnector],
    );

    const submitQaMetric = (isSubmitBtnClicked: boolean) => {
        const metricsToSubmit = metricsWithConnector
            .map(({ id, isAccepted = null, userReportedValue = 0 }) => ({
                qaMetricId: +id,
                userReportedValue,
                isAccepted,
            }))
            .filter(({ qaMetricId }) => isNaN(qaMetricId) === false);
        qaMetricResponsesPost({ orgId, body: metricsToSubmit })
            .unwrap()
            .then(() => {
                if (isSubmitBtnClicked) {
                    if (isAllMetricsAccepted) {
                        navigate(`/org/${orgCode}${HOME_PATH}?data_qa=completed`);
                        return;
                    }
                    setIsDataSubmitted(true);
                } else {
                    enqueueSnackbar("Your changes have been saved.", {
                        id: uniqueId(),
                        variant: "success",
                    });
                }
                window.scrollTo(0, 0);
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong!", {
                    id: uniqueId(),
                    variant: "error",
                });
            });
    };

    return (
        <>
            <PageHeader pageHeading="Settings" />
            <PageContainer
                style={{
                    backgroundColor: "white",
                    padding: "48px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Stack>
                    {isConnectorsLoading || isLoading ? (
                        <Loader />
                    ) : (
                        <MetricDialog
                            isDataSubmitted={isDataSubmitted}
                            meta={qaMetricsQuestion.meta}
                            metricsWithConnector={metricsWithConnector}
                            setMetricsWithConnector={setMetricsWithConnector}
                            onSubmit={submitQaMetric}
                            isAllMetricsProcessed={isAllMetricsProcessed}
                            isSubmitLoading={isError || isSubmitLoading}
                            setIsDataSubmitted={setIsDataSubmitted}
                            isAllMetricsAccepted={isAllMetricsAccepted}
                        />
                    )}
                </Stack>
            </PageContainer>
        </>
    );
};
