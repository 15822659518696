import {
    isCACAvailableForCompany,
    IPerformanceAttributionTableCells,
    PERFORMANCE_ATTRIBUTION_HEADERS,
    PERFORMANCE_CAC_METRICS,
} from "../performancePaidPage/performancePaidPage";
import { FORMATS } from "../../enums/Formats";
import { IDictionary } from "src/interfaces/IDictionary";
import { RecommandationTab, ScenarioOutcomeTab } from "src/interfaces/entities/IScenario";

export const getBaseScenarioType = (scenarioType: SCENARIO_TYPE) => {
    const baseObj: Record<string, string> = {
        cac: "cac",
        amazon_cac: "cac",
        amazon_revenue: "scale",
    };
    return (baseObj[scenarioType] || scenarioType) as SCENARIO_TYPE;
};

export enum SCENARIO_TYPE {
    SHIFT = "shift", // not in use
    GOAL = "goal", // not in use

    FORECAST = "forecast", // when forecasted save
    SCALE = "scale", // default and when roas as target type
    ROAS = "scale",
    CAC = "cac",

    AMAZON_REVENUE = "amazon_revenue",
    AMAZON_CAC = "amazon_cac",
}

export const SCENARIO_BASIS_BASED_ON_TARGET: { [key in SCENARIO_TYPE]?: string } = {
    [SCENARIO_TYPE.SCALE]: "Revenue",
    [SCENARIO_TYPE.CAC]: "Customers",
    [SCENARIO_TYPE.AMAZON_REVENUE]: "Amazon Revenue",
    [SCENARIO_TYPE.AMAZON_CAC]: "Amazon Customers",
};

export enum SCENARIO_OPTIMIZATION_TARGET_LABEL {
    ROAS = "Revenue/ROAS",
    CAC = "New Customers/CAC",
    AMAZON_REVENUE = "Amazon - Revenue/ROAS",
    AMAZON_CUSTOMERS = "Amazon - New Customers/CAC",
}

export enum FORECAST_BY_SPEND_METRIC {
    REVENUE = "revenue",
    CUSTOMERS = "customers",
    AMAZON_REVENUE = "amazon_revenue",
    AMAZON_CUSTOMERS = "amazon_customers",
}

export enum OPTIMIZED_MODELS_BY_METRIC {
    REVENUE = "revenue",
    CUSTOMERS = "customers",
    AMAZON_REVENUE = "amazon_revenue",
    AMAZON_CUSTOMERS = "amazon_customers",
}

export const getModelTypeFromScenarioType = (scenarioType: SCENARIO_TYPE) => {
    if (scenarioType === SCENARIO_TYPE.CAC) {
        return FORECAST_BY_SPEND_METRIC.CUSTOMERS;
    } else if (scenarioType === SCENARIO_TYPE.AMAZON_REVENUE) {
        return FORECAST_BY_SPEND_METRIC.AMAZON_REVENUE;
    } else if (scenarioType === SCENARIO_TYPE.AMAZON_CAC) {
        return FORECAST_BY_SPEND_METRIC.AMAZON_CUSTOMERS;
    }
    return FORECAST_BY_SPEND_METRIC.REVENUE;
};

export enum INCLUDED_TYPE {
    USERS = "users",
    JOBS = "jobs",
    COMPANIES = "companies",
}

export enum OptimizationTab {
    Scenarios = "scenarios",
    Drafts = "drafts",
    Recommended = "recommended",
    Archived = "archived",
}

export enum CAC_HEADER_LABELS {
    cac = "MMM CAC",
    totalMMMCac = "Total MMM CAC",
    totalCac = "Total CAC",
}

export const labelsWhereNegativeIsGood: string[] = [
    CAC_HEADER_LABELS.cac,
    CAC_HEADER_LABELS.totalCac,
    CAC_HEADER_LABELS.totalMMMCac,
];

export type Choice = "asc" | "desc";

export interface ICells {
    name: string;
    createdName: string;
    scenarioType: SCENARIO_TYPE;
    budget: string;
    scenarioLevel: string;
    forecastTimeframe: string;
    isScenarioEditable: string;
}

interface IOptimizationHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

export const OPTIMIZATION_PAGE_TABLE_HEADERS: readonly IOptimizationHeadCell[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Scenario",
    },
    {
        id: "createdName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
    },
    {
        id: "scenarioType",
        numeric: false,
        disablePadding: false,
        label: "Scenario Basis",
    },
    {
        id: "budget",
        numeric: false,
        disablePadding: false,
        label: "Budget",
    },
    {
        id: "scenarioLevel",
        numeric: false,
        disablePadding: false,
        label: "Scenario Level",
    },
    {
        id: "forecastTimeframe",
        numeric: false,
        disablePadding: false,
        label: "Timeframe",
    },
    {
        id: "isScenarioEditable",
        numeric: false,
        disablePadding: false,
        label: "Scenario Performance",
    },
];

export interface IForecastCampaignCells {
    campaignName: string;
    campaignId: string;
    confidenceScore: string;
    spend: string;
    trueRevenue: string;
    trueRoas: string;
    allocationSpend: string;
    forecastedRevenue: string;
    forecastedRoas: string;
    lastScenarioTimeframeMMMCac: string;
    implementationDate: string;
    isAccepted: string;
    daysInFlight?: number;
    newCustomers: number;
    cac: number;
    forecastedNewCustomers: number;
    forecastedCac: number;
}

export interface IForecastCampaignHeadCell {
    id: keyof IForecastCampaignCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    hidden: boolean;
    fixed?: number;
    width?: string;
    sign?: FORMATS;
    exportFixed?: number;
    category?: string;
    align: "inherit" | "left" | "center" | "right" | "justify" | undefined;
    className?: string;
    groupName?: string;
    getLabel?: (timeframe: any) => string;
    tabLabel?: IDictionary;
}

export const FORECASTED_CAMPAIGNS_HEADERS: readonly IForecastCampaignHeadCell[] = [
    {
        id: "campaignName",
        numeric: false,
        disablePadding: false,
        label: "Campaigns",
        hidden: false,
        width: "350px",
        align: "left",
        className: "campaignNameCell",
        groupName: "other",
    },
    {
        id: "confidenceScore",
        numeric: false,
        disablePadding: false,
        label: "Confidence",
        hidden: false,
        align: "right",
        groupName: "other",
        className: "confidenceCell",
    },
    {
        id: "lastScenarioTimeframeMMMCac",
        numeric: false,
        disablePadding: false,
        label: "Last 30 Day CAC",
        getLabel: (timeframe) => `Last ${timeframe} Day MMM CAC`,
        hidden: false,
        align: "right",
        className: "mmmCacCell",
        groupName: "other",
    },
    {
        id: "spend",
        numeric: true,
        disablePadding: false,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "trueRevenue",
        numeric: true,
        disablePadding: false,
        label: "Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "trueRoas",
        numeric: true,
        disablePadding: false,
        label: "ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "newCustomers",
        numeric: true,
        disablePadding: false,
        label: "MMM New Customers",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        align: "right",
        className: "newCustomersCell",
        groupName: "expected",
    },
    {
        id: "cac",
        numeric: true,
        disablePadding: false,
        label: "MMM CAC",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "allocationSpend",
        numeric: true,
        disablePadding: false,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
        align: "right",
        className: "spendCell",
        groupName: "optimal",
    },
    {
        id: "forecastedRevenue",
        numeric: true,
        disablePadding: false,
        label: "Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
        align: "right",
        className: "forecastedRevenueCell",
        groupName: "optimal",
    },
    {
        id: "forecastedRoas",
        numeric: true,
        disablePadding: false,
        label: "ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "forecastedRoasCell",
        groupName: "optimal",
    },
    {
        id: "forecastedNewCustomers",
        numeric: true,
        disablePadding: false,
        label: "MMM New Customers",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
        align: "right",
        className: "newCustomersCell",
        groupName: "optimal",
    },
    {
        id: "forecastedCac",
        numeric: true,
        disablePadding: false,
        label: "MMM CAC",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "forecastedRoasCell",
        groupName: "optimal",
    },
];

export const EXPORT_ROAS_FORECASTED_CAMPAIGNS_HEADERS = [
    ...FORECASTED_CAMPAIGNS_HEADERS,
    {
        id: "totalSpendDiff",
        label: "Spend Change",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalRevenueDiff",
        label: "Revenue Change",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalRoasDiff",
        label: "ROAS Change",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
    },
];

export const EXPORT_CAC_FORECASTED_CAMPAIGNS_HEADERS = [
    ...FORECASTED_CAMPAIGNS_HEADERS,
    {
        id: "totalSpendDiff",
        label: "Spend Change",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalNewCustomersDiff",
        label: "New Customers Change",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalCacDiff",
        label: "CAC Change",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
    },
];

export const FORECASTED_CAMPAIGNS_HEADERS_WITH_INPUTS: IForecastCampaignHeadCell[] = [
    FORECASTED_CAMPAIGNS_HEADERS[0],
    {
        id: "isAccepted",
        numeric: false,
        disablePadding: false,
        label: "Accept/Decline",
        hidden: false,
        align: "center",
        groupName: "other",
        tabLabel: {
            [ScenarioOutcomeTab.Tracking]: "Accept/Reason",
        },
    },
    {
        id: "implementationDate",
        numeric: false,
        disablePadding: false,
        label: "Implementation Date",
        hidden: false,
        align: "center",
        groupName: "other",
    },
    ...FORECASTED_CAMPAIGNS_HEADERS.slice(1),
];

export const ROAS_HEADING_IDS = ["trueRevenue", "trueRoas", "forecastedRevenue", "forecastedRoas"];
export const CAC_HEADING_IDS = ["newCustomers", "cac", "forecastedNewCustomers", "forecastedCac"];

export const TRACKING_CAMPAIGNS_HEADERS: readonly IForecastCampaignHeadCell[] = [
    ...FORECASTED_CAMPAIGNS_HEADERS.filter((c) => c.id !== "lastScenarioTimeframeMMMCac").map((h) =>
        h.id === "confidenceScore"
            ? {
                  ...h,
                  hidden: true,
              }
            : h,
    ),
    {
        id: "daysInFlight",
        numeric: false,
        disablePadding: false,
        label: "Days In Flight",
        hidden: false,
        align: "center",
        groupName: "recommendationHeader",
    },
    {
        id: "implementationDate",
        numeric: false,
        disablePadding: false,
        label: "Implementation Date",
        hidden: false,
        align: "center",
        groupName: "recommendationHeader",
    },
];

export const excludeRows = (
    isCacOptimization: boolean = false,
    defaultRevenueSource?: string,
    orgCode?: string,
): string[] => {
    let excludedRows: string[] =
        !isCacOptimization && isCACAvailableForCompany(defaultRevenueSource, orgCode)
            ? []
            : ["lastScenarioTimeframeMMMCac"];
    excludedRows = isCacOptimization ? excludedRows.concat(ROAS_HEADING_IDS) : excludedRows.concat(CAC_HEADING_IDS);
    return excludedRows;
};

export const getOutcomeCampaignHeaders = (
    tabValue: string,
    isCacOptimization: boolean = false,
    timeframe: number,
    defaultRevenueSource: string,
    orgCode: string,
): IForecastCampaignHeadCell[] => {
    const headers = (
        tabValue === RecommandationTab.Campaigns
            ? FORECASTED_CAMPAIGNS_HEADERS_WITH_INPUTS
            : FORECASTED_CAMPAIGNS_HEADERS
    ).map((c) => ({
        ...c,
        label: c.getLabel ? c.getLabel(timeframe) : c.label,
    }));

    return headers.filter((c) => !excludeRows(isCacOptimization, defaultRevenueSource, orgCode).includes(c.id));
};

export const getTrackingCampaignHeaders = (isCacOptimization: boolean): IForecastCampaignHeadCell[] => {
    return TRACKING_CAMPAIGNS_HEADERS.filter((c) => !excludeRows(isCacOptimization).includes(c.id));
};

export const campaignSelectionForFormTableHeader = (scenarioType: SCENARIO_TYPE): IOptimizedHeadCell[] =>
    CHANNEL_TABLE_HEADERS.filter(
        (h) => !excludeRows([SCENARIO_TYPE.CAC, SCENARIO_TYPE.AMAZON_CAC].includes(scenarioType)).includes(h.id),
    );

interface ITrackingCell {
    id: string;
    numeric: boolean;
    label: string;
    sign?: FORMATS;
    fixed?: number;
    exportFixed?: number;
    width?: string;
    tabLabel?: IDictionary;
}

export const FORECASTED_CAMPAIGNS_TRACKING_HEADERS: ITrackingCell[] = [
    {
        id: "campaignName",
        numeric: false,
        label: "Campaigns",
        width: "350px",
    },
    {
        id: "forecasted",
        numeric: true,
        label: "Forecasted",
        tabLabel: {
            Budget: "Recommended",
        },
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        width: "9%",
    },
    {
        id: "actual",
        numeric: true,
        label: "Actual",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        width: "8%",
    },
    {
        id: "change",
        numeric: true,
        label: "Pacing",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
    },
    {
        id: "potentialFullfilled",
        numeric: true,
        label: "Potential Fullfilled",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
    },
];

export const RECOMMANDATION_TABLE_HEADERS = [
    {
        id: "channelName",
        numeric: false,
        disablePadding: false,
        label: "Channels/Campaigns",
        sign: undefined,
        fixed: 0,
    },
    {
        id: "allocatedDollar",
        numeric: true,
        disablePadding: false,
        label: "Allocated $",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "allocatedPercentage",
        numeric: true,
        disablePadding: false,
        label: "Allocated %",
        sign: FORMATS.PERCENT,
        fixed: 0,
    },
    // {
    //     id: "min",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Min %",
    //     sign: FORMATS.PERCENT,
    //     fixed: 0,
    // },
    // {
    //     id: "max",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Max %",
    //     sign: FORMATS.PERCENT,
    //     fixed: 0,
    // },
];

export interface IForecastTabSeries {
    revenue: any[];
    media: any[];
    holidays: any[];
    trend: any[];
}

export enum TIMEFRAME_OPTIONS {
    DAILY = 1,
    LAST7DAYS = 7,
    LAST14DAYS = 14,
    LAST28DAYS = 28,
}

export enum TIMEFRAME_OPTIONS_LABEL {
    LAST7DAYS = "7 Days",
    LAST14DAYS = "14 Days",
    LAST28DAYS = "28 Days",
}

export const TIMEFRAME_RADIO_OPTIONS = [
    { label: TIMEFRAME_OPTIONS_LABEL.LAST7DAYS, value: TIMEFRAME_OPTIONS.LAST7DAYS },
    { label: TIMEFRAME_OPTIONS_LABEL.LAST14DAYS, value: TIMEFRAME_OPTIONS.LAST14DAYS },
    { label: TIMEFRAME_OPTIONS_LABEL.LAST28DAYS, value: TIMEFRAME_OPTIONS.LAST28DAYS },
];

export enum VISIBILITY {
    private = "Private",
    public = "Public",
}

export interface IMetricData {
    individualData: Array<{
        campaignId: string;
        campaignName: string;
        connectorName?: string;
        channel: string;
        forecasted: number;
        actual: number;
        change: number;
        perChange: number;
        potentialFulfilled: number;
    }>;
    total: { forecasted: number; actual: number; potentialFullfilled: number; pacing: boolean; unit?: FORMATS };
}

export interface IMetricDataObj {
    spend: IMetricData;
    revenue: IMetricData;
    roas: IMetricData;
}

export const SCENARIO_TRACKING_TABS: Array<{ value: "spend" | "revenue" | "roas"; label: string }> = [
    { value: "revenue", label: "Revenue" },
    { value: "roas", label: "ROAS" },
    { value: "spend", label: "Budget" },
];

interface IOptimizedCampaignCells extends IPerformanceAttributionTableCells {
    channelName: string;
    lock: string;
    currentEstimatedDailySpend: number;
    currentEstimatedSpend: number;
}

export interface IOptimizedHeadCell {
    id: keyof IOptimizedCampaignCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    sign?: FORMATS;
    fixed?: number;
    exportFixed?: number; // used to give more granularity in export values.
    hidden?: boolean;
    width?: string;
    align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
    className?: string;
    groupName?: string;
}

export const CHANNEL_TABLE_HEADERS: IOptimizedHeadCell[] = [
    {
        id: "channelName",
        numeric: false,
        disablePadding: false,
        label: "Channels/Campaigns",
        sign: undefined,
        fixed: 0,
    },
    {
        id: "lock",
        numeric: true,
        disablePadding: false,
        label: "",
    },
    {
        id: "currentEstimatedDailySpend",
        numeric: true,
        disablePadding: false,
        label: "No Change Daily Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "currentEstimatedSpend",
        numeric: true,
        disablePadding: false,
        label: "No Change {7 day} Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    ...PERFORMANCE_ATTRIBUTION_HEADERS.filter((h) => ["channelName", "trueRevenue", "trueRoas"].includes(h.id)),
    ...PERFORMANCE_CAC_METRICS,
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        hidden: true,
    },
];
