import { FC, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

import {
    getRecommendationsTableHeading,
    getTrackingScenarioDataWithAllocationData,
    getTotalAllocationForTracking,
    getTrackingDates,
} from "src/services/optimizationPage/optimization";

import { FORMATS } from "src/enums/Formats";
import { IScenario, TrackingViews } from "src/interfaces/entities/IScenario";
import { IDictionary } from "src/interfaces/IDictionary";
import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";
import { channelTabStyle } from "../../../OutcomeTable/OutcomeCampaignTable";

import { TrackingSummary } from "./TrackingSummary";
import { StatusDetails } from "./StatusDetails";
import { CampaignsTable } from "./CampaignsTable";
import { handleSelectedColsHidden } from "../../OutcomeContent";
import { handleFilteredData } from "src/services/utils";
import { CustomColumnFilter } from "src/components/CustomColumnFilter/CustomColumnFilter";
import { getBaseScenarioType, getTrackingCampaignHeaders } from "src/consts/optimizationPage/optimizationPage";
import { isPast } from "date-fns";

interface IPerdictedLiftTab {
    selectedScenario: IScenario;
}

export const TrackingTab: FC<IPerdictedLiftTab> = ({ selectedScenario }) => {
    const navigate = useNavigate();
    const classes = {
        ...optimizationStyle(useTheme()),
        recommandationStyle: css({
            backgroundColor: "white",
            padding: "20px 40px",
            border: "1px solid #D9D9D9",
            borderRadius: "5px",
        }),
        ...channelTabStyle(),
        compareToStyle: css({
            backgroundColor: "white",
            "& .MuiSelect-select": {
                fontWeight: "bold",
            },
            "& .MuiInput-input": {
                paddingBottom: 0,
            },
            "& .MuiInput-underline:before": {
                borderBottom: 0,
            },
            "& .MuiSvgIcon-root": {
                color: "black",
            },
        }),
    };
    const { latestEndDate } = getTrackingDates(selectedScenario.scenarioCampaigns || []);
    const isScenarioExpired = useMemo(() => isPast(latestEndDate || new Date()), [selectedScenario]);
    const [compareTo, setCompareTo] = useState<TrackingViews>(
        isScenarioExpired ? TrackingViews.LastDays : TrackingViews.InFlight,
    );
    const isCacOptimizedScenario = selectedScenario.isCacOptimization;

    const compareToOption = [
        {
            lable: "In-Flight",
            value: TrackingViews.InFlight,
            isHidden: isScenarioExpired,
        },
        { lable: `${selectedScenario?.forecastTimeframe} Days`, value: TrackingViews.LastDays },
    ];
    const getCompareLabel = compareToOption.find((op: { value: any }) => op.value === compareTo)?.lable || "";

    const [campaignTableHeader, setCampaignTableHeader] = useState(
        handleSelectedColsHidden(getTrackingCampaignHeaders(isCacOptimizedScenario)),
    );

    const handleTableCol = (column: any) => {
        const updatedCards = campaignTableHeader.map((c) => {
            if (c.id === column.id) {
                handleFilteredData("excluded_columns", { id: c.id }, navigate);
                return { ...c, hidden: !c.hidden };
            } else {
                return c;
            }
        });
        setCampaignTableHeader(updatedCards);
    };

    const acceptedCampaigns = selectedScenario.scenarioCampaigns?.filter((c) => c.isAccepted) || [];
    const acceptedCampaignsCount = acceptedCampaigns.length;

    const recommendationsTableHeading = getRecommendationsTableHeading(
        selectedScenario.forecastTimeframe,
        getCompareLabel,
        getBaseScenarioType(selectedScenario.scenarioType),
    );

    const campaignsWithPerformanceData = selectedScenario.scenarioCampaigns
        ? getTrackingScenarioDataWithAllocationData(acceptedCampaigns, selectedScenario, compareTo)
        : [];

    const performanceDataTotal: IDictionary = getTotalAllocationForTracking(campaignsWithPerformanceData);
    const spendSummaryRow = {
        label: "Total Spend",
        forecasted: performanceDataTotal.allocationSpend,
        last: performanceDataTotal.spend,
        change: performanceDataTotal.totalSpendDiff,
        percentageChange: performanceDataTotal.totalSpendDiff,
        unit: FORMATS.DOLLAR,
        fixed: 0,
    };

    const roasSummaryArray = [
        spendSummaryRow,
        {
            label: "Total Revenue",
            forecasted: performanceDataTotal.forecastedRevenue,
            last: performanceDataTotal.trueRevenue,
            change: performanceDataTotal.totalRevenueDiff,
            percentageChange: performanceDataTotal.totalForecastedRevenuePercentages,
            unit: FORMATS.DOLLAR,
            fixed: 0,
        },
        {
            label: "Total ROAS",
            forecasted: performanceDataTotal.forecastedRoas,
            last: performanceDataTotal.trueRoas,
            change: performanceDataTotal.totalRoasDiff,
            percentageChange: performanceDataTotal.totalRoasPercentages,
            unit: FORMATS.NUMERIC,
            fixed: 2,
        },
    ];

    const cacSummaryArray = [
        spendSummaryRow,
        {
            label: "Total MMM New Customers",
            forecasted: performanceDataTotal.forecastedNewCustomers,
            last: performanceDataTotal.newCustomers,
            change: performanceDataTotal.totalNewCustomersDiff,
            percentageChange: 0,
            unit: FORMATS.NUMERIC,
            fixed: 0,
        },
        {
            label: "Total CAC",
            forecasted: performanceDataTotal.forecastedCac,
            last: performanceDataTotal.cac,
            change: performanceDataTotal.totalCacDiff,
            percentageChange: performanceDataTotal.totalCacPercentages,
            unit: FORMATS.DOLLAR,
            fixed: 2,
        },
    ];

    const finalSummary = isCacOptimizedScenario ? cacSummaryArray : roasSummaryArray;

    return (
        <Stack gap="50px">
            <Stack gap="30px">
                <Typography
                    color="#000"
                    variant="h2"
                    sx={{ boxShadow: "0px -1px 0px 0px #D9D9D9 inset", paddingBottom: "15px" }}
                >
                    Tracking
                </Typography>
                <StatusDetails
                    selectedScenario={selectedScenario}
                    acceptedCampaignsCount={acceptedCampaignsCount}
                    classes={classes}
                    isScenarioExpired={isScenarioExpired}
                />
                <TrackingSummary
                    tableHeading={recommendationsTableHeading}
                    summaryArray={finalSummary}
                    classes={classes}
                    isLoading={false}
                    compareTo={compareTo}
                    setCompareTo={setCompareTo}
                    compareToOption={compareToOption}
                    isCacScenario={isCacOptimizedScenario}
                />
            </Stack>
            <Stack
                sx={{
                    boxShadow: "0px -1px 0px 0px #D9D9D9 inset",
                    paddingBottom: "5px",
                }}
                justifyContent="space-between"
                direction="row"
                flexWrap="wrap"
                gap="20px"
            >
                <Typography color="#000" variant="h5" fontWeight="bold">
                    Campaigns
                </Typography>
                <CustomColumnFilter
                    cards={campaignTableHeader}
                    handleTableCol={handleTableCol}
                    isDraggable={false}
                    groupByName={"groupName"}
                    columnHeader={{
                        expected: `${
                            isCacOptimizedScenario ? "MMM New Customers" : "MMM Revenue"
                        } (${getCompareLabel})`,
                        optimal: `Optimal (${getCompareLabel})`,
                    }}
                />
            </Stack>
            <CampaignsTable
                campaignsWithPerformanceData={campaignsWithPerformanceData.map((campaignRow) => ({
                    ...campaignRow,
                }))}
                selectedScenario={selectedScenario}
                performanceDataTotal={{
                    ...performanceDataTotal,
                }}
                classes={classes}
                campaignTableHeader={campaignTableHeader}
                isLoading={false}
                compareTo={getCompareLabel}
            />
        </Stack>
    );
};
