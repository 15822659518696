import React, { FC } from "react";
import { MenuItem, MenuList, Typography } from "@mui/material";
import { css } from "@emotion/css";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";

import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import camelCase from "lodash/camelCase";
import { SECONDARY_MAIN_COLOR } from "@prescientai/component-library";
import { DEFAULT_PERFORMANCE_FILTER_TITLE, UNSAVED_PERFORMANCE_FILTER_TITLE } from "src/consts/performancePaidPage/performancePaidPage";

interface IDynamicSavedFilterList {
    isSavedViewOn: boolean;
    currentFilterView: {
        isSavedViewOn: boolean;
        data: {
            name: string;
            filters: string;
            id: string;
            isDefault?: boolean;
        };
    };
    childRef: React.RefObject<any>;
    id?: string;
    dataCy: string;
    defaultFilterList: any[];
    savedFilterList: any[];
    actionList: any[];
    handleMenuClick: (filter: { name: string; filters: string; id: string }) => void;
    exitViewHandler: (event: any) => void;
    isUnsavedFilters?: boolean;
}

const useStyles = (isUnsavedFilters: boolean = false) => ({
    headerBtn: css({
        "& button": {
            width: "229px",
            border: `2px solid ${isUnsavedFilters ? SECONDARY_MAIN_COLOR : "rgba(38, 38, 38, 0.5)"}`,
        },
    }),
    menuClass: css({
        minWidth: "229px",
    }),
});

export const DynamicSavedFilterList: FC<IDynamicSavedFilterList> = ({
    isSavedViewOn,
    currentFilterView,
    childRef,
    defaultFilterList,
    savedFilterList,
    actionList,
    handleMenuClick,
    exitViewHandler,
    id,
    dataCy,
    isUnsavedFilters,
}) => {
    const classes = useStyles(isUnsavedFilters);

    return (
        <LightMenu
            isList={false}
            title={
                isSavedViewOn && currentFilterView?.data ? (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                        {currentFilterView.data.name}
                        {currentFilterView.data.name !== DEFAULT_PERFORMANCE_FILTER_TITLE && (
                            <CloseIcon
                                sx={{ fontSize: "18px", color: "#000000" }}
                                onClick={(event) => exitViewHandler(event)}
                            />
                        )}
                    </Stack>
                ) : isUnsavedFilters ? (
                    UNSAVED_PERFORMANCE_FILTER_TITLE
                ) : (
                    DEFAULT_PERFORMANCE_FILTER_TITLE
                )
            }
            id={id}
            closeCustomEndIcon={<SVGIconRenderer icon="chevronUpIcon" height="16px" width="16px" />}
            openCustomEndIcon={<SVGIconRenderer icon="chevronDownIcon" height="16px" width="16px" />}
            ref={childRef}
            extraButtonClass={classes.headerBtn}
            dataCy={dataCy}
            wideset={true}
            menuClass={classes.menuClass}
            color="primary"
            variant="outlined"
        >
            <MenuList style={{ padding: "8px 0", minWidth: "140px" }}>
                {defaultFilterList.map((filter) => {
                    return (
                        <MenuItem
                            key={filter.id}
                            id={filter.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px" }}
                            onClick={() => handleMenuClick(filter)}
                            selected={currentFilterView?.data?.name === filter.name}
                            data-cy={camelCase(filter.name).replace(/ /g, "")}
                        >
                            <Typography component="span" variant="body1">
                                {filter.name}
                            </Typography>
                            {isSavedViewOn &&
                                currentFilterView?.data?.name !== DEFAULT_PERFORMANCE_FILTER_TITLE &&
                                currentFilterView?.data?.id === filter.id && (
                                    <CloseIcon
                                        sx={{ fontSize: "18px", color: "#000000", marginLeft: "auto" }}
                                        onClick={(event) => exitViewHandler(event)}
                                    />
                                )}
                        </MenuItem>
                    );
                })}
                {(savedFilterList.length > 0 || actionList.length > 0) && (
                    <MenuItem sx={{ padding: "0px", margin: "8px 0" }} divider />
                )}
                {savedFilterList.map((filter) => {
                    return (
                        <MenuItem
                            key={filter.id}
                            id={filter.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px" }}
                            onClick={() => handleMenuClick(filter)}
                            selected={currentFilterView?.data?.name === filter.name}
                            data-cy={camelCase(filter.name).replace(/ /g, "")}
                        >
                            <Typography component="span" variant="body1">
                                {filter.name}
                            </Typography>
                            {isSavedViewOn && currentFilterView?.data?.id === filter.id && (
                                <CloseIcon
                                    onClick={(event) => exitViewHandler(event)}
                                    style={{ marginLeft: "auto", fontSize: "18px", color: "#000000" }}
                                />
                            )}
                        </MenuItem>
                    );
                })}
                {savedFilterList.length > 0 && <MenuItem sx={{ padding: "0px", margin: "8px 0" }} divider />}
                {actionList.map((action) => {
                    return (
                        <MenuItem
                            key={action.name.replace(/ /g, "_")}
                            id={action.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px", gap: "12px" }}
                            onClick={() => action.clickHandler()}
                            data-cy={camelCase(action.name).replace(/ /g, "")}
                            disabled={action.disabled}
                        >
                            {action.icon}
                            <Typography component="span" variant="body1">
                                {action.name}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </LightMenu>
    );
};
