import { ISavedFilter } from "../../interfaces/ISavedFilter";
import { setFilterList } from "../slices/filterViewSlice";
import baseCreateApi from "./baseCreateApi";

interface IFilterBody {
    name: string;
    filters: string;
}

const setFiltersAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            const modifiedData = res.data.map((d: any) => ({
                id: d.id,
                name: d.attributes.name,
                filters: d.attributes.filters,
            }));
            dispatch(setFilterList(modifiedData));
        }
    } catch (error) {
        console.error(error);
    }
};

export const connectors = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        saveFilterView: builder.mutation({
            query: (args: { body: IFilterBody; orgId: string }) => ({
                url: `organizations/${args.orgId}/saved_filters`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            name: args.body.name,
                            filters: args.body.filters,
                        },
                        type: "saved_filters",
                    },
                }),
            }),
        }),
        getFilterView: builder.query({
            query: (args: { orgId: string; include?: string }) => ({
                url: `organizations/${args.orgId}/saved_filters`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }: any) {
                setFiltersAsyncHandler({ dispatch, queryFulfilled });
            },
            transformResponse: (response: { data: ISavedFilter[] }) => {
                return response.data;
            },
        }),
        editFilterView: builder.mutation({
            query: (args: { orgId: string; body: IFilterBody; id: string }) => {
                return {
                    url: `organizations/${args.orgId}/saved_filters/${args.id}`,
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            type: "saved_filters",
                            attributes: args.body,
                        },
                    }),
                };
            },
            transformResponse: (response: { data: ISavedFilter }) => {
                return response.data;
            },
        }),

        deleteFilterView: builder.mutation({
            query: (args: { orgId: string; id: string }) => ({
                url: `organizations/${args.orgId}/saved_filters/${args.id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useSaveFilterViewMutation,
    useLazyGetFilterViewQuery,
    useGetFilterViewQuery,
    useDeleteFilterViewMutation,
    useEditFilterViewMutation,
} = connectors;
