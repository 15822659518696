import React, { FC } from "react";
import { useSelector } from "react-redux";

import { getTotalAmountForPerformanceTable } from "../../services/utils";
import {
    getPerformanceHeaderWithRevenuePercentage,
    MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE,
} from "../../consts/performancePaidPage/performancePaidPage";
import {
    IMetricAttributionTableValuesTransformed,
    IMetricAttributionTableValuesTransformedWithRevenue,
} from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    IPerformancePercentageTableData,
    IPerformanceRevenueData,
} from "../../interfaces/performanceDetails/IPerformancePercentage";
import { percentageCalculation } from "../../services/performancePage/performancePage";
import { getDefaultRevenueOptionByType } from "../../services/homeDashboard/homeDashboard";
import { AggregateMetricHeader } from "../AggregateMetricHeader/AggregateMetricHeader";
import { RevenueType } from "../../enums/RevenueType";
import { IConnector } from "../../interfaces/IConnector";
import { defaultRevenueSourceSelector, isAmazonSellingPartnerConnected } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { isHaloViewSelected } from "src/reduxState/slices/filterViewSlice";

interface ICampaignAggregate {
    totalAmount: IMetricAttributionTableValuesTransformedWithRevenue;
    finalPercentagePerformanceData: IPerformancePercentageTableData;
    isLoading: boolean;
    revenueData: IPerformanceRevenueData;
    pastRevenueData: IPerformanceRevenueData;
    defaultRevenueConnectorSource: string;
    connectors: IConnector[];
}

export const totalHeroMetricsKeyMap = (
    revenueSource: string,
    isAmazonConnected?: boolean,
): {
    [key: string]: {
        title: string;
        info: string;
    };
} => {
    return {
        spend: { title: "Spend", info: "Actual spend on a campaign or channel over a given period of time" },
        impressions: { title: "Impressions", info: "Non-unique ad views" },
        cpm: { title: "CPM", info: "Cost per 1 thousand impressions" },
        clicks: { title: "Clicks", info: "Platform reported ad clicks" },
        cpc: {
            title: "CPC",
            info: "Cost per click",
        },
        channelReportedRevenue: {
            title: "Channel Revenue",
            info: "This refers to the revenue attribution model used by the associated platform to report revenue for a given campaign or channel (e.g. Facebook Ads, Pinterest Ads)",
        },
        channelReportedRoas: {
            title: "Channel ROAS",
            info: "Platform reported revenue divided by ad spend. Campaigns without reported revenue (i.e. those that show N/A in the reported revenue column in the table below) are excluded from this calculation",
        },
        trueRevenue: {
            title: "MMM Revenue",
            info: "Algorithmically calculated revenue attribution. Millions of simulations are run to determine how each campaign impacts total revenue. The results of those simulations are then used to determine the actual amount of money generated by your campaigns",
        },
        trueRoas: {
            title: "MMM ROAS",
            info: "Algorithmically calculated ROAS. This metric is calculated by dividing modeled revenue by ad spend. Campaigns without modeled revenue (i.e. those that show N/A in the modeled revenue column in the table below) are excluded from this calculation",
        },
        modeledPercentage: {
            title: "MMM % of Total",
            info: "This is the percentage of total actual revenue that is attributed to advertising by Prescient’s models",
        },
        reportedPercentage: {
            title: "Channel % of Total",
            info: "This is the percentage of total actual revenue that is attributed to advertising based on the reported revenue from advertising platforms",
        },
        cac: {
            title: "MMM CAC",
            info: "Algorithmically calculated CAC. This metric is calculated by dividing ad spend by modeled new customers for a channel or campaign",
        },
        newCustomers: {
            title: "MMM New Customers",
            info: "Algorithmically calculated new customer attribution. Millions of simulations are run to determine how each campaign impacts total new customers. The results of those simulations are then used to determine how many new customers were generated by your campaigns",
        },
        ecommerceRevenue: {
            title: "Ecommerce Revenue",
            info: `This is transactional data from ${MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[revenueSource]}${
                isAmazonConnected ? " and Amazon" : ""
            } only. It excludes wholesale, trial, and subscription revenue`,
        },
    };
};

export const CampaignAggregate: FC<ICampaignAggregate> = ({
    totalAmount,
    finalPercentagePerformanceData,
    isLoading,
    revenueData,
    pastRevenueData,
    defaultRevenueConnectorSource,
    connectors,
}) => {
    const { currentFilteredData, pastFilteredData } = finalPercentagePerformanceData;
    const currentDefaultRev = getDefaultRevenueOptionByType(revenueData || [], RevenueType.MARKETING);
    const pastDefaultRev = getDefaultRevenueOptionByType(pastRevenueData || [], RevenueType.MARKETING);
    const isAmazonConnected = useSelector(isAmazonSellingPartnerConnected);
    const revenueSource = useSelector(defaultRevenueSourceSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const isHaloView = useSelector(isHaloViewSelected);

    const getDefaultRevenueAdded = (row: IMetricAttributionTableValuesTransformed, defaultRevenue: number) => {
        return {
            ...row,
            modeledPercentage: +(
                row.trueRevenue && defaultRevenue ? (row.trueRevenue / defaultRevenue) * 100 : 0
            ).toFixed(2),
            reportedPercentage: +(
                row.channelReportedRevenue && defaultRevenue ? (row.channelReportedRevenue / defaultRevenue) * 100 : 0
            ).toFixed(2),
            ecommerceRevenue: +defaultRevenue.toFixed(0),
        };
    };

    const currentTotalAmount = getTotalAmountForPerformanceTable(currentFilteredData);
    const pastTotalAmount = getTotalAmountForPerformanceTable(pastFilteredData);
    const totalPercentageAmount = percentageCalculation({
        currentRow: getDefaultRevenueAdded(currentTotalAmount, currentDefaultRev || 0),
        pastRow: getDefaultRevenueAdded(pastTotalAmount, pastDefaultRev || 0),
    });

    const updatedTotalCols = getPerformanceHeaderWithRevenuePercentage(
        defaultRevenueConnectorSource,
        connectors,
        orgCode,
        isHaloView,
    ).map((col) => {
        if (["spend", "channelReportedRevenue", "trueRevenue"].includes(col.id)) {
            return {
                ...col,
                fixed: 0,
            };
        } else {
            return col;
        }
    });

    const updateTotalAmount = getDefaultRevenueAdded(totalAmount, currentDefaultRev || 0);

    return (
        <AggregateMetricHeader
            tableHeader={updatedTotalCols}
            metricTotalMapObj={totalHeroMetricsKeyMap(revenueSource, isAmazonConnected)}
            isMetricLoading={isLoading}
            totalTableAmount={updateTotalAmount}
            totalPercentageTableAmount={totalPercentageAmount}
        />
    );
};
