import React, { Fragment, useEffect } from "react";
import { Box, Divider, Stack, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import * as echarts from "echarts";
import groupBy from "lodash/groupBy";
import queryString from "query-string";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { startOfMonth, startOfQuarter, startOfWeek, startOfYear } from "date-fns";
import { Button } from "@prescientai/component-library";

import { PRIMARY_MAIN_COLOR } from "../../../../consts/colors";
import { checkDateIncludedInData, formatType, formatValue } from "../../../../services/utils";
import { FORMATS } from "../../../../enums/Formats";
import {
    getCurrentLegendName,
    getMetricChartTooltip,
    getMetricNameForLegend,
    handleHighLightChartSeries,
} from "../../../../services/performancePage/performancePage";
import {
    MAP_VALUE_PARAMS,
    PERFORMANCE_ATTRIBUTION_HEADERS,
    NEGATIVE_TREND_GOOD,
} from "../../../../consts/performancePaidPage/performancePaidPage";
import { PercentageTable } from "./PercentageTable";
import { PercentageTrendChip } from "../../../core/Chips/PercentageTrendChip";
import { IDictionary } from "../../../../interfaces/IDictionary";
import { formattedDateSelector } from "../../../../reduxState/slices/dateFilterSlice";
import { pastRangeBasedOnCurrentRange } from "../../../DateRangeFilter/reactDateRangeUtils";

interface IMetricChart {
    chartData: any[];
    customValue: string;
    id: string;
    performanceCampaignData?: any;
    legendValues?: any;
    percentageData?: any;
    pastLegendValues?: any;
    grpBy: string;
    metrics: any;
    selectedUnit?: any;
}

const useStyles = (theme: Theme) => ({
    body: css({
        display: "flex",
    }),
    chartContainer: css({
        width: "100%",
        minHeight: "228px",
        aspectRatio: "9 / 4",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "calc(100vw - 40px)",
        },
    }),
    chartStyle: css({
        width: "100%",
        borderRadius: "4px",
        [theme.breakpoints.down("sm")]: {
            width: "calc(100vw - 30px)",
            maxWidth: "calc(100vw - 30px)",
        },
    }),
    legendBoxClass: css({
        textAlign: "left",
        textTransform: "capitalize",
        display: "block",
        minWidth: "72px",
        padding: "8px",
        borderRadius: "0",
        boxShadow:
            "inset 1px 0px 0px rgba(0, 0, 0, 0.12), inset 0px -1px 0px rgba(0, 0, 0, 0.12), inset -1px 0px 0px rgba(0, 0, 0, 0.12)",
    }),
    subTitle: css({
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "143%",
        letterSpacing: "0.15px",
    }),
});

const definedColor: IDictionary = {
    spend: "rgb(140, 80, 240)",
    revenue: "rgb(220, 38, 127)",
    roas: "rgb(255, 80, 127)",
};

const color: string[] = [
    "rgba(240, 30, 140, 1)",
    "rgba(140, 80, 255, 1)",
    "rgba(255, 200, 0, 1)",
    "rgba(130, 170, 255, 1)",
    "rgba(60, 190, 90, 1)",
    "rgba(215, 15, 40, 1)",
    "rgba(10, 130, 180, 0.9)",
];

export const MetricChart: React.FC<IMetricChart> = ({
    chartData,
    customValue,
    id,
    performanceCampaignData,
    legendValues,
    percentageData,
    pastLegendValues,
    grpBy = "day",
    metrics,
    selectedUnit,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const selectFormattedDates = useSelector(formattedDateSelector);
    const pastRangeString = pastRangeBasedOnCurrentRange(selectFormattedDates);

    const { campaignId } = performanceCampaignData;
    const filterQueryParamsObj = queryString.parse(window.location.search);
    const priority = ["revenue", "roas", "spend"];

    const getColors = (key: string, index: number) => {
        if (id === `return_chart_${campaignId}`) {
            return definedColor[key] || PRIMARY_MAIN_COLOR;
        } else {
            return color[index] || PRIMARY_MAIN_COLOR;
        }
    };

    const chartResize = () => {
        metricChart.resize();
    };

    const specificSelectedMetric =
        selectedUnit ||
        metrics.filter((d: { id: string; label: string; numeric: boolean; sign?: FORMATS; fixed?: number }) => {
            const selectedAllMetrics = customValue.split(",").map((v) => getCurrentLegendName(v));
            return selectedAllMetrics.includes(d.id);
        })[0] ||
        PERFORMANCE_ATTRIBUTION_HEADERS[2];
    let filteredChartData = chartData;

    if (customValue === MAP_VALUE_PARAMS.roasVsSpend) {
        filteredChartData = filteredChartData.filter((d) => d.metricName !== "REVENUE");
    }

    if (customValue === MAP_VALUE_PARAMS.reportedRoasVsSpend) {
        filteredChartData = filteredChartData.filter((d) => d.metricName !== "CHANNEL_REPORTED_REVENUE");
    }

    if (customValue === MAP_VALUE_PARAMS.cacVsSpend) {
        filteredChartData = filteredChartData.filter((d) => d.metricName !== "NEW_CUSTOMERS");
    }

    const groupedData = groupBy(filteredChartData, "name");

    const keys = Object.keys(groupedData).sort(
        (a, b) => priority.indexOf(a.split(" ")[0].toLowerCase()) - priority.indexOf(b.split(" ")[0].toLowerCase()),
    );
    let sortedValuesByDate = [...filteredChartData].sort((a, b) => +new Date(a.date) - +new Date(b.date));

    const dataWithEndDate = sortedValuesByDate.filter((d) => d.date === filterQueryParamsObj.end_date);
    const dataWithStartDate = sortedValuesByDate.filter((d) => d.date === filterQueryParamsObj.start_date);

    const isROASIncludeInReturn = [
        MAP_VALUE_PARAMS.roasVsSpend,
        MAP_VALUE_PARAMS.reportedRoasVsSpend,
        MAP_VALUE_PARAMS.newCustomersVsSpend,
    ].includes(customValue);

    const isCacVsSpend = customValue === MAP_VALUE_PARAMS.cacVsSpend;

    const getFun: IDictionary = {
        week: startOfWeek,
        month: startOfMonth,
        quarter: startOfQuarter,
        year: startOfYear,
    };

    const fillDummyData = (date: string) => {
        if (grpBy === "day") {
            let dummyData: any[] = [];
            keys.forEach((key) => {
                dummyData = [...dummyData, { date, name: key }];
            });
            sortedValuesByDate = [...sortedValuesByDate, ...dummyData];
        } else {
            sortedValuesByDate = checkDateIncludedInData(sortedValuesByDate, keys, date, getFun[grpBy]);
        }
    };

    if (dataWithEndDate.length === 0 && filterQueryParamsObj.end_date) {
        fillDummyData(filterQueryParamsObj.end_date as string);
    }

    if (dataWithStartDate.length === 0 && filterQueryParamsObj.start_date) {
        fillDummyData(filterQueryParamsObj.start_date as string);
    }

    const getYAxisPosition = (seriesName: string) => {
        if ((isCacVsSpend || isROASIncludeInReturn) && seriesName.split(" - ")[0] === "Spend") {
            return 1;
        } else {
            return undefined;
        }
    };

    const getValues = (values: any[], legend: any[]) => {
        return legend.map((i: string, index) => ({
            name: i,
            data: values.filter((t) => t && t.name === i).map((t) => [t.date, t.value]),
            type: "line",
            lineStyle: {
                type: "solid",
                color: getColors(i.split(" ")[0].toLowerCase(), index),
            },
            itemStyle: {
                color: getColors(i.split(" ")[0].toLowerCase(), index),
            },
            showSymbol: false,
            yAxisIndex: getYAxisPosition(i),
        }));
    };

    const finalMetrics = [
        ...getValues(sortedValuesByDate, keys).map((value) => {
            const allUniqueSeriesLinePoints = uniqWith(value.data, isEqual);
            const isOnlyOnePoint = allUniqueSeriesLinePoints.filter((data: any) => data[1] !== undefined).length === 1;
            return {
                ...value,
                showSymbol: isOnlyOnePoint,
            };
        }),
    ];
    const isNoChartData = filteredChartData.length === 0;

    const fetchYAxis = () => {
        if (isCacVsSpend) {
            return [
                {
                    type: "value",
                    name: "CAC",
                    axisLabel: {
                        formatter(value: number) {
                            return formatValue(value, FORMATS.DOLLAR, 0);
                        },
                    },
                    nameTextStyle: {
                        align: "left",
                        padding: [0, 0, 0, -20],
                    },
                    splitLine: {
                        show: false,
                    },
                    nameLocation: "end",
                    minInterval: 0,
                },
                {
                    type: "value",
                    name: "Spend",
                    axisLabel: {
                        formatter(value: number) {
                            return formatValue(value, FORMATS.DOLLAR, 0);
                        },
                    },
                    nameTextStyle: {
                        align: "left",
                    },
                },
            ];
        } else if (id === `return_chart_${campaignId}` && isROASIncludeInReturn) {
            return [
                {
                    type: "value",
                    name: customValue === MAP_VALUE_PARAMS.newCustomersVsSpend ? "New Customers" : "ROAS",
                    show: keys.length > 1,
                    axisLabel: {
                        formatter(value: number) {
                            return formatValue(
                                value,
                                FORMATS.NUMERIC,
                                customValue === MAP_VALUE_PARAMS.newCustomersVsSpend ? 0 : 2,
                            );
                        },
                    },
                    nameTextStyle: {
                        align: "left",
                        padding: [0, 0, 0, -20],
                    },
                    splitLine: {
                        show: false,
                    },
                    nameLocation: "end",
                    minInterval: customValue === MAP_VALUE_PARAMS.newCustomersVsSpend ? 1 : 0,
                },
                {
                    name: "Spend",
                    type: "value",
                    axisLabel: {
                        formatter(value: number) {
                            return formatValue(value, FORMATS.DOLLAR, 0);
                        },
                    },
                    nameTextStyle: {
                        align: isNoChartData ? "right" : "left",
                    },
                },
            ];
        } else {
            return [
                {
                    type: "value",
                    axisLabel: {
                        formatter(value: number) {
                            return formatValue(
                                value,
                                specificSelectedMetric
                                    ? specificSelectedMetric.sign || FORMATS.NUMERIC
                                    : FORMATS.NUMERIC,
                                specificSelectedMetric ? specificSelectedMetric.fixed || 0 : 0,
                            );
                        },
                    },
                    position: "right",
                },
            ];
        }
    };

    const yAxis = [...fetchYAxis()];

    const option = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                label: {
                    show: false,
                },
            },
            confine: "true",
            formatter: (params: any) => getMetricChartTooltip(params, grpBy, metrics, id, selectFormattedDates[0]),
        },
        xAxis: {
            type: "time",
            axisTick: {
                show: false,
            },
            boundaryGap: false,
            axisLabel: {
                color: "rgba(0, 0, 0, 0.6)",
                hideOverlap: true,
                formatter: grpBy && grpBy !== "day" && formatType[grpBy] ? formatType[grpBy] : undefined,
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.23)",
                    width: 1,
                },
            },
        },
        yAxis,
        grid: {
            containLabel: true,
            show: false,
            left: "2%",
            right: "4%",
            bottom: "20%",
            top: isROASIncludeInReturn || isCacVsSpend ? 45 : 15,
        },
        series: finalMetrics,
    };

    let metricChart: any = null;

    const initChart = () => {
        if (document.getElementById(id)) {
            metricChart = echarts.init(document.getElementById(id)!, "prescient");
            if (metricChart) {
                metricChart.resize();
                metricChart.setOption(option);
                window.addEventListener("resize", chartResize);
            }
        }
    };

    useEffect(() => {
        initChart();
    }, [filteredChartData, customValue]);

    const handleHighLight = (key: string, opacity: number) => {
        handleHighLightChartSeries(key, opacity, finalMetrics, metricChart, option);
    };

    const isDisplayTable =
        !isNoChartData &&
        filterQueryParamsObj.comparison_start_date &&
        filterQueryParamsObj.comparison_end_date &&
        legendValues &&
        pastLegendValues;

    return (
        <Box className={classes.body}>
            <Box className={classes.chartStyle}>
                <Stack flexWrap="wrap" direction="row" sx={{ gap: "8px" }}>
                    {filteredChartData &&
                        keys.map((key, i) => {
                            return (
                                <Button
                                    key={key}
                                    className={classes.legendBoxClass}
                                    style={{
                                        borderTop: `2px solid ${getColors(key.split(" ")[0].toLowerCase(), i)}`,
                                    }}
                                    id={`btn_${key}`}
                                    onMouseOver={() => handleHighLight(key, 0.1)}
                                    onMouseOut={() => handleHighLight(key, 1)}
                                >
                                    <Typography
                                        color="text.primary"
                                        variant="subtitle2"
                                        component="div"
                                        className={classes.subTitle}
                                    >
                                        {metrics.map(
                                            (d: {
                                                id: string;
                                                label: string;
                                                numeric: boolean;
                                                sign?: FORMATS;
                                                fixed?: number;
                                            }) => {
                                                const currentLegend = key.split(" - ")[0].toLowerCase();
                                                const k = getMetricNameForLegend(currentLegend, id);

                                                if (k === d.id) {
                                                    return (
                                                        <Fragment key={key}>
                                                            {formatValue(
                                                                legendValues[key],
                                                                specificSelectedMetric
                                                                    ? d.sign || FORMATS.NUMERIC
                                                                    : FORMATS.NUMERIC,
                                                                d ? d.fixed || 0 : 0,
                                                            )}
                                                            <br />
                                                            {percentageData &&
                                                            percentageData[key] &&
                                                            typeof percentageData[key] === "number" ? (
                                                                <Tooltip arrow title={pastRangeString}>
                                                                    <Typography
                                                                        component="div"
                                                                        sx={{
                                                                            fontWeight: "normal",
                                                                            margin: "2px 0 5px 0",
                                                                            cursor: "pointer !important",
                                                                        }}
                                                                    >
                                                                        <PercentageTrendChip
                                                                            value={percentageData[key]}
                                                                            isPositiveTrendGood={
                                                                                NEGATIVE_TREND_GOOD.indexOf(
                                                                                    k.toUpperCase(),
                                                                                ) === -1
                                                                            }
                                                                        />
                                                                    </Typography>
                                                                </Tooltip>
                                                            ) : (
                                                                <Typography sx={{ height: "32px" }}>-</Typography>
                                                            )}
                                                        </Fragment>
                                                    );
                                                }
                                            },
                                        )}
                                    </Typography>
                                    <Divider light sx={{ margin: "2px 0" }} />
                                    <Typography
                                        sx={{ height: "60px" }}
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                    >
                                        {key.split(" - ")[0]}
                                        <br /> <i>{key.split(" - ")[1]}</i>
                                    </Typography>
                                </Button>
                            );
                        })}
                </Stack>
                <div id={id} className={classes.chartContainer}></div>
                {isDisplayTable && (
                    <PercentageTable
                        keys={keys}
                        pastLegendValues={pastLegendValues}
                        legendValues={legendValues}
                        metrics={metrics}
                        id={id}
                    />
                )}
            </Box>
        </Box>
    );
};
