import { formatArrayWithCamelCase } from "./../../services/performancePage/performancePage";
import { ISlackAlert, ISlackChannel, ISlackUser, ISlackUserResponse } from "src/interfaces/entities/ISlackAlert";
import baseCreateApi from "./baseCreateApi";
import {
    ISingleSlackAlertResponse,
    ISlackAlertsResponse,
    ISlackChannelsResponse,
    ISlackUsersResponse,
} from "src/interfaces/ISlackAlertsResponse";
import { keysToCamelCase, keysToSnakeCase } from "src/utils/format";

interface IFormattedSlackAlertsResponse {
    data: ISlackAlert[];
    pagination: {
        totalPages: number;
        totalCount: number;
        currentPage: number;
        offsetValue: number;
    };
}

const transformApiResponse = (response: ISingleSlackAlertResponse[]): ISlackAlert[] => {
    return response.map((alert) => ({
        id: alert.id,
        ...keysToCamelCase(alert.attributes),
    }));
};

export const slackAlertsApi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getSlackAlerts: builder.query({
            query: ({ params }) => ({
                url: buildUrlFromParams(params),
                method: "GET",
            }),
            transformResponse: (response: ISlackAlertsResponse): IFormattedSlackAlertsResponse => {
                return {
                    data: transformApiResponse(response.data),
                    pagination: keysToCamelCase(response.meta),
                };
            },
        }),
        editSlackAlert: builder.mutation({
            query: (queryArgs: { params: { id: number }; body: ISlackAlert }) => ({
                url: `slack_alerts/${queryArgs.params.id}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        type: "slack_alerts",
                        attributes: editAlertAttributes(queryArgs.body),
                    },
                }),
            }),
            transformResponse: (response: { data: ISingleSlackAlertResponse }) => {
                return keysToCamelCase(response.data);
            },
        }),
        getSlackChannels: builder.query({
            query: () => ({
                url: "slack_alerts/available_channels",
                method: "GET",
            }),
            transformResponse: (response: ISlackChannelsResponse): ISlackChannel[] => {
                return formatArrayWithCamelCase(response.data);
            },
        }),
        getSlackUsers: builder.query({
            query: () => ({
                url: "slack_alerts/available_slack_users",
                method: "GET",
            }),
            transformResponse: (response: ISlackUsersResponse): ISlackUserResponse[] => {
                return formatArrayWithCamelCase(response.data);
            },
        }),
    }),
});

const editAlertAttributes = (attributes: ISlackAlert) => {
    return {
        programmatic_name: attributes.programmaticName,
        display_name: attributes.displayName,
        message: attributes.message,
        enabled: attributes.enabled,
        slack_user_ids: attributes.slackUserIds,
        tag_customer_success_manager: attributes.tagCustomerSuccessManager,
        channel_id: attributes.channelId,
    };
};

const buildUrlFromParams = (params: { page: number; rowsPerPage: number }) => {
    return `slack_alerts?page_number=${params.page}&per_page=${params.rowsPerPage}`;
};

export const {
    useLazyGetSlackAlertsQuery,
    useGetSlackChannelsQuery,
    useGetSlackUsersQuery,
    useEditSlackAlertMutation,
} = slackAlertsApi;
