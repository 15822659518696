import { Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Stack } from "@mui/system";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useEditUserSettingsMutation } from "../../../reduxState/apis/userSettingsUpdateApi";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import { paginationCountSelector, setUserSettings } from "../../../reduxState/slices/settingsSlice";
import { userIdSelector } from "../../../reduxState/slices/userSlice";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../services/utils";

interface ICustomTablePagination {
    count: number;
    page: number;
    className?: any;
    extraInfo?: string;
    rowsPerPage?: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomTablePagination: FC<ICustomTablePagination> = ({
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    className,
    extraInfo,
    rowsPerPage,
}) => {
    const analyticsService = useAnalyticsService();
    const location = useLocation();
    const dispatch = useDispatch();
    const [editUserSettings] = useEditUserSettingsMutation();
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const currentUserId = useSelector(userIdSelector);
    const paginationCount = rowsPerPage ? rowsPerPage : useSelector(paginationCountSelector);

    const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const count = parseInt(e.target.value, 10);

        if (rowsPerPage) {
            handleChangeRowsPerPage(e);
        } else {
            editUserSettings({
                orgId: currentOrgId,
                userId: currentUserId,
                paginationCount: count,
            }).then(() => {
                analyticsService.logEvent("Table Length Changed", {
                    Page: getPathForAnalytics(location.pathname, location.search),
                    "Row count": count,
                });
                dispatch(
                    setUserSettings({
                        settings: count,
                        key: "paginationCount",
                        fromApi: false,
                    }),
                );
                handleChangeRowsPerPage(e);
            });
        }
    };

    return (
        <Stack
            justifyContent={extraInfo ? "space-between" : "end"}
            className={className}
            direction="row"
            alignItems="center"
            pl={5}
            pr={2}
        >
            {extraInfo && <Typography variant="body2">{extraInfo}</Typography>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                SelectProps={{
                    MenuProps: {
                        disableScrollLock: true,
                    },
                }}
                count={count}
                rowsPerPage={paginationCount}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </Stack>
    );
};
