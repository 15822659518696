import { endOfMonth, format, startOfMonth, subDays, subMonths } from "date-fns";

import {
    CONNECTORS_CHIPS_VALUE,
    CUSTOM_CONNECTORS,
    LEGACY_CUSTOM_CONNECTORS,
} from "../../consts/connectorsPage/connectorsPage";
import { IGetConnectorResponse, ISingleConnectorResponse } from "../../interfaces/connectors/IGetConnectorResponse";
import { IChips } from "../../interfaces/IChips/IChips";
import { IConnector } from "../../interfaces/IConnector";
import { IDataSource } from "../../interfaces/IData";
import { store } from "../../reduxState/stores/store";
import { dataSourcesByIdSelector } from "../../reduxState/slices/supportedDataSourcesSlice";
import { IGetLegacyCustomConnectorCredentialResponse } from "src/interfaces/connectors/IGetLegacyCustomConnectorCredentialResponse";
import { ICreateCustomConnectorResponseData } from "src/interfaces/connectors/ICreateCustomConnectorResponse";
import { keysToCamelCase } from "src/utils/format";
import { IQaMetricResponsesBody } from "src/reduxState/apis/qaMetricsApi";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";

export const transformConnectors = (connectors: IGetConnectorResponse) => {
    return connectors.data.map((connector) => {
        return transformConnector(connector);
    });
};

export const transformConnector = (connector: ISingleConnectorResponse) => {
    const dataSourcesById = dataSourcesByIdSelector(store.getState());
    return {
        id: connector.id,
        service: connector.attributes.service,
        schema: connector.attributes.schema,
        table: connector.attributes.table,
        createdAt: connector.attributes.created_at,
        status: connector.attributes.status,
        fivetranId: connector.attributes.fivetran_id,
        fivetranToken: connector.attributes.fivetran_token,
        categories: connector.attributes.categories,
        connectorName: dataSourcesById[connector.attributes.data_source_id]?.name,
        dataSource: dataSourcesById[connector.attributes.data_source_id],
        config: connector.attributes.config,
    };
};

export const transformCustomConnector = (connector: ICreateCustomConnectorResponseData) => {
    const dataSourcesById = dataSourcesByIdSelector(store.getState());
    return {
        ...keysToCamelCase(connector),
        id: connector.id.toString(),
        connectorName: dataSourcesById[connector.data_source_id]?.name,
        dataSource: dataSourcesById[connector.data_source_id],
    };
};

export const filterConnectors = (
    connectors: IConnector[],
    search: string,
    filters: number[],
    allConnectors: IDataSource[],
): IConnector[] => {
    const filteredCategories = filters.map((item: number) => CONNECTORS_CHIPS_VALUE[item].label);

    const availableDataSources = filteredCategories
        .map((category: string) => {
            return allConnectors
                .filter((item) => item.categories.some((c) => c === category))
                .map((item) => item.programmaticName);
        })
        .reduce((acc, val) => acc.concat(val), []);

    connectors = connectors.filter((connector) =>
        allConnectors.some((e) => e.programmaticName === connector.dataSource?.programmaticName),
    );

    connectors =
        filteredCategories.length > 0 && !filteredCategories.includes("All")
            ? connectors.filter((connector: IConnector) =>
                  availableDataSources.includes(connector.dataSource?.programmaticName),
              )
            : connectors;
    return search
        ? connectors.filter((connector: IConnector) =>
              `${connector.status} ${connector.dataSource.name}`.toLowerCase().includes(search.toLowerCase()),
          )
        : connectors;
};

export const getConnectorsChipsValues = (connectors: IConnector[], allConnectors: IDataSource[]) => {
    const newChipsValue: IChips[] = [];
    CONNECTORS_CHIPS_VALUE.map((filter) => {
        const items = filterConnectors(connectors, "", [filter.key], allConnectors);
        if (filter.isAll || items.length) {
            newChipsValue.push({
                ...filter,
                disabled: false,
            });
        } else {
            newChipsValue.push({
                ...filter,
                disabled: true,
            });
        }
    });

    return newChipsValue;
};

export const getPreparedConnectors = (
    connectors: IConnector[],
    search: string,
    filter: any,
    allConnectors: IDataSource[],
) => {
    let preparedConnectors: IConnector[] = [];
    preparedConnectors = filterConnectors(connectors, search, filter, allConnectors);
    return preparedConnectors;
};

export const getFilters = (filter: number[]) => {
    const newFilters: string[] = [];
    filter.forEach((filter) =>
        newFilters.push(CONNECTORS_CHIPS_VALUE.filter((value) => value.key === filter)[0].label),
    );

    return newFilters;
};

export const buildInitialSchemaName = (schemaName: string) => {
    return `${schemaName}_${Math.floor(Math.random() * 10000)}`;
};

export const getChosenConnectors = (
    connectors: IConnector[],
    chosenConnectors: number[],
    connectorForDelete: IConnector | null,
) => {
    if (connectorForDelete && connectorForDelete.id) {
        return [connectorForDelete];
    }
    if (connectors && connectors.length) {
        return connectors.filter((connector) => chosenConnectors.indexOf(+connector.id) >= 0);
    }

    return [];
};

export const getLegacyCustomConnectorId = (
    data: IGetLegacyCustomConnectorCredentialResponse[],
    orgId: string,
    connectorId: string,
) => {
    return data.filter(
        (d) => d.attributes.company_id.toString() === orgId && d.attributes.connector_id.toString() === connectorId,
    );
};

export const isCustomConnector = (service: string) => CUSTOM_CONNECTORS.includes(service);

export const isLegacyCustomConnector = (service: string) => LEGACY_CUSTOM_CONNECTORS.includes(service);

export const formatQaMetricResponse = (data: IQaMetricResponsesBody[]) => {
    return data.map((c) => ({
        qa_metric_id: c.qaMetricId,
        user_reported_value: c.userReportedValue,
        is_accepted: c.isAccepted,
    }));
};

export const formatMetricsData = (data: any[], metricTypes: any[], connectors: IConnector[]): IQaMetric[] => {
    const formattedData = data.map((metric) => ({
        ...metric,
        isAccepted: metric.lastResponse?.isAccepted ?? null,
        userReportedValue: +metric.lastResponse?.userReportedValue ?? 0,
        metricType: metricTypes.find((type) => type.id === metric.qaMetricTypeId)?.name ?? "",
        connector: connectors.find((conn) => conn.id.toString() === metric.connectorId.toString()) || null,
        isModalOpen: false,
        disabled: false,
    }));

    connectors.forEach((connector) => {
        const hasMetric = formattedData.some((metric) => metric.connector?.id === connector.id);
        if (!hasMetric) {
            formattedData.push({
                connector,
                metricType: connector.service === "amazon_selling_partner" ? "REVENUE" : "SPEND", // since it's either asp or amazon ads
                isAccepted: null,
                userReportedValue: 0,
                isModalOpen: false,
                disabled: true,
            });
        }
    });

    return formattedData;
};
