import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { css } from "@emotion/css";
import { Typography } from "@mui/material";
import { Button } from "@prescientai/component-library";

import { modalTitle } from "../../fontStyles/fontStyles";

export enum LightModalVariant {
    SMALL = "small",
    NORMAL = "normal",
}

interface IModalProps {
    isOpen: boolean;
    title: string;
    content: any;
    cancelButtonName?: string;
    buttonName: string;
    variant?: LightModalVariant;

    onClose?(): void;
    handleCancelButtonClick?(): void;
    onSubmit?(...args: any): void;
}

const modalWrapper = css`
    margin: 15% auto 30%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    background-color: white;
    align-items: center;
    justify-content: center;
`;

const bodyClass = css`
    padding: 8px 24px;
    width: 100%;
`;

const actionsClass = (normal?: boolean) => css`
    padding: ${normal ? "16px" : "8px"};
    align-self: flex-end;
    display: flex;
    flex-direction: row;
`;

const smallVariant = css`
    padding: 24px 24px 16px 24px;
    align-self: baseline;
`;

const normalVariant = css`
    padding: 16px 24px;
    align-self: baseline;
`;

export const LightModal: React.FC<IModalProps> = ({
    isOpen,
    content,
    onClose = () => {},
    title,
    cancelButtonName,
    buttonName,
    onSubmit = () => {},
    handleCancelButtonClick = () => {},
    variant = LightModalVariant.NORMAL,
}) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const onContinueClicked = () => {
        setIsButtonDisabled(true);
        onSubmit();
    };

    const onCancelClicked = () => {
        handleCancelButtonClick();
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            disableEnforceFocus={true}
        >
            <div className={modalWrapper}>
                <div className={variant === LightModalVariant.SMALL ? smallVariant : normalVariant}>
                    <Typography
                        variant={variant === LightModalVariant.SMALL ? "h6" : "h4"}
                        className={modalTitle(variant === LightModalVariant.NORMAL)}
                    >
                        {title}
                    </Typography>
                </div>
                <div className={bodyClass}>{content}</div>
                <div className={actionsClass(variant === LightModalVariant.NORMAL)}>
                    {cancelButtonName && (
                        <Button variant="text" color="primary" onClick={onCancelClicked} style={{ marginRight: "8px" }}>
                            {cancelButtonName}
                        </Button>
                    )}
                    <Button disabled={isButtonDisabled} variant="contained" color="primary" onClick={onContinueClicked}>
                        {buttonName}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
