import { IQaMetricTypesResponse } from "src/interfaces/api/IQaMetricTypesResponse";
import { IQaMetricsResponse } from "src/interfaces/api/IQaMetricsResponse";
import { formatQaMetricResponse } from "src/services/connectors/connectors";
import { keysToCamelCase } from "src/utils/format";
import baseCreateApi from "./baseCreateApi";

export interface IQaMetricResponsesBody {
    qaMetricId: number;
    userReportedValue: number;
    isAccepted: boolean | null;
}

const buildQueryUrl = (args: { orgId: string; includes?: null | string }) => {
    let url = `/organizations/${args.orgId}/connectors/qa_metrics`;
    if (args.includes) {
        url += `?includes=${args.includes}`;
    }
    return url;
};

const qaMetricsApi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getQaMetricTypes: builder.query({
            query: (args: { orgId: string }) => ({
                url: `/organizations/${args.orgId}/qa_metric_types`,
                method: "GET",
            }),
            transformResponse(response: IQaMetricTypesResponse) {
                return response.data.map((d) => keysToCamelCase(d.attributes));
            },
        }),
        getQaMetrics: builder.query({
            query: (args: { orgId: string; includes?: null | string }) => ({
                url: buildQueryUrl(args),
                method: "GET",
            }),
            transformResponse(response: IQaMetricsResponse) {
                return {
                    data: response.data.map((d) =>
                        keysToCamelCase({
                            ...d.attributes,
                            id: d.id,
                            startDateOverride: d.attributes.start_date_override || null,
                            endDateOverride: d.attributes.end_date_override || null,
                            breakdownByMonth: d.attributes.breakdown_by_month || null,
                        }),
                    ),
                    meta: keysToCamelCase(response.meta),
                };
            },
            providesTags: ["QAMetrics"],
        }),
        qaMetricResponsesPost: builder.mutation({
            query: (args: { orgId: string; body: IQaMetricResponsesBody[] }) => ({
                url: `/organizations/${args.orgId}/connectors/qa_metric_responses`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "qa_metric_responses",
                        attributes: {
                            qa_metric_responses: formatQaMetricResponse(args.body),
                        },
                    },
                }),
            }),
            invalidatesTags: ["QAMetrics"],
        }),
    }),
});

export const { useGetQaMetricsQuery, useQaMetricResponsesPostMutation, useGetQaMetricTypesQuery } = qaMetricsApi;
