import get from "lodash/get";
import { IOrganizationDTO } from "../../interfaces/DTO/IOrganizationDTO";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { isSelect } from "../members/members";
import { keysToCamelCase } from "../../utils/format";
import { store } from "../../reduxState/stores/store";
import { IOrganizationBody } from "../../reduxState/apis/organizationApi";
import { DataState } from "../../enums/DataState";

export const emptyOrganization = {
    id: "",
    code: "",
    contactPerson: "",
    name: "",
    isForecastVisible: false,
    avatarMediumUrl: "",
    updatedAt: "",
    createdAt: "",
    avatar: "",
    avatarThumbUrl: "",
    membersCount: 0,
    connectorsCount: 0,
    sendInsights: false,
    displayName: "",
    showInsightsPage: false,
    featureNameWithIdList: [],
    companiesFeatures: [],
    features: [],
    subscription: null,
    dataState: DataState.NO_DATA,
    stripeCustomerId: "",
    parentId: null,
    isTest: false,
    defaultLastReportedDateDelay: 1,
};

export const transformEditOrganization = (body: IOrganizationBody) => {
    const {
        name,
        avatar,
        send_insights,
        displayName,
        features,
        dataState,
        parentId,
        stripeCustomerId,
        excludeFromAmazonModeling,
        excludeFromStoreModeling,
        isTest,
    } = body;

    let payload = {
        name,
        avatar,
        send_insights,
        display_name: displayName || name,
        companies_features: features?.length ? features.join(",") : "",
        data_state: dataState,
        stripe_customer_id: stripeCustomerId,
        parent_id: parentId || null,
        is_test: isTest,
    };

    if (typeof excludeFromStoreModeling === "boolean") {
        payload = Object.assign(payload, { exclude_from_store_modeling: excludeFromStoreModeling });
    }

    if (typeof excludeFromAmazonModeling === "boolean") {
        payload = Object.assign(payload, { exclude_from_amazon_modeling: excludeFromAmazonModeling });
    }

    return payload;
};

export const transformOrganization = (data: IOrganizationDTO[]): IOrganization[] => {
    const isDemoMode = store.getState().isDemoMode;
    if (isDemoMode) {
        return data.map((item, index) => {
            const { attributes, relationships } = item;
            const transformedAttributes = keysToCamelCase(attributes);
            const features = get(relationships, "features.data") || [];

            return {
                id: item.id,
                features: features.map((f: { id: string; type: string }) => f.id),
                ...transformedAttributes,
                name: `Organization #${index + 1}`,
                displayName: `Organization #${index + 1}`,
                code: `organization_${index + 1}`,
                avatarMediumUrl: "missing_avatar",
                subscriptionStatus: (transformedAttributes.subscription?.status || "").toUpperCase(),
            };
        });
    } else {
        return data.map((item) => {
            const { attributes, relationships } = item;
            const transformedAttributes = keysToCamelCase(attributes);
            const features = get(relationships, "features.data") || [];
            return {
                id: item.id,
                features: features.map((f: { id: string; type: string }) => f.id),
                code: transformedAttributes.name.replace(/\s+/g, "_").toLowerCase(),
                subscriptionStatus: (transformedAttributes.subscription?.status || "").toUpperCase(),
                ...transformedAttributes,
            };
        });
    }
};

export const selectAll = (selectedOrganizations: Array<number | string>, allOrganizations: IOrganization[]) => {
    if (selectedOrganizations.length) {
        return [];
    } else {
        return allOrganizations.map((organization) => +organization.id);
    }
};

export const selectOrganization = (id: number | string, selectedOrganizations: Array<number | string>) => {
    const copyState = [...selectedOrganizations];
    if (isSelect(id, selectedOrganizations)) {
        copyState.splice(selectedOrganizations.indexOf(+id), 1);
    } else {
        copyState.push(+id);
    }

    return copyState;
};

export const getFilteredOrganizaion = (organizationData: any, search: any, filterObj: any) => {
    if (organizationData && organizationData.length) {
        let resultOrgData = organizationData;

        if (filterObj && Object.keys(filterObj).length) {
            if (filterObj.dataState) {
                resultOrgData = resultOrgData.filter((data: any) => {
                    return filterObj.dataState.split(",").includes(data.dataState.toLowerCase());
                });
            }
            if (filterObj.subscriptionStatus) {
                resultOrgData = resultOrgData.filter((data: any) => {
                    const fixSearch = data.subscriptionStatus || "";
                    return filterObj.subscriptionStatus.split(",").includes(fixSearch.toLowerCase());
                });
            }
        }
        if (search) {
            resultOrgData = resultOrgData.filter((data: any) => {
                const fixSearch = `${data.displayName.toLowerCase()} ${data.name.toLowerCase()}`;
                return fixSearch.includes(search.toLowerCase()) || search.includes(data.id.toString());
            });
        }
        return resultOrgData;
    }
    return [];
};
