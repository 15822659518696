import { FC, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import {
    ONBOARDING_PAGES,
    ONBOARDING_VALUE_OPTIONS,
    OnboardingStateValue,
} from "src/consts/onboardingPage/onboardingPage";
import { IOnboardingState } from "src/interfaces/entities/IOnboardingState";
import { Button } from "@prescientai/component-library";

interface IOrganizationOnboardingForm {
    onboardingState?: IOnboardingState;
    isOnboardingSaving: boolean;
    editOnboardingState: (updatedOnboardingState: IOnboardingState) => void;
}

export const OrganizationOnboardingForm: FC<IOrganizationOnboardingForm> = ({
    onboardingState,
    isOnboardingSaving,
    editOnboardingState,
}) => {
    if (!onboardingState) {
        return null;
    }

    const [formState, setFormState] = useState<IOnboardingState>(onboardingState);

    const handleChange = (key: keyof IOnboardingState, value: string) => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const orgOnboardingArr = ONBOARDING_PAGES.map((onboarding) => {
        return {
            ...onboarding,
            value: formState[onboarding.key],
        };
    });

    return (
        <Stack gap={3}>
            {orgOnboardingArr.map((state) => (
                <FormControl key={state.key} fullWidth>
                    <InputLabel id={`select-label-${state.key}`}>{state.label}</InputLabel>
                    <Select
                        labelId={`select-label-${state.key}`}
                        value={state.value || ""}
                        label={state.label}
                        onChange={(e) => handleChange(state.key, e.target.value as keyof IOnboardingState)}
                    >
                        {ONBOARDING_VALUE_OPTIONS.map(
                            (option) =>
                                (state.key === "dataQa" || option.value !== OnboardingStateValue.ready) && (
                                    <MenuItem value={option.value} key={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ),
                        )}
                    </Select>
                </FormControl>
            ))}
            <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => editOnboardingState(formState)}
                disabled={isOnboardingSaving}
            >
                Save Onboarding States
            </Button>
        </Stack>
    );
};
