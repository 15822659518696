import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import isEqual from "lodash/isEqual";

import { IHeadCell } from "../../../consts/performancePaidPage/performancePaidPage";
import { useEditUserSettingsMutation } from "../../../reduxState/apis/userSettingsUpdateApi";
import { userIdSelector } from "../../../reduxState/slices/userSlice";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import { setUserSettings } from "../../../reduxState/slices/settingsSlice";
import { handlePerformanceSelectedColsHidden } from "../../../containers/CampaignPageV2/CampaignPage";
import { DraggableColumnSelect } from "../../../components/DraggableColumnSelect/DraggableColumnSelect";

interface IDraggablePerformanceColumnSelect {
    selectedTableColumns: IHeadCell[];
    setselectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>;

    handleTableCol: (column: IHeadCell) => void;
}

export const DraggablePerformanceColumnSelect: React.FC<IDraggablePerformanceColumnSelect> = ({
    handleTableCol,
    selectedTableColumns,
    setselectedTableColumns,
}) => {
    const [editUserSettings] = useEditUserSettingsMutation();
    const dispatch = useDispatch();
    const currentUserId = useSelector(userIdSelector);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const { enqueueSnackbar } = useSnackbar();

    const [cards, setCards] = useState(selectedTableColumns.filter((c) => c.id !== "campaignName"));
    const [prevCards, setPrevCards] = useState(cards);

    useEffect(() => {
        setCards(selectedTableColumns.filter((c) => c.id !== "campaignName"));
    }, [JSON.stringify(selectedTableColumns)]);

    const updateColumnsInSettingsApi = (selectedCols: IHeadCell[]) => {
        if (!isEqual(cards, prevCards)) {
            const selectedColIds = selectedCols.map((col) => col.id);
            editUserSettings({ orgId: currentOrgId, userId: currentUserId, performanceColumnOrders: selectedColIds })
                .unwrap()
                .then(async (res) => {
                    await dispatch(
                        setUserSettings({
                            settings: selectedColIds,
                            fromApi: false,
                            key: "performanceColumnOrders",
                        }),
                    );
                })
                .catch((error) => {
                    const colsWithPersistHidden = handlePerformanceSelectedColsHidden(prevCards);
                    setCards(colsWithPersistHidden);
                    setselectedTableColumns([selectedTableColumns[0], ...colsWithPersistHidden]);
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`column reorder error ${error}`);
                });
        }
    };

    const onClickHandleOpen = () => {
        setPrevCards(cards);
    };

    return (
        <DraggableColumnSelect
            handleTableCol={handleTableCol}
            selectedTableColumns={selectedTableColumns}
            setselectedTableColumns={setselectedTableColumns}
            updateColumnsInSettingsApi={updateColumnsInSettingsApi}
            onClickHandleOpen={onClickHandleOpen}
            cards={cards}
            setCards={setCards}
        />
    );
};
