import { css } from "@emotion/css";
import {
    FormHelperText,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from "@mui/material";
import { Form, Formik, FormikConfig, FormikProps, FieldArray } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { bind } from "decko";
import Spinner from "@mui/material/CircularProgress";

import { Button } from "@prescientai/component-library";
import { Field } from "../core/Field/Field";
import { Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { ImageInput } from "../core/ImageInput/ImageInput";
import { changeDate } from "../../services/utils";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IFeature } from "../../interfaces/entities/IFeature";
import { DATA_STATE_LIST } from "../../consts/OrganizaionTable/OrganizationTable";
import { caption, fullWidthClass, imageInputClass, imageLabel } from "../../assets/styles/commonStyle";

export const dateWrapper = css`
    flex-grow: 1;
`;

const switchStyle = css`
    margin: 0 auto !important;
`;

const legacyStyle = css`
    padding-top: 0 !important;
`;

export interface IOrganizationModalProps extends FormikConfig<IOrganization> {
    featuresList: IFeature[];
    parentOrgList: IOrganization[];
    isEditMode?: boolean;

    onCancel?(...args: any[]): any;

    onRemove?(...args: any[]): any;

    onChangeMode?(...args: any[]): any;
}

export class OrganizationForm extends React.Component<IOrganizationModalProps, {}> {
    public state = {
        image: "",
        imageName: "",
        isDeleteModal: false,
        accessLevel: "No Access",
    };

    @bind()
    // @ts-ignore
    public closeModal() {
        this.setState({ isDeleteModal: false });
    }

    public render() {
        const toBase64 = (file: Blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        const getFile = async (url: Blob) => {
            return toBase64(url);
        };
        const { onCancel, onRemove, onChangeMode, isEditMode, featuresList, parentOrgList, ...rest } = this.props;
        const imageFunction = async (getImage: any) => {
            return getImage(this.state.image);
        };

        return (
            <Formik
                validationSchema={object().shape({
                    name: string().required("Organization name is required"),
                })}
                {...rest}
            >
                {({
                    errors,
                    handleChange,
                    touched,
                    values,
                    isSubmitting,
                    setFieldValue,
                }: FormikProps<IOrganization>) => {
                    const hasNameError = Boolean(touched.name && (errors.name || errors.base));
                    const hasStripeCustomerIdError = Boolean(touched.stripeCustomerId && errors.stripeCustomerId);

                    const disabledInput = isSubmitting;
                    const minLengthError = values.name.length < 2;
                    const notMatchSchema = Boolean(!values.name.trim().match(/^[a-zA-Z0-9_]{1}[a-zA-Z0-9_\s]*$/));
                    const notMatched = minLengthError || notMatchSchema;
                    const avatarUrl = isEditMode
                        ? values.avatarMediumUrl && !values.avatarMediumUrl.match(/missing_avatar/)
                            ? values.avatarMediumUrl
                            : values.name && values.name[0].toUpperCase()
                        : "";
                    return (
                        <Form>
                            <Typography className={imageLabel}>Logo</Typography>
                            <div className={imageInputClass}>
                                <ImageInput
                                    onChange={handleChange}
                                    initial={avatarUrl}
                                    onSelectImage={(file, name) => {
                                        this.setState(
                                            {
                                                image: file,
                                                imageName: name,
                                            },
                                            async () => {
                                                setFieldValue("avatar", await imageFunction(getFile));
                                                setFieldValue("avatarName", this.state.imageName);
                                            },
                                        );
                                    }}
                                    isAvatar
                                    name="avatar"
                                />
                            </div>
                            <Flex direction={FlexDirection.Column} spacing={"24px"}>
                                <FlexItem className={fullWidthClass}>
                                    <Field
                                        id="name"
                                        name="name"
                                        disabled={disabledInput || isEditMode}
                                        label="Organization name"
                                        error={hasNameError}
                                        value={values.name}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 30 }}
                                        placeholder="Organization name"
                                        helperText={
                                            hasNameError
                                                ? errors.name
                                                : ["Use only lowercase letters, numbers, underscores"]
                                        }
                                        className={fullWidthClass}
                                    />
                                </FlexItem>
                                {isEditMode && (
                                    <FlexItem className={fullWidthClass}>
                                        <Field
                                            id="displayName"
                                            name="displayName"
                                            disabled={disabledInput}
                                            label="Display name"
                                            value={values.displayName}
                                            onChange={handleChange}
                                            placeholder="Display name"
                                            className={fullWidthClass}
                                        />
                                    </FlexItem>
                                )}
                                <FlexItem className={fullWidthClass}>
                                    <Field
                                        id="stripeCustomerId"
                                        name="stripeCustomerId"
                                        disabled={disabledInput}
                                        label="Stripe Customer Id"
                                        error={hasStripeCustomerIdError}
                                        value={values.stripeCustomerId}
                                        onChange={handleChange}
                                        placeholder="Stripe Customer Id"
                                        className={fullWidthClass}
                                        helperText={
                                            hasStripeCustomerIdError
                                                ? errors.stripeCustomerId
                                                : "Add if this is an agency or customer with multiple organizations"
                                        }
                                    />
                                </FlexItem>
                                <FlexItem className={fullWidthClass}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-parent-label">Parent Company</InputLabel>
                                        <Select
                                            labelId="select-parent-label"
                                            onChange={handleChange}
                                            value={values.parentId || ""}
                                            label="Parent Company"
                                            name="parentId"
                                        >
                                            <MenuItem key="clear_form" value="">
                                                Clear Selection
                                            </MenuItem>
                                            {parentOrgList.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            Use to help Customer Success team understand the relationship between
                                            organizations.
                                        </FormHelperText>
                                    </FormControl>
                                </FlexItem>
                                <FlexItem className={fullWidthClass}>
                                    <FormControlLabel
                                        className={switchStyle}
                                        control={
                                            <Switch
                                                className="customSwitch"
                                                color="primary"
                                                name="isTest"
                                                checked={values.isTest}
                                                onChange={() => setFieldValue("isTest", !values.isTest)}
                                            />
                                        }
                                        label="Test organization"
                                    />
                                </FlexItem>
                                {!isEditMode && (
                                    <FlexItem className={fullWidthClass}>
                                        <FormControlLabel
                                            className={switchStyle}
                                            control={
                                                <Switch
                                                    className="customSwitch"
                                                    color="primary"
                                                    name="createVitallyAccount"
                                                    checked={values.createVitallyAccount}
                                                    onChange={() =>
                                                        setFieldValue(
                                                            "createVitallyAccount",
                                                            !values.createVitallyAccount,
                                                        )
                                                    }
                                                />
                                            }
                                            label="Track in Vitally"
                                        />
                                    </FlexItem>
                                )}
                                {isEditMode && (
                                    <FlexItem className={fullWidthClass}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-type-label">Data state</InputLabel>
                                            <Select
                                                labelId="select-type-label"
                                                onChange={handleChange}
                                                value={values.dataState}
                                                label="Data state"
                                                name="dataState"
                                            >
                                                {DATA_STATE_LIST.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                        disabled={item.disabled(values.dataState)}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FlexItem>
                                )}
                                <FlexItem>
                                    <FormControlLabel
                                        className={switchStyle}
                                        control={
                                            <Switch
                                                className="customSwitch"
                                                color="primary"
                                                name="isWeekEmail"
                                                checked={values.sendInsights}
                                                onChange={() => setFieldValue("sendInsights", !values.sendInsights)}
                                            />
                                        }
                                        label="Receive weekly insights emails"
                                    />
                                    {isEditMode && (
                                        <FormControlLabel
                                            className={switchStyle}
                                            control={
                                                <Switch
                                                    className="customSwitch"
                                                    color="primary"
                                                    name="excludeFromStoreModeling"
                                                    checked={values.excludeFromStoreModeling}
                                                    onChange={() =>
                                                        setFieldValue(
                                                            "excludeFromStoreModeling",
                                                            !values.excludeFromStoreModeling,
                                                        )
                                                    }
                                                />
                                            }
                                            label="Exclude from store modeling"
                                        />
                                    )}
                                    {isEditMode && (
                                        <FormControlLabel
                                            className={switchStyle}
                                            control={
                                                <Switch
                                                    className="customSwitch"
                                                    color="primary"
                                                    name="excludeFromAmazonModeling"
                                                    checked={values.excludeFromAmazonModeling}
                                                    onChange={() =>
                                                        setFieldValue(
                                                            "excludeFromAmazonModeling",
                                                            !values.excludeFromAmazonModeling,
                                                        )
                                                    }
                                                />
                                            }
                                            label="Exclude from Amazon modeling"
                                        />
                                    )}
                                </FlexItem>
                                {isEditMode && (
                                    <FieldArray
                                        name="features"
                                        render={(arrayHelpers) =>
                                            featuresList.map((f, i) => {
                                                const checked = values.features.includes(f.id);
                                                return (
                                                    <FlexItem key={`${f.cleanName}-features`} className={legacyStyle}>
                                                        <FormControlLabel
                                                            className={switchStyle}
                                                            control={
                                                                <Switch
                                                                    className="customSwitch"
                                                                    color="primary"
                                                                    name={f.cleanName}
                                                                    checked={checked}
                                                                    onChange={() => {
                                                                        if (checked) {
                                                                            const newFeatures = values.features.filter(
                                                                                (featureId) => f.id !== featureId,
                                                                            );
                                                                            setFieldValue("features", newFeatures);
                                                                        } else {
                                                                            arrayHelpers.push(f.id);
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={f.displayName}
                                                        />
                                                    </FlexItem>
                                                );
                                            })
                                        }
                                    />
                                )}
                                {this.props.isEditMode && (
                                    <FlexItem style={{ width: "100%" }}>
                                        <Typography className={caption}>
                                            Created: {changeDate(values.createdAt)}
                                        </Typography>
                                    </FlexItem>
                                )}
                                <FlexItem style={{ width: "100%" }}>
                                    {this.props.isEditMode ? (
                                        <>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting || notMatched}
                                                className={fullWidthClass}
                                                fullWidth
                                                variant="contained"
                                            >
                                                {isSubmitting ? <Spinner size={25} /> : "Save changes"}
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting || notMatched}
                                            className={fullWidthClass}
                                            fullWidth
                                            variant="contained"
                                        >
                                            {isSubmitting ? <Spinner size={25} /> : "Create Organization"}
                                        </Button>
                                    )}
                                </FlexItem>
                            </Flex>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
