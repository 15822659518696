export const AMAZON_SELLING_PARTNER = "amazon_selling_partner";
export const SALESFORCE_COMMERCE_CLOUD = "salesforce_commerce_cloud";
export const THE_TRADE_DESK = "the_trade_desk";
export const KEYNES_DIGITAL = "keynes_digital";
export const SHOPIFY = "shopify";
export const GOOGLE_ANALYTICS_4 = "google_analytics_4";
export const GOOGLE_ANALYTICS = "google_analytics";
export const AMAZON_ADS = "amazon_ads";
export const GOOGLE_SHEETS = "google_sheets";
export const BIGQUERY_ECOMMERCE = "bigquery_ecommerce";
