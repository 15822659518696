import { css } from "@emotion/css";
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { Button } from "@prescientai/component-library";
import Stack from "@mui/system/Stack";
import { differenceInMilliseconds, format } from "date-fns";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IForecastEligibility } from "src/components/CampaignComponents/InsightsModal/PerformanceTab/PerformanceTabHolderComponent";
import { ScenarioFormType } from "src/components/Optimization/formModel/addScenarioFormModel";
import { AMAZON_ADS } from "src/consts/connectors";
import { defaultRevenueSourceSelector, isAmazonSellingPartnerConnected } from "src/reduxState/slices/connectorsSlice";
import { amazonRevCacOptimizerSelector } from "src/reduxState/slices/featuresSlice";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { SVGIconRenderer } from "../../../../components/SVGIconRenderer/SVGIconRenderer";
import {
    FORECAST_BY_SPEND_METRIC,
    SCENARIO_OPTIMIZATION_TARGET_LABEL,
    SCENARIO_TYPE,
    TIMEFRAME_OPTIONS,
} from "../../../../consts/optimizationPage/optimizationPage";
import {
    dataViewOption,
    getModeledMetricLabelBasedOnService,
    getPaidDataSources,
    IForecastTableRow,
    isCACAvailableForCompany,
    MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE,
} from "../../../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../../../enums/Formats";
import { IErrors } from "../../../../interfaces/entities/IErrors";
import { IForecast } from "../../../../interfaces/entities/IForecast";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    useCreateScenarioMutation,
    useLazyGetScenarioByIdQuery,
    usePollingScenarioJobMutation,
} from "../../../../reduxState/apis/optimizationApi";
import {
    useGetCampaignForecastBySpendMutation,
    useGetForecastMutation,
    useLazyGetPerformancePageQuery,
} from "../../../../reduxState/apis/performancePageApi";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
    selectCurrentlyViewingId,
} from "../../../../reduxState/slices/organizationSlice";
import { userStateSelector } from "../../../../reduxState/slices/userSlice";
import { useAnalyticsService } from "../../../../services/analytics/useAnalyticsService";
import { calculateCAC, calculateROAS, formatValue } from "../../../../services/utils";
import { ForecastChart } from "./ForecastChart.v2";
import { ForecastTable } from "./ForecastTable.v2";
import {
    emptyForecast,
    forecastDate,
    getDataAsPerViewForChart,
    getInterpolation,
    getPercentageChange,
    INCREMENT_INITIAL_PER_CHANGE,
    initialTableValues,
    inputForEvent,
    inputSpendFormat,
    updatedTableHeader,
    XDecreaseByYPer,
    XIncreaseByYPer,
} from "./forecastUtils";

export interface IPrediction {
    label: string;
    days28: string;
    tooltip: string;
}

export interface IPredictionCompare {
    data: {
        percentagechanges: number;
        chartSpendValue: number;
    };
    predictionArray: IPrediction[];
}

interface IForecastTab {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    forecastEligibility: IForecastEligibility;
}

const useStyles = (theme: Theme) => ({
    titleClass: css({
        fontWeight: "bold",
        letterSpacing: "1px",
    }),
    inputClass: css({
        fontSize: "14px",
        width: "100%",
        "& .MuiInput-input": {
            textAlign: "right",
            padding: 0,
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                display: "none",
            },
        },
        "& .MuiTypography-root.MuiTypography-body1": {
            color: theme.palette.text.primary,
        },
    }),
});

// Wrapper is needed for the tooltip to work with MenuItem properly - https://github.com/mui/material-ui/issues/9737#issuecomment-1055739342
const WrappedMenuItem = (props: any) => {
    return (
        <Tooltip {...props.tooltipProps}>
            <span>
                <MenuItem {...props}>{props.children}</MenuItem>
            </span>
        </Tooltip>
    );
};

export interface IChartValue {
    heading: string;
    spendPercentage: number;
    spend: number;
    revenue: number;
    worstCase: number;
    bestCase: number;
    roas?: number;
    confidence?: number;
    formattedSpend?: string;
}

export interface ITableValue {
    [key: string]: IChartValue;
}

export const ForecastTab: React.FC<IForecastTab> = ({ performanceCampaignData, forecastEligibility }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const analyticsService = useAnalyticsService();
    const { enqueueSnackbar } = useSnackbar();
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const isCacSupported = isCACAvailableForCompany(defaultRevenueSource, orgCode);
    const hasAmazonConnector = useSelector(isAmazonSellingPartnerConnected);
    const isAmazonRevCacOptimizerSupported = useSelector(amazonRevCacOptimizerSelector);

    const { campaignId, connectorName } = performanceCampaignData;
    const isAmazonCampaignActive = connectorName === AMAZON_ADS;
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);
    const currentUser = useSelector(userStateSelector);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const modeledMetricLabelForTooltip = getModeledMetricLabelBasedOnService(defaultRevenueSource, false, false);

    const [saturationMax, setSaturationMax] = useState(0);
    const [allForecastData, setAllForecastData] = useState<IForecast>(emptyForecast);
    const [viewAs, setViewAs] = useState<TIMEFRAME_OPTIONS>(TIMEFRAME_OPTIONS.DAILY);

    const [tableValues, setTableValues] = useState<ITableValue>(initialTableValues);
    const [initialTableValuesState, setInitialTableValues] = useState<ITableValue>(initialTableValues);
    const [noChangeInputTitle, setNoChangeInputTitle] = useState("");
    const [priorMetric, setPriorMetric] = useState({
        spend: 0,
    });
    const [currentInputChange, setCurrentInputChange] = useState({ key: "", value: 0, percentage: 0 });
    const [changedForecastInput, setChangedForecastInput] = useState("");
    const revenueLabel = MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[defaultRevenueSource];

    const [forecastData, { isLoading, isSuccess }] = useGetForecastMutation();
    const [createScenario, { isLoading: createLoading }] = useCreateScenarioMutation();
    const [pollingScenarioJob] = usePollingScenarioJobMutation();
    const [getScenarioById] = useLazyGetScenarioByIdQuery();
    const [getPerformancePage, { data: performanceData, isLoading: isPerformanceLoading }] =
        useLazyGetPerformancePageQuery();
    const [getCampaignForecastBySpend] = useGetCampaignForecastBySpendMutation();

    const targetOptions = [
        {
            label: `${revenueLabel || "Store"} - ${SCENARIO_OPTIMIZATION_TARGET_LABEL.ROAS}`,
            value: FORECAST_BY_SPEND_METRIC.REVENUE,
            isHidden: isAmazonCampaignActive,
        },
        {
            label: `${revenueLabel || "Store"} - ${SCENARIO_OPTIMIZATION_TARGET_LABEL.CAC}`,
            value: FORECAST_BY_SPEND_METRIC.CUSTOMERS,
            isHidden: !isCacSupported || isAmazonCampaignActive,
        },
        {
            label: SCENARIO_OPTIMIZATION_TARGET_LABEL.AMAZON_REVENUE,
            value: FORECAST_BY_SPEND_METRIC.AMAZON_REVENUE,
            isHidden: !isAmazonRevCacOptimizerSupported || !hasAmazonConnector,
        },
        {
            label: SCENARIO_OPTIMIZATION_TARGET_LABEL.AMAZON_CUSTOMERS,
            value: FORECAST_BY_SPEND_METRIC.AMAZON_CUSTOMERS,
            isHidden: !isAmazonRevCacOptimizerSupported || !hasAmazonConnector,
        },
    ].filter((c) => !c.isHidden);

    const [metric, setMetric] = useState<FORECAST_BY_SPEND_METRIC>(targetOptions[0].value);

    const isAmazonMetricSelected = [
        FORECAST_BY_SPEND_METRIC.AMAZON_REVENUE,
        FORECAST_BY_SPEND_METRIC.AMAZON_CUSTOMERS,
    ].includes(metric);

    const isCacMetricSelected = [
        FORECAST_BY_SPEND_METRIC.CUSTOMERS,
        FORECAST_BY_SPEND_METRIC.AMAZON_CUSTOMERS,
    ].includes(metric);

    const baseFormula = isCacMetricSelected ? calculateCAC : calculateROAS;
    const modeledMetricLabel = getModeledMetricLabelBasedOnService(defaultRevenueSource, true, isAmazonMetricSelected);

    useEffect(() => {
        const metric = forecastEligibility.availableModels[0] as FORECAST_BY_SPEND_METRIC;
        if (metric) {
            setMetric(metric);
        }
    }, [isAmazonRevCacOptimizerSupported, forecastEligibility]);

    type forecastEligibilityKey = keyof typeof forecastEligibility;

    const getForecastBySpend = (spend: number, changedKey?: string) => {
        if (campaignId && connectorName) {
            if (changedKey) {
                setChangedForecastInput(changedKey);
            }
            return getCampaignForecastBySpend({
                orgId: currentOrgId,
                campaignId,
                connectorName,
                spend: spend / viewAs,
                modelType: metric,
            })
                .unwrap()
                .then((response) => ({ ...response, predRev: response.predRev * viewAs }))
                .catch((error) => {
                    enqueueSnackbar(
                        `${
                            error.status
                                ? `Error in fetching forecast campaign data by spend (Code: ${error.status})`
                                : `Something went wrong`
                        } `,
                        {
                            id: uniqueId(),
                            variant: "error",
                        },
                    );

                    return {
                        predRev: 0,
                        bestCase: 0,
                        worstCase: 0,
                        confidence: 0,
                    };
                })
                .finally(() => setChangedForecastInput(""));
        }
    };

    const getMinimumMaximumSpend = () => {
        return Math.max(tableValues.forecast1.spend || 0, tableValues.forecast2.spend || 0);
    };

    const getForecastData = (shouldResetFields = true) => {
        if (campaignId) {
            const reqStart = new Date();

            analyticsService.logEvent("Forecast Request Sent", {
                "Organization name": currentCompany.displayName,
                "Connector name": performanceCampaignData.channelName,
            });

            return forecastData({
                orgId: currentOrgId,
                campaignId,
                connectorName,
                modelType: metric,
                minimumMaximumSpend: getMinimumMaximumSpend(),
            })
                .unwrap()
                .then((response: IForecast | IErrors) => {
                    if ("error" in response || response === null) {
                        if (response === null) {
                            throw new Error("null data response received");
                        }
                        throw new Error(response.error[0]);
                    }
                    const timeTaken = differenceInMilliseconds(new Date(), reqStart);
                    const newAllForecastData = { shouldResetFields, ...response };

                    const newSaturationMax = response.saturatedSpend
                        ? +(+response.saturatedSpend[response.saturatedSpend.length - 1]?.toFixed(0)).toFixed(0)
                        : 0;
                    if (newSaturationMax > saturationMax) {
                        setSaturationMax(newSaturationMax);
                    }

                    setAllForecastData(newAllForecastData);

                    analyticsService.logEvent("Forecast Response Received", {
                        "Organization name": currentCompany.displayName,
                        "Connector name": performanceCampaignData.channelName,
                        "Response time": timeTaken,
                    });

                    return response;
                })
                .catch((err) => {
                    const timeTaken = differenceInMilliseconds(new Date(), reqStart);
                    analyticsService.logEvent("Forecast Response Error Received", {
                        "Organization name": currentCompany.displayName,
                        "Connector name": performanceCampaignData.channelName,
                        "Response time": timeTaken,
                        "Error text": err,
                    });
                });
        }
    };

    const getDailyData = async (avgSpendValue: number, allForecastData: IForecast) => {
        if (!allForecastData) {
            return;
        }

        const increase10PerchangesInSpend = XIncreaseByYPer(+INCREMENT_INITIAL_PER_CHANGE, avgSpendValue);

        const dailyForecastValues1 = await getForecastBySpend(+avgSpendValue.toFixed(0));
        const dailyForecastValues2 = await getForecastBySpend(+increase10PerchangesInSpend.toFixed(0));

        const interpolateBoundsForForecast1 = getInterpolation(allForecastData, +avgSpendValue.toFixed(0));
        const interpolateBoundsForForecast2 = getInterpolation(
            allForecastData,
            +increase10PerchangesInSpend.toFixed(0),
        );

        if (dailyForecastValues1 && dailyForecastValues2) {
            const updatedTableValues = {
                forecast1: {
                    ...tableValues.forecast1,
                    spendPercentage: 0,
                    revenue: dailyForecastValues1.predRev,
                    worstCase: dailyForecastValues1.worstCase || interpolateBoundsForForecast1.worstCase || 0,
                    bestCase: dailyForecastValues1.bestCase || interpolateBoundsForForecast1.bestCase || 0,
                    spend: avgSpendValue,
                    roas: baseFormula(avgSpendValue, dailyForecastValues1.predRev),
                    confidence: dailyForecastValues1.confidence * 100,
                    formattedSpend: inputSpendFormat(avgSpendValue),
                },

                forecast2: {
                    ...tableValues.forecast2,
                    spendPercentage: INCREMENT_INITIAL_PER_CHANGE,
                    revenue: dailyForecastValues2.predRev,
                    worstCase: dailyForecastValues2.worstCase || interpolateBoundsForForecast2.worstCase || 0,
                    bestCase: dailyForecastValues2.bestCase || interpolateBoundsForForecast2.bestCase || 0,
                    spend: increase10PerchangesInSpend,
                    roas: baseFormula(increase10PerchangesInSpend, dailyForecastValues2.predRev),
                    confidence: dailyForecastValues2.confidence * 100,
                    formattedSpend: inputSpendFormat(increase10PerchangesInSpend),
                },
            };
            setTableValues(updatedTableValues);
            setInitialTableValues(updatedTableValues);
        }
    };

    const getDataBasedOnView = async (avgSpendValue: number, viewAs: TIMEFRAME_OPTIONS) => {
        const increase10PerchangesInSpend = XIncreaseByYPer(+INCREMENT_INITIAL_PER_CHANGE, avgSpendValue);
        const updatedTableValues = {
            forecast1: {
                ...tableValues.forecast1,
                spendPercentage: 0,
                revenue: initialTableValuesState.forecast1.revenue * viewAs,
                worstCase: initialTableValuesState.forecast1.worstCase * viewAs,
                bestCase: initialTableValuesState.forecast1.bestCase * viewAs,
                spend: avgSpendValue,
                roas: baseFormula(avgSpendValue, initialTableValuesState.forecast1.revenue * viewAs),
                confidence: initialTableValuesState.forecast1.confidence || 0,
                formattedSpend: inputSpendFormat(avgSpendValue),
            },

            forecast2: {
                ...tableValues.forecast2,
                spendPercentage: INCREMENT_INITIAL_PER_CHANGE,
                revenue: initialTableValuesState.forecast2.revenue * viewAs,
                worstCase: initialTableValuesState.forecast2.worstCase * viewAs,
                bestCase: initialTableValuesState.forecast2.bestCase * viewAs,
                spend: increase10PerchangesInSpend,
                roas: baseFormula(increase10PerchangesInSpend, initialTableValuesState.forecast2.revenue * viewAs),
                confidence: initialTableValuesState.forecast2.confidence || 0,
                formattedSpend: inputSpendFormat(increase10PerchangesInSpend),
            },
        };
        setTableValues(updatedTableValues);
    };

    useEffect(() => {
        if (!campaignId || !supportedDataSources.length) {
            return;
        }
        // calling metric_attribution for get exact spend fo forecast date
        getPerformancePage({
            orgId: currentOrgId,
            data: {
                start_date: forecastDate,
                end_date: forecastDate,
                connectors: getPaidDataSources(supportedDataSources),
                campaign_id: [campaignId],
            },
        })
            .unwrap()
            .then(async (performanceRes) => {
                if (performanceRes) {
                    const forecastData = await getForecastData();
                    getDailyData(performanceRes?.data?.[0]?.spend || 0, forecastData as IForecast);
                }
            })
            .catch((error) => {
                enqueueSnackbar(
                    `${
                        error.status ? `Error in fetching campaign data (Code: ${error.status})` : `Somthing went wrong`
                    } `,
                    {
                        id: uniqueId(),
                        variant: "error",
                    },
                );
            });
    }, [supportedDataSources, metric]);

    const updateTableValues = () => {
        if (allForecastData?.saturatedSpend?.length) {
            const totalSpend = performanceData?.data?.[0]?.spend ?? 0;
            const avgSpend = totalSpend && viewAs ? +totalSpend.toFixed(0) * viewAs : 0;
            const metric = {
                spend: avgSpend || 0,
            };
            setPriorMetric(metric);
            getDataBasedOnView(avgSpend, viewAs);
            setNoChangeInputTitle(`On ${forecastDate}, you spent ${formatValue(totalSpend, FORMATS.DOLLAR, 0)}`);
        }
    };

    useEffect(() => {
        updateTableValues();
    }, [viewAs]);

    useEffect(() => {
        if (allForecastData?.shouldResetFields) {
            updateTableValues();
        }
    }, [allForecastData]);

    // update forecast table by forecast api response on input change
    useEffect(() => {
        const { key, value, percentage } = currentInputChange;
        if (!value || !allForecastData) {
            return;
        }
        const changedKey = key.includes("forecast1") ? "forecast1" : "forecast2";
        const delayDebounceFn = setTimeout(async () => {
            const getDecreasedForecast = await getForecastBySpend(value, changedKey);

            if (value / viewAs > saturationMax) {
                getForecastData(false);
            }

            const interpolateBoundsForForecast = getInterpolation(allForecastData, value);

            if (getDecreasedForecast) {
                const updatedCol = {
                    heading: `${percentage}% Spend`,
                    spendPercentage: percentage,
                    formattedSpend: inputSpendFormat(value),
                    spend: value,
                    revenue: getDecreasedForecast.predRev,
                    roas: baseFormula(value, getDecreasedForecast.predRev),
                    bestCase: getDecreasedForecast.bestCase || interpolateBoundsForForecast.bestCase || 0,
                    worstCase: getDecreasedForecast.worstCase || interpolateBoundsForForecast.worstCase || 0,
                    avgDailySpend: +value,
                    confidence: getDecreasedForecast.confidence * 100,
                };
                setTableValues({ ...tableValues, [changedKey]: { ...updatedCol } });
                analyticsService.logEvent("Forecast Input Changed", {
                    "Input changed": inputForEvent[key],
                    Value: value,
                    "Connector name": performanceCampaignData.channelName,
                    "Campaign name": performanceCampaignData.campaignName,
                });
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [currentInputChange]);

    // update table values on input change
    const handleForecastInputChange = (
        forecast: string,
        modifySpend: number,
        spendPercentage: number,
        name: string,
    ) => {
        if (modifySpend >= 0) {
            const heading = name.includes("Percentage") ? `${spendPercentage}% Spend` : `${spendPercentage}% Spend`;
            setTableValues((prevTableValues) => ({
                ...prevTableValues,
                [forecast]: {
                    ...prevTableValues[forecast],
                    spend: modifySpend,
                    formattedSpend: inputSpendFormat(modifySpend),
                    heading,
                    spendPercentage,
                },
            }));
            setCurrentInputChange({ key: name, value: modifySpend, percentage: spendPercentage });
        }
    };

    // input change handler
    const onInputChangeHandler = (e: React.ChangeEvent<any>) => {
        const { name, value } = e.target;
        switch (name) {
            case "forecast1Spend": {
                const modifySpend = +value.toString().replace(/,/g, "");
                const spendPercentage = +getPercentageChange(priorMetric.spend, modifySpend).toFixed(0);
                handleForecastInputChange("forecast1", modifySpend, spendPercentage, name);
                break;
            }
            case "forecast1SpendPercentage": {
                const spend = XDecreaseByYPer(value, priorMetric.spend);
                const modifySpend = +spend.toString().replace(/,/g, "");
                handleForecastInputChange("forecast1", modifySpend, value, name);
                break;
            }
            case "forecast2Spend": {
                const modifySpend = +value.toString().replace(/,/g, "");
                const spendPercentage = +getPercentageChange(priorMetric.spend, modifySpend).toFixed(0);
                handleForecastInputChange("forecast2", modifySpend, spendPercentage, name);
                break;
            }
            case "forecast2SpendPercentage": {
                const spend = XIncreaseByYPer(value, priorMetric.spend);
                const modifySpend = +spend.toString().replace(/,/g, "");
                handleForecastInputChange("forecast2", modifySpend, value, name);
                break;
            }
            default:
                break;
        }
    };

    const activationForecastEvent = (selectedForecastKey: string) => {
        analyticsService.logEvent("Forecasting Activation Clicked", {
            "Percent spend change": tableValues[selectedForecastKey].spendPercentage,
            "Daily spend value": tableValues[selectedForecastKey].spend,
        });
    };

    let exit = false;
    const continuousPromise = (promise: { (): Promise<void>; (): Promise<any> }, interval: number) => {
        const execute = () => promise().finally(waitAndExecute);
        const waitAndExecute = () => {
            if (exit) {
                return;
            }
            setTimeout(execute, interval);
        };
        execute();
    };

    const pollJobStatus = (jobId: string, orgId: string) => {
        if (jobId === "" || orgId === "") {
            return;
        }

        continuousPromise(() => {
            return pollingScenarioJob({ jobId, orgId })
                .unwrap()
                .then((res) => {
                    if (res.data.attributes.status === "completed") {
                        exit = true;
                    }
                })
                .catch((error) => {
                    exit = true;
                    console.log(error);
                });
        }, 3000);
    };

    const saveScenario = async (values: ScenarioFormType) => {
        createScenario({ body: values, orgId: currentOrgId })
            .unwrap()
            .then((scenario) => {
                getScenarioById({ orgId: currentOrgId, scenarioId: scenario.id })
                    .unwrap()
                    .then((response) => {
                        if (response?.scenario) {
                            const scenarioRes = { ...response.scenario };
                            if (
                                Object.keys(scenarioRes.job || {}).length > 0 &&
                                scenarioRes.jobStatus !== "completed"
                            ) {
                                const jobId = scenarioRes?.job?.jobId || "";
                                pollJobStatus(jobId, currentOrgId);
                            }
                        }
                    });
                enqueueSnackbar("", {
                    customMsg: (
                        <>
                            Your forecast has now been saved, visit the{" "}
                            <Link to={`/org/${orgCode}/optimization`}>Optimization page</Link> to track this forecast.
                        </>
                    ),
                    id: uniqueId(),
                    variant: "success",
                    autoHideDuration: 3000,
                });
            })
            .catch(() => {
                enqueueSnackbar("Something went wrong!", {
                    id: uniqueId(),
                    variant: "error",
                });
            });
    };

    const handleSave = (forecast: any) => {
        analyticsService.logEvent("Save Tracking Clicked Forecast", {
            "Input changed": `average daily spend ${forecast.spendPercentage}%`,
            Value: forecast.avgDailySpend,
            "Connector name": performanceCampaignData.channelName,
            "Campaign name": performanceCampaignData.campaignName,
        });
        if (performanceCampaignData.connectorName && performanceCampaignData.campaignId) {
            const description =
                +forecast.spendPercentage === 0
                    ? "No change in spend 0%"
                    : `${+forecast.spendPercentage < 0 ? "Decrease" : "Increase"} spend by ${Math.abs(
                          +forecast.spendPercentage,
                      )}%`;

            saveScenario({
                id: "",
                name: `${currentUser ? currentUser.firstName : ""}’s Scenario ${format(new Date(), "MMM d, yyyy")}`,
                description,
                scenarioType: isCacMetricSelected ? SCENARIO_TYPE.CAC : SCENARIO_TYPE.FORECAST,
                forecastTimeframe: viewAs,
                budget: +forecast.spend.toFixed(0),
                revenue: 0,
                roas: 1.0,
                campaignData: [
                    {
                        channel: performanceCampaignData.connectorName,
                        campaignId: performanceCampaignData.campaignId,
                        isLocked: false,
                        budgetAllocated: +forecast.spend.toFixed(0),
                        currentEstimatedSpend: performanceCampaignData.currentEstimatedSpend || 0,
                    },
                ],
                optimizationConfidence: 0,
                isPrivate: false,
                isHidden: false,
            });
        }
    };

    const getRows = () => {
        const {
            spendPercentage: forecast1SpendPercentage,
            revenue: forecast1Revenue,
            roas: forecast1Roas,
            confidence: forecast1Confidence,
            formattedSpend: forecast1FormattedSpend,
            bestCase: forecast1BestCase,
            worstCase: forecast1WorstCase,
        } = tableValues.forecast1;
        const {
            spendPercentage: forecast2SpendPercentage,
            revenue: forecast2Revenue,
            roas: forecast2Roas,
            confidence: forecast2Confidence,
            formattedSpend: forecast2FormattedSpend,
            bestCase: forecast2BestCase,
            worstCase: forecast2WorstCase,
        } = tableValues.forecast2;

        const currentPrediction = [
            {
                id: "avgDailySpend",
                label: `${viewAs === TIMEFRAME_OPTIONS.DAILY ? "Daily Spend" : `${viewAs} Days Spend`} `,
                tooltip: "",
                forecast1: (
                    <Tooltip title={noChangeInputTitle} arrow>
                        <Input
                            type="text"
                            className={classes.inputClass}
                            onChange={onInputChangeHandler}
                            value={forecast1FormattedSpend}
                            name="forecast1Spend"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                    </Tooltip>
                ),
                forecast2: (
                    <Input
                        type="text"
                        className={classes.inputClass}
                        onChange={onInputChangeHandler}
                        value={forecast2FormattedSpend}
                        name="forecast2Spend"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                ),
            },
            {
                id: "spendChange",
                label: "Spend Change",
                forecast1: (
                    <Input
                        type="text"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        className={classes.inputClass}
                        onChange={onInputChangeHandler}
                        value={forecast1SpendPercentage}
                        name="forecast1SpendPercentage"
                    />
                ),
                forecast2: (
                    <Input
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        type="text"
                        className={classes.inputClass}
                        onChange={onInputChangeHandler}
                        value={forecast2SpendPercentage}
                        name="forecast2SpendPercentage"
                    />
                ),
            },
            {
                id: "modeledRevenue",
                label: `${modeledMetricLabel} ${isCacMetricSelected ? "New Customers" : "Revenue"}`,
                forecast1: (
                    <Tooltip
                        title={`Lower - Upper Bounds: ${formatValue(
                            forecast1WorstCase,
                            FORMATS.DOLLAR,
                            0,
                        )} - ${formatValue(forecast1BestCase, FORMATS.DOLLAR, 0)}`}
                        arrow
                    >
                        <span>
                            {formatValue(forecast1Revenue, isCacMetricSelected ? FORMATS.NUMERIC : FORMATS.DOLLAR, 0)}
                        </span>
                    </Tooltip>
                ),
                forecast2: (
                    <Tooltip
                        title={`Lower - Upper Bounds:  ${formatValue(
                            forecast2WorstCase,
                            FORMATS.DOLLAR,
                            0,
                        )} - ${formatValue(forecast2BestCase, FORMATS.DOLLAR, 0)}`}
                        arrow
                    >
                        <span>
                            {formatValue(forecast2Revenue, isCacMetricSelected ? FORMATS.NUMERIC : FORMATS.DOLLAR, 0)}
                        </span>
                    </Tooltip>
                ),
                tooltip: "Base and Halo effect revenue algorithmically calculated revenue",
            },
            {
                id: "roas",
                label: `${modeledMetricLabel}  ${isCacMetricSelected ? "CAC" : "ROAS"}`,
                forecast1: formatValue(forecast1Roas, isCacMetricSelected ? FORMATS.DOLLAR : FORMATS.NUMERIC, 2),
                forecast2: formatValue(forecast2Roas, isCacMetricSelected ? FORMATS.DOLLAR : FORMATS.NUMERIC, 2),
            },
            {
                id: "confidence",
                label: "Confidence",
                forecast1: formatValue(forecast1Confidence, FORMATS.PERCENT, 0),
                forecast2: formatValue(forecast2Confidence, FORMATS.PERCENT, 0),
            },
        ];

        // TODO: add these objects to main currentPrediction when out of feature preview
        currentPrediction.push({
            id: "action",
            label: "Track Forecast",
            tooltip:
                "Click the save button on any custom forecast to track this forecast starting today for the next 28 days. You can see forecast tracking on the optimization homepage.",
            days28: "",
            forecast1: (
                <Button
                    size="small"
                    disabled={createLoading}
                    onClick={() => handleSave(tableValues.forecast1)}
                    variant="contained"
                    sx={{ textTransform: "initial", padding: "2px 10px" }}
                >
                    Save
                </Button>
            ),
            forecast2: (
                <Button
                    size="small"
                    disabled={createLoading}
                    onClick={() => handleSave(tableValues.forecast2)}
                    variant="contained"
                    sx={{ textTransform: "initial", padding: "2px 10px" }}
                >
                    Save
                </Button>
            ),
        } as any);

        return currentPrediction as IForecastTableRow[];
    };

    const handleViewData = (event: SelectChangeEvent<any>) => {
        setViewAs(event.target.value);
    };

    const handleMetricChange = (event: SelectChangeEvent<any>) => {
        setMetric(event.target.value as FORECAST_BY_SPEND_METRIC);
    };

    const forecastOptionDisabled = (option: FORECAST_BY_SPEND_METRIC) => {
        return !forecastEligibility.availableModels.includes(option);
    };

    const forecastDisabledTooltip = (option: FORECAST_BY_SPEND_METRIC) => {
        return forecastOptionDisabled(option)
            ? forecastEligibility.sharedReasonUnavailable ||
                  "Campaign is not currently being modeled. It may be too new or have too little spend"
            : "";
    };

    return (
        <Stack mt={3} mb={7} gap={2}>
            <Stack gap={1} direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center">
                    <Typography variant="h2" display="block" color="text.primary" className={classes.titleClass}>
                        Prediction
                    </Typography>
                    <Tooltip
                        arrow
                        title={
                            <>
                                These predictions are based on an algorithmically calculated forecast that assumes all
                                other campaigns are held constant. The forecasts represent{" "}
                                {modeledMetricLabelForTooltip} spend, revenue, and ROAS only.
                            </>
                        }
                    >
                        <span style={{ position: "relative", top: "3px", marginLeft: "5px" }}>
                            <SVGIconRenderer icon="infoIcon" height="16px" width="16px" />
                        </span>
                    </Tooltip>
                </Stack>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
                {targetOptions.length > 1 && (
                    <FormControl sx={{ minWidth: 120, backgroundColor: "white" }} size="small">
                        <InputLabel id="select-metric-label">Metric</InputLabel>
                        <Select
                            labelId="select-metric-label"
                            label="Metric"
                            value={metric}
                            onChange={handleMetricChange}
                            size="small"
                        >
                            {targetOptions.map((option) => (
                                <WrappedMenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={forecastOptionDisabled(option.value)}
                                    tooltipProps={{
                                        title: forecastDisabledTooltip(option.value),
                                    }}
                                >
                                    {option.label}
                                </WrappedMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControl sx={{ minWidth: 120, backgroundColor: "white" }} size="small">
                    <FormControl fullWidth>
                        <InputLabel id="select-type-label">View as</InputLabel>
                        <Select
                            labelId="select-type-label"
                            onChange={handleViewData}
                            label="View as"
                            size="small"
                            value={viewAs}
                        >
                            {dataViewOption.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.lable}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormControl>
            </Stack>
            <ForecastTable
                tableRows={getRows()}
                activationForecastEvent={activationForecastEvent}
                performanceCampaignData={performanceCampaignData}
                tableHeader={updatedTableHeader(tableValues)}
                isLoading={isLoading || isPerformanceLoading}
                changedForecastInput={changedForecastInput}
            />
            <Stack mt={2}>
                <Typography variant="h2" display="block" color="text.primary" className={classes.titleClass}>
                    Saturation Plot
                </Typography>
                {!isLoading && isSuccess ? (
                    <ForecastChart
                        performanceCampaignData={performanceCampaignData}
                        allForecastData={allForecastData}
                        tableValues={{
                            forecast1: getDataAsPerViewForChart(tableValues.forecast1, viewAs),
                            forecast2: getDataAsPerViewForChart(tableValues.forecast2, viewAs),
                        }}
                        chartStyle={{
                            chartContainer: {
                                height: "350px",
                                width: "100%",
                                maxWidth: "100%",
                            },
                            grid: {
                                containLabel: true,
                                show: false,
                                left: isCacMetricSelected ? "5%" : "2%",
                                bottom: "30%",
                            },
                            legend: {
                                orient: "horizontal",
                                bottom: 0,
                                selected: {
                                    "Worst Case": false,
                                    "Best Case": false,
                                    Confidence: false,
                                },
                            },
                        }}
                        isCacChart={isCacMetricSelected}
                        modeledMetricLabel={modeledMetricLabel}
                    />
                ) : (
                    <Skeleton variant="rectangular" animation="wave" height="350px" />
                )}
            </Stack>
        </Stack>
    );
};
