import React from "react";
import { CustomTableStructure } from "src/components/core/CustomTableStructure/CustomTableStructure";
import { ICells } from "src/consts/slackAlertsPage/slackAlertsPage";
import { useStylesTable } from "src/consts/StylesForTables";
import { ISlackAlert } from "src/interfaces/entities/ISlackAlert";
import { getComparator, stableSort } from "src/utils/sort";
import { SlackAlertsCard } from "./SlackAlertsCard";

interface ISlackAlertsTableProps {
    slackAlerts: ISlackAlert[];
    count: number;
    editClick: (slackAlertName: string) => void;
    orderBy: string;
    order: "asc" | "desc";
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    page: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SlackAlertsTable: React.FC<ISlackAlertsTableProps> = ({
    slackAlerts,
    editClick,
    count,
    order,
    orderBy,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
}) => {
    const classes = useStylesTable();

    const handleEdit = (e: React.MouseEvent<any>, slackAlertName: string) => {
        e.stopPropagation();
        editClick(slackAlertName);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
            noTable
        >
            {stableSort(slackAlerts, getComparator(order, orderBy)).map((slackAlert: any, index) => {
                return (
                    <SlackAlertsCard key={slackAlert.programmaticName} slackAlert={slackAlert} editClick={handleEdit} />
                );
            })}
        </CustomTableStructure>
    );
};
