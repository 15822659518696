import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import step1Image from "src/assets/applovinSetupImage1.png";

interface IAppLovinConnectorModalContent {
    classes: any;
    displayName: string;
}

export const AppLovinConnectorModalContent: FC<IAppLovinConnectorModalContent> = ({ classes, displayName }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Find Report Key
                </Typography>
            </Stack>
            <Typography className={classes.info}>
                Log in to your {displayName} dashboard using your master user account (not a sub-user account) to access
                the Report Key.
            </Typography>
            <Typography className={classes.info}>
                You can retrieve your Report Key in the <a href="https://www.applovin.com/analytics#keys">Keys</a> tab
                of the account page in your {displayName} dashboard.
            </Typography>
            <img src={step1Image} />
            <Box sx={{ backgroundColor: "white" }} p="1rem" mt={1}>
                <Typography className={classes.info}>
                    In case you are unable to find the Report Key, please reach out to your AppLovin Account Manager.
                </Typography>
            </Box>

            {/* Step 2 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>Enter the Report Key you found in Step 1.</Typography>
            <Typography className={classes.info}>Click Save & Test.</Typography>
            <Typography className={classes.info}>
                After submitting, Prescient will begin ingesting historical data and notify you once it is finished to
                complete the data validation and modeling process.
            </Typography>
        </Fragment>
    );
};
