import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { PageHeaderTabContext } from "src/components/CustomTabContext/PageHeaderTabContext";
import { OnboardingAdminHomePage } from "src/components/OnboardingPages/AdminOnboardingPages/OnboardingAdminHomePage";
import { OnboardingViewerOptimizationPage } from "src/components/OnboardingPages/ViewerOnboardingPages/OnboardingViewerOptimizationPage";
import { RecommendedTab } from "src/components/Optimization/OptimizationTabs/RecommendedTab";

import { UserRole } from "src/enums/UserRole";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { listFeaturesSelector, optimizationPageSelector } from "src/reduxState/slices/featuresSlice";
import { checkIsIncompletePagesExist } from "src/services/onboardingPage/onboardingPage";
import { scenatioTableViewTabs } from "src/services/optimizationPage/optimization";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import {
    OptimizationTab,
    SCENARIO_TYPE,
    SCENARIO_TRACKING_TABS,
    TIMEFRAME_OPTIONS,
} from "../../consts/optimizationPage/optimizationPage";
import { OPTIIMIZATION_CREATE_PATH, OPTIIMIZATION_PATH } from "../../consts/path/path";
import { IScenario, ScenarioOutcomeTab } from "../../interfaces/entities/IScenario";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
} from "../../reduxState/slices/organizationSlice";
import {
    scenarioCurrentTabSelector,
    setCurrentlyViewingScenario,
    setScenarioTab,
} from "../../reduxState/slices/scenarioSlice";
import { isCampaignAvailableSelector } from "../../reduxState/slices/settingsSlice";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userStateSelector,
} from "../../reduxState/slices/userSlice";
import { useLazyGetScenarioCreatorsQuery } from "src/reduxState/apis/optimizationApi";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { OnboardingStateValue } from "src/consts/onboardingPage/onboardingPage";
import { getPathForAnalytics } from "src/services/utils";
import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";
import { BasicOptimizationTab } from "src/components/Optimization/OptimizationTabs/BasicOptimizationTab";

export const Optimization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const isOptimizationPageVisible = useSelector(optimizationPageSelector);
    const featureList = useSelector(listFeaturesSelector);
    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const currentUser = useSelector(userStateSelector);
    const scenarioCurrentTab = useSelector(scenarioCurrentTabSelector);
    const currrentOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isDataReadyToQA = currrentOrg?.onboardingState?.dataQa === OnboardingStateValue.ready;

    const [getScenarioCreators, { data: scenarioCreators = [] }] = useLazyGetScenarioCreatorsQuery();

    const isIncompletePagesExist = useMemo(
        () => checkIsIncompletePagesExist(currrentOrg?.onboardingState),
        [currrentOrg],
    );

    useEffect(() => {
        if (currrentOrg?.id) {
            getScenarioCreators({
                orgId: currrentOrg.id,
            });
        }
        analyticsService.logEvent("Optimization Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    useEffect(() => {
        if (featureList.length && !isOptimizationPageVisible) {
            navigate(`/404`);
            return;
        }
    }, [featureList]);

    const openCreateModal = useCallback(() => {
        analyticsService.logEvent("Optimization New Scenario Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        dispatch(
            setCurrentlyViewingScenario({
                id: "",
                name: `${currentUser ? currentUser.firstName : ""}’s Scenario ${format(new Date(), "MMM d, yyyy")}`,
                description: "",
                scenarioType: SCENARIO_TYPE.SCALE,
                forecastTimeframe: TIMEFRAME_OPTIONS.LAST28DAYS,
                budget: 0,
                revenue: 0,
                roas: 1.0,
                campaignData: [],
            }),
        );
        navigate(`/org/${orgCode}${OPTIIMIZATION_CREATE_PATH}`);
    }, []);

    const openEditModal = (optimization: IScenario) => {
        analyticsService.logEvent("Optimization Previous Scenario Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${optimization.id}`);
    };

    const handleTracking = (scenario: IScenario) => {
        analyticsService.logEvent(
            `See Tracking Clicked ${scenario.scenarioType === SCENARIO_TYPE.FORECAST ? "Forecast" : "Optimization"}`,
            {},
        );
        navigate(`/org/${orgCode}/optimization/${scenario.id}?tab=${ScenarioOutcomeTab.Tracking}`);
    };

    const handleScenarioViewChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptimizationTab) => {
        dispatch(setScenarioTab(newValue as OptimizationTab));
    };

    const getCurrentTab = () => {
        const props = {
            handleTracking,
            openEditModal,
            members: scenarioCreators,
        };
        switch (scenarioCurrentTab) {
            case OptimizationTab.Scenarios:
                return <BasicOptimizationTab tabName={OptimizationTab.Scenarios} {...props} />;
            case OptimizationTab.Drafts:
                return <BasicOptimizationTab tabName={OptimizationTab.Drafts} {...props} />;
            case OptimizationTab.Recommended:
                return <RecommendedTab {...props} />;
            case OptimizationTab.Archived:
                return <BasicOptimizationTab tabName={OptimizationTab.Archived} {...props} />;
            default:
                return <BasicOptimizationTab tabName={OptimizationTab.Scenarios} {...props} />;
        }
    };

    const filteredTabs = scenatioTableViewTabs
        .map((tab) => {
            return {
                ...tab,
                isHidden: tab.value === OptimizationTab.Recommended,
            };
        })
        .filter((tab) => !tab.isHidden);

    const renderContent = () => {
        if (isIncompletePagesExist) {
            return (
                <PageContainer style={{ padding: 0, backgroundColor: "#fff" }}>
                    {isSuperAdmin || userRole === UserRole.ADMIN ? (
                        <OnboardingAdminHomePage />
                    ) : (
                        <OnboardingViewerOptimizationPage state="empty" />
                    )}
                </PageContainer>
            );
        }

        if (isDataReadyToQA) {
            return (
                <PageContainer style={{ padding: 0, backgroundColor: "#fff" }}>
                    <OnboardingViewerOptimizationPage state={OnboardingStateValue.ready} />
                </PageContainer>
            );
        }

        return (
            <PageContainer>
                <Stack gap="30px">{getCurrentTab()}</Stack>
            </PageContainer>
        );
    };

    return (
        <>
            {!isCampaignAvailable && !isIncompletePagesExist && !isDataReadyToQA ? (
                <NoCampaignPage
                    title="We're building your dashboard!"
                    subTitle="We're in the process of syncing your data and using it to build your custom attribution model. This
can take some time, so please check back later."
                />
            ) : (
                <div>
                    <PageHeader
                        pageHeading={"Optimization"}
                        buttonName="Create New Optimization"
                        onAdd={openCreateModal}
                        customStyle={{ padding: "30px 30px 0" }}
                        tabs={
                            <PageHeaderTabContext
                                value={scenarioCurrentTab}
                                handleChange={handleScenarioViewChange}
                                tabs={filteredTabs}
                            />
                        }
                        disabled={isIncompletePagesExist || isDataReadyToQA}
                        showBreadcrumbs={!isIncompletePagesExist && !isDataReadyToQA}
                    />
                    {renderContent()}
                </div>
            )}
        </>
    );
};
