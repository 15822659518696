import { Tooltip, Typography } from "@mui/material";
import React from "react";
import Mustache from "mustache";
import { DataObject, List } from "@mui/icons-material";
import { Stack } from "@mui/system";

export const MustachePreview = ({
    originalText,
    availableFields,
}: {
    originalText: string;
    availableFields: string[];
}) => {
    const parseMustacheTemplate = (template: string) => {
        const tokens = Mustache.parse(template);
        const reactElements: React.ReactNode[] = [];
        tokens.forEach((token, index) => {
            const [type, value] = token;

            if (type === "text") {
                value.split("\n").forEach((line, lineIndex, lines) => {
                    reactElements.push(line);
                    if (lineIndex < lines.length - 1) {
                        reactElements.push(<br key={`br-${index}-${lineIndex}`} />);
                    }
                });
            } else if (type === "name") {
                reactElements.push(
                    <Placeholder key={`ph-${index}`} text={value} isValid={availableFields.includes(value)} />,
                );
            } else if (type === "#" || type === "^") {
                reactElements.push(
                    <Section key={`sec-${index}`} text={value} isValid={availableFields.includes(value)} />,
                );
                const newLineToken = token[4]?.[2]?.[1] === "\n";
                if (newLineToken) {
                    reactElements.push(<br key={`br-${index}`} />);
                }
            }
        });

        return reactElements;
    };

    return (
        <div style={{ backgroundColor: "#f6f6f6", borderRadius: "1em", padding: "1em" }}>
            <Typography component="div" sx={{ overflowWrap: "break-word" }}>
                {parseMustacheTemplate(originalText)}
            </Typography>
        </div>
    );
};

const Block = ({ text, icon, isValid = true }: { text: string; icon: React.ReactNode; isValid?: boolean }) => {
    const item = (
        <Stack
            direction="row"
            alignItems="baseline"
            my={0.5}
            sx={{
                maxWidth: "max-content",
                display: "inline-flex",
                verticalAlign: "baseline",
                backgroundColor: "#e3e3e3",
                borderRadius: 1,
                overflow: "hidden",
                color: isValid ? "black" : "error.main",
            }}
        >
            <span
                style={{
                    display: "inline-flex",
                    alignItems: "center",
                    height: "100%",
                    alignSelf: "stretch",
                    backgroundColor: "lightgray",
                    padding: ".25em .4em",
                }}
            >
                {icon}
            </span>
            <span
                style={{
                    padding: ".25em .4em",
                }}
            >
                {text}
            </span>
        </Stack>
    );

    return isValid ? item : <Tooltip title="There is no variable of this name in this message.">{item}</Tooltip>;
};

const Placeholder = ({ text, isValid }: { text: string; isValid: boolean }) => (
    <Block text={text} icon={<DataObject sx={{ fontSize: "1.5em" }} />} isValid={isValid} />
);

const Section = ({ text, isValid }: { text: string; isValid: boolean }) => (
    <Block text={text} icon={<List sx={{ fontSize: "1.5em" }} />} isValid={isValid} />
);
