import React from "react";
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { Choice, IOptimizedHeadCell } from "../../../../consts/optimizationPage/optimizationPage";
import { ScenarioFormType } from "../../formModel/addScenarioFormModel";

interface ICampaignTableHead {
    keyPrefix: string;
    classes: any;
    orderBy: string;
    order: Choice;
    selectedCampaignsCount: number;
    channelCount: number;
    values: ScenarioFormType;
    lastDaysTooltip: string;
    handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined;
    createSortHandler?: (property: string) => (event: React.MouseEvent<unknown>) => void | undefined;
    hidden?: boolean;
    tableHeader: IOptimizedHeadCell[];
}

export const CampaignTableHead: React.FC<ICampaignTableHead> = ({
    keyPrefix = "",
    classes,
    orderBy,
    order,
    selectedCampaignsCount,
    channelCount,
    values,
    lastDaysTooltip,
    handleSelectAllClick,
    createSortHandler = () => undefined,
    hidden = false,
    tableHeader,
}) => {
    return (
        <TableHead sx={hidden ? { visibility: "collapse" } : {}}>
            <TableRow>
                {tableHeader.map((channelHeader) => {
                    const isChannelNameCell = channelHeader.id === "channelName";
                    return (
                        <TableCell
                            key={`${keyPrefix}-${channelHeader.id}`}
                            align={isChannelNameCell ? "left" : "right"}
                            className={`${classes.boldText} ${channelHeader.id}`}
                        >
                            {isChannelNameCell && (
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    indeterminate={selectedCampaignsCount > 0 && selectedCampaignsCount < channelCount}
                                    checked={channelCount > 0 && selectedCampaignsCount === channelCount}
                                    onChange={hidden ? undefined : handleSelectAllClick}
                                    sx={{ paddingLeft: "40px" }}
                                />
                            )}
                            <TableSortLabel
                                active={orderBy === channelHeader.id}
                                direction={orderBy === channelHeader.id ? order : "desc"}
                                onClick={hidden ? undefined : createSortHandler(channelHeader.id)}
                            >
                                {channelHeader.id === "currentEstimatedSpend"
                                    ? `No Change ${values.forecastTimeframe} Days Spend`
                                    : channelHeader.label}
                                {orderBy === channelHeader.id ? (
                                    <Box component="span" style={{ display: "none" }}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
