import React from "react";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { css } from "@emotion/css";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { Button } from "@prescientai/component-library";

import { handleDeleteParamKey } from "../../../services/performancePage/performancePage";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const useStyles = () => ({
    selectInput: css({
        minWidth: 210,
        backgroundColor: "white",
        ".MuiInputBase-root": {
            height: "42px",
        },
        label: {
            fontSize: "15px",
        },
    }),
    resetButton: css({
        textTransform: "inherit",
        boxShadow: "none",
        fontSize: "15px",
    }),
});

interface IEventFilter {
    handleFilter: (
        paramKey: string,
        item: {
            id: string;
        },
    ) => void;
    onClear: () => void;
    changedByList: string[];
    auditableTypeList: string[];
}

export const EventFilter: React.FC<IEventFilter> = ({ handleFilter, onClear, changedByList, auditableTypeList }) => {
    const classes = useStyles();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParamsObj = queryString.parse(search);

    const { changedBy, auditableType } = queryParamsObj;

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        handleDeleteParamKey(name, navigate);
        handleFilter(name, { id: value });
    };

    const handleResetFilter = () => {
        handleDeleteParamKey("changedBy", navigate);
        handleDeleteParamKey("auditableType", navigate);
        onClear();
    };

    const clearFilter = (key: string) => {
        handleDeleteParamKey(key, navigate);
        onClear();
    };

    const getInputValue = (value: string | Array<string | null> | null) => {
        return typeof value === "string" ? value : "";
    };

    const isFilterApplied = Object.keys(queryParamsObj).length !== 0;

    return (
        <>
            <FormControl className={classes.selectInput} size="small">
                <InputLabel id="changed-by">Changed By</InputLabel>
                <Select
                    labelId="changed-by"
                    value={getInputValue(changedBy)}
                    name="changedBy"
                    label="Changed By"
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                        "& .MuiSelect-iconOutlined": { display: getInputValue(changedBy) ? "none" : "" },
                    }}
                    endAdornment={
                        getInputValue(changedBy) && (
                            <span className="MuiIconButton-root" onClick={() => clearFilter("changedBy")}>
                                <SVGIconRenderer
                                    icon="closeIcon"
                                    strokeColor="rgba(0, 0, 0, 0.54)"
                                    height="1em"
                                    width="1em"
                                />
                            </span>
                        )
                    }
                >
                    {changedByList.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.selectInput} size="small">
                <InputLabel id="auditable-type">Auditable Type</InputLabel>
                <Select
                    labelId="auditable-type"
                    name="auditableType"
                    value={getInputValue(auditableType)}
                    label="Auditable Type"
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                        "& .MuiSelect-iconOutlined": { display: getInputValue(auditableType) ? "none" : "" },
                    }}
                    endAdornment={
                        getInputValue(auditableType) && (
                            <span className="MuiIconButton-root" onClick={() => clearFilter("auditableType")}>
                                <SVGIconRenderer
                                    icon="closeIcon"
                                    strokeColor="rgba(0, 0, 0, 0.54)"
                                    height="1em"
                                    width="1em"
                                />
                            </span>
                        )
                    }
                >
                    {auditableTypeList.map((status) => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {isFilterApplied && (
                <Button className={classes.resetButton} variant="contained" onClick={handleResetFilter}>
                    Reset
                </Button>
            )}
        </>
    );
};
