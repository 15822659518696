import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormikHelpers } from "formik";
import cloneDeep from "lodash/cloneDeep";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import addDays from "date-fns/addDays";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { TabPanel } from "@mui/lab";
import isEqual from "lodash/isEqual";

import { Modal } from "../../components/core/Modal/Modal";
import { OrganizationForm } from "../../components/OrganizationForm/OrganizationForm";
import { DeleteOrganizationModal } from "../../components/DeleteOrganizationModal/DeleteOrganizationModal";
import { Message } from "../../components/Message/Message";
import {
    useLazyGetOrganizationsQuery,
    useCreateOrganizationMutation,
    useDeleteOrganizationMutation,
    useEditOrganizationMutation,
} from "../../reduxState/apis/organizationApi";
import {
    OrganizationsListedByOrderSelector,
    setCurrentlyViewing,
    parentOrganizationSelector,
} from "../../reduxState/slices/organizationSlice";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import noConnectors from "../../assets/noConnectors.png";
import wallet from "../../assets/wallet.png";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import Loader from "../../components/core/Loader/Loader";
import { demoModeSelector } from "../../reduxState/slices/demoModeSlice";
import { listFeaturesSelector } from "../../reduxState/slices/featuresSlice";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { OrganizationsTable } from "./OrganizationTable/OrganizationTable";
import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { ISubscription } from "../../interfaces/entities/ISubscription";
import {
    useCreateSubscriptionMutation,
    useDeleteSubscriptionMutation,
    useEditSubscriptionMutation,
} from "../../reduxState/apis/subscription";
import { SubscriptionModal } from "../../components/SubscriptionComponents/SubscriptionModal";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { emptyOrganization, getFilteredOrganizaion } from "../../services/organization/organization";
import { SubscriptionStatusKeys } from "../../enums/SubscriptionStatusKeys";
import { OrganizationsFilter } from "./OrganizationsFilter";
import { handleFilteredData, toCamelCase } from "../../services/utils";
import { CustomTabContext } from "src/components/CustomTabContext/CustomTabContext";
import { OrganizationOnboardingForm } from "src/components/OrganizationForm/OrganizationOnboardingForm";
import { IOnboardingState } from "src/interfaces/entities/IOnboardingState";
import { useEditOnboardingStatesMutation } from "src/reduxState/apis/onboardingStatesApi";
import { OnboardingFormTab, mappingOnboardingToApiState } from "src/consts/onboardingPage/onboardingPage";

export interface ISubscriptionWithOrg extends ISubscription {
    orgId: string;
}

export const Organizations: React.FC = ({}) => {
    const dispatch = useDispatch();
    const analytics = useAnalyticsService();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const filterQueryParamsObj = queryString.parse(window.location.search);

    const defaultSubscription = {
        status: SubscriptionStatusKeys.TRIALING,
        currentPeriodStart: new Date().toISOString(),
        currentPeriodEnd: addDays(new Date(), 14).toISOString(),
    };

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [organization, setOrganization] = useState<IOrganization>(emptyOrganization);
    const organizations = useSelector(OrganizationsListedByOrderSelector);
    const parentOrgList = useSelector(parentOrganizationSelector);
    const [getOrganizations, { isLoading }] = useLazyGetOrganizationsQuery();
    const featuresList = useSelector(listFeaturesSelector);
    const [deleteOrganization, { isLoading: isDeletingOrg }] = useDeleteOrganizationMutation();
    const [createOrganization] = useCreateOrganizationMutation();
    const [editOrganization] = useEditOrganizationMutation();
    const isDemoMode = useSelector(demoModeSelector);

    const [activeOrganizationFormTab, setActiveOrganizationFormTab] = useState(OnboardingFormTab.details);
    const [isEditSub, setIsEditSub] = useState(false);
    const [isSubscriptionModalOpen, setIsSubscriptionEditModalOpen] = useState<boolean>(false);
    const [isSubscriptionDeleteModalOpen, setIsSubscriptionDeleteModalOpen] = useState<boolean>(false);
    const [createSubscription] = useCreateSubscriptionMutation();
    const [editSubscription] = useEditSubscriptionMutation();
    const [deleteSubscription, { isLoading: isDeleteSubscriptionLoadng }] = useDeleteSubscriptionMutation();
    const [editOnboardingStates, { isLoading: isOnboardingSaving }] = useEditOnboardingStatesMutation();

    const filteredOrganizationsData = getFilteredOrganizaion(organizations, search, filterQueryParamsObj);
    const orgLength = filteredOrganizationsData.length;

    useEffect(() => {
        getOrganizations("");
    }, []);

    const handleSubmit = async (values: IOrganization, formikHelpers: FormikHelpers<IOrganization>) => {
        if (!values.id) {
            await createOrganizationAction(values, formikHelpers);
        } else {
            await editOrganizationAction(values, formikHelpers);
        }
    };

    const editOrganizationAction = async (values: IOrganization, formikHelpers: FormikHelpers<IOrganization>) => {
        await editOrganization({
            params: { id: +values.id },
            body: {
                ...values,
                avatar: values.avatar || "",
                send_insights: values.sendInsights,
            },
        })
            .unwrap()
            .then(() => {
                getOrganizations("");
                setIsEditMode(false);
                setIsOpen(false);
            })
            .catch((errors) => {
                let transformedErrors = transformApiErrors({ message: "Error" });
                if (errors.data?.errors?.length > 0) {
                    transformedErrors = errors.data.errors.reduce((acc: any, error: any) => {
                        return { ...acc, [toCamelCase(error.source.parameter)]: error.detail };
                    }, transformedErrors);
                }
                formikHelpers.setErrors(transformedErrors);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };

    const createOrganizationAction = async (values: IOrganization, formikHelpers: FormikHelpers<IOrganization>) => {
        await createOrganization({ ...values, send_insights: values.sendInsights, avatar: values.avatar || "" })
            .unwrap()
            .then((response) => {
                getOrganizations("");
                analytics.logEvent("Organization Created", {
                    "New organization ID": response.data && response.data.id,
                    "Organization logo uploaded": values.avatar ? "True" : "False",
                });
                setIsOpen(false);
            })
            .catch((error) => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
                if (error.status === 422) {
                    formikHelpers.setErrors({
                        ...errors,
                        name: `Organization ${error.data.errors[0].detail.toLowerCase()}.`,
                    });
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }
            })
            .finally(() => {
                setIsEditMode(false);
                formikHelpers.setSubmitting(false);
            });
    };

    const handleRemoveOrganization = async (id: string | number) => {
        await deleteOrganization(+id)
            .unwrap()
            .then(() => {
                getOrganizations("");
            })
            .catch((error) => {
                enqueueSnackbar("Error", {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                setIsOpen(false);
                setIsEditMode(false);
                setDeleteModalOpen(false);
            });
    };

    const editOnboardingState = async (updatedOnboardingState: IOnboardingState) => {
        const { onboardingState } = organization;

        if (!onboardingState?.id) {
            return;
        }

        try {
            await editOnboardingStates({
                onboardingStates: {
                    [mappingOnboardingToApiState.eCommerce]: updatedOnboardingState.eCommerce,
                    [mappingOnboardingToApiState.webAnalytics]: updatedOnboardingState.webAnalytics,
                    [mappingOnboardingToApiState.paidMarketing]: updatedOnboardingState.paidMarketing,
                    [mappingOnboardingToApiState.dataQa]: updatedOnboardingState.dataQa,
                },
                stateId: onboardingState.id,
                orgId: organization.id,
            });
            getOrganizations("");
            setIsEditMode(false);
            setIsOpen(false);
        } catch (error) {
            enqueueSnackbar("Onboarding states Updating error", {
                id: uniqueId(),
                variant: "error",
            });
        }
    };

    const openEditModal = (organization: IOrganization) => {
        setIsOpen(true);
        setIsEditMode(true);
        setActiveOrganizationFormTab(OnboardingFormTab.details);
        setOrganization(cloneDeep(organization));
    };

    const openDeleteModal = (organization: IOrganization) => {
        setOrganization(cloneDeep(organization));
        setDeleteModalOpen(!isDeleteModalOpen);
    };

    const openCreateModal = useCallback(() => {
        analytics.logEvent("Organization Creation Began");
        setOrganization(emptyOrganization);
        setIsOpen(true);
        setIsEditMode(false);
        setActiveOrganizationFormTab(OnboardingFormTab.details);
    }, []);

    const handleFilter = (paramKey: string, item: { id: string }) => {
        handleFilteredData(paramKey, item, navigate);
    };

    const viewOrganization = (org: IOrganization) => {
        dispatch(setCurrentlyViewing(org.code));
    };

    const handleSearchChange = useCallback((item: string) => {
        setSearch(item);
    }, []);

    // subscription stuff

    const handleSubscriptionDelete = () => {
        setIsSubscriptionDeleteModalOpen(true);
    };

    const closeSubscriptionModal = () => {
        setOrganization(emptyOrganization);
        setIsSubscriptionEditModalOpen(false);
        setIsSubscriptionDeleteModalOpen(false);
    };

    const handleSubscriptionAddOrEdit = (
        values: ISubscriptionWithOrg,
        { setErrors, setSubmitting }: FormikHelpers<ISubscriptionWithOrg>,
    ) => {
        let apiResponse = null;

        if (values.id) {
            apiResponse = editSubscription({
                params: { orgId: +values.orgId, subId: +values.id },
                body: values,
            });
        } else {
            apiResponse = createSubscription({
                params: { orgId: +values.orgId },
                body: values,
            });
        }
        apiResponse
            .unwrap()
            .then(() => {
                closeSubscriptionModal();
                getOrganizations("");
            })
            .catch((error) => {
                setSubmitting(false);
                setErrors({ externalSubscriptionId: error.data.errors[0].detail.toLowerCase() });
            });
    };

    const deleteSubscriptionAction = (id: string) => {
        if (id) {
            deleteSubscription({
                id: +id,
                orgId: +organization.id,
            })
                .then(() => {
                    getOrganizations("");
                })
                .finally(() => {
                    closeSubscriptionModal();
                });
        }
    };

    const openSubscriptionModal = (organization: IOrganization) => {
        setOrganization(cloneDeep(organization));
        setIsEditSub(organization.subscription ? true : false);
        setIsSubscriptionEditModalOpen(true);
    };

    const organizationFormTabs = useMemo(
        () => [
            { label: "Details", value: OnboardingFormTab.details, isHidden: false },
            { label: "Onboarding", value: OnboardingFormTab.onboarding, isHidden: !isEditMode },
        ],
        [isEditMode],
    );

    const handleFormTab = (event: React.SyntheticEvent, tab: string) => {
        setActiveOrganizationFormTab(tab as OnboardingFormTab);
    };

    return (
        <div>
            <PageHeader
                pageHeading="Organizations"
                buttonName="Add Organization"
                onAdd={openCreateModal}
                disabled={isDemoMode}
            />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <SubHeader
                            itemsLength={orgLength}
                            title="organization(s)"
                            onSearchChange={handleSearchChange}
                            filterComponent={<OrganizationsFilter handleFilter={handleFilter} />}
                        />
                        {orgLength ? (
                            <OrganizationsTable
                                organizations={filteredOrganizationsData}
                                editClick={openEditModal}
                                deleteClick={openDeleteModal}
                                viewOrganization={viewOrganization}
                                subscriptionClick={openSubscriptionModal}
                            />
                        ) : search ? (
                            <Message
                                title="We didn’t find anything according your request"
                                subtitle="Please make sure you spelled their name correctly or try a different name."
                                icon={noConnectors}
                                filters={[]}
                            />
                        ) : (
                            <Message
                                title="You don’t have any organizations created yet 😟"
                                subtitle="Click the picture above to add your first organization!"
                                icon={wallet}
                                onIconClick={openCreateModal}
                            />
                        )}
                    </>
                )}
            </PageContainer>

            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    title={isEditMode ? "Edit organization" : "New organization"}
                    canOutsideClickClose={false}
                    onClose={() => setIsOpen(false)}
                    content={
                        <CustomTabContext
                            tabValue={activeOrganizationFormTab}
                            handleChange={handleFormTab}
                            tabs={organizationFormTabs}
                        >
                            <>
                                <TabPanel sx={{ padding: "24px 0 0 0" }} value={OnboardingFormTab.details}>
                                    <OrganizationForm
                                        isEditMode={isEditMode}
                                        initialValues={organization as IOrganization}
                                        onSubmit={handleSubmit}
                                        onCancel={() => console.log("cancel")}
                                        onChangeMode={() => console.log("change mode")}
                                        featuresList={featuresList}
                                        parentOrgList={parentOrgList}
                                    />
                                </TabPanel>
                                <TabPanel sx={{ padding: "24px 0 0 0" }} value={OnboardingFormTab.onboarding}>
                                    <OrganizationOnboardingForm
                                        onboardingState={organization.onboardingState}
                                        editOnboardingState={editOnboardingState}
                                        isOnboardingSaving={isOnboardingSaving}
                                    />
                                </TabPanel>
                            </>
                        </CustomTabContext>
                    }
                />
            )}
            {isDeleteModalOpen && organization && organization.id ? (
                <DeleteOrganizationModal
                    organization={organization}
                    handleDelete={() => handleRemoveOrganization(organization.id || "")}
                    closeModal={() => setDeleteModalOpen(false)}
                    isDeletingOrg={isDeletingOrg}
                />
            ) : null}
            {isSubscriptionModalOpen ? (
                <Modal
                    isOpen={isSubscriptionModalOpen}
                    title={`${isEditSub ? "Edit" : "Create"} Subscription For ${organization.displayName}`}
                    canOutsideClickClose={false}
                    onClose={closeSubscriptionModal}
                    content={
                        <SubscriptionModal
                            initialValues={
                                organization.subscription
                                    ? ({
                                          ...organization.subscription,
                                          orgId: organization.id,
                                      } as ISubscriptionWithOrg)
                                    : ({ ...defaultSubscription, orgId: organization.id } as ISubscriptionWithOrg)
                            }
                            onSubmit={handleSubscriptionAddOrEdit}
                            deleteSubscription={handleSubscriptionDelete}
                            isEditSubscription={isEditSub}
                        />
                    }
                />
            ) : null}
            {isSubscriptionDeleteModalOpen && organization.subscription?.id ? (
                <CustomModal
                    title="Remove Subscription?"
                    subTitle=""
                    details={
                        <Typography component="li" variant="body2">
                            Are you sure want to cancel <b>{organization.displayName}</b>'s subscription?
                        </Typography>
                    }
                    buttonName="Delete"
                    handleAction={() => deleteSubscriptionAction(organization.subscription?.id || "")}
                    selectedConnector={organization}
                    closeModal={() => setIsSubscriptionDeleteModalOpen(false)}
                    disableBtn={isDeleteSubscriptionLoadng}
                    type="error"
                />
            ) : null}
        </div>
    );
};
