import { FC } from "react";
import { Alert, Typography } from "@mui/material";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { Button } from "@prescientai/component-library";

interface IAllocationErrorAlert {
    msg: string;
    handleAlert: () => void;
}

export const AllocationErrorAlert: FC<IAllocationErrorAlert> = ({ msg, handleAlert }) => {
    return (
        <Alert
            variant="filled"
            severity="error"
            sx={{
                backgroundColor: "#F53D3D",
                color: "white",
                display: "flex",
                alignItems: "center",
                paddingY: 0,
                marginBottom: "15px",
                "& .MuiAlert-message": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                },
            }}
        >
            <Typography>{msg}</Typography>
            <Button variant="text" sx={{ color: "white", fontWeight: "bold" }} onClick={handleAlert}>
                <SVGIconRenderer icon="closeIcon" strokeColor="white" />
            </Button>
        </Alert>
    );
};
