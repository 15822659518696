import React, { FC } from "react";
import { Stack, useTheme } from "@mui/system";
import { css } from "@emotion/css";
import { Theme, Tooltip, Typography } from "@mui/material";
import { Button } from "@prescientai/component-library";

interface ISettingComponent {
    title: string;
    description: string;
    btnTitle: string;
    buttonTooltipTitle?: string;
    submitClick: any;
    isDisabledBtn?: boolean;
    id?: string;
}

const useStyles = (theme: Theme) => ({
    headingStyle: css({ margin: 0, color: "inherit", borderBottom: "1px solid #D9D9D9" }),
    descriptionStyle: css({
        width: "50%",
        color: "inherit",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    }),
});

export const SettingComponent: FC<ISettingComponent> = ({
    title,
    description,
    btnTitle,
    buttonTooltipTitle = "",
    submitClick,
    isDisabledBtn = false,
    id,
}) => {
    const classes = useStyles(useTheme());

    return (
        <>
            <h3 className={classes.headingStyle}>{title}</h3>
            <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" gap="20px">
                <Typography className={classes.descriptionStyle}>{description}</Typography>
                <Tooltip title={buttonTooltipTitle} placement="right">
                    <span style={{ maxWidth: "max-content" }}>
                        <Button
                            onClick={submitClick}
                            disabled={isDisabledBtn}
                            variant="outlined"
                            data-cy={id}
                            sx={{ padding: 2 }}
                        >
                            {btnTitle}
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
        </>
    );
};
