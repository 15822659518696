import { css } from "@emotion/css";
import { LoadingButton } from "@mui/lab";
import {
    AppBar,
    CircularProgress,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Slide,
    Toolbar,
    Typography,
} from "@mui/material";
import { Button } from "@prescientai/component-library";
import { Link, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { Stack, useTheme } from "@mui/system";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { TransitionProps } from "notistack";
import queryString from "query-string";
import React, { FC, ReactElement, Ref, forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";

import companyLogo from "src/assets/logo/Prescient-Logo.svg";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { ApiError } from "src/containers/Errors/ApiError";
import { ICustomConnectorFormData } from "src/interfaces/ICustomConnectorFormData";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { selectDataSourceConfigSchemaByProgrammaticName } from "src/reduxState/slices/supportedDataSourcesSlice";
import { isCustomConnector } from "src/services/connectors/connectors";
import { toCamelCase } from "src/services/utils";
import { GeneratedCredentialForm } from "../ConnectorsComponent/Modals/customConnectorsModals/GeneratedCredentialForm";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme: Theme) => ({
    logoWrapperLink: css({
        padding: "7px",
        display: "flex",
    }),
    logoClass: css({
        width: "126px",
        height: "22.11px",
        alignSelf: "center",
    }),
    appBarClass: css({
        paddingRight: "0!important",
        backgroundColor: "#FFFFFF",
        boxShadow: "inset 0px -1px 0px #D9D9D9",
    }),
    mainFormClass: css({
        backgroundColor: "white",
        padding: "30px",
        borderLeft: "1px solid #D9D9D9",
        height: "calc(100vh - 128px)",
        overflowY: "auto",
    }),
    footerClass: css({
        borderTop: "1px solid #D9D9D9",
        width: "100%",
        position: "sticky",
        bottom: 0,
        height: "64px",
        backgroundColor: "white",
    }),
    heading: css({
        fontSize: "1.3rem",
        marginTop: "2rem",
        color: "black",
    }),
    info: css({ color: "rgba(0, 0, 0, 0.6)" }),
});

interface ICreateCustomConnectorModal {
    children: ReactElement;
    submitCredentialsHandler: (formData: ICustomConnectorFormData) => void;
    displayName: string;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
    isConfigSetAlready: boolean;
    instructions: any;
    handleFormDataChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    formData: ICustomConnectorFormData;
}

export const CreateCustomConnectorModal: FC<ICreateCustomConnectorModal> = ({
    children,
    submitCredentialsHandler,
    displayName,
    isLoading,
    error,
    isConfigSetAlready,
    instructions,
    handleFormDataChange: handleFormDataChange,
    formData,
}) => {
    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const orgCode = useSelector(selectCurrentlyViewingCode);
    let customError = "";

    const latestQueryParamsObj = queryString.parse(window.location.search);

    const connectorName = (latestQueryParamsObj?.connectorName || "") as string;
    const connectorId = (latestQueryParamsObj?.connectorId || "") as string;

    const cancelAction = () => {
        navigate(`/org/${orgCode}/connectors`);
    };

    if (!connectorId || !connectorName) {
        customError = "Connector id/name is missing, Please provide valid params.";
    }

    if (connectorId && error) {
        if ("status" in error) {
            const e = error.data as any;
            customError = "error" in error ? error.error : e.errors[0].detail;
        } else {
            customError = error.message || "";
        }
    }

    if (connectorName && !isCustomConnector(connectorName)) {
        customError = `Couldn't find Connector with "name"=${connectorName}`;
    }

    const configSchema = useSelector((state) =>
        selectDataSourceConfigSchemaByProgrammaticName(state, connectorName.toLowerCase()),
    );

    const isFormValid = useMemo(() => {
        if (isConfigSetAlready) {
            return true;
        }
        if (!configSchema || !configSchema.required) {
            return formData.apiKey !== "";
        }
        return !configSchema.required.some((key) => {
            key = toCamelCase(key);
            return (
                formData[key as keyof ICustomConnectorFormData] === "" ||
                !formData[key as keyof ICustomConnectorFormData]
            );
        });
    }, [configSchema, formData]);

    const isDisabled = isLoading || !isFormValid;

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "#f5f5f5",
                    overflow: "hidden",
                },
            }}
            fullScreen
            open={true}
            disableEscapeKeyDown
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBarClass} position="sticky">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Link to="/" className={classes.logoWrapperLink}>
                        <img src={companyLogo} className={classes.logoClass} />
                    </Link>
                    <IconButton sx={{ padding: 0 }} onClick={cancelAction} aria-label="close">
                        <SVGIconRenderer icon="closeIcon" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main>
                {customError ? (
                    <ApiError errMsg={customError} />
                ) : (
                    <Stack direction="row">
                        <Stack width="40%">
                            <Stack
                                gap="0.5rem"
                                sx={{
                                    borderBottom: "1px solid #dfe1e5",
                                    padding: "15px 30px",
                                }}
                                direction="row"
                                alignItems="center"
                            >
                                <DataSourceAvatar
                                    programmaticName={connectorName}
                                    className={{ width: "50px", height: "50px" }}
                                />
                                <Typography color="black" variant="h5" fontSize="1.5rem">
                                    {displayName}
                                </Typography>
                            </Stack>
                            <Stack
                                gap="10px"
                                sx={{
                                    height: "calc(100vh - 210px)",
                                    overflowY: "auto",
                                    padding: "0 30px 30px 30px",
                                }}
                            >
                                <Typography className={classes.heading}>Setup Guide</Typography>
                                <Typography className={classes.info}>
                                    {`Follow our setup guide to connect ${
                                        displayName.includes("Kargo") ? "Kargo" : displayName
                                    }.`}
                                </Typography>
                                <Typography className={classes.heading}>Prerequisites</Typography>
                                {instructions?.prerequisites ? (
                                    <RecursiveList data={instructions.prerequisites} />
                                ) : (
                                    <Typography className={classes.info}>
                                        To connect {displayName}, you need a {displayName} account.
                                    </Typography>
                                )}

                                {children}
                            </Stack>
                        </Stack>
                        <Stack flexGrow="1" alignContent="center" className={classes.mainFormClass}>
                            <GeneratedCredentialForm
                                configSchema={configSchema}
                                formData={formData}
                                isConfigSetAlready={isConfigSetAlready}
                                handleFormDataChange={handleFormDataChange}
                            />
                        </Stack>
                    </Stack>
                )}
            </main>

            <Stack className={classes.footerClass}>
                <Toolbar sx={{ Width: "100%", justifyContent: "end", gap: "20px" }}>
                    <Button
                        sx={{ textTransform: "inherit", fontSize: "15px", color: "black" }}
                        variant="outlined"
                        onClick={cancelAction}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        sx={{ textTransform: "inherit", fontSize: "15px" }}
                        endIcon={isLoading ? <CircularProgress color={"inherit"} size={16} /> : <></>}
                        loading={isLoading}
                        disabled={isDisabled}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => submitCredentialsHandler(formData)}
                    >
                        Save & Test
                    </LoadingButton>
                </Toolbar>
            </Stack>
        </Dialog>
    );
};

const listStyle = (depth: number) => {
    return depth % 2 === 0 ? "decimal" : "lower-alpha";
};

const RecursiveList = ({ data, depth = 0 }: { data: string | any[]; depth?: number }) => {
    if (typeof data === "string") {
        return (
            <ListItem sx={{ display: "list-item", overflowWrap: "break-word" }}>
                <ListItemText primary={data} />
            </ListItem>
        );
    }

    return (
        <List sx={{ listStyleType: listStyle(depth), pl: 3, typography: "body1" }}>
            {data.map((item, index) => {
                // No prefix for deeper levels
                return <RecursiveList key={index} data={item} depth={depth + 1} />;
            })}
        </List>
    );
};
