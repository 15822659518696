export type Choice = "asc" | "desc";

export interface ICells {
    displayName: string;
    cleanName: string;
    description: string;
    companies: number;
}

interface IHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

export const FEATURES_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "displayName",
        numeric: false,
        disablePadding: false,
        label: "Display Name",
    },
    {
        id: "cleanName",
        numeric: false,
        disablePadding: false,
        label: "Clean Name",
    },
    {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
    },
    {
        id: "companies",
        numeric: false,
        disablePadding: false,
        label: "Organizations",
    },
];

export const KLAVIYO_EMAIL_PAGE = "klaviyo_email_page";
export const ATTRIBUTION_METRIC_DROPDOWN = "attribution_metric_dropdown";
export const OPTIMIZER_WITH_CONFIDENCE = "optimizer_with_confidence";
export const COMPANY_WIDE_FORECASTING_CARDS = "company_wide_forecasting_cards";
export const CAMPAIGN_INCLUSIONS_PAGE = "campaign_inclusions_page";
export const OPTIMIZATION_PREDICTED_LIFT = "optimization_predicted_lift";
export const OPTIMIZATION_PAGE = "optimization_page";
export const EXCLUDE_OPTIMIZER = "exclude_optimizer";
export const AMAZON_REV_CAC_OPTIMIZER = "amazon_rev_cac_optimizer";
export const EDITABLE_OPTIMIZATIONS = "editable_optimizations";
