import baseCreateApi from "./baseCreateApi";
import { IOrganizationDTO } from "../../interfaces/DTO/IOrganizationDTO";
import { transformEditOrganization, transformOrganization } from "../../services/organization/organization";
import { setOrganizations } from "../slices/organizationSlice";
import { ICommonDTO } from "../../interfaces/DTO/ICommonDTO";
import { IOrganizationMetaDataResponse } from "../../interfaces/organizations/IOrganizationMetaDataResponse";
import { keysToCamelCase } from "../../utils/format";
import { ICreateBillingSessionResponse } from "../../interfaces/organizations/ICreateBillingSessionResponse";
import { IBillingSession } from "../../interfaces/entities/IBillingSession";
import { IOrganizationLogoUpdateResponse } from "../../interfaces/organizations/IOrganizationLogoUpdateResponse";

export interface IOrganizationBody {
    name: string;
    avatar: any;
    send_insights: boolean;
    excludeFromStoreModeling?: boolean;
    excludeFromAmazonModeling?: boolean;
    displayName: string;
    features?: string[];
    dataState?: string;
    stripeCustomerId: string;
    parentId: number | null;
    createVitallyAccount?: boolean;
    isTest: boolean;
    hasCustomIdentityManager?: boolean;
}

const setOrganizationAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const resp = await queryFulfilled;
        const { data, included }: { data: IOrganizationDTO[]; included: ICommonDTO[] } = resp.data;
        const organizationData = transformOrganization(data);
        if (data) {
            await dispatch(setOrganizations(organizationData));
        }
    } catch (error) {
        console.error(error);
    }
};

export const organizations = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        createOrganization: builder.mutation({
            query: (body: IOrganizationBody) => ({
                url: "organizations",
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            name: body.name,
                            avatar: body.avatar,
                            send_insights: body.send_insights,
                            stripe_customer_id: body.stripeCustomerId,
                            parent_id: body.parentId || null,
                            create_vitally_account: body.createVitallyAccount,
                            is_test: body.isTest,
                        },
                        type: "sessions",
                    },
                }),
            }),
        }),
        getOrganizations: builder.query({
            query: (include?: string) => ({
                url: `organizations?include=features${include ? `,${include}` : ""}`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setOrganizationAsyncHandler({ dispatch, queryFulfilled });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response) => {
                return response;
            },
        }),
        editOrganization: builder.mutation({
            query: (queryArgs: { params: { id: number }; body: IOrganizationBody }) => ({
                url: `organizations/${queryArgs.params.id}`,
                method: "PATCH",
                body: JSON.stringify({
                    data: {
                        attributes: transformEditOrganization(queryArgs.body),
                        type: "sessions",
                    },
                }),
            }),
        }),
        deleteOrganization: builder.mutation({
            query: (id: number) => ({
                url: `organizations/${id}`,
                method: "DELETE",
            }),
        }),
        getOrganizationMetaData: builder.query({
            query: (orgId: string) => ({
                url: `organizations/${orgId}/metadata`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: IOrganizationMetaDataResponse) => {
                return keysToCamelCase(response);
            },
        }),
        createCustomerBillingSession: builder.mutation({
            query: (id: number) => ({
                url: `organizations/${id}/billing_portal`,
                method: "POST",
            }),
            transformResponse: (response: ICreateBillingSessionResponse): IBillingSession => {
                return { redirectUrl: response.data.redirect_url };
            },
        }),
    }),
});

export const {
    useLazyGetOrganizationsQuery,
    useCreateOrganizationMutation,
    useDeleteOrganizationMutation,
    useEditOrganizationMutation,
    useLazyGetOrganizationMetaDataQuery,
    useCreateCustomerBillingSessionMutation,
} = organizations;
