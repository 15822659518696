import { Chip, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Button } from "@prescientai/component-library";

import { activeStatus, brokenStatus, pausedStatus, syncStatus } from "src/assets/styles/commonStyle";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { ConnectorStatus, IConnector } from "src/interfaces/IConnector";
import { IDictionary } from "src/interfaces/IDictionary";

interface IConnectorStatusChip {
    connector: IConnector;
    brokenStatusClick?: (e: React.MouseEvent<any>, connector: any) => void;
}

export const getStatusClassName: IDictionary = {
    broken: brokenStatus,
    incomplete: brokenStatus,
    syncing: syncStatus,
    historical_syncing: syncStatus,
    active: activeStatus,
    paused: pausedStatus,
};

export const ConnectorStatusChip: FC<IConnectorStatusChip> = ({ connector, brokenStatusClick }) => {
    const status = connector.status;
    const theme = useTheme();

    const renderChip = (label: React.ReactNode, className: string, onClick?: (e: React.MouseEvent<any>) => void) => (
        <Chip
            label={label}
            variant="outlined"
            sx={{ textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}
            className={className}
            onClick={onClick}
        />
    );

    const renderStatus: Record<string, React.ReactNode> = {
        incomplete: renderChip(
            <Stack alignItems="center" flexDirection="row">
                <span>{status} - finish setup</span>
                <SVGIconRenderer
                    icon="chevronRightIcon"
                    strokeColor={theme.palette.error.main}
                    height="18px"
                    width="18px"
                />
            </Stack>,
            getStatusClassName[status],
            (e) => brokenStatusClick && brokenStatusClick(e, connector),
        ),
        broken: (
            <Button
                variant="outlined"
                color="error"
                onClick={(e) => brokenStatusClick && brokenStatusClick(e, connector)}
            >
                Re-Connect
            </Button>
        ),
        syncing: renderChip(
            <div className="syncClass">
                <span>{status === "historical_syncing" ? "historical sync" : "syncing"}</span>
                <SVGIconRenderer
                    icon="refreshIcon"
                    width="18px"
                    height="18px"
                    strokeColor="#a19923"
                    style={{
                        marginLeft: "6px",
                        animation: "spin 2s linear infinite",
                    }}
                />
            </div>,
            getStatusClassName[status],
        ),
        active: renderChip("active", getStatusClassName[status]),
        paused: renderChip("paused", getStatusClassName[status]),
    };

    return <>{status === ConnectorStatus.HISTORICAL_SYNCING ? renderStatus.syncing : renderStatus[status]}</>;
};
