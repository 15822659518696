import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OverridableStringUnion } from "@mui/types";
import { Box, Paper, Table, TableContainer, TablePropsSizeOverrides, Theme, useTheme } from "@mui/material";

import { tableScrollbarStyles, useStylesTable } from "../../../consts/StylesForTables";
import { CustomTablePagination } from "../CustomTablePagination/CustomTablePagination";

interface ICustomTableStructure {
    paginationProps: {
        count: number;
        page: any;
        handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
        handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
        rowsPerPage?: number;
        extraInfo?: string;
        className?: any;
    };
    noTable?: boolean;
    showPagination?: boolean;
    children: React.ReactNode;

    tableProps?: {
        id?: string;
        size: OverridableStringUnion<"small" | "medium", TablePropsSizeOverrides>;
        className?: string;
    };
    className?: any;
}

const tablePaperStyle = {
    width: "100%",
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    position: "relative",
};

export const CustomTableStructure: FC<ICustomTableStructure> = ({
    children,
    paginationProps,
    showPagination = true,
    tableProps = { size: "medium" },
    className,
    noTable = false,
}) => {
    const classes = useStylesTable();
    const theme = useTheme();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollbarRef = useRef<HTMLDivElement>(null);

    const [isOverflown, setIsOverflown] = useState(false);

    const tableElem = useMemo(() => wrapperRef.current?.querySelector("table"), [wrapperRef.current]);

    const isFirefox = useMemo(() => navigator.userAgent.toLowerCase().includes("firefox"), []);

    const checkOverflow = () => {
        if (!tableElem || !wrapperRef.current) {
            return false;
        }
        setIsOverflown(tableElem.offsetWidth > wrapperRef.current?.offsetWidth);
    };

    const showScrollbar = useMemo(() => isOverflown && !isFirefox, [isOverflown]);

    const scrollbarStyles = tableScrollbarStyles(theme);

    useEffect(() => {
        checkOverflow();
    }, [tableElem, wrapperRef]);

    useEffect(() => {
        // sync sticky scrollbar with table scroll
        const tableEl = wrapperRef.current;
        const scrollbarEl = scrollbarRef.current;

        if (!tableEl || !scrollbarEl) {
            return;
        }

        const syncScroll = (e: Event) => {
            const source = e.target as HTMLElement;
            const target = source === tableEl ? scrollbarEl : tableEl;
            target.scrollLeft = source.scrollLeft;
        };

        tableEl.addEventListener("scroll", syncScroll);
        scrollbarEl.addEventListener("scroll", syncScroll);
        tableEl.addEventListener("resize", checkOverflow);
        window.addEventListener("resize", checkOverflow);

        return () => {
            tableEl.removeEventListener("scroll", syncScroll);
            scrollbarEl.removeEventListener("scroll", syncScroll);
            tableEl.removeEventListener("resize", checkOverflow);
            window.removeEventListener("resize", checkOverflow);
        };
    }, [wrapperRef.current]);

    useEffect(() => {}, [wrapperRef.current?.offsetWidth]);

    return (
        <Paper className={className} sx={tablePaperStyle}>
            {noTable ? (
                children
            ) : (
                <>
                    <TableContainer
                        className={classes.tableWrapper}
                        ref={wrapperRef}
                        sx={{
                            "::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        <Table {...tableProps}>{children}</Table>
                    </TableContainer>

                    <Box
                        ref={scrollbarRef}
                        sx={{
                            display: showScrollbar ? "block" : "none",
                            position: "sticky",
                            bottom: showPagination ? "53px" : 0,
                            left: 0,
                            right: 0,
                            overflowX: "auto !important",
                            overflowY: "hidden",
                            borderTop: showScrollbar ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                            borderBottom: showScrollbar ? `1px solid ${theme.palette.background.paper}` : "none",
                            backgroundColor: "background.paper",
                            maxWidth: "100%",
                            width: "100%",
                            ...scrollbarStyles,
                        }}
                    >
                        {!isFirefox && (
                            <Box
                                sx={{
                                    visibility: showScrollbar ? "visible" : "hidden",
                                    height: "1px",
                                    minWidth: tableElem?.offsetWidth || "101%",
                                    pointerEvents: "none",
                                }}
                            />
                        )}
                    </Box>
                </>
            )}
            {showPagination && (
                <Box
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "background.paper",
                        borderTop: 1,
                        borderColor: "divider",
                        zIndex: 1,
                    }}
                >
                    <CustomTablePagination {...paginationProps} />
                </Box>
            )}
        </Paper>
    );
};
