import { Circle, Edit } from "@mui/icons-material";
import { Chip, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Button } from "@prescientai/component-library";
import { alpha, Stack, useTheme } from "@mui/system";
import React, { useEffect } from "react";
import { ISlackAlert } from "src/interfaces/entities/ISlackAlert";
import { MustachePreview } from "./MustachePreview";

interface ISlackAlertsTableRowProps {
    slackAlert: ISlackAlert;
    editClick: (e: React.MouseEvent<HTMLButtonElement>, slackAlertName: string) => void;
}

export const SlackAlertsCard: React.FC<ISlackAlertsTableRowProps> = ({ editClick, slackAlert }) => {
    const {
        enabled,
        displayName,
        programmaticName,
        channelName,
        availableFieldsForMessage,
        message,
        tagCustomerSuccessManager,
        slackUsers,
    } = slackAlert;

    const theme = useTheme();

    return (
        <Stack
            m={2}
            p={2}
            gap={2}
            sx={{
                border: "1px solid rgba(0, 0, 0, 0.5)",
                borderRadius: 1,
                display: "grid",
                gridTemplateColumns: "1fr 2fr 1fr",
            }}
        >
            <Stack gap={2} alignContent="start" sx={{ overflowWrap: "break-word", minWidth: 0 }}>
                <Stack>
                    <Typography variant="h3">{displayName}</Typography>
                    <Typography color="text.secondary">{programmaticName}</Typography>
                </Stack>
                <Chip
                    variant="outlined"
                    sx={{
                        maxWidth: "max-content",
                        backgroundColor: alpha(enabled ? theme.palette.success.main : theme.palette.error.main, 0.1),
                    }}
                    icon={<Circle sx={{ fontSize: "1em", paddingLeft: ".5em" }} />}
                    color={enabled ? "success" : "error"}
                    label={enabled ? "Enabled" : "Disabled"}
                />
                <Typography>Channel: #{channelName}</Typography>
            </Stack>
            <Stack sx={{ overflowWrap: "break-word", minWidth: 0 }}>
                <Typography pb={2} variant="h3">
                    Message
                </Typography>
                <MustachePreview originalText={message} availableFields={availableFieldsForMessage} />
            </Stack>
            <Stack sx={{ overflowWrap: "break-word", minWidth: 0 }} height="100%">
                <Typography pb={2} variant="h3">
                    Tagged users
                </Typography>
                <Stack flexGrow={1} pb={2}>
                    {slackUsers?.length || tagCustomerSuccessManager ? (
                        <List disablePadding>
                            {tagCustomerSuccessManager && <Item key="csm">Customer Success Manager</Item>}
                            {slackUsers?.map((user, index) => (
                                <Item key={index}>{user.name}</Item>
                            ))}
                        </List>
                    ) : (
                        <Typography color="text.secondary">No users tagged directly.</Typography>
                    )}
                </Stack>
                <Button
                    data-cy={`edit-alert-${slackAlert.programmaticName}`}
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={(e) => editClick(e, programmaticName)}
                >
                    Edit Slack alert
                </Button>
            </Stack>
        </Stack>
    );
};

const Item = ({ children }: { children: React.ReactNode }) => (
    <ListItem disablePadding>
        <ListItemText primary={children} />
    </ListItem>
);
