import get from "lodash/get";

import { IFeatureDTO } from "../../interfaces/DTO/IFeatureDTO";
import { IFeature } from "../../interfaces/entities/IFeature";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IDataType } from "../../interfaces/entities/IDataType";
import { keysToCamelCase } from "../../utils/format";

export const transformFeature = (data: IFeatureDTO[]): IFeature[] => {
    return data.map((item) => {
        const { attributes, relationships } = item;
        const transformedAttributes = keysToCamelCase(attributes);
        const companies = get(relationships, "companies.data", []).map((c: IDataType) => c.id);

        return {
            id: item.id,
            companies,
            ...transformedAttributes,
        };
    });
};

export const generateFeaturesBody = (body: IFeature, isEdit: boolean) => {
    const resultBody: { data: any } = {
        data: {
            attributes: {
                display_name: body.displayName,
                clean_name: isEdit ? body.cleanName : body.displayName.toLowerCase().replace(/ /g, "_"),
                description: body.description,
                company_id: body.companies ? body.companies.join(",") : "",
            },
            type: "users",
        },
    };

    return resultBody;
};

export const getFilteredFeatureList = (featuresList: IFeature[], search: string, companies: IOrganization[]) => {
    const companyIds = companies.map((c) => c.id);
    const withCompany: IFeature[] = featuresList.map((f) => {
        const companyId = Object.values(f.companiesFeatures || {}).map((c: any) => c.companyId);

        let featureWithCompany: any[] = [];
        companyId.forEach((fc) => {
            if (companyIds.includes(fc.toString())) {
                featureWithCompany = [...featureWithCompany, companies.find((c) => c.id === fc.toString())];
            }
        });

        return { ...f, companyInfo: featureWithCompany };
    });

    if (withCompany && withCompany.length) {
        return withCompany.filter((feature) =>
            `${feature.displayName || feature.cleanName}`.toLowerCase().includes(search.toLowerCase()),
        );
    }
    return [];
};
