import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
    AMAZON_ADS,
    AMAZON_SELLING_PARTNER,
    BIGQUERY_ECOMMERCE,
    GOOGLE_ANALYTICS_4,
    SALESFORCE_COMMERCE_CLOUD,
    SHOPIFY,
} from "src/consts/connectors";
import { SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR } from "src/consts/performancePaidPage/performancePaidPage";
import {
    DEFAULT_REVENUE_CONNECTOR,
    QUATERNARY_REVENUE_CONNECTOR,
    SECONDARY_REVENUE_CONNECTOR,
    TERTIARY_REVENUE_CONNECTOR,
} from "src/services/homeDashboard/homeDashboard";
import { IConnector } from "../../interfaces/IConnector";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { brokenConnectorsSelector } from "./brokenConnectorSlice";
import { selectCurrentlyViewingCode, setCurrentlyViewing } from "./organizationSlice";

interface IConnectorsState {
    connectors: any[];
    loading: boolean;
}

const initialState: IConnectorsState = {
    connectors: [],
    loading: false,
};

export const getDefaultSource = (connectors: string[] = []) => {
    const prioritizedConnectors = [
        DEFAULT_REVENUE_CONNECTOR,
        SECONDARY_REVENUE_CONNECTOR,
        TERTIARY_REVENUE_CONNECTOR,
        QUATERNARY_REVENUE_CONNECTOR,
    ];
    for (const connector of prioritizedConnectors) {
        if (connectors.includes(connector)) {
            return connector;
        }
    }
    // If none of the prioritized connectors are found, return the first connector
    return connectors.length > 0 ? connectors[0] : null;
};

export const getDefaultRevenueSource = (connectors: IConnector[], orgCode: string) => {
    const validDataSources = new Set([SALESFORCE_COMMERCE_CLOUD, BIGQUERY_ECOMMERCE, SHOPIFY, GOOGLE_ANALYTICS_4]);

    // extract programmatic names for relevant connectors
    const services = connectors
        .map((c: IConnector) => c.dataSource?.programmaticName)
        .filter((name: string) => name && validDataSources.has(name));

    // return default source if available
    const defaultSource = getDefaultSource(services);
    if (defaultSource) {
        return defaultSource;
    }

    // handle missing revenue connectors by org code
    if (SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR.includes(orgCode)) {
        return SALESFORCE_COMMERCE_CLOUD;
    }

    return "";
};

const connectorsSlice = createSlice({
    name: "connectors",
    initialState,
    reducers: {
        setConnector: (state, { payload }) => {
            return {
                ...state,
                connectors: payload,
            };
        },
        addConnector: (state, { payload }) => {
            state.connectors.push(payload);
        },
        deleteConnectorById: (state, { payload }) => {
            return {
                ...state,
                connectors: state.connectors.filter((connector) => connector.id !== payload),
            };
        },
        setConnectorsLoading(state, action) {
            return {
                ...state,
                loading: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setConnector, addConnector, deleteConnectorById, setConnectorsLoading } = connectorsSlice.actions;

export default connectorsSlice.reducer;

// Selectors
export const connectorsSelector = (state: RootState) => state.connectors?.connectors || [];

export const isConnectorsLoadingSelector = (state: RootState) => state.connectors.loading;

export const selectBrokenConnectors = createSelector(
    [connectorsSelector, brokenConnectorsSelector, selectCurrentlyViewingCode],
    (connectors, brokenConnectors, code) =>
        connectors.filter(
            (c: IConnector) =>
                c.status === "broken" && !brokenConnectors.filter((b) => b.includes(c.id.toString())).length,
        ),
);

export const isShopifyConnectedSelector = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === SHOPIFY) ? true : false,
);

export const shopifyConnectedSelector = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === SHOPIFY),
);

export const isAmazonSellingPartnerConnected = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === AMAZON_SELLING_PARTNER) ? true : false,
);

export const defaultRevenueSourceSelector = createSelector(
    [connectorsSelector, selectCurrentlyViewingCode],
    (connectors, orgCode) => getDefaultRevenueSource(connectors, orgCode),
);
