import { FC, useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, IconButton, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { imageContainerClass } from "src/consts/StylesForTables";
import { CAMPAIGN_TACTICS_HEADER } from "src/consts/campaignTacticsPage/campaignTactics";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { ICampaignTacticTypes } from "src/interfaces/entities/ICampaignTacticTypes";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { handleClickWithTextSelectionCheck } from "src/services/utils";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { IAddCampaignTactic } from "src/reduxState/apis/campaignTacticsApi";

interface ICampaignTacticsTableRow {
    row: IDimCampaign & { cleanChannelName: string };
    getSelectedTactics: (tacticId?: string) => ICampaignTacticTypes | null;
    updatedCampaignsTactics: IAddCampaignTactic[];
    tacticsType: ICampaignTacticTypes[];
    handleTacticSelectionChange: (
        e: React.SyntheticEvent<Element, Event>,
        newValue: ICampaignTacticTypes | null,
        row: IDimCampaign,
    ) => void;
    onTacticRowClick: (campaignId: string) => void;
    isSelected: boolean;
    labelId: string;
    isRowEdited: boolean;
    handleUndoItem: (campaignId: string) => void;
    handleSaveCampaignTactics: (isUniversalTacticApply: boolean, singleCampaignToSave?: IAddCampaignTactic) => void;
}

export const CampaignTacticsTableRow: FC<ICampaignTacticsTableRow> = ({
    row,
    getSelectedTactics,
    tacticsType,
    handleTacticSelectionChange,
    isSelected,
    labelId,
    onTacticRowClick,
    isRowEdited,
    handleUndoItem,
    updatedCampaignsTactics,
    handleSaveCampaignTactics,
}) => {
    const getTacticSelectionValue = (campaignId: string) => {
        const updatedCampaign = updatedCampaignsTactics.find((uc) => uc.campaignId === campaignId);
        if (updatedCampaign) {
            return getSelectedTactics(updatedCampaign.tacticId);
        }
        return getSelectedTactics(row.campaignTacticTypeId?.toString());
    };

    const [selectedTactic, setSelectedTactic] = useState<ICampaignTacticTypes | null>(
        getTacticSelectionValue(row.campaignId),
    );

    useEffect(() => {
        setSelectedTactic(getTacticSelectionValue(row.campaignId));
    }, [row.campaignId, updatedCampaignsTactics]);

    const handleAutocompleteChange = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: ICampaignTacticTypes | null,
    ) => {
        e.stopPropagation();
        setSelectedTactic(newValue);
        handleTacticSelectionChange(e, newValue, row);
    };

    const handleSingleCampaignSave = () => {
        handleSaveCampaignTactics(false, {
            campaignId: row.campaignId,
            tacticId: (selectedTactic?.id || "").toString(),
        });
    };

    return (
        <TableRow
            onClick={(e) => handleClickWithTextSelectionCheck(onTacticRowClick)(row.campaignId)}
            selected={isSelected || isRowEdited}
            aria-checked={isSelected || isRowEdited}
            tabIndex={-1}
            sx={{ cursor: "pointer" }}
            hover
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                />
            </TableCell>
            {CAMPAIGN_TACTICS_HEADER.map((header, i) => {
                return (
                    <TableCell key={i}>
                        {header.id === "channel" && row.channel ? (
                            <Stack gap="8px" direction="row" alignItems="center" justifyContent="flex-start">
                                <DataSourceAvatar
                                    className={imageContainerClass}
                                    programmaticName={row.channel.toLowerCase()}
                                />

                                <Typography variant="body2">{row.cleanChannelName}</Typography>
                            </Stack>
                        ) : (
                            row[header.id]
                        )}
                    </TableCell>
                );
            })}
            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <Stack direction="row" gap={2} alignItems="center">
                    <Autocomplete
                        sx={{ width: 250 }}
                        size="small"
                        options={tacticsType}
                        getOptionLabel={(option: ICampaignTacticTypes) => option.displayName}
                        value={selectedTactic}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={handleAutocompleteChange}
                    />
                    {isRowEdited && (
                        <>
                            <Button onClick={handleSingleCampaignSave}>Save</Button>
                            <IconButton sx={{ padding: 0 }} onClick={() => handleUndoItem(row.campaignId)}>
                                <SVGIconRenderer icon="undoIcon" title="Undo" />
                            </IconButton>
                        </>
                    )}
                </Stack>
            </TableCell>
        </TableRow>
    );
};
