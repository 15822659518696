import { FC } from "react";
import { Toolbar, Typography, alpha } from "@mui/material";
import { Button } from "@prescientai/component-library";

interface ICampaignInclusionTableToolbar {
    displayMsg: string;
    bulkCampaignSaveBtnAction: () => void;
}

export const CampaignInclusionTableToolbar: FC<ICampaignInclusionTableToolbar> = ({
    displayMsg,
    bulkCampaignSaveBtnAction,
}) => {
    return (
        <Toolbar
            sx={{
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }}
        >
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                {displayMsg}
            </Typography>
            <Button onClick={bulkCampaignSaveBtnAction} variant="outlined" sx={{ textTransform: "inherit" }}>
                Save
            </Button>
        </Toolbar>
    );
};
