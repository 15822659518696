import React from "react";
import { Button } from "@prescientai/component-library";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides } from "@mui/material/Button/Button";

interface ILoadingButton {
    loading?: boolean;
    text: string;
    variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>;
    color?: OverridableStringUnion<
        "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
        ButtonPropsColorOverrides
    >;
    className?: any;
    disabled?: boolean;
    loadingIndicator?: any;
    onClick(...args: any): any;
}

export const LoadingButton: React.FC<ILoadingButton> = ({
    variant = "contained",
    color = "primary",
    loading = false,
    text,
    onClick,
    className,
    disabled = false,
    loadingIndicator,
}) => {
    return (
        <Button
            variant={variant}
            color={color}
            onClick={onClick}
            className={`${className}`}
            sx={{ pointerEvents: loading ? "none" : "unset" }}
            disabled={disabled}
            data-cy="loading-button"
        >
            {loading && loadingIndicator}
            {!loading && text}
        </Button>
    );
};
