import React, { FC } from "react";
import { Box } from "@mui/system";
import { css } from "@emotion/css";

import { CustomChart } from "./CustomChart";
import { ReturnChart } from "./ReturnChart";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { Theme, useTheme } from "@mui/material";

export const useStylesForMetricCharts = () => ({
    boldText: css({
        fontWeight: "bold",
        letterSpacing: "1px",
    }),
    chartContainer: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
    }),
});

const useStylesForResponsiveMetricCharts = (theme: Theme) => ({
    boldText: css({
        fontWeight: "bold",
        letterSpacing: "1px",
    }),
    chartContainer: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
        },
    }),
});

interface IPerformanceTab {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    isResponsive?: boolean;
}

export const PerformanceTab: FC<IPerformanceTab> = ({ performanceCampaignData, isResponsive = false }) => {
    const theme = useTheme();
    const classes = isResponsive ? useStylesForResponsiveMetricCharts(theme) : useStylesForMetricCharts();

    return (
        <Box pt={3} mb={5} className={classes.chartContainer} id="performance-chart-details">
            <ReturnChart performanceCampaignData={performanceCampaignData} classes={classes} />
            <CustomChart performanceCampaignData={performanceCampaignData} classes={classes} />
        </Box>
    );
};
