import { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/system";
import * as echarts from "echarts";
import groupBy from "lodash/groupBy";
import { calculatePercentage } from "src/services/utils";

import { IDictionary } from "src/interfaces/IDictionary";
import { capitalizeFirstLetter, formatValue } from "src/services/utils";
import { FORMATS } from "src/enums/Formats";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { baseVsHaloTooltip, getHaloMetricLegend } from "src/services/homeDashboard/homePageTab";
import { CardLoader } from "../CardComponents/CardLoader";
import { getComparator, stableSort } from "src/utils/sort";
import {
    dataSourcesByProgrammaticNameSelector,
    selectDataSourceColor,
} from "src/reduxState/slices/supportedDataSourcesSlice";
import { store } from "src/reduxState/stores/store";
import { CHART_COLORS } from "src/consts/colors";
import { defaultRevenueSourceSelector } from "src/reduxState/slices/connectorsSlice";
import { MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE } from "src/consts/performancePaidPage/performancePaidPage";
import { AMAZON_SELLING_PARTNER_REVENUE } from "src/consts/targetRevenue";

interface IBaseVsHaloChart {
    seriesData: IChartItem[];
    isFetching: boolean;
    grpBy: string;
}

export const getSeriesData = (
    seriesData: IChartItem[] = [],
    metrics: IDictionary,
    grpBy: string,
    storeRevenue: string,
    getColors?: IDictionary,
) => {
    let targetObject: IDictionary = {};
    let chartData: any[];

    const allRevenueTotal = seriesData?.reduce((a, c) => a + c.value, 0) || 0;
    const grpBeMetricName = groupBy(seriesData, "metricName");

    Object.keys(grpBeMetricName)
        .sort()
        .map((key) => {
            targetObject = { ...targetObject, [key]: groupBy(grpBeMetricName[key], "target") };
        });

    if (grpBy === "baseVsHalo") {
        chartData = Object.entries(targetObject).flatMap(([key, valueArr]) => {
            const combinedData = Object.values(valueArr).flatMap((c) => c);
            return stableSort(
                combinedData.map((c: any) => ({
                    ...c,
                    seriesName: `${metrics[key].name}`,
                    color: metrics[key].color,
                })),
                getComparator("asc", "seriesName"),
            );
        });
    } else if (grpBy === "haloEffectBreakdown") {
        let targetObject: IDictionary = {};
        const grpByTargetChannelName = groupBy(seriesData, "targetChannelName");
        const organicByChannels = groupBy(grpByTargetChannelName.organic, "target");
        Object.keys(grpByTargetChannelName).forEach((key) => {
            if (key === "organic" && organicByChannels.hasOwnProperty(AMAZON_SELLING_PARTNER_REVENUE)) {
                Object.entries(organicByChannels).forEach(([organicChannelKey, organicData]) => {
                    const legendKey =
                        organicChannelKey === AMAZON_SELLING_PARTNER_REVENUE
                            ? "Organic search - Amazon selling partner"
                            : getHaloMetricLegend(storeRevenue)[key]?.name || key.replace("_", " ");

                    targetObject = {
                        ...targetObject,
                        [legendKey]: groupBy(organicData, "target"),
                    };
                });
            } else {
                targetObject = {
                    ...targetObject,
                    [key]: groupBy(grpByTargetChannelName[key], "target"),
                };
            }
        });

        chartData = Object.entries(targetObject).flatMap(([key, valueArr]) => {
            const targetData = Object.keys(valueArr).map((value) => {
                return valueArr[value].map((c: any) => ({
                    ...c,
                    seriesName: getHaloMetricLegend(storeRevenue)[key]?.name || key,
                }));
            });
            return stableSort(targetData, getComparator("asc", "seriesName"));
        });
    } else {
        chartData = Object.entries(targetObject).flatMap(([key, valueArr]) => {
            const targetData = Object.keys(valueArr).map((value) => {
                return valueArr[value].map((c: any) => ({
                    ...c,
                    seriesName: `${metrics[key].name} - ${capitalizeFirstLetter(
                        value.replace(/_/g, " ").replace("revenue", ""),
                    ).trim()}`,
                    color: metrics[key].color,
                }));
            });
            return stableSort(targetData, getComparator("asc", "seriesName"));
        });
    }

    const grp = groupBy(chartData.flat(1), "seriesName");

    return Object.keys(grp).map((s) => {
        const grpByName = groupBy(Object.values(grp).flat(1), "channelName");
        const channelGrp = groupBy(grp[s], "channelName");

        let data = Object.keys(channelGrp)
            .map((channel) => {
                const total = grpByName[channel].reduce((a, c) => a + c.value, 0) || 0;
                const subTotal = channelGrp[channel].reduce((a, c) => a + c.value, 0) || 0;
                return [calculatePercentage(subTotal, total), channel, subTotal, channelGrp[channel]];
            })
            .filter((d) => d[2]);

        const totalGrpData = grp[s].reduce((a, c) => a + c.value, 0);
        data = [...data, [calculatePercentage(totalGrpData, allRevenueTotal), "Total", totalGrpData, grp[s]]];

        return {
            name: s,
            type: "bar",
            stack: "revenue",
            data,
            barWidth: 50,
            color: getColors?.[s.toLowerCase()],
        };
    });
};

export const BaseVsHaloChart: FC<IBaseVsHaloChart> = ({ seriesData, isFetching, grpBy }) => {
    if (isFetching) {
        return <CardLoader height="100%" width="99%" />;
    }

    const getChartColor = useCallback((seriesName: string = "") => {
        return selectDataSourceColor(store.getState(), seriesName);
    }, []);

    let chartElement: any = null;

    const getColors: IDictionary = {
        ["base - amazon selling partner"]: getChartColor("amazon_selling_partner"),
        ["halo - amazon selling partner"]: CHART_COLORS["halo - amazon selling partner"],
        ["base - shopify"]: CHART_COLORS["base - shopify"],
        ["halo - shopify"]: CHART_COLORS["halo - shopify"],
    };

    const metrics: IDictionary = useMemo(
        () => ({
            first_order_revenue: {
                name: "Base",
            },
            second_order_revenue: {
                name: "Halo",
            },
        }),
        [grpBy],
    );

    const grpData = groupBy(seriesData, "channelName");
    const removedAllZeroValues = Object.keys(grpData).filter((key) => {
        if (!grpData[key].every((v) => v.value === 0)) {
            return grpData[key];
        }
    });

    const yAxisLabel = [...[...new Set(removedAllZeroValues)].sort().reverse(), "Total"];

    const dataSourcesByProgrammaticName = useSelector(dataSourcesByProgrammaticNameSelector);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const storeRevenue = MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[defaultRevenueSource] || "Store";
    const sortedSeriesData = stableSort(
        getSeriesData(seriesData, metrics, grpBy, storeRevenue, getColors),
        getComparator("asc", "name"),
    );

    const option = {
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "axis",
            confine: "true",
            axisPointer: {
                type: "shadow",
                label: {
                    show: false,
                },
            },
            formatter: (params: any) => baseVsHaloTooltip(dataSourcesByProgrammaticName, params, storeRevenue),
        },
        legend: {
            show: true,
            selectedMode: false,
        },
        grid: {
            right: 20,
            left: 5,
            top: 40,
            containLabel: true,
        },
        xAxis: {
            name: "Revenue",
            type: "value",
            splitLine: {
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisLabel: {
                hideOverlap: true,
                formatter: (value: number) => formatValue(value, FORMATS.PERCENT, 0),
            },
            nameLocation: "middle",
            nameTextStyle: {
                fontSize: 12,
                align: "center",
                verticalAlign: "top",
                lineHeight: 50,
            },
            max: 100,
        },
        yAxis: {
            type: "category",
            splitLine: {
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 15,
            },
            data: yAxisLabel,
        },
        series: sortedSeriesData,
    };

    const initChart = (data: IChartItem[]) => {
        if (document.getElementById("baseVsHaloChart")) {
            chartElement = echarts.init(document.getElementById("baseVsHaloChart")!, "prescient");
            if (chartElement) {
                chartElement.setOption({ ...option }, true, true);
                window.addEventListener("resize", chartResize);
            }
        }
    };

    const chartResize = () => {
        chartElement.resize();
    };

    useEffect(() => {
        initChart(seriesData);
        return () => {
            window.removeEventListener("resize", chartResize);
        };
    }, [JSON.stringify(seriesData), grpBy, storeRevenue]);

    return (
        <Stack style={{ height: "100%" }}>
            <Box id="baseVsHaloChart" style={{ minWidth: "99%", width: "99%", height: "100%" }}></Box>
        </Stack>
    );
};
