import { Chip, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface ITheTradeDeskModalContent {
    classes: any;
}

const steps = [
    "Log in to your Trade Desk account",
    "Retrieve the name of the report you created that contains daily updating data. Copy and paste that in to the Daily Data Report Name box",
    "Retrieve the name of the report you created that contains historical data. Copy and paste that in to the Historical Data Report Name box",
    "Enter your Trade Desk Login and Password",
    "Click Save & Test",
];

export const TheTradeDeskModalContent: FC<ITheTradeDeskModalContent> = ({ classes }) => {
    return (
        <>
            <Typography className={classes.heading}>Setup instructions</Typography>
            <List sx={{ listStyleType: "decimal", pl: 3, typography: "body1" }}>
                {steps.map((step) => {
                    return (
                        <ListItem sx={{ display: "list-item", overflowWrap: "break-word" }}>
                            <ListItemText primary={step} />
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};
