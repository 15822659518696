import { css } from "@emotion/css";
import pickBy from "lodash/pickBy";
import queryString from "query-string";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CreateCustomConnectorModal } from "src/components/CustomConnectorModal/CreateCustomConnectorModal";
import { IDictionary } from "src/interfaces/IDictionary";
import { selectDataSourceNameByProgrammaticName } from "src/reduxState/slices/supportedDataSourcesSlice";
import { isLegacyCustomConnector } from "src/services/connectors/connectors";
import { normalizeKeysWithUnderscores } from "src/services/utils";
import { keysToCamelCase } from "src/utils/format";
import {
    IEditConnectorBody,
    useEditConnectorMutation,
    useGetConnectorQuery,
    useLazyGetLegacyCustomConnectorsQuery,
    useLegacyCustomConnectorCredentialsAddMutation,
    useLegacyCustomConnectorCredentialsEditMutation,
} from "../../../../reduxState/apis/connectorsApi";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../../../reduxState/slices/organizationSlice";
import { AppLovinConnectorModalContent } from "./AppLovinConnectorModalContent";
import { GrinConnectorModalContent } from "./GrinConnectorModalContent";
import { KargoCtvModalContent } from "./KargoCtvModalContent";
import { MountainConnectorModalContent } from "./MountainConnectorModalContent";
import { NeonPixelConnectorModalContent } from "./NeonPixelConnectorModalContent";
import { PartnerizeAffiliateModalContent } from "./PartnerizeAffiliateModalContent";
import { TatariConnectorCredentialModalContent } from "./TatariConnectorCredentialModalContent";
import { TheTradeDeskModalContent } from "./TheTradeDeskModalContent";
import baseCreateApi from "src/reduxState/apis/baseCreateApi";

interface IProperty {
    type: string;
    isPassword?: boolean;
    [key: string]: string | boolean | undefined;
}

interface ISchema {
    type: string;
    required: string[];
    properties: Record<string, IProperty>;
}

const useStyles = () => ({
    heading: css({
        fontSize: "1.3rem",
        marginTop: "2rem",
        color: "black",
    }),
    info: css({ color: "rgba(0, 0, 0, 0.6)" }),
    link: css({ textDecoration: "none" }),
});

const isEmptyObject = (obj: Record<string, any> | undefined): boolean => {
    if (!obj) {
        return true;
    }

    if (Object.keys(obj).length === 0) {
        return true;
    }

    return Object.values(obj).every((value) => value == null || value === "");
};

const formatConfigData = (config: Record<string, any>, schema: ISchema) => {
    const formattedConfig = keysToCamelCase(config);
    const passwordKeys = Object.entries(schema.properties)
        .filter(([key, value]) => value.isPassword === true)
        .map(([key]) => key);

    for (const key of passwordKeys) {
        formattedConfig[key] = "";
    }

    return formattedConfig;
};

export const CustomConnectorCredentialForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const orgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const latestQueryParamsObj = queryString.parse(window.location.search);

    const {
        connectorName = "",
        connectorId = "",
        path = "",
    } = (latestQueryParamsObj as {
        connectorName?: string;
        connectorId?: string;
        path?: string;
    }) || {};

    const { data: connector, refetch } = useGetConnectorQuery({
        orgId,
        connectorId,
    });

    const [formData, setFormData] = useState<Record<string, any>>({});

    useEffect(() => {
        if (connector && connector.config) {
            setFormData(formatConfigData(connector.config, connector.dataSource.configSchema));
        }
    }, [connector]);

    const [legacyCustomConnectorCredentialsAdd, { isLoading }] = useLegacyCustomConnectorCredentialsAddMutation();
    const [legacyCustomConnectorCredentialsEdit] = useLegacyCustomConnectorCredentialsEditMutation();
    const [editConnector] = useEditConnectorMutation();
    const [getLegacyCustomConnectors, { data: legacyCustomConnectorIds, error }] =
        useLazyGetLegacyCustomConnectorsQuery();
    const isConfigSetAlready =
        (isLegacyCustomConnector(connectorName) && !!legacyCustomConnectorIds?.length) ||
        !isEmptyObject(connector?.config);

    useEffect(() => {
        if (isLegacyCustomConnector(connectorName)) {
            getLegacyCustomConnectors({
                orgId,
                connectorName: connectorName.includes("kargo") ? "kargo" : connectorName,
                connectorId,
            });
        }
    }, []);

    // @ts-ignore
    const formattedConnectorName = useSelector((state) => selectDataSourceNameByProgrammaticName(state, connectorName));
    const displayName = formattedConnectorName || connectorName;

    const submitCredentialsHandler = async (formData: any) => {
        isLegacyCustomConnector(connectorName)
            ? submitLegacyCustomConnectorCredentials(formData)
            : submitCustomConnectorCredentials(formData);
    };

    const submitLegacyCustomConnectorCredentials = async (formData: any) => {
        let apiBody: {
            api_key?: string;
            api_username?: string;
            api_password?: string;
            schema?: string;
        } = {
            api_key: formData.apiKey,
            api_username: formData.username,
            api_password: formData.password,
        };

        if (connectorName.includes("kargo")) {
            apiBody = {
                ...apiBody,
                schema: connectorName,
            };
        }

        const baseParams = {
            body: { ...pickBy(apiBody) },
            orgId,
            connectorName: connectorName.includes("kargo") ? "kargo" : connectorName,
            connectorId,
        };

        const apiResponse = isConfigSetAlready
            ? await legacyCustomConnectorCredentialsEdit({
                  ...baseParams,
                  customConnectorId: legacyCustomConnectorIds?.[0].id,
              })
            : await legacyCustomConnectorCredentialsAdd(baseParams);

        if (apiResponse) {
            navigate(path || `/org/${orgCode}/connectors`);
            refetch();
        }
    };

    const submitCustomConnectorCredentials = async (formData: any) => {
        const apiBody: IEditConnectorBody = {
            config: normalizeKeysWithUnderscores(formData),
        };

        const apiResponse = await editConnector({
            body: apiBody,
            orgId,
            connectorId,
        });
        if (apiResponse) {
            navigate(path || `/org/${orgCode}/connectors?connector_id=${connectorId}`);
            refetch();
        }
    };

    const handleFormDataChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    const kargo = {
        component: <KargoCtvModalContent classes={classes} displayName={displayName} />,
        prerequisites: `To connect Kargo, you need a Kargo API OKTA account. Contact your Kargo representative to help get this set up.`,
    };

    const tatari = {
        component: <TatariConnectorCredentialModalContent classes={classes} />,
        prerequisites: [
            "You must have a Tatari account.",
            "There may be a fee associated with the data transfer from Tatari. Please speak to your Tatari representative for more details.",
            "Your Tatari S3 reports may only be available for dates after Tatari enabled your S3 reporting feature, but you may request that they populate earlier data by reaching out to your Tatari representative. Prescient will automatically populate all available S3 report data.",
        ],
    };

    const theTradeDesk = {
        component: <TheTradeDeskModalContent classes={classes} />,
        prerequisites: [
            "You must have a Trade Desk account.",
            "You must execute an Access Agreement and an API Addendum with The Trade Desk. The Trade Desk manages the end-to-end execution of this paperwork. To begin the process, reach out to your Trade Desk Account Manager. Here are additional details on the two agreements:",
            [
                "Access Agreement - This is an agreement between you and The Trade Desk that allows our data provider Fivetran to access your data.",
                "API Addendum - This will enable API access for your Trade Desk account, which is required for us to access your data using Fivetran. There may be an up front cost attached to this addendum.",
            ],
            "You must create two reports within The Trade Desk that Prescient can use to ingest data.",
            [
                "Historical data report - This report should contain as much historical performance data as you can retrieve, up to two years. We recommend naming this report something along the lines of {your_orgs_name}_PRESCIENT_HISTORICAL_DATA_SINGLE_RUN (e.g. NIKE_PRESCIENT_HISTORICAL_DATA_SINGLE_RUN).",
                "Daily data report - This report should update daily, and should only include data from the previous day. We recommend naming this report something along the lines of {your_orgs_name}_PRESCIENT_YESTERDAY_RUN_ONLY_SINGLE_RUN (e.g. NIKE_PRESCIENT_YESTERDAY_RUN_ONLY_SINGLE_RUN).",
                "These reports should include the following metrics and grains: DATE, MEDIA_TYPE, DEVICE_TYPE, CAMPAIGN, CAMPAIGN_ID, IMPRESSIONS, CLICKS, ADVERTISTER_COST_USD, TOTAL_CLICK_VIEW_CONVERSIONS, and TOTAL_CLICK_VIEW_CONVERSION_REVENUE.",
            ],
        ],
    };

    const renderConnector: IDictionary = {
        partnerize_affiliate: {
            component: <PartnerizeAffiliateModalContent classes={classes} />,
            prerequisites: "You must have a Partnerize account.",
        },
        applovin: {
            component: <AppLovinConnectorModalContent classes={classes} displayName={displayName} />,
        },
        neonpixel: { component: <NeonPixelConnectorModalContent classes={classes} /> },
        mountain: { component: <MountainConnectorModalContent classes={classes} displayName={displayName} /> },
        grin: { component: <GrinConnectorModalContent classes={classes} displayName={displayName} /> },
        kargo_ctv: kargo,
        kargo_display: kargo,
        tatari_linear: tatari,
        tatari_streaming: tatari,
        the_trade_desk: theTradeDesk,
        keynes_digital: theTradeDesk,
    };

    return (
        <CreateCustomConnectorModal
            submitCredentialsHandler={submitCredentialsHandler}
            displayName={displayName}
            isLoading={isLoading}
            error={error}
            isConfigSetAlready={isConfigSetAlready}
            instructions={renderConnector[connectorName]}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
        >
            <Fragment>{renderConnector[connectorName]?.component}</Fragment>
        </CreateCustomConnectorModal>
    );
};
