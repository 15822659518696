import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import groupBy from "lodash/groupBy";
import { useContext } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";

import { FORMATS } from "../../../../../enums/Formats";
import { ISecondOrderEffects } from "../../../../../interfaces/entities/ISecondOrderEffects";
import { IMetricAttributionTableValuesTransformed } from "../../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { calculateROAS, formatValue } from "../../../../../services/utils";
import { HorizontalBarChart } from "../HorizontalBarChart";
import {
    getDefaultRevenueFromReveueArr,
    getHaloEffectBreakdownDataForRevenue,
    getKeyAttributionMetricsForRevenue,
} from "../attributionUtils";
import { PerformanceContext } from "src/containers/CampaignPageV2/CampaignPage";
import { IDictionary } from "src/interfaces/IDictionary";
import { PercentageTable } from "../../PerformanceTab/PercentageTable";
import { getPercentChangeInMetric } from "src/services/performancePage/performancePage";

export const RevenueChart = ({
    chartData,
    comparisonData,
    performanceCampaignData,
}: {
    chartData: ISecondOrderEffects[];
    comparisonData: ISecondOrderEffects[];
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
}) => {
    const performanceContextProps = useContext(PerformanceContext);
    const filterQueryParamsObj = queryString.parse(window.location.search);

    // Key attribution metric logic start
    const groupByMetricName = groupBy(chartData, "metricName");
    const groupByTarget = groupBy(chartData, "target");
    const defaultRevenue = getDefaultRevenueFromReveueArr(Object.keys(groupByTarget));

    const keyAttributionMetricsForRevenue: any[] = getKeyAttributionMetricsForRevenue(
        performanceCampaignData,
        groupByMetricName,
        defaultRevenue,
    );

    const totalRevenue = keyAttributionMetricsForRevenue.reduce((a, c) => a + c.metricValue, 0);
    const totalMetrics = [
        {
            name: `Total Revenue (${formatValue(
                calculateROAS(performanceCampaignData.spend, totalRevenue),
                FORMATS.NUMERIC,
                2,
            )} ROAS)`,
            value: formatValue(totalRevenue, FORMATS.DOLLAR, 0),
        },
    ];
    const keyAttributionMetricsForRevenueFormatted = {
        legendBoxArr: keyAttributionMetricsForRevenue,
        totalMetrics,
        allCalculatedValues: keyAttributionMetricsForRevenue.map((a) => ({
            name: a.key,
            value: a.metricValue,
        })),
        chartData,
        total: totalRevenue,
        id: `attribution-metrics-chart-${performanceCampaignData.campaignId}`,
    };
    // Key attribution metric logic end

    // past Key attribution metric logic start
    const keys = keyAttributionMetricsForRevenue.map((r) => r.name);
    const legends = keyAttributionMetricsForRevenue.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const pastGroupByMetricName = groupBy(comparisonData, "metricName");
    const pastGroupByTarget = groupBy(comparisonData, "target");
    const pastDefaultRevenue = getDefaultRevenueFromReveueArr(Object.keys(pastGroupByTarget));

    const pastKeyAttributionMetricsForRevenue: any[] = getKeyAttributionMetricsForRevenue(
        performanceCampaignData,
        pastGroupByMetricName,
        pastDefaultRevenue,
    );

    const pastLegends = pastKeyAttributionMetricsForRevenue.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const percentageData = getPercentChangeInMetric(legends, pastLegends);
    // past Key attribution metric logic end

    // Halo effect breakdown logic start
    const revenueHaloEffects = groupBy(chartData, "metricName").SECOND_ORDER_REVENUE;
    const haloEffectBreakdownDataForRevenue = getHaloEffectBreakdownDataForRevenue(revenueHaloEffects);

    const haloTotal = haloEffectBreakdownDataForRevenue.reduce((a, c) => a + c.metricValue, 0);
    const haloTotalMetrics = [
        {
            name: "Total Halo Effect",
            value: formatValue(haloTotal, FORMATS.DOLLAR, 0),
        },
    ];

    const haloEffectBreakdownDataForRevenueFormatted = {
        legendBoxArr: haloEffectBreakdownDataForRevenue,
        totalMetrics: haloTotalMetrics,
        allCalculatedValues: haloEffectBreakdownDataForRevenue.map((a) => ({
            name: a.key,
            value: a.metricValue,
        })),
        total: haloTotal,
        chartData,
        id: `halo-effect-chart-${performanceCampaignData.campaignId}`,
    };
    // Halo effect breakdown logic end

    // past Halo effect breakdown logic start
    const haloKeys = haloEffectBreakdownDataForRevenue.map((r) => r.name);
    const haloLegends = haloEffectBreakdownDataForRevenue.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const pastRevenueHaloEffects = groupBy(comparisonData, "metricName").SECOND_ORDER_REVENUE;
    const pastHaloEffectBreakdownDataForRevenue = getHaloEffectBreakdownDataForRevenue(
        pastRevenueHaloEffects,
    );

    const pastHaloLegends = pastHaloEffectBreakdownDataForRevenue.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const haloPercentageData = getPercentChangeInMetric(haloLegends, pastHaloLegends);
    // past Halo effect breakdown logic end

    const isDisplayTable =
        filterQueryParamsObj.comparison_start_date &&
        filterQueryParamsObj.comparison_end_date &&
        pastLegends &&
        legends;

    return (
        <>
            <Stack gap="20px" className="key-attribution-metrics">
                <Stack>
                    <Typography
                        variant="h2"
                        display="block"
                        gutterBottom
                        color="text.primary"
                        sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                    >
                        Key Attribution Metrics
                    </Typography>
                    <HorizontalBarChart
                        formattedData={keyAttributionMetricsForRevenueFormatted}
                        percentageData={percentageData}
                    />
                </Stack>
                {isDisplayTable && (
                    <PercentageTable
                        keys={keys}
                        pastLegendValues={pastLegends}
                        legendValues={legends}
                        metrics={[]}
                        id="attribution-chart"
                    />
                )}
            </Stack>
            <Stack gap="20px" className="halo-effect-metrics">
                <Stack>
                    <Typography
                        variant="h2"
                        display="block"
                        gutterBottom
                        color="text.primary"
                        sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                    >
                        Halo Effect Breakdown
                    </Typography>
                    <HorizontalBarChart
                        formattedData={haloEffectBreakdownDataForRevenueFormatted}
                        percentageData={haloPercentageData}
                    />
                </Stack>
                {isDisplayTable && (
                    <PercentageTable
                        keys={haloKeys}
                        pastLegendValues={pastHaloLegends}
                        legendValues={haloLegends}
                        metrics={[]}
                        id="attribution-chart"
                    />
                )}
            </Stack>
        </>
    );
};
