import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import groupBy from "lodash/groupBy";
import React, { memo, useEffect, useMemo, useState } from "react";

import { SearchField } from "src/components/SearchField/SearchField";
import { getSearchedCampaigns } from "src/services/optimizationPage/optimization";
import { calculateCAC, changeDate, recalculateSortDirection } from "src/services/utils";
import { XIncreaseByYPer } from "../../../../components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { Choice, SCENARIO_TYPE } from "../../../../consts/optimizationPage/optimizationPage";
import { ICampaignData } from "../../../../interfaces/entities/IScenario";
import { calculateROAS } from "../../../../services/utils";
import { IScenarioFromProps } from "../../formModel/addScenarioFormModel";
import { CampaignTable } from "./CampaignTable";
import { useDispatch, useSelector } from "react-redux";
import { selectFilterViewList, DEFAULT_STATE_FOR_SAVED_VIEW } from "src/reduxState/slices/filterViewSlice";
import { IDictionary } from "src/interfaces/IDictionary";
import { DynamicSavedFilterList } from "src/components/DynamicSavedFilterList/DynamicSavedFilterList";
import { LightMenu } from "src/components/LightMenu/LightMenu";
import { getFilteredPerformance } from "src/services/performancePage/performancePage";
import { DEFAULT_PERFORMANCE_FILTER_TITLE } from "src/consts/performancePaidPage/performancePaidPage";

const defaultFilterList = [
    {
        id: "defaultFilter",
        filters: "",
        name: DEFAULT_PERFORMANCE_FILTER_TITLE,
    },
    {
        id: "mmmRevenueFilter",
        filters: `filter[trueRevenue][gt]=0`,
        name: "Has MMM Revenue",
    },
    {
        id: "secondOrderEffectFilter",
        filters: `properties=has_halo_effects`,
        name: "Halo View",
    },
];

interface ICampaignSelectionExtraProps {
    filterChangeEvent: (timeframe: string | number, scenarioType: string, campaigns: ICampaignData) => void;
}

const CampaignSelection = (props: IScenarioFromProps & ICampaignSelectionExtraProps) => {
    const {
        classes,
        performanceData,
        setFieldValue,
        errors,
        values,
        isPerformanceLoading,
        touched,
        filterChangeEvent,
        lastDaysTooltip,
        performancePageData,
    } = props;

    const dispatch = useDispatch();

    const filterViewList = useSelector(selectFilterViewList);
    const childRef = React.useRef<React.ElementRef<typeof LightMenu>>(null);

    const [selectedCampaignList, setSelectedCampaigns] = useState<ICampaignData>(values.campaignData);
    const [searchCampaigns, setSearchCampaigns] = useState("");
    const [filterCampaigns, setFilterCampaigns] = useState<IDictionary>({});

    const [currentView, setCurrentView] = useState({ isSavedViewOn: false, data: { name: "", filters: "", id: "" } });

    useEffect(() => {
        setSelectedCampaigns(values.campaignData);
    }, [values.campaignData]);

    useEffect(() => {
        const selectedCampaignIds = selectedCampaignList.map((s: any) => s.campaignId);
        const selectedPerformances: any = performanceData.data.filter((p) =>
            selectedCampaignIds.includes(p.campaignId || ""),
        );

        const selectedTotalSpend = +selectedPerformances
            .reduce((a: number, p: { currentEstimatedSpend: any }) => a + +(p.currentEstimatedSpend || 0).toFixed(0), 0)
            .toFixed(0);

        // Limit budget movement to +10% for shift type
        const increasedTotalSpend = XIncreaseByYPer(10, selectedTotalSpend);
        if (
            values.scenarioType === SCENARIO_TYPE.SHIFT &&
            increasedTotalSpend < values.budget &&
            increasedTotalSpend > 0
        ) {
            setFieldValue("scenarioType", SCENARIO_TYPE.SCALE);
        }
    }, [values.budget, values.campaignData, performanceData.data]);

    useEffect(() => {
        setSelectedCampaigns(selectedCampaignList);
        setFieldValue("campaignData", selectedCampaignList);
    }, [selectedCampaignList]);

    useEffect(() => {
        setFieldValue("campaignData", []);
        setSelectedCampaigns([]);
    }, [currentView.data.name]);

    const [openChannelList, setOpenChannelList] = useState("");

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("trueRevenue");
    const channelGrp = useMemo(() => groupBy(performanceData.data, "connectorName"), [performanceData.data]);
    const eligibleCampaigns = performanceData.data;

    useEffect(() => {
        setOrderBy(values.scenarioType === SCENARIO_TYPE.CAC ? "newCustomers" : "trueRevenue");
    }, [values.scenarioType]);

    const searchedCampaignData = useMemo(() => {
        const searchedCampaigns = getSearchedCampaigns(performanceData.data, searchCampaigns);

        const excludedTactics = Array.isArray(filterCampaigns.excluded_tactics)
            ? filterCampaigns.excluded_tactics.join(",")
            : filterCampaigns.excluded_tactics || "";

        const filteredCampaigns = getFilteredPerformance(performancePageData, "", {
            ...filterCampaigns,
            excluded_tactics: excludedTactics,
        });

        const filteredCampaignIds = new Set(filteredCampaigns.map((campaign: any) => campaign.campaignId));

        return searchedCampaigns.filter((campaign: any) => filteredCampaignIds.has(campaign.campaignId));
    }, [performancePageData, searchCampaigns, filterCampaigns, values.scenarioType, performanceData.data]);

    const searchedChannelGrp = useMemo(() => groupBy(searchedCampaignData, "connectorName"), [searchedCampaignData]);

    const channelGroupData = useMemo(() => {
        // make channel group data with particular campaign belonging
        return Object.entries(searchedChannelGrp).map(([channel, campaigns]) => {
            const spend = campaigns.reduce((prev, curr) => prev + (curr.spend ? curr.spend : 0), 0);
            const trueRevenue = campaigns.reduce((prev, curr) => prev + (curr.trueRevenue ? curr.trueRevenue : 0), 0);
            const trueRoas = calculateROAS(spend, trueRevenue);
            const currentEstimatedDailySpend = campaigns.reduce(
                (prev, curr) => prev + +(curr.currentEstimatedDailySpend || 0).toFixed(0),
                0,
            );
            const currentEstimatedSpend = campaigns.reduce(
                (prev, curr) => prev + +(curr.currentEstimatedSpend || 0).toFixed(0),
                0,
            );

            const findLockedCampaign = values.campaignData.map((campaign) => campaign.campaignId);
            const campaignsWithLock = campaigns.map((c) => ({
                ...c,
                isLocked: findLockedCampaign.includes(c.campaignId || "")
                    ? values.campaignData.find((vc) => vc.campaignId === c.campaignId)?.isLocked
                    : false,
            }));
            const newCustomers = campaigns.reduce((prev, curr) => prev + Math.max(curr.newCustomers || 0, 0), 0);
            const cac = calculateCAC(spend, newCustomers);

            return {
                channelName: channel,
                connectorName: campaigns[0].connectorName || "",
                spend,
                trueRevenue,
                trueRoas,
                campaigns: campaignsWithLock,
                cleanChannelName: campaigns[0].channelName || "",
                currentEstimatedDailySpend,
                currentEstimatedSpend,
                isLocked: campaignsWithLock.every((c) => c.isLocked),
                newCustomers,
                cac,
            };
        });
    }, [searchedChannelGrp]);

    // set form budget, revenue data as per selected campaigns
    const updateScenarioInputs = (list: ICampaignData) => {
        const selectedCampaignsId = list.map((s: any) => s.campaignId);
        const selectedPerformances: any = performanceData.data.filter((p) =>
            selectedCampaignsId.includes(p.campaignId || ""),
        );

        setFieldValue(
            "revenue",
            +selectedPerformances
                .reduce(
                    (prev: any, curr: { trueRevenue: any }) =>
                        prev + (curr.trueRevenue < 0 ? 0 : curr.trueRevenue || 0),
                    0,
                )
                .toFixed(0),
        );
        setFieldValue(
            "budget",
            +selectedPerformances
                .reduce(
                    (prev: any, curr: { currentEstimatedSpend: any }) => prev + +(curr.currentEstimatedSpend || 0),
                    0,
                )
                .toFixed(0),
        );
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSearchCampaigns(value);
    };

    const searchedChannelGroupData = useMemo(() => {
        return channelGroupData
            .map((channel) => ({
                ...channel,
                campaigns: getSearchedCampaigns(channel.campaigns, searchCampaigns),
            }))
            .filter((channel) => channel.campaigns.length > 0);
    }, [channelGroupData, searchCampaigns]);

    useEffect(() => {
        if (!isPerformanceLoading) {
            const availableCampaignIds = searchedChannelGroupData.flatMap((channel) =>
                channel.campaigns.flatMap((c) => c.campaignId),
            );
            setSelectedCampaigns(selectedCampaignList.filter((c) => availableCampaignIds.includes(c.campaignId)));
        }
    }, [searchedChannelGroupData]);

    const handleSavedViewExitClick = (event: any) => {
        event.stopPropagation();
        setCurrentView({
            isSavedViewOn: false,
            data: DEFAULT_STATE_FOR_SAVED_VIEW,
        });
        setFilterCampaigns({});
    };

    const handleSavedViewClick = ({ name, filters, id }: { name: string; filters: string; id: string }) => {
        setCurrentView({ isSavedViewOn: true, data: { name, filters, id } });
        const queryObject: IDictionary = Array.from(new URLSearchParams(filters)).reduce((acc, [key, value]) => {
            acc[key] = value.includes(",") ? value.split(",") : value;
            return acc;
        }, {} as IDictionary);
        setFilterCampaigns(queryObject);
        if (childRef.current) {
            childRef.current.closeDialog();
        }
    };

    return (
        <Stack gap="20px">
            <Typography variant="h2" fontWeight="700">
                Campaign Selection
            </Typography>
            <Stack gap="20px" direction="row" alignItems="center">
                <SearchField placeholder={`Search campaign(s)`} onChange={handleChange} value={searchCampaigns} />
                <DynamicSavedFilterList
                    isSavedViewOn={currentView.isSavedViewOn}
                    currentFilterView={currentView}
                    childRef={childRef}
                    defaultFilterList={defaultFilterList}
                    savedFilterList={filterViewList}
                    actionList={[]}
                    handleMenuClick={({ name, filters, id }) => handleSavedViewClick({ name, filters, id })}
                    exitViewHandler={handleSavedViewExitClick}
                    id="campaign_saved_views"
                    dataCy="campaignSavedViews"
                />
            </Stack>
            <CampaignTable
                channelTableData={searchedChannelGroupData}
                channelGrp={searchedChannelGrp}
                openChannelList={openChannelList}
                setOpenChannelList={setOpenChannelList}
                classes={classes}
                errors={errors}
                touched={touched}
                handleRequestSort={handleRequestSort}
                orderBy={orderBy}
                order={order}
                selectedCampaigns={selectedCampaignList}
                setSelectedCampaigns={setSelectedCampaigns}
                values={values}
                isLoading={isPerformanceLoading}
                updateScenarioInputs={updateScenarioInputs}
                eligibleCampaigns={eligibleCampaigns}
                filterChangeEvent={filterChangeEvent}
                lastDaysTooltip={lastDaysTooltip}
            />
            {performanceData.meta.spendDate ? (
                <Typography variant="subtitle1" color="text.secondary">
                    No change spend is based on your reported spend on {changeDate(performanceData.meta.spendDate)} for
                    each campaign
                </Typography>
            ) : null}
        </Stack>
    );
};

export default memo(CampaignSelection);
