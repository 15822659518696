import { Button } from "@prescientai/component-library";
import { css } from "@emotion/css";
import { Box, ButtonGroup, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ConnectorTable } from "src/components/ConnectorV3/ConnectorsTable/ConnectorTable";
import { FixedConnectorModal } from "src/components/ConnectorsComponent/Modals/FixedConnectorModal";
import { IDataSourceWithConnected } from "src/interfaces/IData";
import { isDuplicateConnector } from "src/services/onboardingPage/onboardingPage";
import noConnectors from "../../assets/noConnectors.png";
import wallet from "../../assets/wallet.png";
import { Message } from "../../components/Message/Message";
import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import Loader from "../../components/core/Loader/Loader";
import { CONNECTORS_PATH, NEW_CONNECTOR_PATH } from "../../consts/path/path";
import { IConnector } from "../../interfaces/IConnector";
import { STATUS_MAPPING, useLazyGetConnectorsQuery } from "../../reduxState/apis/connectorsApi";
import { connectorsSelector } from "../../reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { supportedDataSourcesSelector } from "../../reduxState/slices/supportedDataSourcesSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getConnectorsChipsValues, getFilters, getPreparedConnectors } from "../../services/connectors/connectors";
import { getPathForAnalytics } from "../../services/utils";
import { orgSpecificCleanDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesByOrgSlice";

const useStyles = () => ({
    container: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "32px",
    }),
});

export enum ConnectorView {
    YOUR_CONNECTORS = "your_connectors",
    AVAILABLE_CONNECTORS = "available_connectors",
    ALL = "all",
}

interface IConnectorsListingPageProps {
    openRespectiveConfirmModal: (connector: IConnector, action: string) => void;
    editConnector: (connector: IConnector) => void;
    handleCreateConnectorWithFivetran: (service: IDataSourceWithConnected) => Promise<void>;
    isConnectionInProgress: boolean;
}

const ALL_CONNECTORS_SLUG = "all";

export const ConnectorsListingPage: React.FC<IConnectorsListingPageProps> = ({
    openRespectiveConfirmModal,
    editConnector,
    handleCreateConnectorWithFivetran,
    isConnectionInProgress,
}) => {
    const connectors = useSelector(connectorsSelector);

    const parsePathname = useCallback(() => {
        if (location.pathname.includes(NEW_CONNECTOR_PATH)) {
            return ConnectorView.AVAILABLE_CONNECTORS;
        } else if (location.pathname.includes(ALL_CONNECTORS_SLUG)) {
            return ConnectorView.ALL;
        } else if (connectors.length === 0) {
            return ConnectorView.AVAILABLE_CONNECTORS;
        } else {
            return ConnectorView.YOUR_CONNECTORS;
        }
    }, [location.pathname]);

    const classes = useStyles();
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<number[]>([0]);
    const [selectedView, setSelectedView] = useState<ConnectorView>(parsePathname());

    const [getConnectors, { isLoading }] = useLazyGetConnectorsQuery();

    const orgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const analyticsService = useAnalyticsService();
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const availableDataSources = useSelector(orgSpecificCleanDataSourcesSelector);

    const searchParam = useLocation().search;
    const [fixedConnector, setFixedConnector] = useState<string | null>(null);

    useEffect(() => {
        getConnectors(orgId).then((res) => {
            const connectors = res.data;
            if (searchParam && connectors?.length) {
                const params = new URLSearchParams(searchParam);
                const id = params.get("id");
                const newConnector = connectors.filter((connector: any) => connector.fivetranId === id);

                if (newConnector.length) {
                    const reconnectConnector = localStorage.getItem("reconnectConnector");
                    analyticsService.logEvent(`Connector ${reconnectConnector ? "Reconnected" : "Created"} `, {
                        Page: getPathForAnalytics(window.location.pathname),
                        "Connector categories": newConnector[0].dataSource.categories,
                        "Connector name": newConnector[0].connectorName,
                    });
                }
                localStorage.removeItem("reconnectConnector");
                navigate(`/org/${orgCode}${CONNECTORS_PATH}`, { replace: true });

                const sentFromEmail =
                    params.get("utm_medium") === "email" && params.get("utm_source") === "broken_connector_email";
                const brokenConnectorId = params.get("connector_id");
                const fixed = connectors.find(
                    (connector) =>
                        connector.id.toString() === brokenConnectorId && connector.status !== STATUS_MAPPING.broken,
                );
                if (sentFromEmail && brokenConnectorId && fixed) {
                    setFixedConnector(fixed.connectorName);
                }
            }
        });
    }, [orgId]);

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const handleChangeFilterValue = (filterValue: number[]) => {
        setFilter(filterValue);
    };

    const availableDataSourcesFormatted = availableDataSources
        .map((dataSource) => {
            return {
                ...dataSource,
                status: "incomplete",
                connectorName: dataSource.name,
                dataSource,
                isConnected: false,
            };
        })
        .filter((dataSource) => !isDuplicateConnector(connectors, dataSource));

    const connectedConnectors = connectors.map((c: IConnector) => ({ ...c, isConnected: true }));

    const data: {
        [key in ConnectorView]: any[];
    } = {
        [ConnectorView.YOUR_CONNECTORS]: connectedConnectors,
        [ConnectorView.AVAILABLE_CONNECTORS]: availableDataSourcesFormatted,
        [ConnectorView.ALL]: [...connectedConnectors, ...availableDataSourcesFormatted],
    };

    const filteredConnectors = getPreparedConnectors(data[selectedView], search, filter, supportedDataSources);

    const createNewConnHandler = useCallback(() => {
        navigate(`/org/${orgCode}${NEW_CONNECTOR_PATH}`);
    }, []);

    useEffect(() => {
        if (connectors.length === 0) {
            // returns to new connector page if last connector is deleted
            setSelectedView(ConnectorView.AVAILABLE_CONNECTORS);
            navigate(`/org/${orgCode}${NEW_CONNECTOR_PATH}`, { replace: true });
        }
    }, [connectors.length]);

    const handleConnectorGroupBtnClick = (e: any) => {
        if (e.target.disabled !== false) {
            return;
        }
        switch (e.target.value) {
            case ConnectorView.YOUR_CONNECTORS:
                navigate(`/org/${orgCode}${CONNECTORS_PATH}`, { replace: true });
                break;
            case ConnectorView.AVAILABLE_CONNECTORS:
                navigate(`/org/${orgCode}${NEW_CONNECTOR_PATH}`, { replace: true });
                break;
            case ConnectorView.ALL:
                navigate(`/org/${orgCode}${CONNECTORS_PATH}/${ALL_CONNECTORS_SLUG}`, { replace: true });
                break;
        }

        setSelectedView(e.target.value);
    };

    return (
        <div className={classes.container}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Typography variant="h2">Connectors</Typography>
                    </Box>
                    <SubHeader
                        onSearchChange={handleSearchChange}
                        onChipsChange={handleChangeFilterValue}
                        title={"Connectors"}
                        chosenItemsLength={0}
                        chipsValues={getConnectorsChipsValues(
                            data[selectedView as keyof typeof data],
                            supportedDataSources,
                        )}
                        itemsLength={filteredConnectors && filteredConnectors.length ? filteredConnectors.length : 0}
                        isEventPerform={false}
                        marginBottom={false}
                        showCount={false}
                        extraComponent={
                            <ButtonGroup
                                variant="outlined"
                                aria-label="Basic button group"
                                onClick={(e) => handleConnectorGroupBtnClick(e)}
                                sx={{ " .MuiButtonBase-root": { boxShadow: "none" } }}
                            >
                                <Button
                                    value={ConnectorView.ALL}
                                    variant={selectedView === ConnectorView.ALL ? "contained" : "outlined"}
                                >
                                    All
                                </Button>
                                <Button
                                    value={ConnectorView.YOUR_CONNECTORS}
                                    variant={selectedView === ConnectorView.YOUR_CONNECTORS ? "contained" : "outlined"}
                                    data-cy="myConnectorsBtn"
                                    disabled={connectors.length === 0}
                                >
                                    Your Channels ({connectors.length})
                                </Button>
                                <Button
                                    value={ConnectorView.AVAILABLE_CONNECTORS}
                                    variant={
                                        selectedView === ConnectorView.AVAILABLE_CONNECTORS ? "contained" : "outlined"
                                    }
                                    data-cy="newConnectorBtn"
                                >
                                    Available Channels ({availableDataSourcesFormatted.length})
                                </Button>
                            </ButtonGroup>
                        }
                        chipUpdated={true}
                    />
                    {!filteredConnectors.length ? (
                        isLoading ? (
                            <Loader />
                        ) : (
                            <Message
                                title={
                                    search
                                        ? "We didn’t find anything "
                                        : "You don’t have any data sources connected yet  "
                                }
                                subtitle={
                                    search
                                        ? "Please make sure you spelled everything correctly or try a different search term."
                                        : "Click the picture above to connect your first source!"
                                }
                                icon={search ? noConnectors : wallet}
                                filters={search ? getFilters(filter) : undefined}
                                onIconClick={!search ? createNewConnHandler : undefined}
                            />
                        )
                    ) : (
                        <>
                            <ConnectorTable
                                connectors={filteredConnectors}
                                openRespectiveConfirmModal={openRespectiveConfirmModal}
                                editClick={editConnector}
                                selectedView={selectedView}
                                isListingPage={true}
                                handleCreateConnectorWithFivetran={handleCreateConnectorWithFivetran}
                                isConnectionInProgress={isConnectionInProgress}
                            />
                            <FixedConnectorModal
                                isFixedModalOpen={fixedConnector !== null}
                                connectorName={fixedConnector}
                                handleModalClose={() => setFixedConnector(null)}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
