import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Checkbox, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Stack } from "@mui/system";
import { snakeCase, startCase } from "lodash";
import React, { useMemo, useState } from "react";
import { ICustomConnectorFormData } from "src/interfaces/ICustomConnectorFormData";
import { IConfigSchema } from "src/interfaces/IData";

interface IGeneratedCredentialFormProps {
    configSchema: IConfigSchema;
    formData: ICustomConnectorFormData;
    isConfigSetAlready: boolean;
    handleFormDataChange: (e: any) => void;
}

function getTitleFromPropertyName(str: string) {
    return startCase(str)
        .split(" ")
        .map((word) => (word.toLowerCase() === "api" ? "API" : word)) // so that API is in all caps
        .join(" ");
}

const placeholderText = "[unchanged]";

export const GeneratedCredentialForm: React.FC<IGeneratedCredentialFormProps> = ({
    configSchema,
    formData,
    isConfigSetAlready,
    handleFormDataChange,
}) => {
    return configSchema.properties ? (
        <Stack gap={3}>
            {Object.keys(configSchema.properties).map((propertyName) => {
                const property = useMemo(() => configSchema.properties[propertyName], [propertyName]);
                const title = getTitleFromPropertyName(propertyName);
                const formValue = formData[propertyName];
                const isRequired = configSchema.required.includes(snakeCase(propertyName));
                const [showPassword, setShowPassword] = useState(false);

                const handleToggleShowPassword = () => setShowPassword(!showPassword);

                return (
                    <Stack key={propertyName}>
                        <InputLabel sx={{ color: "black" }} required={isRequired}>
                            {title}
                        </InputLabel>
                        {property.type === "boolean" ? (
                            <Checkbox
                                checked={Boolean(formValue) || false}
                                onChange={(e) =>
                                    handleFormDataChange({
                                        target: { name: propertyName, value: e.target.checked },
                                    })
                                }
                                sx={{ marginTop: "10px", maxWidth: "max-content" }}
                                data-cy={`${propertyName}_checkbox`}
                            />
                        ) : (
                            <OutlinedInput
                                name={propertyName}
                                type={property.isPassword && !showPassword ? "password" : "text"}
                                value={formValue || ""}
                                placeholder={isConfigSetAlready ? placeholderText : `Enter ${title}`}
                                onChange={handleFormDataChange}
                                required={isRequired}
                                autoComplete="off"
                                data-cy={propertyName}
                                endAdornment={
                                    property.isPassword && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle field visibility"
                                                onClick={handleToggleShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                                sx={{ maxWidth: "400px", marginTop: "10px" }}
                            />
                        )}
                    </Stack>
                );
            })}
        </Stack>
    ) : (
        <Stack>
            <InputLabel sx={{ color: "black" }}>API Key</InputLabel>
            <OutlinedInput
                name="apiKey"
                autoComplete="off"
                value={formData.apiKey || ""}
                placeholder={isConfigSetAlready ? placeholderText : "Enter API Key"}
                onChange={handleFormDataChange}
                sx={{ maxWidth: "400px", marginTop: "10px" }}
            />
        </Stack>
    );
};
