import { createSlice, createSelector } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";
import { DEFAULT_PERFORMANCE_FILTER_TITLE } from "src/consts/performancePaidPage/performancePaidPage";

interface IFilterView {
    name: string;
    filters: string;
    id: string;
}

export const DEFAULT_STATE_FOR_SAVED_VIEW = {
    id: "defaultFilter",
    filters: "",
    name: DEFAULT_PERFORMANCE_FILTER_TITLE,
};

const initialState: {
    list: IFilterView[];
    currentView: { isSavedViewOn: boolean; data: IFilterView };
} = {
    list: [],
    currentView: {
        isSavedViewOn: false,
        data: DEFAULT_STATE_FOR_SAVED_VIEW,
    },
};

const filterViewSlice = createSlice({
    name: "filterView",
    initialState,
    reducers: {
        setFilterView: (state, { payload }: { payload: { isSavedViewOn: boolean; data: IFilterView } }) => {
            state.currentView = payload;
        },
        setFilterList: (state, { payload }: { payload: IFilterView[] }) => {
            state.list = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const { setFilterView, setFilterList } = filterViewSlice.actions;

export default filterViewSlice.reducer;

// Selectors
export const filterViewSelector = (state: RootState) => state.filterView;

export const selectCurrentFilterView = createSelector(filterViewSelector, (data) => data.currentView);
export const selectFilterViewList = createSelector(filterViewSelector, (data) => data.list);
export const selectIsSavedViewOn = createSelector(filterViewSelector, (data) => data.currentView.isSavedViewOn);

export const isHaloViewSelected = createSelector(
    filterViewSelector,
    (data) => data.currentView.data.id === "secondOrderEffectFilter",
);
