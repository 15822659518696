import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography, Dialog, useTheme } from "@mui/material";
import { Button } from "@prescientai/component-library";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import differenceWith from "lodash/differenceWith";
import uniqueId from "lodash/uniqueId";

import { FORMATS } from "../../enums/Formats";
import {
    IScenario,
    IScenarioFormattedJobs,
    MetricRate,
    ScenarioOutcomeTab,
    Views,
} from "../../interfaces/entities/IScenario";
import { calculateROAS, formatValue, getPathForAnalytics } from "../../services/utils";
import {
    FORECAST_JOB,
    OPTIMIZATION_JOB,
    getPerformanceDataForNoChange,
    getPerformanceDataWithAllocationData,
    getReportingDateObj,
} from "../../services/optimizationPage/optimization";
import { TIMEFRAME_OPTIONS } from "../../consts/optimizationPage/optimizationPage";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import Loader from "../../components/core/Loader/Loader";
import {
    IScenarioCampaignBody,
    useEditScenarioCampaignsMutation,
    useRevertPastJobMutation,
    useLazyGetScenarioByIdQuery,
    useOptimizeScenarioAllocationMutation,
    usePollingScenarioJobMutation,
    useEditScenarioMutation,
} from "../../reduxState/apis/optimizationApi";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import {
    getReadableJobError,
    isScenarioEditableSelector,
    setCurrentlyViewingScenario,
} from "../../reduxState/slices/scenarioSlice";
import { useLazyGetPerformancePageQuery } from "../../reduxState/apis/performancePageApi";
import { getPaidDataSources } from "../../consts/performancePaidPage/performancePaidPage";
import { OPTIIMIZATION_PATH } from "../../consts/path/path";
import { TransitionComponent } from "../../components/core/TransitionComponent/TransitionComponent";
import { Message } from "../../components/Message/Message";
import { ScenaioAppBar } from "../../components/Optimization/AppBar/ScenaioAppBar";
import { ScenarioFooter } from "../../components/Optimization/ScenarioFooter/ScenarioFooter";
import noConnectors from "../../assets/noConnectors.png";
import { LeftDrawer } from "../../components/Optimization/ScenarioOutcome/ScenarioOutcomeMain/LeftDrawer";
import { AllocationHistory } from "../../components/Optimization/ScenarioOutcome/AllocationHistory";
import { OutcomeContent } from "../../components/Optimization/ScenarioOutcome/ScenarioOutcomeMain/OutcomeContent";
import { IMetricAttributionTableValuesTransformed } from "src/interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IJob } from "src/interfaces/entities/IJob";
import underConstruction from "src/assets/underConstruction.png";
import { useLazyGetDimCampaignQuery } from "src/reduxState/apis/dimCampaignAPi";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { IDataSource } from "src/interfaces/IData";
import { IDictionary } from "src/interfaces/IDictionary";
import { store } from "src/reduxState/stores/store";

interface IOptimizationOutcomeContext {
    metricRate: MetricRate;
    handleMetricRateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedScenario: IScenario;
    setSelectedScenario: React.Dispatch<React.SetStateAction<IScenario | null>>;
}

export const OptimizationOutcomeContext = React.createContext<IOptimizationOutcomeContext | null>(null);

export const OptimizationOutcomePage = () => {
    const theme = useTheme();
    const analyticsService = useAnalyticsService();
    const navigate = useNavigate();
    const { scenarioId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const isScenarioEditable = useSelector(isScenarioEditableSelector);
    const supportedDataSources: IDataSource[] = useSelector(supportedDataSourcesSelector) || [];

    const [isAllocationError, setIsAllocationError] = useState<boolean>(false);
    const [isRecommendationsModalOpen, setRecommendationsModalOpen] = useState<boolean>(false);
    const [budgetInput, setBudgetInput] = useState(0);
    const [selectedScenario, setSelectedScenario] = useState<IScenario | null>(null);
    const [isRecalculateScenarioDisabled, setIsRecalculateScenarioDisabled] = useState(false);
    const [isPollingAPi, setIsPollingAPi] = useState(false);
    const [isScenarioNotFound, setScenarioNotFound] = useState(false);
    const [isScenarioPrivate, setScenarioPrivate] = useState(false);
    const [currentJobId, setCurrentJobId] = useState("");
    const [originalJobId, setOriginalJobId] = useState("");
    const [scenarioEventLogs, setScenarioEventLogs] = useState<any[]>([]);
    const [compareTo, setCompareTo] = useState<Views>(Views.NoChange);
    const [noChangeJobList, setNoChangeJobList] = useState<IScenarioFormattedJobs[]>([]);
    const [metricRate, setMetricRateValue] = useState(MetricRate.Total);
    const [performanceData, setPerformanceData] = useState<IMetricAttributionTableValuesTransformed[]>([]);
    const [isPerformanceDataFetched, setIsPerformanceDataFetched] = useState(false);
    const [historyJobError, setHistoryJobError] = useState<string>("");
    const params = new URLSearchParams(useLocation().search);
    const tabByUrl =
        params.get("tab") === ScenarioOutcomeTab.Tracking ? ScenarioOutcomeTab.Tracking : ScenarioOutcomeTab.Overview;
    const [tabValue, setTabValue] = useState<ScenarioOutcomeTab>(tabByUrl);

    const [editScenario] = useEditScenarioMutation();
    const [getScenarioById, { isLoading: isSingleScenarioLoading }] = useLazyGetScenarioByIdQuery();
    const [getPerformancePage, { data: last30DaysPerformance = { data: [] }, isLoading, isFetching }] =
        useLazyGetPerformancePageQuery();
    const [pollingScenarioJob] = usePollingScenarioJobMutation();
    const [editScenarioCampaigns, { isLoading: isBulkLoading }] = useEditScenarioCampaignsMutation();
    const [optimizeScenarioAllocation] = useOptimizeScenarioAllocationMutation();
    const [revertPastJob] = useRevertPastJobMutation();
    const [getDimCampaign, { data: allCampaignData, isFetching: isCampaignFetching }] = useLazyGetDimCampaignQuery();

    const isPerformanceLoading = isLoading || isFetching;
    const isLastestScenario = selectedScenario?.activeJobId === originalJobId;

    const compareToOption = [
        {
            lable: "No Change",
            value: Views.NoChange,
            disabled: Object.keys(selectedScenario?.noChangeJob || {}).length === 0,
        },
        { lable: `Last ${selectedScenario?.forecastTimeframe} Days`, value: Views.LastDays },
    ];

    const getLogsDetails = (jobs: IScenarioFormattedJobs[], timeframe: TIMEFRAME_OPTIONS) => {
        if (jobs.length) {
            const logs: any[] = [];

            jobs.forEach((job, index) => {
                const totalAllocation = job.campaigns.reduce(
                    (accumulator, campaign) => accumulator + (campaign.budgetAllocated || 0),
                    0,
                );

                if (job.jobName === OPTIMIZATION_JOB) {
                    const isInitialJob = index === 0;
                    const isModelSuccess = job.metadata.forecastModelSuccess && job.metadata.allocationModelSuccess;

                    const logMsg = isModelSuccess ? (
                        <Typography>
                            User{" "}
                            <span
                                style={{ color: isInitialJob ? theme.palette.success.main : theme.palette.info.main }}
                            >
                                {isInitialJob ? "created" : "edited"}{" "}
                            </span>
                            total budget input: {formatValue(+totalAllocation * timeframe, FORMATS.DOLLAR, 0)}
                        </Typography>
                    ) : (
                        <Typography>
                            Allocation <span style={{ color: "rgb(246, 104, 94)" }}>error</span>
                        </Typography>
                    );

                    logs.unshift({ msg: logMsg, ...job });
                }

                if (job.jobName === FORECAST_JOB) {
                    const prevJob = jobs[index - 1];
                    const diff = differenceWith(
                        job.campaigns,
                        prevJob ? prevJob.campaigns : [],
                        (campaign1, campaign2) => campaign1.budgetAllocated === campaign2.budgetAllocated,
                    );

                    const isModelSuccess = job.metadata?.forecastModelSuccess;

                    const logMsg = isModelSuccess ? (
                        <Typography>
                            User <span style={{ color: theme.palette.info.main }}>edited</span> {diff.length}{" "}
                            allocations: {formatValue(+totalAllocation * timeframe, FORMATS.DOLLAR, 0)}
                        </Typography>
                    ) : (
                        <Typography>
                            Modeling <span style={{ color: "rgb(246, 104, 94)" }}>error</span>
                        </Typography>
                    );

                    logs.unshift({ msg: logMsg, ...job });
                }
            });
            setScenarioEventLogs(logs);
        }
    };

    const getCurrentScenario = useCallback((callback?: (r: any) => void) => {
        if (scenarioId) {
            getScenarioById({ orgId: currentOrgId, scenarioId })
                .unwrap()
                .then((response) => {
                    if (response) {
                        const scenario = { ...response.scenario };
                        setIsAllocationError(!!scenario?.allocationError);
                        setSelectedScenario(scenario);
                        setCurrentJobId(scenario.activeJobId || "");
                        setOriginalJobId(scenario.activeJobId || "");
                        setBudgetInput(scenario.budget || 0);
                        setIsRecalculateScenarioDisabled(false);
                        setRecommendationsModalOpen(false);
                        if (callback) {
                            callback(scenario);
                        }
                        setNoChangeJobList(response.allNoChangeJobs);
                        getLogsDetails(response.jobs, scenario.forecastTimeframe);
                    }
                });
        }
    }, []);

    let exit = false;
    const continuousPromise = (promise: { (): Promise<void>; (): Promise<any> }, interval: number) => {
        const execute = () => promise().finally(waitAndExecute);
        const waitAndExecute = () => {
            if (exit) {
                return;
            }
            setTimeout(execute, interval);
        };
        execute();
    };

    const pollJobStatus = (jobId: string, orgId: string) => {
        if (jobId === "" || orgId === "") {
            return;
        }

        setIsPollingAPi(true);
        continuousPromise(() => {
            return pollingScenarioJob({ jobId, orgId })
                .unwrap()
                .then((res) => {
                    if (res.data.attributes.status === "completed") {
                        analyticsService.logEvent("Outcome Optimization Request Received", {
                            Page: getPathForAnalytics(location.pathname),
                        });
                        getCurrentScenario(() => {
                            setIsPollingAPi(false);
                        });
                        exit = true;
                    }
                })
                .catch((error) => {
                    setIsPollingAPi(false);
                    exit = true;
                    console.log(error);
                });
        }, 3000);
    };

    const fetchScenarioWithJob = useCallback(() => {
        if (scenarioId) {
            setRecommendationsModalOpen(false);
            getScenarioById({ orgId: currentOrgId, scenarioId })
                .unwrap()
                .then((response) => {
                    if (response?.scenario) {
                        const scenario = response.scenario;
                        setSelectedScenario(scenario);
                        setCurrentJobId(scenario.activeJobId || "");
                        setOriginalJobId(scenario.activeJobId || "");
                        setBudgetInput(scenario.budget || 0);
                        if (Object.keys(scenario.job || {}).length > 0 && scenario.jobStatus !== "completed") {
                            const jobId = scenario?.job?.jobId || "";
                            pollJobStatus(jobId, currentOrgId);
                        }
                        if (
                            scenario.jobStatus === "completed" &&
                            Object.keys(scenario.noChangeJob || {}).length === 0
                        ) {
                            setCompareTo(Views.LastDays);
                        }
                        setNoChangeJobList(response.allNoChangeJobs);
                        getLogsDetails(response.jobs, scenario.forecastTimeframe);
                    }
                })
                .catch((e) => {
                    switch (e.status) {
                        case 404:
                            setScenarioNotFound(true);
                            break;
                        case 403:
                            setScenarioPrivate(true);
                            break;
                    }
                });
        }
    }, []);

    useEffect(() => {
        // call campaigns api first then scenario details
        getDimCampaign({
            orgId: currentOrgId,
        }).then(() => {
            fetchScenarioWithJob();
        });

        return () => {
            exit = true;
        };
    }, []);

    useEffect(() => {
        // get performance campaigns for selected scenario timeframe
        if (
            supportedDataSources.length &&
            !isPerformanceDataFetched &&
            selectedScenario &&
            selectedScenario.createdAt &&
            selectedScenario.scenarioCampaigns
        ) {
            const campaignIds = selectedScenario.scenarioCampaigns.map((sc) => sc.campaignId);
            getPerformancePage({
                orgId: currentOrgId,
                data: {
                    ...getReportingDateObj(selectedScenario.forecastTimeframe, selectedScenario.createdAt),
                    connectors: getPaidDataSources(supportedDataSources),
                    campaign_id: campaignIds,
                },
            })
                .unwrap()
                .then((res) => {
                    setPerformanceData(res.data || []);
                    setIsPerformanceDataFetched(true);
                });
        }
    }, [
        compareTo,
        selectedScenario?.forecastTimeframe,
        selectedScenario?.scenarioCampaigns,
        isRecommendationsModalOpen,
        supportedDataSources,
    ]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (selectedScenario?.budget !== budgetInput) {
                analyticsService.logEvent("Outcome Optimization Input Changed", {
                    Budget: budgetInput,
                });
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [budgetInput]);

    const recommendationsModalToggle = useCallback(() => {
        if (!isRecommendationsModalOpen) {
            analyticsService.logEvent("Outcome Optimization Customize Clicked", {
                Page: getPathForAnalytics(location.pathname),
            });
        }
        setRecommendationsModalOpen((recomendationsModal) => !recomendationsModal);
    }, []);

    const onFinalEditSubmit = async (values: IScenario, runForecastOnly: boolean = false) => {
        if (values.id) {
            setIsRecalculateScenarioDisabled(true);
            editScenario({
                body: { ...values, runForecastOnly },
                orgId: currentOrgId,
                scenarioId: values.id.toString(),
            })
                .unwrap()
                .then(() => {
                    getCurrentScenario((cbScenario: IScenario) => {
                        if (Object.keys(cbScenario.job || {}).length > 0 && cbScenario.jobStatus !== "completed") {
                            const jobId = cbScenario?.job?.jobId || "";
                            pollJobStatus(jobId, currentOrgId);
                        }
                    });
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    setIsRecalculateScenarioDisabled(false);
                });
        }
    };

    const saveCustomizationChanges = (updatedCampaignList: IScenarioCampaignBody[], allocationSpendTotal: number) => {
        // bulk_update -> edit scenario with updated budget and run_forecast_only:true
        if (!updatedCampaignList.length) {
            setRecommendationsModalOpen(false);
            return;
        }
        if (scenarioId && selectedScenario) {
            editScenarioCampaigns({
                orgId: currentOrgId,
                scenarioId,
                body: updatedCampaignList,
            }).then(() => {
                setRecommendationsModalOpen(false);
                onFinalEditSubmit({ ...selectedScenario, budget: allocationSpendTotal }, true);
            });
        }
    };

    const reallocateRemainingBudget = (updatedCampaignList: IScenarioCampaignBody[]) => {
        // bulk_update -> optimize_allocation
        if (!updatedCampaignList.length) {
            setRecommendationsModalOpen(false);
            return;
        }
        if (scenarioId) {
            editScenarioCampaigns({
                orgId: currentOrgId,
                scenarioId,
                body: updatedCampaignList,
            }).then(() => {
                setRecommendationsModalOpen(false);
                optimizeScenarioAllocation({
                    orgId: currentOrgId,
                    scenarioId,
                }).then(() => {
                    fetchScenarioWithJob();
                });
            });
        }
    };

    const handleBack = () => {
        analyticsService.logEvent("Back to Scenario Set Up Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${scenarioId}/edit`);
    };

    const handleExit = () => {
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}`);
    };

    const handleSaveScenario = () => {
        analyticsService.logEvent("Save Optimization Scenario Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        analyticsService.logEvent("Outcome Optimization Saved", {
            Page: getPathForAnalytics(location.pathname),
        });
        if (selectedScenario?.id) {
            const { id, isHidden } = selectedScenario;

            // Handle revert if not the latest scenario
            if (!isLastestScenario) {
                revertPastJob({
                    orgId: currentOrgId,
                    scenarioId: id,
                    jobId: currentJobId,
                }).then(handleExit);
            }
            // Handle editing if the scenario is hidden
            else if (isHidden) {
                editScenario({
                    orgId: currentOrgId,
                    body: { ...selectedScenario, isHidden: false },
                    scenarioId: id,
                }).then(handleExit);
            }
            // Handle case when no changes are needed
            else {
                handleExit();
            }
        } else {
            handleExit();
        }
    };

    const handleAlert = () => {
        setIsAllocationError(false);
    };

    const handleModelingRetry = () => {
        if (scenarioId) {
            optimizeScenarioAllocation({
                orgId: currentOrgId,
                scenarioId,
            }).then(() => {
                fetchScenarioWithJob();
            });
        }
    };

    const handleJobWithError = useCallback((job: IJob) => {
        const shouldRetryForecast = job.jobName === FORECAST_JOB && !job.metadata.forecastModelSuccess;
        const shouldRetryOptimization =
            job.jobName === OPTIMIZATION_JOB &&
            !job.metadata.forecastModelSuccess &&
            !job.metadata.allocationModelSuccess;

        if (shouldRetryForecast || shouldRetryOptimization) {
            const updatedScenario = selectedScenario
                ? {
                      ...selectedScenario,
                      scenarioCampaigns: [],
                      campaignData: [],
                      job: (({ msg, ...rest }) => rest)(job),
                  }
                : null;

            setCurrentJobId(job.jobId);
            setHistoryJobError(
                job.metadata?.forecastModelOutput?.error || job.metadata?.allocationModelOutput?.error || "",
            );
            setSelectedScenario(updatedScenario);
            dispatch(setCurrentlyViewingScenario(updatedScenario));
            return true;
        }
        return false;
    }, []);

    const goToJob = useCallback(
        (job: any) => {
            const noChangeJobId = job.metadata?.scenario?.noChangeJobId || "";
            const noChangeJob = noChangeJobList.find((job) => job.jobId === noChangeJobId);

            if (handleJobWithError(job)) {
                return; // Exit function early if conditions were met
            }

            if (selectedScenario && selectedScenario.scenarioCampaigns && noChangeJob) {
                // this will add campaignName from dim campaigns data with matching campaignId
                const campaignsWithName = job.campaigns.map((campaign: { campaignId: string }) => {
                    const foundCampaign = allCampaignData?.find(
                        (campaignData) => campaignData.campaignId === campaign.campaignId,
                    );
                    return foundCampaign ? { ...campaign, campaignName: foundCampaign.campaignName } : campaign;
                });

                const updatedScenario = {
                    ...job.metadata.scenario,
                    noChangeJob,
                    scenarioCampaigns: campaignsWithName,
                    campaignData: job.campaigns.map((c: { channel: string; campaignId: string }) => ({
                        channel: c.channel,
                        campaignId: c.campaignId,
                    })),
                };

                setHistoryJobError("");
                setCurrentJobId(job.jobId);
                setBudgetInput(job.metadata?.scenario?.budget || 0);
                setSelectedScenario(updatedScenario as unknown as IScenario);
                dispatch(setCurrentlyViewingScenario(updatedScenario));
            }
        },
        [noChangeJobList],
    );

    const handleMetricRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMetricRateValue((event.target as HTMLInputElement).value as MetricRate);
    };
    const forecastMultipler =
        metricRate === MetricRate.Total && selectedScenario ? selectedScenario.forecastTimeframe : 1;

    // used noChangeJob data instead of performance, noChangeJob data in avg daily and used performance only for get channel and campaign details
    const campaignDataWithNoChange =
        selectedScenario && selectedScenario.scenarioCampaigns
            ? getPerformanceDataWithAllocationData(
                  selectedScenario.scenarioCampaigns,
                  selectedScenario.campaignData,
                  forecastMultipler,
                  getPerformanceDataForNoChange(
                      selectedScenario.scenarioCampaigns,
                      forecastMultipler,
                      supportedDataSources,
                      selectedScenario?.noChangeJob,
                  ),
              )
            : [];
    const isTotalView = selectedScenario ? forecastMultipler === selectedScenario.forecastTimeframe : false;

    // used performance data for last days and data not in avg daily
    const campaignDataWithLastDays =
        selectedScenario && selectedScenario.scenarioCampaigns
            ? getPerformanceDataWithAllocationData(
                  selectedScenario.scenarioCampaigns,
                  selectedScenario.campaignData,
                  forecastMultipler,
                  performanceData.map((pd) => {
                      const spend = isTotalView ? pd.spend || 0 : (pd.spend || 0) / selectedScenario.forecastTimeframe;
                      const trueRevenue = isTotalView
                          ? pd.trueRevenue || 0
                          : (pd.trueRevenue || 0) / selectedScenario.forecastTimeframe;
                      const trueRoas = calculateROAS(spend, trueRevenue);
                      return {
                          campaignId: pd.campaignId || "",
                          campaignName: pd.campaignName || pd.campaignId || "",
                          connectorName: pd.connectorName || "",
                          channelName: pd.channelName || "",
                          spend,
                          trueRevenue,
                          trueRoas,
                          tacticId: pd.tacticId,
                          lastDaysSpend: pd.spend,
                          newCustomers: pd.newCustomers || 0,
                          cac: pd.cac || 0,
                      };
                  }),
              )
            : [];

    const campaignDataBasedOnCompareTo =
        compareTo === Views.NoChange ? campaignDataWithNoChange : campaignDataWithLastDays;

    const actualBudget = useMemo(
        () =>
            compareTo === Views.NoChange
                ? selectedScenario?.noChangeJob?.campaigns?.reduce(
                      (a, sc) => a + sc.budgetAllocated * selectedScenario.forecastTimeframe,
                      0,
                  ) || 0
                : performanceData?.reduce((a, sc) => a + (sc.spend || 0), 0),
        [compareTo, selectedScenario, performanceData],
    );

    const campaignNameDict: IDictionary = {};
    const jobError: string =
        getReadableJobError(
            store.getState(),
            campaignDataBasedOnCompareTo.reduce((a, c) => {
                if (!c.campaignId) {
                    return a;
                }
                a[c.campaignId] = c.campaignName;
                return a;
            }, campaignNameDict),
        ) || historyJobError;

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "#f5f5f5",
                },
            }}
            fullScreen
            open={true}
            onClose={handleExit}
            TransitionComponent={TransitionComponent}
            disableEscapeKeyDown
        >
            <ScenaioAppBar heading="Optimization Outcome" isCloseIcon={false} />
            {isSingleScenarioLoading || isCampaignFetching ? (
                <Loader />
            ) : isScenarioPrivate ? (
                <Stack gap={2} alignContent="center" height="100%" justifyContent="center">
                    <Message
                        title="This is a private scenario"
                        subtitle="You do not have sufficient permissions to access this scenario."
                        icon={underConstruction}
                    />
                    <Button sx={{ width: "max-content", alignSelf: "center" }} variant="outlined" onClick={handleExit}>
                        Back to optimization
                    </Button>
                </Stack>
            ) : (
                selectedScenario && (
                    <Fragment>
                        <Stack direction="row">
                            <LeftDrawer
                                selectedScenario={selectedScenario}
                                setSelectedScenario={setSelectedScenario}
                                isRecalculateScenarioDisabled={isRecalculateScenarioDisabled}
                                budgetInput={budgetInput}
                                setBudgetInput={setBudgetInput}
                                onFinalEditSubmit={onFinalEditSubmit}
                                activeHistoryComponent={
                                    <AllocationHistory
                                        scenarioEventLogs={scenarioEventLogs}
                                        goToJob={goToJob}
                                        currentJobId={currentJobId}
                                    />
                                }
                                actualBudget={actualBudget}
                                jobError={jobError}
                                isPollingAPi={isPollingAPi}
                            />
                            <OptimizationOutcomeContext.Provider
                                value={{ metricRate, handleMetricRateChange, setSelectedScenario, selectedScenario }}
                            >
                                <OutcomeContent
                                    isAllocationError={isAllocationError}
                                    selectedScenario={selectedScenario}
                                    handleAlert={handleAlert}
                                    handleModelingRetry={handleModelingRetry}
                                    isPollingAPi={isPollingAPi}
                                    isRecalculateScenarioDisabled={isRecalculateScenarioDisabled}
                                    isPerformanceLoading={isPerformanceLoading}
                                    compareTo={compareTo}
                                    setCompareTo={setCompareTo}
                                    compareToOption={compareToOption}
                                    recommendationsModalToggle={recommendationsModalToggle}
                                    isRecommendationsModalOpen={isRecommendationsModalOpen}
                                    saveCustomizationChanges={saveCustomizationChanges}
                                    reallocateRemainingBudget={reallocateRemainingBudget}
                                    isBulkLoading={isBulkLoading}
                                    historyJobError={historyJobError}
                                    campaignDataWithNoChange={campaignDataWithNoChange}
                                    campaignDataWithLastDays={campaignDataWithLastDays}
                                    last30DaysPerformance={last30DaysPerformance?.data || []}
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                />
                            </OptimizationOutcomeContext.Provider>
                        </Stack>
                    </Fragment>
                )
            )}

            {isScenarioNotFound && (
                <Stack alignContent="center" height="100%" justifyContent="center">
                    <Message
                        title={`We couldn't find Scenario with id=${scenarioId}`}
                        subtitle=""
                        icon={noConnectors}
                        filters={[]}
                    />
                </Stack>
            )}

            {selectedScenario && !isScenarioPrivate && (
                <ScenarioFooter
                    isDisplayBackBtn={isScenarioEditable}
                    handleBackClick={handleBack}
                    isBtnDisabled={isPerformanceLoading}
                    handleCancelClick={handleExit}
                    onFinalSubmit={handleSaveScenario}
                    submitBtnTitle={isLastestScenario ? "Save Optimization Scenario" : "Save Optimization Version"}
                    cancelBtnTitle={selectedScenario.isHidden ? "Close without saving" : "Close"}
                />
            )}
        </Dialog>
    );
};
