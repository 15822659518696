import React, { FC, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { createStaticRanges, DateRangePicker, Range, RangeKeyDict, StaticRange } from "react-date-range";
import { endOfMonth, format, isValid, startOfDay, startOfMonth, subDays } from "date-fns";
import { Switch, Theme, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Button } from "@prescientai/component-library";

import { IDateRange } from "src/interfaces/dashboard/trend";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "src/services/utils";
import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { DATE_RANGE_COLORS, useStylesForDateRange } from "./dateRangeStyle";
import "./reactDateRangeStyles.css";
import {
    DefaultDateRangeInText,
    IDateRanges,
    getPastPeriod,
    getRangeFormattedTitle,
    getSelectedDateRange,
    getSelectedPreset,
    isCorrectPreset,
    isNewFirstOfMonth,
    sideBarDateOptions,
    wholeMonthSelected,
} from "./reactDateRangeUtils";
import { selectDefaultLastReportedDate } from "src/reduxState/slices/organizationSlice";
import { useSelector } from "react-redux";

interface IProps {
    minDate?: Date | undefined;
    compareView: boolean;
    applyRangeHandler: (selectedRange: any, preset: DefaultDateRangeInText) => void;
    startRange: {
        selection: IDateRange;
        compare?: IDateRange;
    };
    isDisabled?: boolean;
    defaultComapreSwitch?: boolean;
    setCompareMode?: React.Dispatch<React.SetStateAction<boolean>>;
    compareMode?: boolean;
    chartTitle: string;
    isAlwaysOnCompareMode?: boolean;
    isUpgraded?: boolean;
    isTitleInOneLine?: boolean;
}

const useStyles = (theme: Theme) => ({
    dateRangeTooltip: css({
        zIndex: theme.zIndex.appBar - 1,
    }),
});

const SimpleReactDateRange: FC<IProps> = ({
    minDate,
    compareView,
    applyRangeHandler,
    startRange,
    isDisabled = false,
    compareMode = false,
    setCompareMode,
    chartTitle,
    isAlwaysOnCompareMode = false,
    isUpgraded = false,
    isTitleInOneLine = false,
}) => {
    const classes = { ...useStylesForDateRange(useTheme(), true, isUpgraded), ...useStyles(useTheme()) };
    const childRef = React.useRef<React.ElementRef<typeof LightMenu>>(null);
    const analyticsService = useAnalyticsService();
    const defaultLastReportedDate = useSelector(selectDefaultLastReportedDate);

    const datePickerRef = useRef(null);
    const buttonStackRef = useRef(null);

    const [isDatePickerMenuOpen, setDatePickerMenu] = useState<boolean>(false);
    const [compareSwitch, setCompareSwitch] = useState(compareMode);

    const [state, setState] = useState<IDateRanges>({
        selection: { ...startRange.selection, key: "selection" },
        compare: {
            ...getPastPeriod([startRange.selection], true)[0],
            key: "compare",
        },
    });

    const sideBar = sideBarDateOptions(state, compareSwitch, compareView || false, defaultLastReportedDate);
    const staticRanges = [...createStaticRanges(sideBar as StaticRange[])];
    let timeout: ReturnType<typeof setTimeout>;

    useEffect(() => {
        timeout = setTimeout(() => {
            const rdrStaticRangeSelected = document.querySelectorAll(".rdrStaticRangeSelected");
            if (rdrStaticRangeSelected.length) {
                if (rdrStaticRangeSelected.length > 1) {
                    const prevNode = rdrStaticRangeSelected[rdrStaticRangeSelected.length - 1] as HTMLElement;
                    const textElements = prevNode.getElementsByTagName("span");
                    prevNode.style.color = DATE_RANGE_COLORS.compare;
                    prevNode.style.boxShadow = "inset -4px 0px 0px rgb(255, 214, 57) !important";

                    if (textElements.length) {
                        textElements[0].style.color = "#000000";
                    }
                }
                if (rdrStaticRangeSelected && rdrStaticRangeSelected[0]) {
                    const currNode = rdrStaticRangeSelected[0] as HTMLElement;
                    currNode.style.color = DATE_RANGE_COLORS.selection;
                }
            }
            const wrapperDateDisplayClass = document.getElementsByClassName("rdrDateDisplayWrapper");
            if (wrapperDateDisplayClass.length && wrapperDateDisplayClass[0]) {
                if (compareSwitch && compareView) {
                    wrapperDateDisplayClass[0].classList.add("extraText");
                } else {
                    wrapperDateDisplayClass[0].classList.remove("extraText");
                }
            }
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [state, compareSwitch]);

    const changeCustomRange = (item: IDateRanges) => {
        if (item.selection.startDate && item.selection.endDate) {
            const presetSelector = getSelectedPreset(item.selection, sideBar);
            analyticsService.logEvent("Dates Filtered", {
                Chart: chartTitle,
                Page: getPathForAnalytics(location.pathname),
                "End date": format(item.selection.startDate, "yyyy-MM-dd"),
                "Start date": format(item.selection.endDate, "yyyy-MM-dd"),
                "Preset selected": presetSelector,
            });

            applyRangeHandler(item, presetSelector);
        }
    };

    const closeDatePicker = () => {
        if (childRef.current) {
            childRef.current.closeDialog();
            handleDatePickerOpenAction(false);
        }
    };

    const handleDatePickerOpenAction = (isOpen: boolean) => {
        setCompareSwitch(compareMode);
        setDatePickerMenu(isOpen);
        if (isOpen && startRange) {
            if (startRange.compare?.startDate && startRange.compare?.endDate) {
                setState({
                    selection: { ...startRange.selection, key: "selection" },
                    compare: {
                        startDate: new Date(startRange.compare.startDate),
                        endDate: new Date(startRange.compare.endDate),
                        key: "compare",
                    },
                });
            } else {
                setState({
                    selection: { ...startRange.selection, key: "selection" },
                    compare: {
                        ...getPastPeriod([startRange.selection], true)[0],
                        key: "compare",
                    },
                });
            }
        }
    };

    const applyDateRangeChangesToUI = () => {
        let isAllValidDates = true;
        Object.values(state).forEach((value) => {
            if (!isValid(value.startDate) || !isValid(value.endDate)) {
                isAllValidDates = false;
            }
        });
        if (isAllValidDates) {
            closeDatePicker();
            changeCustomRange(state);
            if (setCompareMode) {
                const eventName = `Performance Comparison ${compareSwitch ? "Enabled" : "Disabled"}`;
                analyticsService.logEvent(eventName, {
                    Chart: chartTitle,
                    Page: getPathForAnalytics(location.pathname),
                });
                setCompareMode(compareSwitch);
            }
        }
    };

    const handleCompareRageSwitch = () => {
        setCompareSwitch((prevSwitch) => !prevSwitch);
    };

    const renderDateRangeButtonTitle = () => {
        return (
            <Stack direction="row" alignItems="center" gap={1}>
                <DateRangeIcon />
                {compareMode && compareView ? (
                    isTitleInOneLine ? (
                        <Stack
                            direction="row"
                            gap={1}
                            rowGap={0.25}
                            justifyContent="center"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Typography>{getRangeFormattedTitle([startRange.selection])}</Typography>
                            <Typography>Vs.</Typography>
                            <Typography>
                                {startRange.compare?.startDate && getRangeFormattedTitle([startRange.compare])}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography component="div">
                            <Typography>{getRangeFormattedTitle([startRange.selection])}</Typography>
                            <Typography>
                                {startRange.compare?.startDate && getRangeFormattedTitle([startRange.compare])}
                            </Typography>
                        </Typography>
                    )
                ) : (
                    <Typography>{getRangeFormattedTitle([startRange.selection])}</Typography>
                )}
            </Stack>
        );
    };

    const renderStaticSidebar = (sidebar: StaticRange) => {
        if (sidebar.label === "Compare") {
            return (
                <label style={{ width: "100%", cursor: "pointer" }}>
                    {sidebar.label}
                    <Switch
                        disabled={isAlwaysOnCompareMode}
                        onChange={handleCompareRageSwitch}
                        size="small"
                        checked={compareSwitch}
                    />
                </label>
            );
        }
        return <>{sidebar.label}</>;
    };

    const onRangeChangeHandler = (item: RangeKeyDict) => {
        const key = Object.keys(item)[0];
        const innerKey = item[key].key;

        if (innerKey !== "compareSwitch") {
            const preset = getSelectedPreset(item[key], sideBar);
            if (wholeMonthSelected(state, key) && isNewFirstOfMonth(state, item, key) && isCorrectPreset(preset)) {
                item[key].startDate = startOfMonth(item[key].startDate!); // confirmed not to be null in if statement
                item[key].endDate =
                    preset === DefaultDateRangeInText.THIS_MONTH
                        ? subDays(new Date(), 1)
                        : startOfDay(endOfMonth(item[key].startDate!));
            }
            const updatedStateObj = getSelectedDateRange(key, innerKey || "", state, item);
            setState(updatedStateObj);

            const allInputsArr = document.querySelectorAll(".rdrDateDisplayItem input");
            let focusInput = allInputsArr[0] as HTMLElement;
            if (compareSwitch) {
                if (item[key].key === "compare" || item[key].key === "compareCustom") {
                    focusInput = allInputsArr[2] as HTMLElement;
                }
            }
            focusInput.focus();
        }
    };

    // Make the buttons sticky to the bottom of the viewport
    useEffect(() => {
        const updateFooterPosition = () => {
            if (datePickerRef.current && buttonStackRef.current) {
                const datePicker = datePickerRef.current as HTMLDivElement;
                const buttonStack = buttonStackRef.current as HTMLDivElement;
                const buttonStackHeight = buttonStack.offsetHeight;
                const viewportHeight = window.innerHeight;
                const datePickerRect = datePicker.getBoundingClientRect();
                const datePickerBottom = datePickerRect.bottom;
                const difference = datePickerBottom - viewportHeight;

                datePicker.style.paddingBottom = `${buttonStackHeight}px`;

                if (difference > 0) {
                    buttonStack.style.bottom = "0";
                    buttonStack.style.transform = `translateY(${-difference}px)`;
                } else {
                    buttonStack.style.transform = "unset";
                }
            }
        };

        const observerCallback = () => {
            if (datePickerRef.current && buttonStackRef.current) {
                const buttonStack = buttonStackRef.current as HTMLDivElement;
                buttonStack.style.transitionDuration = "0.1s";
                updateFooterPosition();
                setTimeout(() => (buttonStack.style.transitionDuration = "0s"), 100); // wait for animation to complete
            }
        };

        const observer = new MutationObserver(observerCallback);

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        window.addEventListener("scroll", updateFooterPosition);
        window.addEventListener("resize", updateFooterPosition);
        updateFooterPosition();

        return () => {
            observer.disconnect();
            window.removeEventListener("scroll", updateFooterPosition);
            window.removeEventListener("resize", updateFooterPosition);
        };
    }, [compareSwitch, isDatePickerMenuOpen]);

    return (
        <LightMenu
            id="current-date-filter-btn"
            dataCy="currentDateFilterBtn"
            title={<>{renderDateRangeButtonTitle()}</>}
            isList={false}
            placement="bottom-end"
            transformOrigin="top right"
            extraButtonClass={isDatePickerMenuOpen ? classes.openMenuBtnStyle : classes.buttonClass}
            closeCustomEndIcon={<SVGIconRenderer icon="chevronUpIcon" />}
            openCustomEndIcon={<SVGIconRenderer icon="chevronDownIcon" />}
            ref={childRef}
            onClickHandleOpen={() => handleDatePickerOpenAction(true)}
            menuCloseHandle={() => handleDatePickerOpenAction(false)}
            overFlow={false}
            menuClass={classes.dateRangeTooltip}
            isDisabled={isDisabled}
        >
            <Stack
                className="range-selector"
                ref={datePickerRef}
                sx={{ zIndex: 10, position: "relative", height: "100%" }}
            >
                <DateRangePicker
                    onChange={(item) => onRangeChangeHandler(item)}
                    renderStaticRangeLabel={(sidebar: StaticRange) => renderStaticSidebar(sidebar)}
                    ranges={compareView && compareSwitch ? [state.selection, state.compare] : [state.selection]}
                    rangeColors={[DATE_RANGE_COLORS.selection, DATE_RANGE_COLORS.compare]}
                    dateDisplayFormat="MMM dd, yyyy"
                    months={2}
                    staticRanges={staticRanges}
                    minDate={minDate ? new Date(minDate) : undefined}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    editableDateInputs
                    showMonthAndYearPickers={false}
                    moveRangeOnFirstSelection
                    retainEndDateOnFirstSelection={false}
                    showPreview={
                        getSelectedPreset(state.selection, sideBar) !== DefaultDateRangeInText.LAST_MONTH &&
                        getSelectedPreset(state.selection, sideBar) !== DefaultDateRangeInText.THIS_MONTH
                    }
                    navigatorRenderer={() => {
                        return <></>;
                    }}
                    calendarFocus="backwards"
                    startDatePlaceholder="From"
                    endDatePlaceholder="To"
                    inputRanges={[]}
                    monthDisplayFormat="MMMM yyyy"
                    maxDate={endOfMonth(new Date())}
                />
                <Stack
                    ref={buttonStackRef}
                    flexDirection="row"
                    justifyContent="end"
                    gap="15px"
                    sx={{
                        padding: "15px 20px",
                        zIndex: 20,
                        boxShadow: "inset 0px 1px 0px #bfbfbf",
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "0 0 4px 4px",
                        bottom: 0,
                        position: "absolute",
                        overflow: "hidden",
                        transitionDuration: "0.1s",
                    }}
                >
                    <Button
                        sx={{ textTransform: "inherit", color: "#000000", fontSize: "15px", padding: "7px 30px" }}
                        variant="outlined"
                        onClick={closeDatePicker}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ textTransform: "inherit", fontSize: "15px", padding: "7px 30px" }}
                        variant="contained"
                        onClick={applyDateRangeChangesToUI}
                        id="btn-apply-range"
                        data-cy="btnApplyRange"
                    >
                        Apply
                    </Button>
                </Stack>
            </Stack>
        </LightMenu>
    );
};

export default SimpleReactDateRange;
