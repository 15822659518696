import { FC, memo, useState } from "react";
import { useSelector } from "react-redux";
import {
    CircularProgress,
    Drawer,
    FormControl,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Slider,
    Typography,
    useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Theme, CSSObject } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import {
    DRAWER_WIDTH,
    getDateRangeForTimeframe,
    getTimeframeValue,
    scenarioBasisSentence,
} from "src/services/optimizationPage/optimization";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
} from "src/interfaces/entities/IScenario";
import { isScenarioEditableSelector } from "src/reduxState/slices/scenarioSlice";
import { inputSpendFormat } from "src/components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import InfoComponent from "../InfoComponent";
import { userIdSelector, userSuperAdminRoleSelector } from "src/reduxState/slices/userSlice";
import { useEditScenarioMutation } from "src/reduxState/apis/optimizationApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { calculatePercentDiff } from "src/services/utils";
import { SCENARIO_TYPE, VISIBILITY } from "src/consts/optimizationPage/optimizationPage";
import { defaultRevenueSourceSelector } from "src/reduxState/slices/connectorsSlice";
import { MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE } from "src/consts/performancePaidPage/performancePaidPage";

interface ILeftDrawer {
    selectedScenario: IScenario;
    setSelectedScenario: React.Dispatch<React.SetStateAction<IScenario | null>>;
    isRecalculateScenarioDisabled: boolean;
    budgetInput: number;
    setBudgetInput: React.Dispatch<React.SetStateAction<number>>;
    onFinalEditSubmit: (values: IScenario, runForecastOnly?: boolean) => Promise<void>;
    activeHistoryComponent: JSX.Element;
    jobError: string;
    isPollingAPi: boolean;
    actualBudget: number;
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    top: "unset",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    top: "unset",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const LeftDrawer: FC<ILeftDrawer> = memo((props) => {
    const {
        selectedScenario,
        isRecalculateScenarioDisabled,
        budgetInput,
        setBudgetInput,
        onFinalEditSubmit,
        activeHistoryComponent,
        setSelectedScenario,
        jobError,
        isPollingAPi,
        actualBudget,
    } = props;
    const { enqueueSnackbar } = useSnackbar();

    const defaultRevenue = useSelector(defaultRevenueSourceSelector);
    const revenueLabel = MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[defaultRevenue];
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const currentUerId = useSelector(userIdSelector);
    const isSuperAdmin = useSelector(userSuperAdminRoleSelector);
    const isScenarioCreatedByCurrentUser = selectedScenario?.creatorId === currentUerId;
    const isAmazonOptimization = [SCENARIO_TYPE.AMAZON_CAC, SCENARIO_TYPE.AMAZON_REVENUE].includes(
        selectedScenario.scenarioType,
    );

    const classes = optimizationStyle(useTheme());
    const analyticsService = useAnalyticsService();

    const [editScenario, { isLoading }] = useEditScenarioMutation();

    const [isDrawerOpen, setDrawerOpen] = useState(true);

    const isScenarioEditable = useSelector(isScenarioEditableSelector);
    const isBudgetChangeNotAllow = !isScenarioEditable || isRecalculateScenarioDisabled;

    const handleDrawerToggle = () => {
        setDrawerOpen((isDrawerOpen) => !isDrawerOpen);
    };

    const budgetChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/^(\d|,)*\.?\d*$/.test(inputValue) || inputValue === "") {
            const clearBudget = +inputValue.toString().replace(/,/g, "");
            setBudgetInput(clearBudget);
        }
    };

    const isBudgetChanged = selectedScenario?.budget !== budgetInput;

    const recalculateScenario = () => {
        if (selectedScenario) {
            analyticsService.logEvent("Re-Calculate Scenario clicked", {
                Budget: budgetInput,
            });
            onFinalEditSubmit({ ...selectedScenario, budget: budgetInput });
        }
    };

    const visibilityHandle = (e: any) => {
        const isPrivate = e.target.value === "true";
        if (selectedScenario && selectedScenario.id) {
            editScenario({
                body: { ...selectedScenario, isPrivate },
                orgId: currentOrgId,
                scenarioId: selectedScenario.id.toString(),
            })
                .then(() => {
                    setSelectedScenario({ ...selectedScenario, isPrivate });
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                });
        }
    };

    const formatSliderLabel = (value: number) => {
        if (isNaN(value) || isPollingAPi) {
            return <CircularProgress size={15} disableShrink sx={{ color: "white" }} />;
        }
        const percentageChange = calculatePercentDiff(budgetInput, actualBudget, false);
        return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(0)}%`;
    };

    return (
        <Drawer
            variant="permanent"
            sx={(theme: Theme) => ({
                width: DRAWER_WIDTH,
                flexShrink: 0,
                boxSizing: "border-box",
                ...(isDrawerOpen && {
                    ...openedMixin(theme),
                    "& .MuiDrawer-paper": openedMixin(theme),
                }),
                ...(!isDrawerOpen && {
                    ...closedMixin(theme),
                    "& .MuiDrawer-paper": closedMixin(theme),
                }),
            })}
            anchor="left"
            open={isDrawerOpen}
        >
            {isDrawerOpen ? (
                <>
                    <Stack
                        alignItems="end"
                        onClick={handleDrawerToggle}
                        sx={{ paddingTop: "30px", paddingRight: "10px" }}
                    >
                        <SVGIconRenderer icon="chevronsLeftIcon" />
                    </Stack>
                    <Stack
                        className={`left-col ${classes.leftSection}`}
                        gap="50px"
                        sx={{
                            width: `${DRAWER_WIDTH}px!important`,
                            paddingTop: "20px!important",
                            marginBottom: "150px",
                            minHeight: "max-content!important",
                        }}
                    >
                        <Stack gap="30px">
                            <Typography data-cy="scenarioBasisSentence" color="#070707" variant="h2">
                                {scenarioBasisSentence[selectedScenario.scenarioType]}
                            </Typography>
                            <Stack gap="15px">
                                <InfoComponent info="Name" value={selectedScenario?.name} />
                                <InfoComponent info="Description" value={selectedScenario?.description} />
                                <InfoComponent
                                    info="Forecast Timeframe"
                                    value={
                                        <span>
                                            {getTimeframeValue(selectedScenario?.forecastTimeframe)} <br />(
                                            {getDateRangeForTimeframe(selectedScenario)})
                                        </span>
                                    }
                                />
                                <InfoComponent info="Type" value="Campaign" />
                                <InfoComponent
                                    info="Visibility"
                                    value={selectedScenario.isPrivate ? VISIBILITY.private : VISIBILITY.public}
                                />
                                <InfoComponent
                                    info="Storefront"
                                    value={isAmazonOptimization ? "Amazon" : revenueLabel}
                                />
                            </Stack>
                        </Stack>

                        <Stack>
                            <Box>
                                <Typography color="text.secondary" fontSize="15px" gutterBottom>
                                    Estimated Budget
                                </Typography>
                                <OutlinedInput
                                    sx={{ width: "100%" }}
                                    disabled={isBudgetChangeNotAllow}
                                    name="budget"
                                    size="small"
                                    type="text"
                                    value={inputSpendFormat(+(budgetInput || "").toString().replace(/,/g, ""))}
                                    onChange={budgetChangeHandler}
                                    startAdornment={
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                ".MuiTypography-body1": { color: "black" },
                                            }}
                                        >
                                            $
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                ".MuiTypography-body1": { color: "black" },
                                            }}
                                        >
                                            {isBudgetChanged && !isRecalculateScenarioDisabled && (
                                                <Stack
                                                    onClick={() => {
                                                        setBudgetInput(selectedScenario.budget);
                                                    }}
                                                >
                                                    <SVGIconRenderer
                                                        icon="closeIcon"
                                                        width="20px"
                                                        height="20px"
                                                        strokeColor="rgba(0, 0, 0, 0.6)"
                                                    />
                                                </Stack>
                                            )}
                                        </InputAdornment>
                                    }
                                />
                                {jobError === "" && (
                                    <Slider
                                        sx={(theme) => ({
                                            width: "91%",
                                            padding: "10px 0",
                                            marginTop: "40px",
                                            "& .MuiSlider-valueLabel": {
                                                fontSize: 14,
                                                fontWeight: "normal",
                                                backgroundColor: isBudgetChangeNotAllow
                                                    ? "#bdbdbd"
                                                    : theme.palette.primary.main,
                                                color: "white",
                                            },
                                        })}
                                        size="small"
                                        name="budget"
                                        value={budgetInput}
                                        onChange={(e: any) => setBudgetInput(e.target.value)}
                                        aria-label="Small"
                                        min={0}
                                        max={actualBudget * 2}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={formatSliderLabel}
                                        disabled={isBudgetChangeNotAllow}
                                    />
                                )}
                            </Box>
                        </Stack>
                        {isBudgetChanged && (
                            <LoadingButton
                                className={classes.containedBtn}
                                type="submit"
                                endIcon={
                                    isRecalculateScenarioDisabled ? (
                                        <CircularProgress color={"inherit"} size={16} />
                                    ) : (
                                        <></>
                                    )
                                }
                                loading={isRecalculateScenarioDisabled}
                                disabled={isRecalculateScenarioDisabled}
                                loadingPosition="end"
                                variant="contained"
                                onClick={recalculateScenario}
                                sx={{ textWrap: "nowrap" }}
                            >
                                Re-Calculate Optimization
                            </LoadingButton>
                        )}
                        {(isSuperAdmin || isScenarioCreatedByCurrentUser) && (
                            <FormControl>
                                <Stack
                                    direction="row"
                                    gap="8px"
                                    alignItems="center"
                                    sx={{ padding: "10px 0", borderBottom: "1px solid", marginBottom: "20px" }}
                                >
                                    <Typography>Scenario Visibility</Typography>
                                </Stack>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="visibility"
                                    onChange={visibilityHandle}
                                    value={(selectedScenario.isPrivate || false).toString()}
                                    sx={{ gap: "15px" }}
                                >
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <b>{VISIBILITY.public} •</b> Viewable by anyone in your organization
                                            </>
                                        }
                                        disabled={isLoading}
                                    />
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <b>{VISIBILITY.private} •</b> Only viewable by you
                                            </>
                                        }
                                        disabled={isLoading}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                        {isScenarioEditable && activeHistoryComponent}
                    </Stack>
                </>
            ) : (
                <Stack alignSelf={"center"} sx={{ paddingTop: "30px" }} onClick={handleDrawerToggle}>
                    <SVGIconRenderer icon="chevronsRightIcon" />
                </Stack>
            )}
        </Drawer>
    );
});
