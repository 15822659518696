import React, { FC, useEffect, useMemo, useState } from "react";
import { Tab, Tabs, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";

import { PerformanceTab } from "./PerformanceTab";
import { ActivePerformanceTabs } from "../../../../enums/ActivePerfomanceTabs";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { AttributionTab } from "../AttributionTab/AttributionTab";
import { ForecastTab } from "../ForecastTab/ForecastTab";
import { useLazyGetForecastEligibilityQuery } from "../../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId } from "../../../../reduxState/slices/organizationSlice";
import { AMAZON_ADS } from "src/consts/connectors";
import { amazonRevCacOptimizerSelector } from "src/reduxState/slices/featuresSlice";

const useStyles = () => ({
    tabs: css({
        minHeight: "30px !important",
        borderRight: 0,
        "&:first-child": {
            borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "& .MuiTabs-scroller div button": {
            padding: "4px 12px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderLeft: 0,
            minHeight: "30px !important",
            fontSize: "13px !important",
            textTransform: "capitalize",
            "&.Mui-selected": {
                backgroundColor: "#616161",
                color: "white",
                border: 0,
            },
        },
    }),
    tooltip: css({
        fontSize: "14px",
    }),
});

interface IPerformanceTabHolderComponent {
    tabValue: string | ActivePerformanceTabs;
    handleTabChange: (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => void;
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    isExpandedView?: boolean;
    modalInfo?: {
        data: IMetricAttributionTableValuesTransformed;
        isMinimize: boolean;
        activeTab: ActivePerformanceTabs;
        activeMetric: string;
    };
}

export interface IForecastEligibility {
    availableModels: string[];
    sharedReasonUnavailable: string;
}

export const PerformanceTabHolderComponent: FC<IPerformanceTabHolderComponent> = ({
    tabValue,
    handleTabChange,
    performanceCampaignData,
    modalInfo,
    isExpandedView = false,
}) => {
    const { hasSecondOrderEffect, campaignId, connectorName } = performanceCampaignData;
    const isAmazonConn = connectorName === AMAZON_ADS;
    const isAttributionDisabled = !hasSecondOrderEffect || isAmazonConn;
    const classes = useStyles();
    const orgId = useSelector(selectCurrentlyViewingId);
    const isAmazonRevCacOptimizerSupported = useSelector(amazonRevCacOptimizerSelector);

    const [getForecastEligibility] = useLazyGetForecastEligibilityQuery();
    const [forecastEligibility, setForecastEligibility] = useState<IForecastEligibility>({
        availableModels: [],
        sharedReasonUnavailable: "",
    });

    useEffect(() => {
        if (campaignId) {
            getForecastEligibility({
                campaignId,
                orgId,
            })
                .unwrap()
                .then((res) => {
                    if (res) {
                        setForecastEligibility({
                            availableModels: res.availableModels,
                            sharedReasonUnavailable: res.sharedReasonsUnavailable[0] || "",
                        });
                    } else {
                        throw new Error("Unexpected response for available models");
                    }
                });
        }
    }, []);

    const isAnyForecastEligible = useMemo(() => {
        return (forecastEligibility.availableModels.length > 0)
    }, [forecastEligibility]);

    useEffect(() => {
        if (tabValue === ActivePerformanceTabs.Forecast && !isAnyForecastEligible) {
            handleTabChange({} as React.SyntheticEvent, ActivePerformanceTabs.Performance);
        }
    }, [forecastEligibility]);

    const tabChangeHandle = (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => {
        if (
            (newValue === ActivePerformanceTabs.Forecast && !isAnyForecastEligible) ||
            (newValue === ActivePerformanceTabs.Attribution && isAttributionDisabled)
        ) {
            return false;
        }
        handleTabChange(event, newValue);
    };

    return (
        <>
            <Tabs
                className={classes.tabs}
                value={tabValue}
                onChange={tabChangeHandle}
                aria-label="tabs"
                TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }}
                variant="scrollable"
                scrollButtons={false}
            >
                <Tab value="performance" id="performance-tab" label="Performance" />
                <Tab
                    value="attribution"
                    sx={
                        isAttributionDisabled
                            ? { cursor: "not-allowed", opacity: 0.4 }
                            : { cursor: "pointer", opacity: 1 }
                    }
                    id="attribution-tab"
                    label={
                        <Tooltip
                            arrow
                            title={
                                isAmazonConn
                                    ? "Amazon ads campaigns do not have halo effects"
                                    : !hasSecondOrderEffect
                                    ? "This campaign has no halo effects"
                                    : ""
                            }
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <span> Attribution</span>
                        </Tooltip>
                    }
                />
                <Tab
                    value="forecast"
                    sx={
                        !isAnyForecastEligible
                            ? { cursor: "not-allowed", opacity: 0.4 }
                            : { cursor: "pointer", opacity: 1 }
                    }
                    id="forecast-tab"
                    label={
                        <Tooltip
                            arrow
                            title={forecastEligibility.sharedReasonUnavailable}
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <span>Forecast</span>
                        </Tooltip>
                    }
                />
            </Tabs>
            {tabValue === "performance" && (
                <PerformanceTab isResponsive={isExpandedView} performanceCampaignData={performanceCampaignData} />
            )}
            {tabValue === "attribution" && (
                <AttributionTab modalInfo={modalInfo} performanceCampaignData={performanceCampaignData} />
            )}
            {tabValue === "forecast" && (
                <ForecastTab
                    performanceCampaignData={performanceCampaignData}
                    forecastEligibility={forecastEligibility}
                />
            )}
        </>
    );
};
