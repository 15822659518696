import { css } from "@emotion/css";
import {
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";

import { useState } from "react";
import { IForecastTableCell, IForecastTableRow } from "../../../../consts/performancePaidPage/performancePaidPage";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { useStyles } from "../PerformanceTab/PercentageTable";

interface IForecastTable {
    tableRows: IForecastTableRow[];
    tableHeader: IForecastTableCell[];
    isLoading: boolean;
    changedForecastInput: string;
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    activationForecastEvent: (selectedForecastKey: string) => void;
}
const useExtraStyles = () => ({
    actionCell: css({
        "& button": {
            height: "16px",
            minWidth: "16px",
            width: "16px",
            padding: 0,
        },
        "& .itemsContainer": {
            textAlign: "left",
        },
    }),
});

export const ForecastTable: React.FC<IForecastTable> = ({
    tableRows,
    tableHeader,
    isLoading = false,
    performanceCampaignData,
    activationForecastEvent,
    changedForecastInput,
}) => {
    const classes = { ...useExtraStyles(), ...useStyles() };
    const staticElements = ["label"];
    const excludeInputFields = ["modeledRevenue", "roas", "confidence"];
    const [selectedForecast, setSelectedForecast] = useState("");

    const handleActionClick = (id: string) => {
        setSelectedForecast(id);
    };

    const renderElement = (element: IForecastTableCell, row: IForecastTableRow) => {
        if (isLoading && !staticElements.includes(element.id)) {
            return <Skeleton />;
        }
        if (changedForecastInput === element.id && excludeInputFields.includes(row.id)) {
            return <Skeleton />;
        }
        return <span>{row[element.id] || "-"}</span>;
    };

    return (
        <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {tableHeader.map((k) => (
                            <TableCell
                                align={k.id === "label" ? "left" : "right"}
                                key={k.id}
                                className={classes.tableHead}
                                width={k.width}
                                sx={{ padding: "8px 12px", textWrap: "nowrap" }}
                                onClick={() => handleActionClick(k.id)}
                            >
                                {k.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows.map((row) => {
                        return (
                            <TableRow key={row.label} sx={{ height: "43px" }}>
                                {tableHeader.map((element) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell}
                                            sx={{ whiteSpace: "nowrap" }}
                                            align={element.id === "label" ? "left" : "right"}
                                            key={element.id}
                                        >
                                            <Tooltip title={element.id === "label" ? row.tooltip : ""} arrow>
                                                {renderElement(element, row)}
                                            </Tooltip>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
