import React, { FC, useContext, useEffect, useState } from "react";
import {
    Typography,
    Skeleton,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    ListSubheader,
    ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { Stack } from "@mui/system";

import {
    useLazyGetAttributionDataQuery,
    useLazyGetPerformanceMetricQuery,
} from "../../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId, selectCurrentlyViewingCode } from "../../../../reduxState/slices/organizationSlice";
import { MetricChart } from "./MetricChart";
import {
    getMetricParams,
    getUpdatedChartDataForCustomChart,
    removeNegativeTrueMetrics,
    getPercentChangeInMetric,
    getSpecificGrpByData,
    getRevenueTotalData,
    getRevenueWithAmazonAndStore,
} from "../../../../services/performancePage/performancePage";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IPerformanceCampaignMetrics } from "../../../../interfaces/IPerformanceCampaignMetrics";
import { useAnalyticsService } from "../../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../../services/utils";
import {
    getAllMetricsForAttributionTab,
    getAmazonRevenueMetricConfig,
    getMetricNames,
    CUSTOM_METRIC_OPTION,
    isCACAvailableForCompany,
} from "../../../../consts/performancePaidPage/performancePaidPage";
import {
    formattedCompareDateStringSelector,
    formattedCurrentDateStringSelector,
} from "../../../../reduxState/slices/dateFilterSlice";
import { insightsModalSelector } from "../../../../reduxState/slices/insightsModalSlice";
import { ISecondOrderEffects, ISecondOrderEffectsMetrics } from "../../../../interfaces/entities/ISecondOrderEffects";
import GroupBySelection from "./GroupBySelection";
import { PerformanceContext } from "../../../../containers/CampaignPageV2/CampaignPage";
import { AMAZON_SELLING_PARTNER_REVENUE } from "src/consts/targetRevenue";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";

interface ICustomChart {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    classes: any;
}

export interface ICustomOptionsType {
    group: string;
    id: string;
    label: string;
    columnName: string;
    isGroupHeader?: boolean;
    isSecondOrder?: boolean;
}

const mapSecondOrderData = (arr: ISecondOrderEffects[]) => {
    return arr.map((obj) => ({
        metricName: obj.metricName === "SPEND" ? "SECOND_ORDER_SPEND" : obj.metricName,
        value: obj.metricValue,
        date: obj.reportedDate,
        connectorName: obj.sourceChannelName.toLowerCase(),
        aggregationFunction: "SUM",
        modelDerived: false,
    }));
};

export const CustomChart: FC<ICustomChart> = ({ performanceCampaignData, classes }) => {
    const { campaignId, connectorName, trueRevenue } = performanceCampaignData;
    const performanceContext = useContext(PerformanceContext);
    const connectors = useSelector(connectorsSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    let updatedMetricOptions = isCACAvailableForCompany(performanceContext?.defaultRevenueConnectorSource, orgCode)
        ? CUSTOM_METRIC_OPTION
        : CUSTOM_METRIC_OPTION.filter((c) => !["cac", "newCustomers"].includes(c.columnName));

    if (performanceContext?.isAmazonCampaignAvailable) {
        const g = groupBy(updatedMetricOptions, "group");
        updatedMetricOptions = [...g["Dollar($)"], ...getAmazonRevenueMetricConfig(connectors), ...g["Numeric(#)"]];
    }

    const analyticsService = useAnalyticsService();
    const currentDate = useSelector(formattedCurrentDateStringSelector);
    const pastDate = useSelector(formattedCompareDateStringSelector);
    const insightsModal = useSelector(insightsModalSelector);
    const performancePastRow = insightsModal.find((d) => d.pastData?.campaignId === performanceCampaignData.campaignId);
    const [grpBy, setGrpBy] = useState("day");

    const [
        getPerformanceMetric,
        {
            data: chartData = {
                data: [],
                percentageData: {},
                meta: { comparisonData: [] },
            } as unknown as IPerformanceCampaignMetrics,
            isFetching,
            isSuccess,
            isUninitialized,
        },
    ] = useLazyGetPerformanceMetricQuery();
    const [
        getAttributionData,
        {
            data: attributionData = {
                data: [],
                percentageData: {},
                meta: { comparisonData: [] },
            } as unknown as ISecondOrderEffectsMetrics,
            isFetching: isAttributionFetching,
            isSuccess: isAttributionSuccess,
            isUninitialized: isAttributionUninitialized,
        },
    ] = useLazyGetAttributionDataQuery();

    const currentOrgId = useSelector(selectCurrentlyViewingId);

    const performanceMetrics = updatedMetricOptions.filter((option) => !option.isSecondOrder).map((o) => o.id);
    const secondOrderMetrics = updatedMetricOptions.filter((option) => option.isSecondOrder).map((o) => o.id);

    const [customValue, setCustomValue] = useState<string[]>(["revenue", "channel_reported_revenue"]);

    const isperformanceMetrics = performanceMetrics.some((m) => customValue.includes(m));
    const isSecondOrderMetrics = secondOrderMetrics.some((m) => customValue.includes(m));

    useEffect(() => {
        if (campaignId && currentDate[0].startDate && currentDate[0].endDate) {
            const defaultMetricParams =
                `${getMetricParams(customValue.filter((val) => performanceMetrics.includes(val)))}${
                    isSecondOrderMetrics ? "&metric_names[]=spend" : ""
                }` || "metric_names[]=spend";

            if (isperformanceMetrics) {
                getPerformanceMetric({
                    orgId: currentOrgId,
                    data: {
                        campaign_id: campaignId,
                        start_date: currentDate[0].startDate,
                        end_date: currentDate[0].endDate,
                        metrics: defaultMetricParams,
                    },
                    pastDate: pastDate[0],
                });
            }

            if (isSecondOrderMetrics) {
                if (customValue.includes("baseRoas") || customValue.includes("haloEffectRoas")) {
                    getPerformanceMetric({
                        orgId: currentOrgId,
                        data: {
                            campaign_id: campaignId,
                            start_date: currentDate[0].startDate,
                            end_date: currentDate[0].endDate,
                            metrics: defaultMetricParams,
                        },
                        pastDate: pastDate[0],
                    });
                }
                getAttributionData({
                    orgId: currentOrgId,
                    campaignId,
                    startDate: currentDate[0].startDate,
                    endDate: currentDate[0].endDate,
                    pastDate: pastDate[0],
                    metrics: getMetricParams(customValue.filter((val) => secondOrderMetrics.includes(val))),
                });
            }
        }
    }, [performanceCampaignData, customValue, JSON.stringify(currentDate)]);

    let currentData = [...chartData.data, ...mapSecondOrderData(attributionData.data)];
    let pastData = [...chartData.meta.comparisonData, ...mapSecondOrderData(attributionData.meta.comparisonData)];

    if (customValue.includes(AMAZON_SELLING_PARTNER_REVENUE) || customValue.includes("store_modeled_revenue")) {
        currentData = getRevenueWithAmazonAndStore(currentData);
        pastData = getRevenueWithAmazonAndStore(pastData);
    }

    // set modeled revenue as total of default revenue and amazon revenue
    currentData = getRevenueTotalData(currentData);
    pastData = getRevenueTotalData(pastData);

    const getComparisonDataWithLegend = getUpdatedChartDataForCustomChart(
        pastData,
        customValue,
        connectorName || "",
        performancePastRow?.pastData || performanceCampaignData,
        connectors,
    );

    const metricNames = getMetricNames(connectors);

    const handleChange = (options: string[]) => {
        let selectedOptionStr;
        const selectedOptions = updatedMetricOptions.filter((o) => options.includes(o.id));
        if (Object.keys(groupBy(selectedOptions, "group")).length > 1) {
            selectedOptionStr = [options[options.length - 1]];
            setCustomValue([options[options.length - 1]]);
        } else {
            selectedOptionStr = options;
            setCustomValue(options);
        }
        analyticsService.logEvent("Performance Detail Metric Changed", {
            Page: getPathForAnalytics(window.location.pathname, window.location.search),
            "Selected metric": selectedOptionStr.map((op) => metricNames[op]).join(","),
        });
    };

    const sortedValuesByDate = [...currentData].sort((a, b) => +new Date(a.date) - +new Date(b.date));
    const sortedValuesByDatePast = [...pastData].sort((a, b) => +new Date(a.date) - +new Date(b.date));

    let getDataWithLegend = getUpdatedChartDataForCustomChart(
        currentData,
        customValue,
        connectorName || "",
        performanceCampaignData,
        connectors,
    );

    getDataWithLegend = getSpecificGrpByData(
        grpBy,
        sortedValuesByDate,
        customValue,
        connectorName || "",
        getDataWithLegend,
        getUpdatedChartDataForCustomChart,
        performanceCampaignData,
        connectors,
    );

    const legendValues = getDataWithLegend.legendValues;
    currentData = removeNegativeTrueMetrics(legendValues, currentData, trueRevenue);
    const percentageData = getPercentChangeInMetric(legendValues, getComparisonDataWithLegend.legendValues);

    const isChartDataReceived = isperformanceMetrics ? Boolean(!isFetching && !isUninitialized && isSuccess) : false;
    const isAttributionReceived = isSecondOrderMetrics
        ? Boolean(!isAttributionFetching && !isAttributionUninitialized && isAttributionSuccess)
        : false;

    currentData = getDataWithLegend.data;

    const handleGrpByChange = (grp: string) => {
        setGrpBy(grp);
    };

    const selectedSpecificMetric = () =>
        getAllMetricsForAttributionTab(connectors).find((h) =>
            updatedMetricOptions
                .filter((op) => customValue.includes(op.id))
                .map((c) => c.columnName)
                .includes(h.id),
        );

    return (
        <div className="customChart" style={{ minWidth: "512px", width: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2" display="block" gutterBottom color="text.primary" className={classes.boldText}>
                    Custom
                </Typography>
                <GroupBySelection grpBy={grpBy} handleGrpByChange={handleGrpByChange} />
            </Stack>
            <FormControl variant="standard" sx={{ mb: 2, minWidth: 210, fontSize: "18px" }}>
                <Select
                    multiple
                    value={customValue}
                    renderValue={(selected) => {
                        const labels = updatedMetricOptions.filter((s) => selected.includes(s.id)).map((s) => s.label);
                        return labels.join(", ");
                    }}
                    onChange={(e: any) => handleChange(e.target.value)}
                    id="custom-grouped-select"
                    MenuProps={{ disableScrollLock: true }}
                    sx={{
                        ".MuiSelect-select.MuiInput-input": {
                            whiteSpace: "normal",
                        },
                    }}
                >
                    {updatedMetricOptions.map((o) => {
                        return o.isGroupHeader ? (
                            <ListSubheader key={o.id}>{o.label}</ListSubheader>
                        ) : (
                            <MenuItem value={o.id} key={o.id}>
                                <Checkbox checked={customValue.indexOf(o.id) > -1} />
                                <ListItemText primary={o.label} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {isChartDataReceived || isAttributionReceived ? (
                <>
                    {currentData.length ? (
                        <MetricChart
                            chartData={currentData}
                            customValue={customValue.join(",")}
                            id={`metric_chart_${campaignId}`}
                            performanceCampaignData={performanceCampaignData}
                            legendValues={legendValues}
                            percentageData={percentageData}
                            pastLegendValues={getComparisonDataWithLegend.legendValues}
                            grpBy={grpBy}
                            metrics={getAllMetricsForAttributionTab(connectors)}
                            selectedUnit={selectedSpecificMetric()}
                        />
                    ) : (
                        <Stack sx={{ height: "228px", justifyContent: "center", alignItems: "center" }}>
                            <Typography fontSize="20px" fontWeight="700" color="text.secondary">
                                {" "}
                                No data found
                            </Typography>
                        </Stack>
                    )}
                </>
            ) : (
                <Skeleton variant="rectangular" animation="wave" height="344px" style={{ marginTop: "1rem" }} />
            )}
        </div>
    );
};
