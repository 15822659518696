import React, { useState } from "react";
import { Snackbar, Theme } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";
import { Range } from "react-date-range";
import { Button } from "@prescientai/component-library";

import { EMAIL_ATTRIBUTION_HEADERS, IHeadCell } from "../../../consts/emailPage/emailPage";
import { IEmailAttributionTableCells } from "../../../consts/emailPage/emailPage";
import { Choice } from "../../../consts/emailPage/emailPage";
import { formattedCompareDateSelector, formattedDateSelector } from "../../../reduxState/slices/dateFilterSlice";
import { IExportHead, exportCSV, prepareDataForCSV } from "../../../services/exportCSV";
import { getComparator, stableSort } from "../../../utils/sort";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { getCSVPercentageTable } from "../../../services/performancePage/performancePage";
import { getRangeFormattedTitle } from "../../DateRangeFilter/reactDateRangeUtils";
import { ExportTableData } from "../../ExportTableData/ExportTableData";
import { getTotalAmountForEmail } from "../../../services/emailPage/emailPage";
import { IEmailPercentageTableData } from "../../../interfaces/emailDetails/IEmailPercentage";
import { IEmail } from "../../../interfaces/entities/IEmail";

const useStyles = (theme: Theme) => ({
    headerButton: css({
        textTransform: "inherit",
        fontSize: "15px",
        border: 0,
        "&:hover": {
            border: 0,
            backgroundColor: "inherit",
        },
    }),
});

interface IEmailFunctionalityTools {
    selectedTableColumns?: IHeadCell[];
    tableData?: IEmailAttributionTableCells[];
    order?: Choice;
    orderBy?: string;
    isSpecialHeader?: boolean;
    compareToPast?: boolean;
    finalPercentagePerformanceData?: IEmailPercentageTableData;
    currentDateFilter?: Range[];
}

export const EmailFunctionalityTools: React.FC<IEmailFunctionalityTools> = ({
    selectedTableColumns = [],
    tableData = [],
    order = "desc",
    orderBy = "spend",
    isSpecialHeader = false,
    compareToPast = false,
    finalPercentagePerformanceData,
    currentDateFilter,
}) => {
    const classes = useStyles(useTheme());
    const formattedDates = useSelector(formattedDateSelector);
    const pastDateFilter = useSelector(formattedCompareDateSelector);

    const [openToast, setOpenToast] = useState(false);
    const [openExportMenu, setOpenExportMenu] = React.useState(false);
    const totalAmount = getTotalAmountForEmail(tableData);

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const csvFileTitle = `Email Campaigns(${getRangeFormattedTitle(formattedDates)?.replace("-", "to")})`;
    const csvComparisonFileTitle = `Email Campaigns(${
        pastDateFilter && `Compare to ${getRangeFormattedTitle(pastDateFilter)?.replace("-", "to")}`
    } - ${getRangeFormattedTitle(formattedDates)?.replace("-", "to")})`;

    const handleShareClick = () => {
        setOpenToast(true);
        navigator.clipboard.writeText(window.location.href);
    };

    const exportVisibleColumns = () => {
        const exportedColumns: IExportHead[] = [...selectedTableColumns];

        if (compareToPast && finalPercentagePerformanceData && pastDateFilter && currentDateFilter) {
            const finalPercentageDatalist: IEmailAttributionTableCells[] = getCSVPercentageTable(
                finalPercentagePerformanceData,
                order,
                orderBy,
                pastDateFilter,
                currentDateFilter,
            );
            exportCSV(csvComparisonFileTitle, prepareDataForCSV(finalPercentageDatalist, exportedColumns));
        } else {
            exportCSV(
                csvFileTitle,
                prepareDataForCSV(
                    [totalAmount].concat(stableSort(tableData, getComparator(order, orderBy))),
                    exportedColumns,
                ),
            );
        }
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        if (index === 1) {
            const exportedColumns: IExportHead[] = EMAIL_ATTRIBUTION_HEADERS.map((header) => ({
                ...header,
                hidden: false,
            }));

            if (compareToPast && finalPercentagePerformanceData && pastDateFilter && currentDateFilter) {
                const finalPercentageDatalist: IEmail[] = getCSVPercentageTable(
                    finalPercentagePerformanceData,
                    order,
                    orderBy,
                    pastDateFilter,
                    currentDateFilter,
                );
                exportCSV(
                    csvComparisonFileTitle,
                    prepareDataForCSV(finalPercentageDatalist, exportedColumns as IExportHead[]),
                );
            } else {
                exportCSV(
                    csvFileTitle,
                    prepareDataForCSV(
                        [totalAmount].concat(stableSort(tableData, getComparator(order, orderBy))),
                        exportedColumns as IExportHead[],
                    ),
                );
            }
        } else if (index === 0) {
            exportVisibleColumns();
        }
        setOpenExportMenu(false);
    };

    const handleExportToggle = () => {
        setOpenExportMenu((prevOpen) => !prevOpen);
    };

    const handleExportMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenExportMenu(false);
    };

    return (
        <>
            {!isSpecialHeader && (
                <>
                    <Button
                        title="Click to copy current url link to share with other members"
                        className={classes.headerButton}
                        onClick={handleShareClick}
                        variant="outlined"
                        color="tertiary"
                        startIcon={<SVGIconRenderer icon="linkIcon" />}
                    >
                        Copy Link
                    </Button>
                    <ExportTableData
                        handleMenuItemClick={handleMenuItemClick}
                        anchorRef={anchorRef}
                        exportVisibleColumns={exportVisibleColumns}
                        openExportMenu={openExportMenu}
                        handleExportToggle={handleExportToggle}
                        handleExportMenuClose={handleExportMenuClose}
                    />
                </>
            )}
            <Snackbar
                open={openToast}
                onClose={() => setOpenToast(false)}
                autoHideDuration={2000}
                message="Link copied!"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            />
        </>
    );
};
